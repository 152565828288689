import React from 'react';
import {Table} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './TableBoardPrices.scss';

function TableBoardPrices({prices}) {
  const { t } = useTranslation();

  const loader = <div className="loader container"><div className="animated-background" /></div>;

  const setTable = () => {
    const tbody = prices.map((value, idx) =>
      <tr key={`prices-${idx}`}>
        <td>{value.name}</td>
        <td>
          {
            value.price.Precio === 'S/C' ? 'S/C' :
              // eslint-disable-next-line no-nested-ternary
              <div className={`${parseFloat(value.price.Var) > 0 ? 'up arrow flex-center' : parseFloat(value.price.Var) < 0 ? 'down arrow flex-center' : 'equal flex-center'}`}>
                <label className="percent pr-2 m-0">{value.price.Precio}</label>
                <label className="indicator m-0">
                  <label className="icon m-0"></label>
                </label>
              </div>
          }
        </td>
        <td>
          {
            !(value.price.Var && value.price.Var.length) ? 'S/C' : value.price.Var
          }
        </td>
      </tr>
    );
    return (
      <Table className="table-agrofy is-gray">
        <thead>
          <tr>
            <th className="text-uppercase text5">{t('product')}</th>
            <th className="text-uppercase text5">{t('price')} $</th>
            <th className="text-uppercase text5">{t('variation')} $</th>
          </tr>
        </thead>
        <tbody>
          {tbody}
        </tbody>
      </Table>
    );
  };

  return (
    <div className="board-prices-section">
      {prices ? setTable() : loader}
    </div>
  );
}

export default TableBoardPrices;
