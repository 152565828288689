import React from "react";

function PlayerIcon(props) {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={20} cy={20} r={20} fill="#63A105" />
      <path
        d="M15 11.353l15.008 8.713L15 28.778V11.353z"
        fill="#63A105"
        stroke="#fff"
        strokeWidth={2}
      />
    </svg>
  )
}

export default PlayerIcon
