import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

const NewsPlayerList = (props) => {
    const player = useRef(null);
    const {playlist, onChildPlayerClick, defaultVideo, onEndedVideo} = props;
    const [videos, setVideos] = useState(playlist)
    const [currentVideoIndex, setCurrentVideoIndex] = useState(1);
    const [isReady, setIsReady] = useState(false)
    const [currentVideo, setCurrentVideo] = useState(null)
    const [isFinished, setIsFinished] = useState(false)
    useEffect(() => {
        const currentVideoData = isReady && player.current && player.current.getInternalPlayer();
        setCurrentVideo(player.current.getInternalPlayer())
        setVideos(playlist)
    }, [playlist, currentVideo, isFinished]);

    const handleEnded = (e) => {
        setCurrentVideoIndex(currentVideoIndex + 1)
        onChildPlayerClick(videos[currentVideoIndex])
        onEndedVideo(currentVideo)
        setIsFinished(!isFinished)
    }
    const playlistResult =  videos ? [...new Set(videos)] : [defaultVideo.url]; 

    return (
        <PlayerContainer>
            <ReactPlayer className="react-player"
                ref={player}
                playing={true}
                url={playlistResult}
                muted={false} 
                loop={false}
                controls 
                pip={true} 
                onEnded={e => handleEnded(e)}
                onReady={() => setIsReady(true)}
                config={{
                    youtube: {
                      playerVars: { showinfo: 1, rel: 0 }
                    }
                }}
            />
        </PlayerContainer>
    )
}

export default NewsPlayerList;

const PlayerContainer = styled.div`
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
    height: 0;
    .react-player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
`;