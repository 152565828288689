import React from "react";
import AgrofyHeader from '../atoms/AgrofyHeader/AgrofyHeader';
import { useTranslation } from "react-i18next";
import './ErrorFallback.scss';

export default function ErrorFallback({ error, hideTabs }) {
  const {t} = useTranslation();
  
  return (
    <div className="landing-error">
      {hideTabs ? null : <AgrofyHeader />}
      <header id="header" className="page-header">
        <nav className="">
          <div>
            <div className="row-section">
              <div className="logo-agrofy-wrapper-new-header">
                <a className="logo-agrofy solo-logo-index-new-svg" id='logo' href="https://news.agrofy.com.ar/">
                </a>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <section className="padding-section">
        <div className="row-section">
          <div>
            <img src="https://www.agrofy.com.ar/skin/frontend/fyo/fyo/images/error/pag-500.png" alt="Agrofy" />
          </div>
          <div className="issue">
            <h2>{t('server_issue')} <br />{t('try_again_later')}</h2>
          </div>
        </div>
      </section>
    </div>
  )
}