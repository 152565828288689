/* eslint-disable no-nested-ternary */
import React from 'react';
import {Table} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './TableOfitialPrices.scss';

function TableOfitialPrices({dataPrices}) {
  const { t } = useTranslation();
  const loader = <div className="loader container pr-0"><div className="animated-background" /></div>;

  const setTable = () => {
    const tbody = dataPrices.map((item, idx) =>
      <tr key={idx}>
        <td>{item.Producto}</td>
        <td className="sub-td">{item.Precios.map((subItem, ind) =>
          <tr className="align-left sub-item condition" key={ind}>
            <td>{subItem.tipoProducto}</td>
          </tr>
        )}</td>
        <td className="sub-td">{item.Precios.map((subItem, ind) =>
          <tr className="align-left sub-item month" key={ind}>
            <td>{subItem.Mes}</td>
          </tr>
        )}</td>
        <td className="sub-td">{item.Precios.map((subItem, ind) =>
          <tr className="align-left sub-item price" key={ind}>
            <td>
              {subItem.Moneda} {subItem.Precio}
            </td>
          </tr>
        )}</td>
        <td className="sub-td">{item.Precios.map((subItem, ind) =>
          <tr className="align-left sub-item price" key={ind}>
            <td>
              <div className={`${parseFloat(subItem.Variacion) > 0 ? 'up arrow' : parseFloat(subItem.Variacion) < 0 ? 'down arrow' : 'equal'}`}>
                <label className="percent pr-2">{subItem.Variacion}</label>
                <label className="indicator">
                  <label className="icon"></label>
                </label>
              </div>
            </td>
          </tr>
        )}</td>
      </tr>
    );
    return (
      <Table className="oficial-prices-table table-agrofy is-gray">
        <thead>
          <tr>
            <th className="align-left">{t('product')}</th>
            <th className="align-left">{t('condition')}</th>
            <th className="align-left">{t('month')}</th>
            <th className="align-left">{t('price')}</th>
            <th className="align-left">{t('variation')}</th>
          </tr>
        </thead>
        <tbody>
          {tbody}
        </tbody>
      </Table>
    );
  };

  return (
    <div className="oficial-prices-table-section">
      {dataPrices ? setTable() : loader}
    </div>
  );
}

export default TableOfitialPrices;
