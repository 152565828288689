import React, { useState, useEffect } from 'react';
import getData from '../../services/GetData';
import Calendar from '../../atoms/Calendar/Calendar';
import { AgroButton, AgroSelect } from 'agrofy-ui-components';
import { format, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab } from 'react-bootstrap';
import './FuturesAndOptionsContent.scss';
import TableFutures from '../../atoms/Tables/TableFutures/TableFutures';
import OptionsTab from '../OptionsTab/OptionsTab';
import NoFilterData from '../../atoms/NoFilterData/NoFilterData';

const FuturesAndOptionsContent = () => {
  const { t } = useTranslation();
  const loader = <div className="loader container p-0"><div className="animated-background" /></div>;
  const [showMessage, setShowMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialDate, setInitialDate] = useState();
  const [current, setCurrent] = useState({ online: null, close: null, market: 'CBOT', product: 'Soja', date: null });
  const [fieldsSearch, setFieldsSearch] = useState({ ...current })
  const [changeMarket, setChangeMarket] = useState(current)
  const [key, setKey] = useState('Online');
  const [buttonClicked, setButtonClicked] = useState(false)
  const todayDate = new Date()
  const resetTodayDateHr = todayDate.setHours(0, 0, 0, 0);
  const isTodayDate = obj => obj && obj.date && obj.date.getTime() === todayDate.getTime();

  const markets = [
    { label: 'MtR', value: 1 },
    { label: 'CBOT', value: 2 }
  ];

  const CBOTProducts = [
    { label: 'Soja', value: 'Soja' },
    { label: 'Maíz', value: 'Maiz' },
    { label: 'Trigo', value: 'Trigo' },
    { label: 'Harina De Soja', value: 'HarinaDeSoja' },
    { label: 'Aceite De Soja', value: 'AceiteDeSoja' }
  ];

  const ROFEXProducts = [
    { label: 'Soja', value: 'Soja' },
    { label: 'Maíz', value: 'Maiz' },
    { label: 'Trigo', value: 'Trigo' }
  ];

  const fetchData = (marketName, product, date) => {
    var marketID = markets.find(x => x.label === marketName).value;
    var productCode = CBOTProducts.find(x => x.label === product).value;

    const url = `${window.env.RAZZLE_ENDPOINT_COTZ_API}/api/Prices/GetClosureAndOnlineQuotes?marketID=${marketID}&productName=${productCode}&date=${date}`;

    return getData({
      url: url
    });
  };

  useEffect(() => {
    setIsLoading(true);

    fetchData('CBOT', 'Soja', null).then(resp => {
      if (resp.data) {
        const formatDate = resp.data.onlineQuote.updateDate && parse(resp.data.onlineQuote.updateDate.split('|')[0], 'dd-MM-yy', new Date());
        setInitialDate(formatDate);

        setCurrent({
          ...current,
          date: formatDate,
          online: resp.data.onlineQuote,
          close: resp.data.closureQuotes
        });

        setShowMessage(false);
      }
      else {
        setShowMessage(true);
      }

      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    setCurrent({ ...current, product: 'Soja' });
  }, [current.market]);

  const setActiveTab = () => {
    current.date.getTime() !== todayDate.getTime() ?
      setKey("Cierre") :
      setKey('Online');
  }

  const search = () => {
    setButtonClicked(true)
    setShowMessage(false);
    setIsLoading(true);
    setChangeMarket(current);
    setCurrent({ ...current, online: false, close: false });
    setFieldsSearch(current)
    const date = current.date && format(current.date, 'yyyy-MM-dd');

    fetchData(current.market, current.product, date).then(resp => {
      processResponse(resp)
    });
    setActiveTab()
  };
  const processResponse = resp => {
    const response = resp.data;
    //var element = getCloseData(response.closureQuotes);

    var hasOnlineData = response.onlineQuote != null && response.onlineQuote.data.length > 0;
    var hasClosureData = response.closureQuotes != null && response.closureQuotes.data.length > 0;

    setShowMessage(!hasOnlineData && !hasClosureData);

    setCurrent({ ...current, online: response.onlineQuote, close: response.closureQuotes });

    setIsLoading(false);
  }

  // const getCloseData = input => {
  //   var output = [];

  //   if (input != null) {
  //     input.forEach(element => {
  //       output.push(element.data[0]);
  //     });
  //   }

  //   return output;
  // };

  const getProducts = () => {
    if (current.market === 'CBOT') {
      return CBOTProducts;
    } else if (current.market === 'MtR') {
      return ROFEXProducts;
    }
  };

  const validFilters = () => {
    return current.market && current.product;
  }

  const getFilters = () => {
    markets

    return (
      <div className="mb-20 p-0 col-md-9 col-lg-8 d-flex futures-options-content__futures__search">
        <AgroSelect
          className="futuresSelect mr-15"
          value={current.market}
          label={t('market')}
          options={markets}
          onChange={(e) => setCurrent({ ...current, market: e.label })}
        />
        <AgroSelect
          className="futuresSelect mr-15"
          value={current.product ? current.product : CBOTProducts[0].label}
          placeholder={CBOTProducts[0].label}
          label={t('product')}
          options={getProducts()}
          onChange={(e) => setCurrent({ ...current, product: e.label })}
        />
        <Calendar
          parentCallback={(selected) => setCurrent({ ...current, date: selected })}
          maxDate={initialDate}
          initialDate={initialDate} />
        <AgroButton onClick={search} disabled={!validFilters()}>{t('search')}</AgroButton>
      </div>
    );
  };

  return (
    <div className="futures-options-content">
      <Tabs className="basic-tabs green pb-20" transition={false}>
        <Tab eventKey="futures" title={t('futures')}>
          <div className="futures-options-content__tab gray-tabs">
            {getFilters()}
            <div className="futures-options-content__tab__table mb-15 p-0">
              { // eslint-disable-next-line no-nested-ternary
                showMessage ? <NoFilterData /> :
                  (!isLoading) ?
                    <Tabs activeKey={key} onSelect={(key) => setKey(key)}>
                      {
                        current.online && current.online.data && current.online.data.length ?
                          <Tab key="Online" eventKey="Online" title="Online">
                            <TableFutures prices={current.online.data} nameMarket={changeMarket.market}></TableFutures>
                          </Tab> : <NoFilterData />}
                      {
                        !buttonClicked ? null :
                          isTodayDate(fieldsSearch) ? null : <Tab key="Cierre" eventKey="Cierre" title="Cierre">
                            {current && current.close.data && current.close.data.length ? <TableFutures prices={current.close.data} nameMarket={changeMarket.market}></TableFutures> : <NoFilterData />}
                          </Tab>
                      }
                    </Tabs>
                    : loader
              }
            </div>
          </div>
        </Tab>
        {/*<Tab eventKey="options" title={t('options')}>
            <OptionsTab />
          </Tab>*/}
      </Tabs>
    </div>
  );
};

export default FuturesAndOptionsContent;
