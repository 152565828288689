import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './CurrenciesResume.scss';

const CurrenciesResume = ({ prices, selectedCurrency }) => {
    const { t } = useTranslation();
    
    const loader = <div className="loader container"><div className="animated-background" /></div>;
    const pricesWithoutDolarCurrency = prices && prices[1].items && prices[1].items.filter(i => !["U$ Nación ", "U$ Min", "U$ Blue"].includes(i.name));
    const setBody = (indicator, idx) => {
        const classNames = item => item % 2 ? 'even-item' : 'odd-item';
        const indicators = indicator && indicator[idx] && indicator[idx].name === "Monedas" ?  pricesWithoutDolarCurrency : indicator[idx].items;

        return (
            <React.Fragment>
                {indicators.map((val, index) =>
                    <tr key={`row-${index}`} className={classNames(index)}>
                        <td><Link to={`/economia-politica/${indicator[idx].name.toLowerCase()}`}>{indicator[idx].name}</Link></td>
                        <td className="td-md-content">{val.name}</td>
                        <td>{val.buy}</td>
                        <td>
                            {val.sell}
                        </td>
                        <td className="td-lg-content">{
                            // eslint-disable-next-line no-nested-ternary
                            <div className={`${val.variationPercentage === "0" ? '' : val.variationPercentage.startsWith("-") ? 'down-icon arrow' : 'up-icon arrow'} tab-item d-flex var-item`}>
                                <label className="priceTable pr-2">{`${(parseFloat(val.variationPercentage.replace(',', '.')).toFixed(2)).replace(".", ",")} %`}</label>
                                <label className="indicator">
                                    <label className="icon"></label>
                                </label>
                            </div>
                        }</td>
                        <td className="td-lg-content">{val.updateDate}</td>
                    </tr>
                )}

            </React.Fragment>
        )
    }
    const setTable = () => {
        return (
            <Table className="table-agrofy">
                <thead>
                    <tr className="resume-title-items">
                        <th className="text-uppercase">{t('categoría')}</th>
                        <th className="text-uppercase">{t('nombre')}</th>
                        <th className="text-uppercase">{t('compra')}</th>
                        <th className="text-uppercase">{t('venta')}</th>
                        <th className="text-uppercase">{t('variación')}</th>
                        <th className="text-uppercase">{t('actualización')}</th>
                    </tr>
                </thead>
                <tbody>
                    {prices && selectedCurrency === "Bolsas" && setBody(prices, 0)}
                    {prices && selectedCurrency === "Monedas" && setBody(prices, 1)}
                    {prices && selectedCurrency === "Dolar" && setBody(prices, 2)}
                    {prices && selectedCurrency === "Resumen" && <React.Fragment>
                        {setBody(prices, 1)}
                        {setBody(prices, 2)}
                        {setBody(prices, 0)}
                    </React.Fragment>
                    }
                </tbody>
            </Table>
        );
    };

    return (
        <>
            <div className="resume-table-section">
                {prices ? setTable() : loader}
            </div>
        </>
    );
};

export default CurrenciesResume;
