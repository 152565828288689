import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const AgroTextButton = ({ text, parentCallback, children, elementId = null }) => {
  const clicked = (event) => {
    if (parentCallback) {
      parentCallback();
    }
  };

  return (
    <TextBtn onClick={(event) => clicked(event)} id={elementId ? elementId : null}>
      {text}
      {children}
    </TextBtn>
  );
};

AgroTextButton.propTypes = {
  text: PropTypes.string,
  parentCallback: PropTypes.func,
  children: PropTypes.node,
  elementId: PropTypes.string,
};

const TextBtn = styled.button`
  background-color: transparent;
  font-family: Open Sans,sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.2px;
  text-transform: capitalize;
  margin-right: 0;
  border: none;
  cursor: pointer;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.gray8};
  -webkit-text-decoration: none;
  text-decoration: none;
  vertical-align: middle;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.gray10};
  }
  &:active {
    color: ${({ theme }) => theme.colors.black2};
  }
`;
