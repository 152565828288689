import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

const SimplePlayer = (props) => {
    const { playlist, withCarousel, lightVideo} = props;
    const [lightStatus, setLightStatus] = useState(true);
    const [key, setKey] = useState(1)
    if(lightVideo != lightStatus){
        setLightStatus(lightVideo);
    }
    useEffect(() => {
        setKey(key + 1)
    }, [props])
    
    return (
        <PlayerContainer withCarousel={withCarousel}>
            <ReactPlayer className="react-player"
                key={key}
                url={playlist}
                playing={true}
                loop={false}
                muted={false}
                controls
                pip={true}
                light={lightStatus}
                config={{
                    youtube: {
                      playerVars: { showinfo: 1, rel: 0 }
                    }
                }}
            />
        </PlayerContainer>
    )
}

export default SimplePlayer;

const PlayerContainer = styled.div`
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
    height: 0;
    .react-player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
`;