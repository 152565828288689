import { initStore } from './store'

const configureStore = () => {
  const actions = {
    SET_CURRENT_VIDEO_DATA: (curState, videoData) => {
        return {...curState, videoData: {...videoData}}
    }
  }
  initStore(actions)
}

export default configureStore