import React from 'react';
import { useTranslation } from 'react-i18next';

const NoFilterData = () => {
  const { t } = useTranslation();
  
  return (
    <div className="no-data d-flex">
      <i className="icon-magnifier"></i>
      <div className="no-data-text">
        <h2>{t('no_data')}</h2>
        <p>{t('try_other_date')}</p>
      </div>
    </div>
  );
};

export default NoFilterData;
