import TagManager from 'react-gtm-module';

var isInitialized = false;

export const gtmInitialize = () => {
  if (!isInitialized) {
    const gtmInit = {
      gtmId: 'GTM-W64BSZD'
    };

    TagManager.initialize(gtmInit);
    isInitialized = true;
  }
};

export const manageGTMPageName = pPageName => {
  const tagManagerArgs = {
    dataLayer: {
      pageName: pPageName
    }
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const manageGTMHome = filter => {
  const tagManagerArgs = {
    dataLayer: {
      pageName: "Home News",
      filter: "Home-" + filter.join("_")
    }
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const manageGTMUserStatus = userData => {
  const hasData = userData && userData !== null;
  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === 'Brasil';

  const tagManagerArgs = {
    dataLayer: {
      isLogged: hasData && !isBrasil ? 'Si' : 'No',
      userId: hasData ? userData.id : ''
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const trackEvent = (eventCategory, eventAction, eventLabel) => {
  const gtmEvent = {
    dataLayer: {
      event: 'trackEvent',
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel
    }
  };

  TagManager.dataLayer(gtmEvent)
};

export const trackEventWithItemID = (event, contentType, itemID) => {
  const gtmEvent = {
    dataLayer: {
      event: event,
      content_type: contentType,
      item_id: itemID
    }
  };

  TagManager.dataLayer(gtmEvent)
};

export const getTagManager = () => {
  return TagManager;
};