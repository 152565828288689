import React, {useState, useEffect, useContext} from 'react';
import getData from '../../services/GetData';
import {Helmet} from 'react-helmet';
import AdhesionBanner from '../../atoms/AdhesionBanner/AdhesionBanner';
import PopUpBanner from '../../atoms/PopUpBanner/PopUpBanner';
import PaginationAgrofy from '../../atoms/PaginationAgrofy/PaginationAgrofy';
import SectionTemplate from '../../templates/sectionTemplate/SectionTemplate';
import { AgroPushNotification } from '../../components/AgroPushNotification/AgroPushNotification';
import {DFPSlotsProvider, AdSlot} from 'react-dfp';
import withSSR from '../../withSSR';
import {getBreadcrumbData, buildBreadcrumbJson} from '../../utils/breadcrumbJson';
import { trackEventWithItemID, manageGTMUserStatus } from '../../utils/GTMDataLayerFuncs';
import styled, { css } from 'styled-components';
import {
  ItwitterCircle, IinstagramCircle, IfacebookCircle, IlinkedinCircle
} from 'agrofy-ui-components';
import UserContext from '../../atoms/UserContext/UserContext';

const AuthorPage = props => {
  const serverData = props && props.serverData;
  const pathName = props ? props.location.pathname.replace(/^\/+/, '') : false;
  const [authorData, setAuthorData] = useState(serverData || undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const amount = (serverData && serverData.pages);

  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";
  
  const dfpPrefix = isBrasil ? 'seccion' : 'general';
  
  const dfpNetworkID = process.env.DFP_NETWORK_ID || typeof window !== 'undefined' && window.env.DFP_NETWORK_ID;
  
  const { mkpUserData, userLoading } = useContext(UserContext);

  const templateBanners = [
    {
      adUnit: `${dfpPrefix}_middle_1`,
      sizes: [[728, 90], [300, 250], [320, 50]]
    },
    {
      adUnit: `${dfpPrefix}_middle_2`,
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: `${dfpPrefix}_middle_3`,
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: `${dfpPrefix}_filmstrip`,
      sizes: [[300, 600]]
    }
  ];

  const authorName = authorData && authorData.authorName;

  const manageGTM = (param) => {
    trackEventWithItemID(
      "link_click",
      "author_social_profile",
      `${authorName.toLowerCase().replaceAll(" ", "-")}_${param}`
    );
  };

  const fetchData = () => {
    setIsLoading(true);

    const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
    return getData({url: `${url}/api/News/GetNewsListByAuthor?authorUrlAlias=${pathName}&page=${currentPage - 1}&limit=15`})
      .then(result => {
        setAuthorData(result.data);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    fetchData();

    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      window.onload = () => {
        setTimeout(() => {
          gtmInitialize();
        }, 3000)
      };
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (authorData || serverData) {
      const section = document.getElementById('section-template');
      setIsLoading(false);

      if (section && ((serverData !== authorData) || (currentPage !== previousPage))) {
        setPreviousPage(currentPage);
        fetchData();
        document.getElementById('section-template').scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  }, [currentPage]);

  const breadcrumbArr = authorName && `[{"${authorName}":"${pathName.replace(/^\/|\/$/g, '')}"}]`;

  const loader = <div className="loader container p-0"><div className="animated-background" style={{height: '1050px'}}/></div>;

  const renderSocialMediaIcons = (author) => {
    return (
      <>
        {author.twitterProfile ?
          <a href={author.twitterProfile} target="blank" onClick={() => manageGTM("twitter")}>
            <ItwitterCircle height='32px' width='32px' fill='gray800' />
          </a> : null
        }
        {author.instagramProfile ?
          <a href={author.instagramProfile} target="blank" onClick={() => manageGTM("instagram")}>
            <IinstagramCircle height='32px' width='32px' fill='gray800' />
          </a> : null
        }
        {author.facebookProfile ?
          <a href={author.facebookProfile} target="blank" onClick={() => manageGTM("facebook")}>
            <IfacebookCircle height='32px' width='32px' fill='gray800' />
          </a> : null
        }
        {author.linkedInProfile ?
          <a href={author.linkedInProfile} target="blank" onClick={() => manageGTM("linkedin")}>
            <IlinkedinCircle className="lnkdIcon" height='32px' width='32px' />
          </a> : null
        }
        {author.tikTokProfile ?
          <a href={author.tikTokProfile} className="tikTokIcon" onClick={() => manageGTM("tiktok")}>
            <div className="tikTok"></div>
          </a> : null
        }
      </>
    );
  };

  return (
    authorData ? 
    <div className="container px-0">
      <Helmet>
        <title>{`${authorName} | Agrofy News`}</title>
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href={pathName} />
        <meta name="description" content="" />
        <script type="application/ld+json">{buildBreadcrumbJson(getBreadcrumbData(breadcrumbArr, false))}</script>
      </Helmet>
      <AgroPushNotification location="Section" />
      <section className="dfp-banner container top-banner">
        <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
          <AdSlot adUnit={`${dfpPrefix}_header`} sizes={[[970, 90], [970, 250], [320, 80]]} />
        </DFPSlotsProvider>
      </section>
      <section id="main-section" className="d-flex flex-wrap mt-25">
        <div className="fluid-container col-lg-9 px-0">
          <section className="container px-3">
            {authorData &&
              <ColumnistDataContainer>
                <ColumnistPicture src={authorData.authorProfilePicture}/>
                <ColumnistDescription>
                  <h1 className="authorName">{authorData.authorName}</h1>
                  <p className="authorBio">{authorData.authorBio}</p>
                  <div className="authorSocials">{renderSocialMediaIcons(authorData)}</div>
                </ColumnistDescription>
              </ColumnistDataContainer>
            }
            <SectionTemplate hasVideo={false} data={authorData} bannersData={templateBanners} loading={isLoading}/>
            <div className="mb-36">
              {currentPage && amount &&
                <PaginationAgrofy
                  parentCallback={setCurrentPage}
                  currentPage={currentPage}
                  amount={amount}
                />
              }
            </div>
          </section>
        </div>
        <div className="row col-lg-3 sidebar-item px-0 dfp-banner p-0 banner-section d-none d-lg-block">
          <div className="make-me-sticky">
            <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
              <AdSlot adUnit={`${dfpPrefix}_followme`} sizes={[[260, 600]]} />
            </DFPSlotsProvider>
          </div>
        </div>
      </section>
      <AdhesionBanner adUnit={`${dfpPrefix}_adhesion`} sizes={[[320, 80]]} />
      <PopUpBanner adUnit={`${dfpPrefix}_fullscreen`} sizes={[[800, 600], [320, 480]]} />
    </div>
  : loader);
};

AuthorPage.getInitialProps = async ({match, req, res}) => {
  const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
  const param = match.url.replace(/^\/+/, '');

  try {
    const {
      data
    } = await getData({url: `${url}/api/News/GetNewsListByAuthor?authorUrlAlias=${param}&page=0&limit=15`});
    return {serverData: data};
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(AuthorPage);

const ColumnistDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  margin-bottom: 20px;
  gap: 8px;

  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoint.md}) {   
      flex-direction: row;
      gap: 24px;
    }
  `}
`;

const ColumnistPicture = styled.img`
  width: 116px;
  height: 116px;
  border-radius: 50%;
  border: 1px solid #E9ECEF;
  display: flex;
`;

const ColumnistDescription = styled.div`
  display: flex;
  flex-direction: column;

  .authorName {
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: 600;
    color: #0B0D1D;
    line-height: 32px;
    letter-spacing: -0.5px;
    text-align: left;
    margin-bottom: 8px

    ${({ theme }) => css`
      @media (min-width: ${theme.breakpoint.md}) {   
        margin-bottom: inherit;
      }
    `}
  }

  .authorBio {
    font-family: 'Open Sans',sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #495057;
    white-space: pre-wrap;
    line-height: 22px;
    letter-spacing: 0px;
    margin-bottom: 24px;
  }

  .authorSocials {
    display: flex;
    gap: 16px;

    .lnkdIcon rect {
      fill: #495057;
    }

    .tikTokIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #495057;
      height: 32px;
      width: 32px;
      border-radius: 50%;

      .tikTok {
        height: 18px;
        width: 18px;
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
      }
    }
  }
`;
