import React, {useState, useEffect} from 'react';
import getData from '../../services/GetData';
import Card from 'react-bootstrap/Card';
import PlayerIcon from '../VideoCardImg/PlayerIcon';
import './AgrofyTVVideoCard.scss';

const AgrofyTVVideoCard = props => {
  const [playlistID] = useState(props && props.playlistID);
  const [video, setVideo] = useState();
  const loader = <div className="loader container pr-0"><div className="animated-background" /></div>;

  useEffect(() => {
    const url = process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;

    const fetchData = () => {
      getData({url: `${url}/api/AgrofyTV/GetVideosListByPlaylistID?playlistID=${playlistID}&size=1`})
        .then(resp => setVideo(resp.data && resp.data != null && resp.data.videos && resp.data.videos[0]));
    };

    fetchData();
  }, []);

  return (
    <div className="video-card">
      { !(video) ? loader :
        <a href={video.urlSlug}>
          <Card className="video-card-body">
            <Card.Body>
              <div className="video-section container px-0" >
                <div className="rs-image">
                  <img className="thumbnail" src={video.imageUrl}/>
                </div>
                <PlayerIcon />
              </div>
              <div className="container video-container">
                <p className="video-description">{video.title}</p>
              </div>
            </Card.Body>
          </Card>
        </a>
      }
    </div>
  );
};

export default AgrofyTVVideoCard;
