import React from 'react'
import { Lnews, LnewsDark } from 'agrofy-ui-components';

const AgrofyLogo = (props) => {
    const { width, height } = props;

    return (
        <>
            <div className='logo-dark'>
                <LnewsDark width={width} height={height} />
            </div>
            <div className='logo-white'>
                <Lnews width={width} height={height} />
            </div>
        </>

    )
}

export default AgrofyLogo