import axios from 'axios';

const deleteData = config => {
  if (config && config.params) {
    return axios.delete(config.url, {
      params: config.params
    });
  }
  return axios.delete(config.url);
}
export default deleteData;