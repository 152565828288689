import React from 'react';
import Card from 'react-bootstrap/Card';
import {LoaderCard} from '../../components/standarCard/StandarCard';
import './VideoCard.scss';

const VideoCard = ({video}) => {
  const getIdFromLink = () => {
    const pathname = video && new URL(video.link).pathname;
    const videoId = pathname.length ? /[^/]*$/.exec(pathname)[0] : '';
    return videoId;
  };

  return (
    <div className="video-card">
      { !(video && video.id) ? <LoaderCard /> :
        <a href={`https://www.youtube.com/watch?v=${getIdFromLink()}`} target="_blank" rel="noopener noreferrer">
          <Card className="video-card-body">
            <Card.Body>
              <div className="video-section container px-0" >
                <iframe
                  title="Video"
                  width="300"
                  height="167"
                  src={video.link} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                />
              </div>
              <div className="container video-container">
                <p className="video-description">{video.body}</p>
              </div>
            </Card.Body>
          </Card>
        </a>
      }
    </div>
  );
};

export default VideoCard;

