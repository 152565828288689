import React, { useEffect, useContext } from "react";
import { AgroFooterV2, AgroReducedFooter } from "agrofy-ui-components";
import TagManager from 'react-gtm-module';
import getGeneralData from "./services/GetGeneralData";
import getGeneralDataBR from "./services/GetGeneralDataBR";
import DarkModeContext from "../../atoms/DarkModeContext/DarkModeContext";

const Footer = () => {
  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";
  const { isDarkMode, toggleMode } = useContext(DarkModeContext);

  const footerData = isBrasil
    ? getGeneralDataBR().footer
    : getGeneralData().footer;
  const reducedFooterData = isBrasil
    ? getGeneralDataBR().reducedFooter
    : getGeneralData().reducedFooter;

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]);

  return (
    <>
      <AgroFooterV2
        footerData={footerData}
        isDarkMode={isDarkMode}
        toggleMode={toggleMode}
        tagManager={(dataLayerEvent) => {
          TagManager.dataLayer(dataLayerEvent);
        }}
      />
      <AgroReducedFooter
        footerData={reducedFooterData}
        variant="reduced"
        language={isBrasil ? "pt-BR" : "es-UY"}
      />
    </>
  );
};

export default Footer;
