import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './TopBreakingNews.scss';
import ReactPlayer from 'react-player';

const TopBreakingNewsVideo = ({ data }) => {
  const [isMobile, setIsMobile] = useState();

  useEffect(() => {
    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
    setIsMobile(isMobileDevice);
  });

  const content = () => {
    return (
    <>
      <div className="media col-12 col-lg-4 pr-lg-15 p-0">
        <ReactPlayer className="react-player"
          key={data?.nid}
          url={data?.youTubeLink}
          playing={true}
          loop={true}
          muted={true}
          controls
          config={{
            youtube: {
              playerVars: { showinfo: 1, rel: 0 }
            }
          }}
        />
      </div>
      <div className="content col-12 col-lg-8">
        { 
          (!isMobile && data?.isLive) ?
          <h6 className="in-live" style={{ marginTop: "3px" }}>EN VIVO</h6> : null
        }
        {
          (!data?.isLive) ?
          <h6 className="category">{data?.newsCategory ? data?.newsCategory : "Streaming"}</h6> : null
        }
        <h2 className="title">{data && data?.title}</h2>
        <p className="bajada mb-m-20">{data && (data?.summary || data?.descripcion)}</p>
      </div>
    </>);
  };

  return (
    <div className="breaking-news mt-20 py-20">
      <section className="container">
        {
          (data?.isLive && isMobile) ?
          <h6 className="in-live">EN VIVO</h6> : null
        }
        {
          <a href={(data && data?.newsLink) || '/'} className="row m-0">
            {content()}
          </a>
        }
      </section>
    </div>
  );
};

TopBreakingNewsVideo.propTypes = {
  data: PropTypes.object
};

export default TopBreakingNewsVideo;
