/* eslint-disable no-return-assign */
/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable default-case */
/* eslint-disable quotes */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, {useState} from 'react';
import ButtonAgrofy from '../../../../atoms/ButtonAgrofy/ButtonAgrofy';
import {useTranslation} from 'react-i18next';
import data from './../../ConverterData';

const PriceConverter = () => {
  const {t} = useTranslation();
  const [priceSelected, setPriceSelected] = useState('soja');
  const [multiplier, setMultiplier] = useState(data.precios.multiplier.soja);
  const [inputValue, setImputValue] = useState();
  const [radioChecked, setRadioChecked] = useState('radio1');
  const [operator, setOperator] = useState('m');
  const [convertedValue, setConvertedValue] = useState();
  const [suffix, setSuffix] = useState();

  const handleChangePriceSelected = e => {
    const el = e.target.value;
    setPriceSelected(el);
    switch (el) {
      case 'soja':
        setMultiplier(data.precios.multiplier.soja);
        break;
      case 'trigo':
        setMultiplier(data.precios.multiplier.trigo);
        break;
      case 'maiz':
        setMultiplier(data.precios.multiplier.maiz);
        break;
      case 'aceite-soja':
        setMultiplier(data.precios.multiplier.aceiteSoja);
        break;
      case 'harina-soja':
        setMultiplier(data.precios.multiplier.harinaSoja);
        break;
    }
  };

  const handleChangeInput = e => {
    setImputValue(e.target.value);
  };

  const handleChangeRadio = (radio, op) => {
    setRadioChecked(radio);
    setOperator(op);
  };

  const getLabel = op => {
    const ldata = data.precios.labels;
    if (op === 'm') {
      return priceSelected === 'soja' ? ldata.soja.m :
        priceSelected === 'trigo' ? ldata.trigo.m :
          priceSelected === 'maiz' ? ldata.maiz.m :
            priceSelected === 'aceite-soja' ? ldata.aceiteSoja.m : ldata.harinaSoja.m;
    } return priceSelected === 'soja' ? ldata.soja.d :
      priceSelected === 'trigo' ? ldata.trigo.d :
        priceSelected === 'maiz' ? ldata.maiz.d :
          priceSelected === 'aceite-soja' ? ldata.aceiteSoja.d : ldata.harinaSoja.d;
  };

  const getSuffix = () => {
    const ldata = data.precios.suffix;
    if (operator === 'm') {
      return priceSelected === 'soja' ? ldata.soja.m :
        priceSelected === 'trigo' ? ldata.trigo.m :
          priceSelected === 'maiz' ? ldata.maiz.m :
            priceSelected === 'aceite-soja' ? ldata.aceiteSoja.m : ldata.harinaSoja.m;
    } return priceSelected === 'soja' ? ldata.soja.d :
      priceSelected === 'trigo' ? ldata.trigo.d :
        priceSelected === 'maiz' ? ldata.maiz.d :
          priceSelected === 'aceite-soja' ? ldata.aceiteSoja.d : ldata.harinaSoja.d;
  };

  const handleGetConvertion = () => {
    operator === 'm' ? setConvertedValue((inputValue * multiplier).toFixed(4)) : setConvertedValue((inputValue / multiplier).toFixed(4));
    setSuffix(getSuffix());
  };

  return (
    <div className="converter price">
      <span className="d-flex w-100 mb-10 text text1">{t('I_want_to_convert')}</span>
      <select className="select-agrofy mr-20" value={priceSelected} onChange={e => handleChangePriceSelected(e)}>
        {data.precios.options.map(item => <option key={item.value} value={item.value}>{item.name}</option>)}
      </select>
      <input value={inputValue} placeholder={t('enter_value')} type="number" onChange={e => handleChangeInput(e)} />
      <br />
      <span className="d-flex w-100 my-10 text text1">{t('from')}:</span>

      <label className="radio text">
        <input id="radio1" type="radio" name="priceGroup" className="hidden" checked={radioChecked === 'radio1'} onChange={() => handleChangeRadio('radio1', 'm')} />
        <span className="label" />{getLabel('m')}
      </label>
      <label className="radio text">
        <input id="radio2" type="radio" name="priceGroup" className="hidden" checked={radioChecked === 'radio2'} onChange={() => handleChangeRadio('radio2', 'd')} />
        <span className="label" />{getLabel('d')}
      </label>
      <br />
      <p className="w-100 d-flex mt-10 mb-20">
        {typeof convertedValue === "undefined" ? '' :
          convertedValue > 0 ? <h5 className="result open-sans title5">{convertedValue} {suffix}</h5> :
            <span className="error open-sans bold title5">{t('enter_value_greater_than_0')}</span>}
      </p>
      <ButtonAgrofy title={t('convert')} parentCallback={handleGetConvertion} classes="primary" />
      <br />
    </div>
  );
};

export default PriceConverter;
