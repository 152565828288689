import React, { useEffect, useState,useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';
import { toDate, formatDistance } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { es, pt } from 'date-fns/locale';
import getData from '../../services/GetData';
import ConditionalLink from '../ConditionalLink/ConditionalLink';
import DarkModeContext from '../DarkModeContext/DarkModeContext';
import './MostReadedNew.scss';
import { AgroExclusiveRegistered } from 'agrofy-ui-components';

const MostReadedNew = ({ data, currentNewsId, isColumn, showTitle }) => {
  const { t } = useTranslation();
  const [news, setNews] = useState();
  const { isDarkMode, toggleMode } = useContext(DarkModeContext);

  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";

  const loader = <div className="twoTabs loader container p-0">
    <div className="animated-background m-0">
      <div className="background-masker i1"></div><div className="background-masker i2"></div><div className="background-masker i3"></div><div className="background-masker i4"></div><div className="background-masker i5"></div><div className="background-masker i6"></div>
    </div>
  </div>;


  const manageGTMClick = item => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'trackEvent',
        eventCategory: 'Navigation',
        eventAction: 'Agrofy News-Las mas leidas',
        eventLabel: item
      }
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  useEffect(() => {
    const fetchData = () => {
      getData({ url: `${window.env.RAZZLE_ENDPOINT_NOTI_API}/api/News/GetMostRead?cant=4` })
        .then(resp => setNews(resp.data));
    };
    if (!data) {
      fetchData();
    } else {
      setNews(data);
    }
  }, []);


  const formatDate = dates => {
    const totalWords = dates;
    const date = totalWords.replace(/ .*/, '');
    const time = totalWords.split(" ").splice(-1)[0];
    const dateInfo = date.split('/');
    const timeInfo = time.split(':');
    const res = toDate(new Date(dateInfo[2], dateInfo[1] - 1, dateInfo[0], timeInfo[0], timeInfo[1]))
    const humanDate = formatDistance(new Date(res), new Date(), { addSuffix: true, locale: isBrasil ? pt : es })
    const textToReplace = isBrasil ? "aproximadamente" : "alrededor de";
    const resDate = humanDate.replace(textToReplace, '');
    return resDate;
  }

  const hasLink = categoryUrl => categoryUrl && categoryUrl !== null;

  const newsList = () => news.map((item, idx) =>
    <li key={item.titulo || item.title} className={currentNewsId && item.id.toString() === currentNewsId ? 'selected' : ''} onClick={() => manageGTMClick(idx + 1)}>
      {item.url && item.url.length ?
        <>
        {item.exclusive && (
        <div className="exclusive-container exclusive-space">
          {isDarkMode ? (
            <AgroExclusiveRegistered variant="dark" />
          ) : (
            <AgroExclusiveRegistered variant="light" />
          )}
        </div>
      )}
          <a href={`/${item.url}`}>
            <span className="most-read-title">{item.title}</span>
          </a>
          <div className="description">
            <span className="time">{formatDate(item.date)}  |</span>
            <ConditionalLink to={item.categoryUrl} condition={hasLink(item.categoryUrl)}>
              <span className={`category ${hasLink(item.categoryUrl) ? '' : 'categoryDisabled'}`}>{item.category}</span>
            </ConditionalLink>
          </div>
        </>
        :
        <a href={item.ruta.split('.com.ar')[1] || item.ruta.split('.com')[1]}>
          <span>{item.titulo}</span>
          <span>{item.category}</span>
        </a>
      }
    </li>
  );

  return (
    <div className={`most-readed news-list new-panel-class ${isColumn ? "panel-class-col" : ""}`}>
      {!news ? loader :
        <div>
          {showTitle && <div className='title-section'><h3 className='main-title'>{t('most_read')}</h3></div>}
          <div className='tab-section'>
            <ol>{newsList()}</ol>
          </div>
        </div>
      }
    </div>
  );
};

MostReadedNew.propTypes = {
  tagId: PropTypes.string,
  newsSize: PropTypes.string,
  data: PropTypes.array,
  currentNewsId: PropTypes.string
};

export default MostReadedNew;
