import React from 'react';
import { Lnews, LnewsDark } from 'agrofy-ui-components';
import './WatermarkLogo.scss';

const TableWatermarkLogo = () => {
  return (
    <div className="watermarkTable">
      <Lnews className="grayscale logo-white" height={"100%"} width={"100%"} />
      <LnewsDark className="grayscale logo-dark" height={"100%"} width={"100%"} />
    </div>
  );
};

export default TableWatermarkLogo;