import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { AgroIcon } from 'agrofy-ui-components';
import { Link } from 'react-router-dom';
import NoScrollBehavior from './NoScrollBehavior';

const ESCAPE_KEY_CODE = 27;

const AgroModal = ({
  children,
  closeOnOutsideClick = true,
  onDismiss = () => { },
  className,
  modalAriaLabel = 'agrofy-modal',
  showCloseBtn = true,
  disablePageScroll = false,
  title,
  closeFromParent
}) => {
  const wrapperRef = useRef(null);


  useEffect(() => {
    closeFromParent === true && onDismiss();
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (closeOnOutsideClick) {
          onDismiss();
        }
      }
    };
    const handleKeyUp = event => {
      if (event.keyCode === ESCAPE_KEY_CODE) {
        onDismiss();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [closeFromParent]);

  const closeModal = e => {
    e.preventDefault();
    onDismiss();
  };

  const getModal = () => <ModalWrapper
    aria-label={modalAriaLabel}
    className={className}
    role='dialog'
  >
    <Backdrop className="snd-cont">
      <ModalContainer >
        <ModalContent ref={wrapperRef} tabIndex={-1}>
          {
            showCloseBtn ?
              <ModalHeader>
                <TitleContainer>
                  <Link to="/tv" onClick={e => closeModal(e)}><Title>AgrofyTV</Title></Link>
                  <AgroIcon iname="arrowRight" fill="#63A105" className="arrow-icon" /><Title>{title}</Title>
                </TitleContainer>
                <CloseButton onClick={e => closeModal(e)}>
                  <AgroIcon iname="close" fill="white" width="25px" height="25px" />
                </CloseButton>
              </ModalHeader>
              : null
          }
          {children}
        </ModalContent>
      </ModalContainer>
    </Backdrop>
  </ModalWrapper>;

  return (
    <>
      {
        disablePageScroll ?
          <NoScrollBehavior>
            {getModal()}
          </NoScrollBehavior>
          : getModal()
      }
    </>
  );
};
export default AgroModal;

AgroModal.propTypes = {
  children: PropTypes.node,
  closeOnOutsideClick: PropTypes.bool,
  onDismiss: PropTypes.func,
  className: PropTypes.string,
  modalAriaLabel: PropTypes.string,
  showCloseBtn: PropTypes.bool,
  disablePageScroll: PropTypes.bool
};

const ModalContent = styled.div`
  width: 100%;
  max-width: 612px;
  /* height: 100vh; */
  background: #212529;
  box-shadow: 0px -3px 5px rgba(0,0,0,0.1);
  /* border-radius: 20px; */
  min-height: 200px;
  z-index: 99999;
  /* margin: 0px 10px; */
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
  outline: none;
`;

const ModalWrapper = styled.div`
`;

const ModalContainer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  /* height: 100%; */
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
`;

const Backdrop = styled.div`
  cursor: default;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  background: rgba(0, 0, 0, 0.4);
  /* overflow-y: scroll; */
  z-index: 99999;
`;

const CloseButton = styled.button`
  all: unset;
  cursor: pointer;
  transition: all .3s ease;
  &:hover {
    opacity: 0.8;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;
const Title = styled.h4`
    font-size: 20px;
    color: '#ffffff';
    margin: 0;
`;
const TitleContainer = styled.div`
    display: flex;
    .arrow-icon {
      margin-top: 1px;
    }
`;