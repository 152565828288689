/* eslint-disable eqeqeq */
import React, {useState, useEffect} from 'react';
import PaginationAgrofy from '../../atoms/PaginationAgrofy/PaginationAgrofy';
import TableCurrencyHistorical from '../../atoms/Tables/TableCurrencyHistorical/TableCurrencyHistorical';
import {CSVLink} from 'react-csv';
import ButtonAgrofy from '../../atoms/ButtonAgrofy/ButtonAgrofy';
import {flattened} from '../../utils/helpers';

const PAGE_AMOUNT = 20;

const CurrenciesTablePagination = ({data}) => {
  const [currentPage, setCurrentPage] = useState(1);
const [previousPage, setPreviousPage] = useState(1);
  const [amount, setAmount] = useState(null);
  const [formatData, setFormatData] = useState(false);

  useEffect(() => {
    if (!(data && data.Productos)) {
      return;
    }
    setCurrentPage(1);
    setFormatData(formatTableData());
  }, [data]);

  const formatTableData = () => {
    const newData = [];
    data.Productos.map(currency => {
      return currency.Cotizaciones.map(item => {
        return newData.push({
          date: item.Fecha,
          currency: currency.Nombre,
          buy: item.Compra,
          sell: item.Venta
          // average: item.
        });
      });
    });
    const count = Math.ceil(newData.length / PAGE_AMOUNT);
    setAmount(count);
    let init = 0;
    const arr = Array(count).fill().map(() => {
      const sub = newData.slice(init, init += PAGE_AMOUNT);
      return sub;
    });
    return arr;
  };

  const headers = [
    {label: 'Fecha', key: 'date'},
    {label: 'Moneda', key: 'currency'},
    {label: 'Compra', key: 'buy'},
    {label: 'Venta', key: 'sell'}
  ];

  return (
    <div className="currencies-table-pag-content">
      { data && data.Productos && formatData ?
        <div className="currencies-table-pag-content__table">
          <TableCurrencyHistorical data={formatData && formatData[currentPage - 1]} />
          {formatData && <CSVLink data={flattened(formatData)} headers={headers} separator={';'} filename={`series-historicas-monedas-${data.Indicador}.csv`}><ButtonAgrofy parentCallback={() => {}} title="Descargar" classes="outline-primary small open-sans d-none d-sm-inline-block csv-export"></ButtonAgrofy></CSVLink>}
          {currentPage &&
            <PaginationAgrofy
              parentCallback={setCurrentPage}
              currentPage={currentPage}
              amount={amount}
            />
          }
        </div> : null
      }
    </div>
  );
};

export default CurrenciesTablePagination;
