import React, {useState, useEffect, useContext} from 'react';
import AdhesionBanner from '../../atoms/AdhesionBanner/AdhesionBanner';
import PopUpBanner from '../../atoms/PopUpBanner/PopUpBanner';
import Breadcrumb from '../../atoms/Breadcrumb/Breadcrumb';
import {DFPSlotsProvider, AdSlot} from 'react-dfp';
import getData from '../../services/GetData';
import PaginationAgrofy from '../../atoms/PaginationAgrofy/PaginationAgrofy';
import PhysicalMarketContent from '../../organisms/PhysicalMarketContent/PhysicalMarketContent';
import SectionTemplate from '../../templates/sectionTemplate/SectionTemplate';
import { useTranslation } from 'react-i18next';
import {Helmet} from 'react-helmet-async';
import withSSR from '../../withSSR';
import {gtmInitialize, manageGTMPageName, manageGTMUserStatus} from './../../utils/GTMDataLayerFuncs';
import { getBreadcrumbData } from './../../utils/breadcrumbJson';
import { AgroPushNotification } from '../../components/AgroPushNotification/AgroPushNotification';
import UserContext from '../../atoms/UserContext/UserContext';

const PhysicalMarket = props => {
  const {t} = useTranslation();
  const serverData = props && props.serverData;
  const pathName = props ? props.location.pathname : false;
  const [updatedData, setUpdatedData] = useState(serverData || null);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const amount = (serverData && serverData.pages);
  const breadcrumb = [
    {
      title: t('grain'),
      link: '/granos'
    },
    {
      title: t('physical_market_prices'),
      link: '/granos/mercado-fisico'
    }
  ];
  const templateBanners = [
    {
      adUnit: 'precios_middle_1',
      sizes: [[728, 90], [300, 250], [320, 50]]
    },
    {
      adUnit: 'precios_middle_2',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: 'precios_middle_3',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: 'precios_filmstrip',
      sizes: [[300, 600]]
    }
  ];
  
  const dfpNetworkID = process.env.DFP_NETWORK_ID || typeof window !== 'undefined' && window.env.DFP_NETWORK_ID;

  const { mkpUserData, userLoading } = useContext(UserContext);

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      window.onload = () => {
        setTimeout(() => {
          gtmInitialize();
        }, 3000)
      };
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  // Refresh page after 10 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 600000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const fetchData = () => {
    const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
    setIsLoading(true)
    return getData({url: `${url}/api/News/GetNewsListByCategory?categoryID=31&page=${currentPage - 1}&limit=15&canonical=${pathName}`})
      .then(result => {
        setUpdatedData(result.data)
        setIsLoading(false)
      });
  };

  useEffect(() => {
    if (updatedData || serverData) {
      const section = document.getElementById('section-template');
      setIsLoading(false);
      if (section && ((serverData !== updatedData) || (currentPage !== previousPage))) {
        setPreviousPage(currentPage);
        fetchData();
        document.getElementById('section-template').scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if(window && serverData) {
      manageGTMPageName('Mercado Físico');
    }
  }, [serverData]);

  return (
    <div className="physical-market container px-0">
      <Helmet>
        <title>{serverData && serverData.metaTitle}</title>
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href={serverData && serverData.canonical} />
        <meta name="description" content={serverData && serverData.metaDescription} />
      </Helmet>
      <AgroPushNotification location="Section" />
      <div className="physical-market--page container px-0">
        <section className="dfp-banner stripped container top-banner">
          <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
            <AdSlot adUnit={'precios_header'} sizes={[[970, 90], [970, 250], [320, 80]]} />
          </DFPSlotsProvider>
        </section>
        <section id="main-section" className="physical-market--page-content d-flex flex-wrap mt-20">
          <div className="fluid-container col-lg-9 px-0">
            <section className="breadcrumb-wrapper p-0 mb-10">
              <Breadcrumb breadcrumbLinks={getBreadcrumbData(serverData && serverData.breadcrumbNav, true)} />
            </section>
            <section className="container">
              <h1>{serverData && serverData.seoTitle}</h1>
              <p>{serverData && serverData.seoText}</p>
              <PhysicalMarketContent />
              <SectionTemplate category='Granos' data={updatedData || serverData} sectionTitle={t('keep_reading')} bannersData={templateBanners} loading={isLoading}/>
              <div className="mb-36">
                {currentPage && amount &&
                  <PaginationAgrofy
                    parentCallback={setCurrentPage}
                    currentPage={currentPage}
                    amount={amount}
                  />
                }
              </div>
            </section>
          </div>
          <div className="row col-lg-3 sidebar-item px-0 dfp-banner stripped p-0 banner-section d-none d-lg-block">
            <div className="make-me-sticky">
              <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
                <AdSlot adUnit={'precios_followme'} sizes={[[260, 600]]} />
              </DFPSlotsProvider>
            </div>
          </div>
        </section>
      </div>
      <AdhesionBanner adUnit='precios_adhesion' sizes={[[320, 80]]} />
      <PopUpBanner adUnit='precios_fullscreen' sizes={[[800, 600], [320, 480]]} />
    </div>
  );
};

PhysicalMarket.getInitialProps = async ({match, req, res}) => {
  const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
  const pathName = match && match.url;
  try {
    const {
      data
    } = await getData({url: `${url}/api/News/GetNewsListByCategory?categoryID=31&page=0&limit=15&canonical=${pathName}`});
    return {serverData: data};
  } catch (e) {
    return console.error(e);
  }
};
export default withSSR(PhysicalMarket);
