export const truncateString = (str, num) => {
  const string = str;
  if (string.length >= num) {
    return `${string.slice(0, num)}...`;
  }
  return string;
};

export const splitUrl = (arr, inx) => {
  let str = '';

  for (let i = 0; i <= inx; i++) {
    str = `${arr.split('/')[i]}/`;
  }

  return str.replace(/\/$/, '');;
};

let specialTag = (result1, result2) => result1.filter(o1 => result2.some(o2 => o1.name === o2.name));

export const adUnitStrNews = (newsTags, specialTags, data ) => {
  if (specialTag(newsTags, specialTags).length > 0) {
    const tagSelected = specialTag(specialTags, newsTags)
    return tagSelected[0].str;
  } else {
    return `agrofynews_site/${data.noticia.dfp_code}_noticia`
  }
};

export const toTitleCase = (str) => {
  return str && str.replace(/(?:^|\s)\S/g, (match) => { return match.toUpperCase(); });
};

export const removeAccents = str => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const removeString = (strToRemove, str) => str.replace(strToRemove,'');

export const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const strImageWithoutParams = str => {
  const refImage = str ? /[^/]*$/.exec(str)[0] : "";
  return refImage.substr(0, refImage.indexOf('?')); 
}

export const strToLowerCase = str => str ? str.toLowerCase() : "";

export const countMatches = (str, match) => (str.match(new RegExp(match,'g')) || []).length;