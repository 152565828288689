import React from 'react';
import {Table} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './TablePhysicalMarket.scss';

const TablePhysicalMarket = ({prices}) => {
  const { t } = useTranslation();
  const loader = <div className="loader container pr-0"><div className="animated-background" /></div>;

  const setTable = () => {
    return (
      <Table className="table-agrofy is-gray">
        <thead>
          <tr>
            <th>{t('product')}</th>
            <th>{t('quality')}/{t('destination')}</th>
            <th>{t('delivery')}</th>
            <th>{t('price')}</th>
            <th>{t('comment')}</th>
          </tr>
        </thead>
        <tbody>
          { prices.map((value, index) =>
            value.Valores.map((val, idx) =>
              <tr key={`row-${index}-${idx}`}>
                {
                  // eslint-disable-next-line no-nested-ternary
                  value.Valores.length === 1 ?
                    <td>{value.Producto}</td> :
                    idx === 0 ?
                      <td rowSpan={(value.Valores.length).toString()}>{value.Producto}</td>
                      : null
                }
                <td key={`cant-${index}-${idx}`}>{val.CalidadDestino}</td>
                <td key={`entrega-${index}-${idx}`}>{val.Entrega}</td>
                <td key={`price-${index}-${idx}`}>{val.Precio}</td>
                <td key={`comm-${index}-${idx}`}>{val.Comentario || '-'}</td>
              </tr>
            )
          )}
        </tbody>
      </Table>
    );
  };

  return (
    <div className="physical-market-table-section">
      {prices ? setTable() : loader}
    </div>
  );
};

export default TablePhysicalMarket;
