import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './ButtonAgrofy.scss';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';

function ButtonAgrofy({title, parentCallback, classes, link, disabled, loading}) {
  const { t } = useTranslation();
  const handleClick = () => {
    if (parentCallback) {
      parentCallback();
    }
  };

  return (
    <Button className={`agrofy-btn ${classes}`} onClick={handleClick} disabled={disabled || false}>
      { // eslint-disable-next-line no-nested-ternary
        link ? <Link to={link} className="agrofy-btn__link">{t('{title}')}</Link> : loading ?
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          : t(title) }
    </Button>
  );
}

ButtonAgrofy.defaultProps = {
  title: 'Button',
  parentCallback: null,
  classes: 'primary',
  link: null,
  loading: false
};

ButtonAgrofy.propTypes = {
  title: PropTypes.string,
  parentCallback: PropTypes.any,
  classes: PropTypes.string,
  link: PropTypes.string,
  loading: PropTypes.any
};
export default ButtonAgrofy;
