import React, { useState, useEffect } from 'react';
import styled, {css} from 'styled-components';
import { AgroIcon } from 'agrofy-ui-components';
import { Link } from 'react-router-dom';
import NewsModal from '../../atoms/NewsModal/NewsModal';
import MainVideo, { Description, Title, Content, Date, ShareButton, sharedLineStyles } from '../../molecules/MainVideo/MainVideo';
import VideoCardImg from '../../atoms/VideoCardImg/VideoCardImg';
import { formattedDate } from '../../utils/dateUtils';
import TagManager from 'react-gtm-module';

const CategoriesModal = (props) => {
    const { onDismiss, selectedVideo, videos, category, isCategoryPage, url } = props;
    const isStreaming = category === "Streamings";
    const urlVideo = video => category === "Streamings" ? video.id : video.url
    const [currentVideoPlaying, setCurrentVideoPlaying] = useState(selectedVideo);
    const firstList = videos.filter(item => urlVideo(item) !== urlVideo(currentVideoPlaying));
    const firstListOrdered = firstList.unshift(currentVideoPlaying);
    const urlVideos = firstList.map(video =>  urlVideo(video));
    const [playList, setPlaylist] = useState(urlVideos);
    const [listQueue, setQueue] = useState(firstList);
    const [closeModal, setCloseModal] = useState(false);
    const [isIosPhone, setIsIosPhone] = useState(false);

    const manageGTM = (action,label) => {
        const tagManagerArgs = {
            dataLayer: {
            event: 'trackEvent',
            eventCategory: 'Videos',
            eventAction: `${action}`,
            eventLabel: `${label}`
            }
        };
        TagManager.dataLayer(tagManagerArgs);
    };
    
    useEffect(() => {
        setQueue(listQueue.filter(item =>  urlVideo(item) !==  urlVideo(currentVideoPlaying)));
        currentVideoPlaying === undefined && setCloseModal(true)
        const isIphone = /iPad|iPhone|iPod/.test(navigator.platform);
        setIsIosPhone(isIphone)
    }, [currentVideoPlaying])

    const handleChildClick = data => {
        setCurrentVideoPlaying(data)
        urlVideos.unshift(urlVideo(data)) //add first the selected video;
        setPlaylist(urlVideos)
    }

    const handleChildPlayerClick = e => {
        const findedVideo = videos.find(video =>  urlVideo(video) === e);
        findedVideo ? setCurrentVideoPlaying(findedVideo) : setCloseModal(true)
    }
    const shareData = {
        title: currentVideoPlaying ? currentVideoPlaying.title : '',
        text: currentVideoPlaying ? currentVideoPlaying.title : '', //podría ir algo relacionado a AgrofyNews
        url: currentVideoPlaying ?  urlVideo(currentVideoPlaying) : '',
    };
    const handleShareClick = async () => {
        manageGTM(`Share Video`,`Modal-Button`);
        try {
            await navigator.share(shareData)
        } catch (err) {
            console.error(err)
        }
    }
    const handleEndedVideo = (e) => {
        //e === null && setCloseModal(true)
    }

    return (
        <NewsModal className="news-modal" disablePageScroll={true} onDismiss={onDismiss} closeOnOutsideClick={false} title={category} closeFromParent={closeModal}>
            <MainVideo data={currentVideoPlaying} isModal videoToPlay={currentVideoPlaying} playList={playList} isStreaming={isStreaming} onEndedVideo={handleEndedVideo} onChildPlayerClick={handleChildPlayerClick} />
            {<VideoList className="videoList">
                <Description isModal>
                    <Title>{currentVideoPlaying.title}</Title>
                    <InfoContent>
                        <DateMob isLiveNews={currentVideoPlaying.isLive}>{currentVideoPlaying.isLive ? "Ahora" : formattedDate(currentVideoPlaying.publishedDate)}</DateMob>
                        {<ShareButton className="share-btn" onClick={handleShareClick}>Compartir<AgroIcon iname="share" width="16px" height="16px" fill="primary" /> </ShareButton>}
                    </InfoContent>
                </Description>

                {listQueue.map((item, index) => {
                    return <VideoCardImg key={index} data={item} isModal={true} onChildClick={handleChildClick} isCategoryPage={false} isMobile/>
                })}
                <Link to={isCategoryPage ? "/tv" : url} onClick={isCategoryPage ? '' :() => manageGTM(`Go to Category`,`${category}-Modal`)}>
                    <CategoryLink isCategoryPage={isCategoryPage} isIphone={isIosPhone}>
                        <TitleContainer>{isCategoryPage && <NewsIcon iname="arrowLeft" fill="#63A105" />}<MainTitle>{isCategoryPage ? "Volver a AgrofyTv" : "Ver más"} </MainTitle>{!isCategoryPage && <NewsIcon iname="arrowRight" fill="#63A105" />}</TitleContainer>
                    </CategoryLink>
                </Link>
            </VideoList>}
        </NewsModal>
    )
}

export default CategoriesModal;

export const MainTitle = styled.h4`
    font-size: 20px;
    color: ${props => props.theme.colors.green5};
`;
const TitleContainer = styled.div`
    display: flex;
`;
export const NewsIcon = styled(AgroIcon)`
    margin-top: 2px;
`;
export const CategoryLink = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    background: ${p => p.isCategoryPage ? "#212529" : "#FFFFFF"};
    margin-top: 24px;
    ${p => p.isIphone && css`
        min-height: 150px;
        align-items: flex-start;
        padding-top: 32px;
    `}
`;
const VideoList = styled.div`
    overflow-y: scroll;
    position: relative;
    max-height: calc(100vh - 300px); 
    margin-top: 16px;
    -webkit-overflow-scrolling: touch;
    > a:first-child > .video-img-container {
        margin-top: 0;
    }
`;

export const InfoContent = styled(Content)` 
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 0.5px solid #495057;
    padding-bottom: 8px;
`;

const DateMob = styled(Date)`
    padding-top: 0;
`;