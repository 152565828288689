/* eslint-disable max-len */
/* eslint-disable dot-notation */
import React from 'react';
import styled from 'styled-components';
import { Box, IavatarUser2, Text } from 'agrofy-ui-components';
import { useTranslation } from 'react-i18next';

export const UserNotifications = ({ parentCallback }) => {
  const marketUrl = process.env.RAZZLE_ENDPOINT_MARKET || window.env.RAZZLE_ENDPOINT_MARKET;
  const { t } = useTranslation();
  const clicked = (event) => {
    if (parentCallback) {
      parentCallback();
    }
  };
  return (
    <Notifications id='user-notifications'>
      <Header>
        <BoxN display='flex'>
          <AccountBtn
            target="_blank"
            href={`${marketUrl}/account/contact-info`}
          >
            <IavatarUserIcon width='16px' height='16px' fill='#0067a0' ml='xs' className="user-icon-msg" />
            <Text color='#0067a0' type='label-sm' pr='xxs'>
              {t('edit_profile')}
            </Text>
          </AccountBtn>
          <Btn
            onClick={(event) => clicked(event)} href="#"
          >
            <Text color='#0067a0' type='label-sm' pr='sm' pl='xxs'>
              {t('logout')}
            </Text>
          </Btn>
        </BoxN>
      </Header>
    </Notifications>
  );
}
  ;

const Notifications = styled.div`
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  position: absolute;
  z-index: 200;
  top: 40px;
  padding: 0;
  color: #999;
  font-size: 12px;
  right: -25px;

  :after {
    content: '';
    position: absolute;
    display: block;
    right: 55px;
    top: -10px;
    z-index: 99999999;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${({ theme }) => theme.colors.gray1};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: flex;
    justify-content: center;
    width: 110px;
    margin-right: 20px;
    background: #F8F9FA;

    :after {
      right: 12px;
    }
  }
`;

const BoxN = styled(Box)`
  a > span {
    white-space: nowrap;
    font-family: Open Sans,sans-serif;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    align-items: center;

    a {
      height: 38px;
      padding: 5px 0px;
    }

    .user-icon-msg {
      display: none;
    }
  }
`;

const Header = styled.div`
  background: ${({ theme }) => theme.colors.gray1};
  border-radius: 12px 12px 0px 0px;
  line-height: 35px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: auto;
  }
`;

const IavatarUserIcon = styled(IavatarUser2)`
  margin-right: 5px;
`;

const Btn = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 40px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 80, 138, 0.05);
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    span {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
`;

const AccountBtn = styled(Btn)`
  &:after {
    content: '';
    height: 16px;
    border-right: 1px solid ${({ theme }) => theme.colors.gray5};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    &:after {
      content: '';
      height: 0;
      border-right: none;
    }
  }
`;
