import React from 'react';

function AgrofyPayLogo(props) {
  return (
    <svg x="0px" y="0px" viewBox="0 0 88.9 16" height="16px" width="auto" {...props}>
      <g>
        <g>
          <rect x="0" y="0" fill="#719837" width="13.6" height="13.6"/>
          <rect x="1.3" y="1.3" fill="#FFF" width="11.1" height="0.9"/>
          <rect x="1.3" y="3.3" fill="#FFF" width="11.1" height="0.9"/>
          <rect x="1.3" y="7.4" fill="#FFF" width="11.1" height="0.9"/>
          <rect x="1.3" y="9.4" fill="#FFF" width="4.6" height="0.9"/>
          <rect x="7.8" y="9.4" fill="#FFF" width="4.6" height="0.9"/>
          <rect x="1.3" y="5.4" fill="#FFF" width="4.6" height="0.9"/>
          <rect x="7.8" y="5.4" fill="#FFF" width="4.6" height="0.9"/>
          <rect x="1.3" y="11.4" fill="#FFF" width="4.6" height="0.9"/>
          <rect x="7.8" y="11.4" fill="#FFF" width="4.6" height="0.9"/>
          <path d="M19.6,8.3l3.8,0l-1.9-4.9L19.6,8.3z M20.2,1.2l2.6,0l4.2,11l-2.3,0L24,10L19,10l-0.8,2.2l-2.3,0L20.2,1.2z"/>
          <path d="M46.7,10.4c1.4,0,2.4-1,2.4-2.4c0-1.4-0.9-2.4-2.4-2.4c-1.4,0-2.3,1.1-2.3,2.4C44.4,9.3,45.4,10.4,46.7,10.4 M46.7,3.5
            c2.6,0,4.5,1.9,4.5,4.5c0,2.5-1.9,4.4-4.5,4.4c-2.6,0-4.5-1.9-4.5-4.4C42.3,5.4,44.1,3.5,46.7,3.5"/>
          <path d="M56.4,2.6c0.5,0,1.1,0.2,1.4,0.5V0.9c-0.5-0.2-1-0.3-1.7-0.3c-2,0-2.9,1.4-2.9,3v0.6h-1.5v1.8h1.5v6.3h2.2V5.9H58V4.2
            h-2.5V3.6C55.5,3,55.7,2.6,56.4,2.6"/>
          <path d="M40.7,3.6c-0.2,0-0.5,0-0.7,0.1c0,0,0,0,0,0l0,0c-0.1,0-0.8,0.2-1.3,0.6l0-0.6l-2.3,0l0,8.5l2.3,0l0-3.5c0,0,0,0,0,0l0-1
            c0-1.3,0.5-2,1.6-2c0.7,0,1.3,0.2,1.5,0.4l0-2.3C41.6,3.7,41.2,3.6,40.7,3.6"/>
          <path d="M65.8,4.1l-2,5.5l-2.5-5.5l-2.5,0l3.9,8.1l-0.3,0.7C62.1,13.7,61.8,14,61,14c-1,0-1.2-0.3-1.5-0.6l0,2.2
            C60,15.8,60.5,16,61,16c1.9,0,2.9-1,3.8-3.1l3.5-8.7L65.8,4.1z"/>
          <path d="M33.3,9.3c-0.4,0.5-1.1,0.8-1.9,0.8c-1.4,0-2.3-0.9-2.3-2.1c0-1.3,0.9-2.2,2.3-2.2c0.8,0,1.5,0.4,1.9,0.8L33.3,9.3z
            M33.3,3.7l0,0.9c-0.6-0.6-1.4-0.9-2.5-0.9c-2.3,0-3.9,1.9-3.9,4.2c0,2.3,1.6,4.2,3.9,4.2c1.1,0,1.9-0.4,2.5-0.9l0,0.8
            c0,1.5-1,2-2,2c-1.2,0-1.9-0.2-2.5-0.5l0,2.1c0.6,0.3,1.3,0.4,2.5,0.4c2.5,0,4.3-1.5,4.3-4.4l0-7.8L33.3,3.7z"/>
        </g>
        <g>
          <path fill="#929497" d="M71.9,10.3v2h-1.1V5.7c1,0,2,0,3.1,0c3.1,0,3.2,4.6,0,4.6H71.9z M71.9,9.3h2c1.7,0,1.7-2.6,0-2.6h-2V9.3z"/>
          <path fill="#929497" d="M81.4,10.9h-3.6l-0.6,1.4h-1.1l3-6.6h1.1l3,6.6H82L81.4,10.9z M79.6,6.8L78.2,10H81L79.6,6.8z"/>
          <path fill="#929497" d="M85.8,8.6l1.9-2.9h1.3v0l-2.6,3.8v2.7h-1.1V9.6l-2.5-3.8v0h1.3L85.8,8.6z"/>
        </g>
      </g>
    </svg>
  )
}

export default AgrofyPayLogo