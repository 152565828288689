import React, {useState} from 'react';
import ButtonAgrofy from '../../atoms/ButtonAgrofy/ButtonAgrofy';
import BoardTablePagination from '../../organisms/BoardTablePagination/BoardTablePagination';
import {format} from 'date-fns';
import Calendar from '../../atoms/Calendar/Calendar';
import getData from '../../services/GetData';
import Graphic from '../../atoms/Graphic/Graphic';
import MultiSelectAgrofy from '../../atoms/MultiSelectAgrofy/MultiSelectAgrofy';
import CustomSelectAgrofy from '../../atoms/CustomSelectAgrofy/CustomSelectAgrofy';
import NoFilterData from '../../atoms/NoFilterData/NoFilterData';
import {useTranslation} from 'react-i18next';

const markets = [
  {value: '10001', label: 'Buenos Aires'},
  {value: '10002', label: 'Quequén'},
  {value: '10003', label: 'Bahía Blanca'},
  {value: '10004', label: 'Rosario'},
  {value: '10005', label: 'Córdoba'}
];
const products = [
  {value: '4', label: 'Soja'},
  {value: '3', label: 'Girasol'},
  {value: '5', label: 'Maíz'},
  {value: '10', label: 'Sorgo'},
  {value: '1', label: 'Trigo'},
  {value: '63', label: 'Trigo art. 12'}
];
const currencies = [
  {value: 'pesos', label: 'Peso'},
  {value: 'dolares', label: 'Dólar'}
];
const loader = <div className="loader container p-0"><div className="animated-background" style={{height: '300px'}}/></div>;

const BoardHistoricalDataContent = props => {
  const {t} = useTranslation();
  const [selected, setSelected] = useState({
    products: [],
    markets: [],
    currency: '',
    fromDate: new Date(),
    toDate: new Date()
  });
  const [hasData, setHasData] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [data, setData] = useState([]);
  const [graphData, setGraphData] = useState(null);

  const formatData = oldData => {
    const newData = [];
    oldData.Mercados.map(market => {
      return market.Productos.map(prod => {
        const graphicData = [];
        prod.Items.map(item => {
          return graphicData.push({t: item.Fecha, y: item.Valor});
        });
        return newData.push({
          label: prod.Label,
          graphicData: graphicData
        });
      });
    });
    return newData;
  };

  const fetchData = (codMarkets, codProd, currency, fromDate, toDate) => {
    const url =
    `/api/BoardPrices/GetHistoricalBoardPrices/?marketIDs=${codMarkets}&productIDs=${codProd}&dateFrom=${fromDate}&dateTo=${toDate}&currency=${currency}`;
    return getData({
      url: `${window.env.RAZZLE_ENDPOINT_COTZ_API}${url}`
    });
  };

  const getFilteredData = () => {
    setHasData(false);
    setHasFilters(true);
    const codMarkets = selected.markets.map(item => item).join(',');
    const codProd = selected.products.map(item => item).join(',');
    const fromDate = format(selected.fromDate, 'yyyyMMdd');
    const toDate = format(selected.toDate, 'yyyyMMdd');
    fetchData(codMarkets, codProd, selected.currency, fromDate, toDate).then(resp => {
      setGraphData(resp.data && resp.data.length ? formatData(resp.data[0]) : []);
      setData(resp.data && resp.data.length ? resp.data[0] : []);
      setHasData(true);
    });
  };

  const validFilters = () => {
    return selected.products.length && selected.markets.length && selected.currency;
  };

  return (
    <div className="board-historical-data__content">
      <div className="board-historical-data__filters">
        <div className="board-historical-data__filters_dates d-md-flex flex-wrap justify-content-between mb-20">
          <h1 className="title4 pb-10 pb-md-0">{t('historical_prices_wheat_and_corn')}</h1>
          <div className="board-historical-data__filters_dates__calendar d-flex align-items-end">
            <div className="board-historical-data__filters_dates__calendar--left d-md-flex align-items-center pr-0 pr-md-15">
              <label className="pr-10">{t('choose_date_from')}:</label>
              <Calendar
                initialDate={selected.fromDate}
                maxDate={selected.toDate}
                parentCallback={date => setSelected({...selected, fromDate: date})}/>
            </div>
            <div className="board-historical-data__filters_dates__calendar--right d-md-flex align-items-center">
              <label className="pr-10">{t('choose_date_to')}:</label>
              <Calendar
                initialDate={selected.toDate}
                minDate={selected.fromDate}
                parentCallback={date => setSelected({...selected, toDate: date})}/>
            </div>
          </div>
        </div>
        <div className="board-historical-data__filters__selects d-md-flex mb-20 justify-content-between">
          <div className="board-historical-data__filters__selects__inputs d-md-flex">
            <MultiSelectAgrofy
              placeholder={t('product')}
              options={products}
              parentCallback={items => setSelected({...selected, products: items})}
            />
            <MultiSelectAgrofy
              placeholder={t('markets')}
              options={markets}
              parentCallback={items => setSelected({...selected, markets: items})}
            />
            <CustomSelectAgrofy
              nameSelectId="currency"
              placeholder={t('currency')}
              parentCallback={item => setSelected({...selected, currency: item})}
              options={currencies}
              value="value"
              label="label" />
          </div>
          <ButtonAgrofy title="search" parentCallback={getFilteredData} disabled={!validFilters()}/>
        </div>
      </div>
      {
        // eslint-disable-next-line no-nested-ternary
        !hasFilters ?
          <div className="board-historical-data__generate-graphic text-center">
            <div className="i-container mx-auto mb-10">
              <i className="icon-graphic" />
            </div>
            <label className="title6">{t('complete_filters')}</label>
            {/* eslint-disable-next-line no-nested-ternary */}
          </div> : !hasData ? loader : data.Mercados ?
            <div className="board-historical-data__graph mb-20">
              <Graphic data={hasData && data.Mercados && graphData}/>
            </div> : <NoFilterData/>
      }
      {<BoardTablePagination data={hasData && data}/>}
    </div>
  );
};


export default BoardHistoricalDataContent;
