import React from 'react';
import './AgrofyHeader.scss';
import { AgroHeaderTabs } from 'agrofy-ui-components';
import { getTagManager } from '../../utils/GTMDataLayerFuncs';

const AgrofyHeader = () => {

  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";

  const tabs = [{
    active: false,
    show: true,
    url: isBrasil ? 'https://www.agrofy.com.br' : 'https://www.agrofy.com.ar',
    title: 'Agrofy',
    subtitle: 'Market'
  },
  {
    active: true,
    show: true,
    url: isBrasil ? 'https://news.agrofy.com.br/' : 'https://news.agrofy.com.ar/',
    title: 'Agrofy',
    subtitle: 'News'
  },
  {
    active: false,
    show: !isBrasil,
    url: 'https://pay.agrofy.com.ar',
    title: 'Agrofy',
    subtitle: 'Pay'
  }];

  return (
    <div className="header-tabs">
      <AgroHeaderTabs
        tabs={tabs}
        tagManager={(dataLayerEvent) => {
          getTagManager().dataLayer(dataLayerEvent)
        }}
      />
    </div>
  );
};

export default AgrofyHeader;
