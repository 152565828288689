import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import logo from '../../images/icons/cadena3.svg';

export default function Cadena3() {
    const [data, setData] = useState(null)
    const [url, setUrl] = useState("")
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent)
        setIsMobile(isMobileDevice)
        const radioData = JSON.parse(localStorage.getItem('cadena3')) || false;
        const urlData = localStorage.getItem('previousUrl') || false;
        if (radioData && urlData) {
            setData(radioData);
            setUrl(urlData);
        }
    }, []);

    const manageGTMClick = () => {
        const tagManagerArgs = {
            dataLayer: {
                event: 'trackEvent',
                eventCategory: 'Navigation',
                eventAction: 'Agrofy News - Widget Cadena3',
                eventLabel: 'Back to News'
            }
        };

        TagManager.dataLayer(tagManagerArgs);
    };

    return (
        <Container>
            {data && <Wrapper withBg>
                <ContainerLogo><img src={logo} alt="logo cadena3" width="224px" />
                    <h3>{data.titleRadio}</h3>
                    <p>{data.descriptionRadio}</p>
                </ContainerLogo>
                <Audio id="radioPlayer" controls src={data.stream} autoplay type="audio/mpeg" />
            </Wrapper>}
            {isMobile && <Link to={`${url}`} onClick={() => manageGTMClick()} target="_blank"> <Button>Volver a <i className="agrofy-news-logo"></i></Button></Link>}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    background: #E5E5E5;
    position: absolute;
    top: 0;
    min-height: 100vh;
    width: 100%;
`;
const Audio = styled.audio`
    padding: 4px 2px;
    outline: none;
    &:focus, &:active {
        outline: none;
    } 
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: ${p => p.withBorder ? "1px solid black" : "none"};
    background: inherit;
`;

const ContainerLogo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #E5E5E5;
    padding: 12px;
    margin-top: 16px;
    margin-bottom: 42px;
    img {
        margin-bottom: 24px;
    }
`;

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background: inherit;
    border: none;
    margin-top: 16px;
    font-family: 'Open Sans';
    font-size: 14px;
    .agrofy-news-logo {
        width: 120px;
        height: 34px;
        transition: 0s;
        margin-left: 8px;
        margin-top: 2px;
    }
    &:focus, &:active {
        outline: none;
    } 
`;