import React, {useState, useEffect} from 'react';
import MultiSelect from '@khanacademy/react-multi-select';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './MultiSelectAgrofy.scss';

const MultiSelectAgrofy = ({placeholder, options, parentCallback}) => {
  const {t} = useTranslation();
  const placeholderLabel = placeholder || t('select');
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState([]);

  const handleChange = items => {
    parentCallback(items);
    setSelected(items);
  };
  useEffect(() => {
    setSelected([]);
  }, [options]);

  return <MultiSelect
    options={options}
    selected={selected}
    onSelectedChanged={items => handleChange(items)}
    disableSearch={true}
    overrideStrings={{
      allItemsAreSelected: t('all'),
      selectAll: t('all'),
      selectSomeItems: placeholderLabel,
      search: ' '
    }}
  />;
};

MultiSelectAgrofy.propTypes = {
  options: PropTypes.array,
  parentCallback: PropTypes.instanceOf(Function),
  placeholder: PropTypes.string
};

export default MultiSelectAgrofy;
