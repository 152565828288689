import React, {useState} from 'react';
import ButtonAgrofy from '../../atoms/ButtonAgrofy/ButtonAgrofy';
import Calendar from '../../atoms/Calendar/Calendar';
import filters from './FiltersData';
import {format} from 'date-fns';
import getData from '../../services/GetData';
import Graphic from '../../atoms/Graphic/Graphic';
import MultiSelectAgrofy from '../../atoms/MultiSelectAgrofy/MultiSelectAgrofy';
import PhysicalTablePagination from '../../organisms/PhysicalTablePagination/PhysicalTablePagination';
import CustomSelectAgrofy from '../../atoms/CustomSelectAgrofy/CustomSelectAgrofy';
import NoFilterData from '../../atoms/NoFilterData/NoFilterData';
import { useTranslation } from 'react-i18next';

const loader = <div className="loader container p-0"><div className="animated-background" style={{height: '300px'}}/></div>;

const PhysicalHistoricalDataContent = props => {
  const {t} = useTranslation();
  const [selected, setSelected] = useState({
    fromDate: new Date(),
    toDate: new Date(),
    product: '',
    destination: [],
    quality: [],
    delivery: [],
    currency: ''
  });
  const [hasData, setHasData] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [graphData, setGraphData] = useState(null);
  const [data, setData] = useState(false);

  const formatData = oldData => {
    const newData = [];
    oldData.Precios.map(price => {
      return price.Destinos.map(dest => {
        return dest.Calidades.map(qual => {
          return qual.Entregas.map(deli => {
            const graphicData = [];
            const name = `${dest.Nombre} - ${qual.Nombre} ${deli.Nombre}`;
            deli.Items.map(item => {
              return graphicData.push({t: item.Fecha, y: item.Precio});
            });
            return newData.push({
              label: name,
              graphicData: graphicData
            });
          });
        });
      });
    });
    return newData;
  };

  const fetchData = (fromDate, toDate, codProd, codDestination, codQuality, codDelivery, codCurrency) => {
    const url =
    `/api/PhysicalPrices/GetHistoricalPhysicalPrices?fromDate=${fromDate}&toDate=${toDate}&productID=${codProd}&destinationID=${codDestination}&qualityID=${codQuality}&deliveryKindID=${codDelivery}&currency=${codCurrency}`;
    return getData({
      url: `${window.env.RAZZLE_ENDPOINT_COTZ_API}${url}`
    });
  };

  const getFilteredData = () => {
    setHasData(false);
    setHasFilters(true);
    const codQuality = selected.quality.map(item => item).join(',');
    const codDelivery = selected.delivery.map(item => item).join(',');
    const codDestination = selected.destination.map(item => item).join(',');
    const fromDate = format(selected.fromDate, 'yyyyMMdd');
    const toDate = format(selected.toDate, 'yyyyMMdd');

    fetchData(fromDate, toDate, selected.product, codDestination, codQuality, codDelivery, selected.currency).then(resp => {
      setGraphData(resp.data && resp.data.length ? formatData(resp.data[0]) : []);
      setData(resp.data && resp.data.length ? resp.data[0] : []);
      setHasData(true);
    });
  };

  const validFilters = () => {
    return selected.fromDate
      && selected.toDate
      && selected.product
      && selected.destination && selected.destination.length
      && selected.quality && selected.quality.length
      && selected.delivery && selected.delivery.length
      && selected.currency;
  };

  return (
    <div className="physical-historical-data__content">
      <div className="physical-historical-data__filters">
        <div className="physical-historical-data__filters_dates d-md-flex flex-wrap justify-content-between mb-20">
          <h1 className="title4 pb-10 pb-md-0">{t('historical_physical_grain_market_prices')}</h1>
          <div className="physical-historical-data__filters_dates__calendar d-flex align-items-end">
            <div className="physical-historical-data__filters_dates__calendar--left d-md-flex align-items-center pr-0 pr-md-15">
              <label className="pr-10">{t('choose_date_from')}:</label>
              <Calendar
                initialDate={selected.fromDate}
                maxDate={selected.toDate}
                parentCallback={date => setSelected({...selected, fromDate: date})}/>
            </div>
            <div className="physical-historical-data__filters_dates__calendar--right d-md-flex align-items-center">
              <label className="pr-10">{t('choose_date_to')}:</label>
              <Calendar
                initialDate={selected.toDate}
                minDate={selected.fromDate}
                parentCallback={date => setSelected({...selected, toDate: date})}/>
            </div>
          </div>
        </div>
        <div className="physical-historical-data__filters__selects d-md-flex mb-20 justify-content-between">
          <div className="physical-historical-data__filters__selects__inputs d-md-flex">
            <CustomSelectAgrofy
              nameSelectId="products"
              placeholder={t('product')}
              parentCallback={item => setSelected({...selected, product: item})}
              options={filters.products}
              value="value"
              label="label" />
            <MultiSelectAgrofy
              placeholder={t('destination')}
              options={filters.destinations}
              parentCallback={items => setSelected({...selected, destination: items})}
            />
            <MultiSelectAgrofy
              placeholder={t('quality')}
              options={filters.qualities}
              parentCallback={items => setSelected({...selected, quality: items})}
            />
            <MultiSelectAgrofy
              placeholder={t('delivery')}
              options={filters.deliveries}
              parentCallback={items => setSelected({...selected, delivery: items})}
            />
            <CustomSelectAgrofy
              nameSelectId="currencies"
              placeholder={t('currencies')}
              parentCallback={item => setSelected({...selected, currency: item})}
              options={filters.currencies}
              value="value"
              label="label" />
          </div>
          <ButtonAgrofy title="search" parentCallback={getFilteredData} disabled={!validFilters()}/>
        </div>
      </div>
      {
        // eslint-disable-next-line no-nested-ternary
        !hasFilters ?
          <div className="physical-historical-data__generate-graphic text-center">
            <div className="i-container mx-auto mb-10">
              <i className="icon-graphic" />
            </div>
            <label className="title6">{t('complete_filters')}</label>
            {/* eslint-disable-next-line no-nested-ternary */}
          </div> : !hasData ? loader : graphData.length ?
            <div className="physical-historical-data__graph-content">
              <div className="physical-historical-data__graph-content__graph">
                <Graphic data={hasData && graphData}/>
              </div>
              <PhysicalTablePagination data={hasData && data} />
            </div> : <NoFilterData/>
      }
    </div>
  );
};

export default PhysicalHistoricalDataContent;

