import getData from '../../services/GetData';

export const fetchGrainData = () => {
  const result = getData({
    url: `${window.env.RAZZLE_ENDPOINT_COTZ_API}/api/Prices/GetPricesResumeGroup`
  }).then(resp => {
    return resp.data;
  });
  return result;
};
