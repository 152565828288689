import React from 'react';
import './RainsTabContent.scss';

function RainsTabContent(props) {
  return (
    <div className="rains-tab-content">
      <div className="map-wrapper mt-20">
        <img src={props.rainsMap} alt={props.mapAlt} />
        <img src={props.refImg} alt={props.refAlt} />
      </div>
      <div className="table-wrapper mt-10">
        <div dangerouslySetInnerHTML={{__html: props.table}} />
      </div>
    </div>
  );
}

RainsTabContent.defaultProps = {
  data: {
    rainsMap: 'https://news.agrofy.com.ar/sites/all/themes/fyo_theme/images/seg_map_entrerios.gif',
    mapAlt: 'Mapa de Buenos Aires',
    refImg: 'https://news.agrofy.com.ar/sites/all/themes/fyo_theme/images/referencia-deficit-lluvias-temp.png',
    refAlt: 'Referencia del mapa de lluvias',
    table: 'Tabla de lluvias'
  }
};

export default RainsTabContent;
