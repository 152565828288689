import React, {useState, useEffect, Fragment} from 'react';

const NoScrollBehavior = ({
  children
}) => {
  const [prevOverflow, setPrevOverflow] = useState(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setPrevOverflow(window.document.body.style.overflow);
      window.document.body.style.overflow = 'hidden';
    }
    return function cleanup() {
      if (typeof window !== 'undefined') {
        window.document.body.style.overflow = prevOverflow;
      }
    };
  }, [prevOverflow]);

  return <Fragment>{children}</Fragment>;
};

export default NoScrollBehavior;
