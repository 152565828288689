import React from 'react';
import {Table} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TableWatermarkLogo from '../../WatermarkLogo/TableWatermarkLogo';
import './TableBoardHistorical.scss';

const TableBoardHistorical = ({data}) => {
  const { t } = useTranslation();
  
  const loader = <div className="loader container"><div className="animated-background" /></div>;

  const setTable = () => {
    return (
      <>
      <TableWatermarkLogo />
      <Table className="table-agrofy is-gray">
        <thead>
          <tr>
            <th>{t('date')}</th>
            <th>{t('market')}</th>
            <th>{t('product')}</th>
            <th>{t('price')}</th>
          </tr>
        </thead>
        <tbody>
          { data.map((val, index) =>
            <tr key={`row-${index}`}>
              <td key={`date-${index}`}>{val.date}</td>
              <td key={`mark-${index}`}>{val.market}</td>
              <td key={`prod-${index}`}>{val.product}</td>
              <td key={`price-${index}`}>{val.price}</td>
            </tr>
          )}
        </tbody>
      </Table>
      </>
    );
  };

  return (
    <div className="board-historical-table-section">
      {data ? setTable() : loader}
    </div>
  );
};

export default TableBoardHistorical;
