/* eslint-disable no-return-assign */
/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable default-case */
/* eslint-disable quotes */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, {useState} from 'react';
import ButtonAgrofy from '../../../../atoms/ButtonAgrofy/ButtonAgrofy';
import {useTranslation} from 'react-i18next';
import data from './../../ConverterData';

const ProductionConverter = () => {
  const {t} = useTranslation();
  const [productionSelected, setProductionSelected] = useState('soja');
  const [multiplier, setMultiplier] = useState(data.rendimiento.multiplier.soja);
  const [inputValue, setImputValue] = useState();
  const [radioChecked, setRadioChecked] = useState('radio5');
  const [operator, setOperator] = useState('m');
  const [convertedValue, setConvertedValue] = useState();
  const [suffix, setSuffix] = useState();

  const handleChangeProductionSelected = e => {
    const el = e.target.value;
    setProductionSelected(el);
    switch (el) {
      case 'soja':
        setMultiplier(data.rendimiento.multiplier.soja);
        break;
      case 'sorgo':
        setMultiplier(data.rendimiento.multiplier.sorgo);
        break;
      case 'trigo':
        setMultiplier(data.rendimiento.multiplier.trigo);
        break;
      case 'maiz':
        setMultiplier(data.rendimiento.multiplier.maiz);
        break;
      case 'aceite-soja':
        setMultiplier(data.rendimiento.multiplier.aceiteSoja);
        break;
      case 'harina-soja':
        setMultiplier(data.rendimiento.multiplier.harinaSoja);
        break;
    }
  };

  const handleChangeInput = e => {
    setImputValue(e.target.value);
  };

  const handleChangeRadio = (radio, op) => {
    setRadioChecked(radio);
    setOperator(op);
  };

  const getLabel = op => {
    const ldata = data.rendimiento.labels;
    if (op === 'm') {
      return productionSelected === 'soja' ? ldata.soja.m :
        productionSelected === 'sorgo' ? ldata.sorgo.m :
          productionSelected === 'trigo' ? ldata.trigo.m :
            productionSelected === 'maiz' ? ldata.maiz.m : ldata.aceiteSoja.m;
    } return productionSelected === 'soja' ? ldata.soja.d :
      productionSelected === 'sorgo' ? ldata.sorgo.d :
        productionSelected === 'trigo' ? ldata.trigo.d :
          productionSelected === 'maiz' ? ldata.maiz.d : ldata.aceiteSoja.d;
  };

  const getSuffix = () => {
    const ldata = data.rendimiento.suffix;
    if (operator === 'm') {
      return productionSelected === 'soja' ? ldata.soja.m :
        productionSelected === 'sorgo' ? ldata.sorgo.m :
          productionSelected === 'trigo' ? ldata.trigo.m :
            productionSelected === 'maiz' ? ldata.maiz.m : ldata.aceiteSoja.m;
    } return productionSelected === 'soja' ? ldata.soja.d :
      productionSelected === 'sorgo' ? ldata.sorgo.d :
        productionSelected === 'trigo' ? ldata.trigo.d :
          productionSelected === 'maiz' ? ldata.maiz.d : ldata.aceiteSoja.d;
  };

  const handleGetConvertion = () => {
    operator === 'm' ? setConvertedValue((inputValue * multiplier).toFixed(4)) : setConvertedValue((inputValue / multiplier).toFixed(4));
    setSuffix(getSuffix());
  };

  return (
    <div className="converter yield">
      <span className="d-flex w-100 mb-10 text text1">{t('I_want_to_convert')}</span>
      <select className="select-agrofy mr-20" value={productionSelected} onChange={e => handleChangeProductionSelected(e)}>
        {data.rendimiento.options.map(item => <option key={item.value} value={item.value}>{item.name}</option>)}
      </select>
      <input value={inputValue} placeholder={t('enter_value')} type="number" onChange={e => handleChangeInput(e)} />
      <br />
      <span className="d-flex w-100 my-10 text text1">{t('from')}:</span>

      <label className="radio text">
        <input id="radio5" type="radio" name="yieldGroup" className="hidden" checked={radioChecked === 'radio5'} onChange={() => handleChangeRadio('radio5', 'm')} />
        <span className="label" />{getLabel('m')}
      </label>
      <label className="radio text">
        <input id="radio6" type="radio" name="yieldGroup" className="hidden" checked={radioChecked === 'radio6'} onChange={() => handleChangeRadio('radio6', 'd')} />
        <span className="label" />{getLabel('d')}
      </label>
      <br />
      <p className="w-100 d-flex mt-10 mb-20">
        {typeof convertedValue === "undefined" ? '' :
          convertedValue > 0 ? <h5 className="result open-sans title5">{convertedValue} {suffix}</h5> :
            <span className="error open-sans bold title5">{t('enter_value_greater_than_0')}</span>}
      </p>
      <ButtonAgrofy title={t('convert')} parentCallback={handleGetConvertion} classes="primary" />
    </div>
  );
};

export default ProductionConverter;
