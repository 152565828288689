import getData from "../../../services/GetData";
import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import { listedCitiesAR, listedCitiesBR, provincesBR } from "./ListedCities";
import ForecastCard from "../../../atoms/ForecastCard/ForecastCard";
import "./Forecast.scss";

function Forecast({
  pronosticoData,
  propsCityId,
  propsCityName,
  propsProvince,
  onChangeCity,
  initialParams,
}) {
  const { t } = useTranslation();
  const serverForecastData = pronosticoData;
  const [showAlert, setShowAlert] = useState(false);

  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";

  const defaultCitySlug = isBrasil
    ? "brasilia+distrito+federal"
    : "rosario+santa+fe";
  const defaultCityName = isBrasil ? "Brasília" : "Rosario";
  const defaultProvinceName = isBrasil ? "Distrito Federal" : "Santa Fe";
  const defaultCityId = isBrasil ? "43348" : "11222";

  // Initial city
  const [cityId, setCityId] = useState(propsCityId);
  const [cityName, setCityName] = useState(propsCityName);
  const [province, setProvince] = useState(propsProvince);
  const [forecast5days, setForecast5days] = useState(serverForecastData);

  // State to filter cities
  const [filter, setFilter] = useState("");
  const [dataCities, setDataCities] = useState([]);
  const [listCities, setListCities] = useState([]);

  // Slug state to change url when clicks on a city
  const [slug, setSlug] = useState(initialParams && initialParams.city);

  // Responsive rendering for on-screen cities list
  const isClient = typeof window === "object";
  const [width, setWidth] = useState(getWindowWidth);
  const [elementCount, setElementCount] = useState(5);
  const breakpoint = 576;

  // Search a city forecast when the user clicks in a suggested option
  useEffect(() => {
    // Initial state to filter the cities and set the cities to be filtered
    setFilter("");
    setListCities(isBrasil ? listedCitiesBR : listedCitiesAR);

    const fetchForecastData = () => {
      const url =
        process.env.RAZZLE_ENDPOINT_NEWS_API ||
        window.env.RAZZLE_ENDPOINT_NEWS_API;
      if (!cityId) {
        getData({
          url: `${url}/api/Weather/GetWeatherBySlug?slug=${slug}`,
        }).then((result) => setForecast5days(result.data));
      } else {
        getData({
          url: `${url}/api/Weather/GetWeatherForecast?idCity=${cityId}`,
        }).then((result) => setForecast5days(result.data));
      }
    };

    (cityId || slug) && fetchForecastData();
  }, [cityId, slug]);

  useEffect(() => {
    if (window) {
      window.addEventListener("resize", () => setWidth(window.innerWidth));
    }
  }, []);

  useEffect(() => {
    setCityId(propsCityId);
    setCityName(propsCityName);
    setProvince(propsProvince);
  }, [propsCityId, propsCityName, propsProvince]);

  function changeURL(citySlug) {
    setSlug(citySlug);
    window.history.pushState(slug, slug, `${t("weather_url")}/${citySlug}`);
  }

  function handleChangeInput(e) {
    setFilter(e.target.value);
    let query = e.target.value;

    const url =
      process.env.RAZZLE_ENDPOINT_NEWS_API ||
      window.env.RAZZLE_ENDPOINT_NEWS_API;
    const fetchData = () => {
      getData({
        url: `${url}/api/Weather/GetSuggestedLocations?query=${query}`,
      }).then((result) => setDataCities(result.data));
    };

    if (query.length > 2) {
      setDataCities([]);
      fetchData();
      document
        .querySelector(".forecast-wrapper")
        .classList.add("show-suggests");
    } else {
      document
        .querySelector(".forecast-wrapper")
        .classList.remove("show-suggests");
    }
  }

  const updateLocalStoge = () => {
    localStorage.setItem(
      "savedCityId",
      JSON.stringify(cityId || defaultCityId)
    );
    localStorage.setItem(
      "savedCityName",
      JSON.stringify(cityName || defaultCityName)
    );
    localStorage.setItem(
      "savedProvinceName",
      JSON.stringify(province || defaultProvinceName)
    );
    localStorage.setItem(
      "savedCitySlug",
      JSON.stringify(slug || defaultCitySlug)
    );
    setShowAlert(true);

    manageGTMClickPreferences("Save City");
  };

  function handleClickCity(item) {
    changeURL(item.slug);
    setCityId(item.id);
    setCityName(item.city);
    setProvince(item.province);
    handleClickCloseSuggests();
    const element = document.querySelector(".forecast-wrapper");
    element.classList.add("animate");
    element.classList.remove("show");
    element.classList.remove("show-input");
    /* Set breadcrumb updated city */
    onChangeCity(item.city);
    scrollUp();
    setShowAlert(false);
  }

  function handleClickInput() {
    const element = document.querySelector(".forecast-wrapper");
    element.classList.add("show-suggests");
    element.classList.remove("animate");
  }

  function handleClickChangeCity() {
    setShowAlert(false);
    const element = document.querySelector(".forecast-wrapper");
    element.classList.toggle("show-input");
    element.classList.remove("show-suggests");
    document.getElementById("change-city-input").focus();
    document.getElementById("change-city-input").click();

    manageGTMClickPreferences("Change City");
  }

  function handleClickCloseSuggests() {
    const element = document.querySelector(".forecast-wrapper");
    element.classList.remove("show-suggests");
    element.classList.remove("show-input");
    setFilter("");
  }

  const manageGTMClickPreferences = (label) => {
    const tagManagerArgs = {
      dataLayer: {
        event: "trackEvent",
        eventCategory: "Users",
        eventAction: "Agrofy News - Preferencias Clima",
        eventLabel: label,
      },
    };

    TagManager.dataLayer(tagManagerArgs);
  };

  function listChanged() {
    const element = document.querySelector(".forecast-wrapper");
    element.classList.remove("animate");
  }

  function getCities() {
    const toDisplay = [...listCities];
    replaceUsedCity(toDisplay);
    return toDisplay;
  }

  function replaceUsedCity(toDisplay) {
    const cityToHideInx = cityId
      ? listCities.findIndex((city) => city.id === cityId.toString())
      : -1;
    const cityNew = isBrasil ? "Goiania" : "San Luis";
    const cityToShowInx = listCities.findIndex((x) => x.city === cityNew);

    if (cityToHideInx >= 0 && cityToShowInx >= 0) {
      [toDisplay[cityToHideInx], toDisplay[cityToShowInx]] = [
        toDisplay[cityToShowInx],
        toDisplay[cityToHideInx],
      ];
    }

    return toDisplay;
  }

  function getWindowWidth() {
    let a;
    return isClient ? window.innerWidth : a;
  }

  function handleClickLoadMore() {
    if (elementCount < 15) {
      setElementCount(elementCount + 5);
    }
  }

  function scrollUp() {
    const element = document.querySelector(".pages-togler");

    if (element && width < breakpoint) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }

  const loader = (
    <div className="loader container p-0">
      <div className="animated-background" />
    </div>
  );

  const loadMore = () => handleClickLoadMore();

  return (
    <div>
      <p className="mb-10">
        {t("weather_at_detailed")} {cityName} {t("today_and_tomorrow")}.
      </p>
      <Alert
        variant="success"
        show={showAlert}
        onClose={() => setShowAlert(false)}
        dismissible
      >
        <p style={{ margin: "0" }}>
          {t("you_have_added")} <b>{cityName}</b> {t("like_favourite_city")}.
        </p>
      </Alert>
      <div className="forecast-wrapper">
        <div className="header-container">
          <div className="intro-wrapper">
            {isBrasil ? (
              <h1>{`${t("weather_at")} ${cityName}, ${
                provincesBR[province]
              }`}</h1>
            ) : (
              <h1>{`${t("weather_at")} ${cityName}, ${province}`}</h1>
            )}
            <div className="buttons-wrapper">
              <span
                className="text4 save-city-button"
                onClick={updateLocalStoge}
              >
                {t("save_city")}
              </span>
              <span
                className="text4 change-city-button"
                onClick={handleClickChangeCity}
              >
                {t("change_city")}
              </span>
            </div>
          </div>
          {/* Change city */}
          <div className="change-city-container">
            <input
              className="text1"
              id="change-city-input"
              value={filter}
              placeholder={t("write_city_name")}
              onChange={(event) => handleChangeInput(event)}
              onClick={handleClickInput}
            />
            <span
              className="close-input"
              onClick={handleClickCloseSuggests}
            ></span>
            <ul className="autocomplete-container">
              {dataCities.map((item) => (
                <li key={item.id} onClick={() => handleClickCity(item)}>
                  <span className="open-sans bold">{item.city}</span>
                  <span className="open-sans">
                    {item.province}, {countryName}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="data-container mt-10">
          {!forecast5days ? (
            loader
          ) : (
            <ul>
              <ForecastCard forecast5days={forecast5days} />
            </ul>
          )}
        </div>
      </div>
      <div className="cities-wrapper mt-15">
        <h2 className="mb-20">{t("weather_at_other_cities")}</h2>
        <div className="listContainer mb-20">
          {!listCities
            ? null
            : getCities()
                .map((item) => (
                  <h3
                    key={item.id}
                    onClick={() => {
                      handleClickCity(item);
                      setTimeout(() => listChanged(), 500);
                    }}
                  >
                    {" "}
                    {`${t("weather_of")} ${item.city} ${
                      isBrasil ? "(" + provincesBR[item.province] + ")" : ""
                    }`}
                  </h3>
                ))
                .slice(0, width < breakpoint ? elementCount : 15)}
          {elementCount < 15 && width < breakpoint && (
            <span onClick={loadMore}>{t("weather_see_more_cities")} ▼</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default Forecast;
