import axios from 'axios';

const postData = config => {
  if (config && config.params) {
    return axios.post(config.url, {
      params: config.params
    });
  }
  return axios.post(config.url);
}
export default postData;

