import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import TagManager from 'react-gtm-module';
import './BgImageCard.scss';
import { AgroExclusiveRegistered } from 'agrofy-ui-components';

function BgImageCard(props) {
  const [isExternal, setIsExternal] = useState(false);
  const data = props && props.data;
  const hasTracking = props && props.hasTracking;
  const bg = props.image.includes('https://news')
    ? props.image
    : `${props.image}`;

  const link =
    props.link.includes('.com.ar') || props.link.includes('.com')
      ? props.link.split('.com.ar')[1] || props.link.split('.com')[1]
      : `/${props.link}`;

  const bgImage = {
    backgroundImage: `url(${bg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  const clampText = props && props.clampText;

  var clamped = clampText && {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: clampText,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'break-spaces',
  };

  const manageGTM = () => {
    if (hasTracking) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'select_content',
          content_type: 'click_home_news',
          item_id: props.position,
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    }
  };

  const imgOrVideo = (image) => {
    let response = '';
    data && data?.video !== null
      ? (response = (
          <div className="videoContainer">
            <ReactPlayer
              className="player-standar"
              key={data?.id}
              url={data?.video}
              playing={true}
              loop={true}
              muted={true}
              controls
              config={{
                youtube: {
                  playerVars: { showinfo: 1, rel: 0 },
                },
              }}
              width="100%"
              height="100%"
            />
            <div>
              <h2 className="title" style={clamped}>
                {props.body}
              </h2>
            </div>
          </div>
        ))
      : (response = (
          <Card
            className={`${props.bigBackground} ${props.customheight} bg-image-card`}
            style={image}
          >
            {props.exclusive && (
              <div className="exclusive-container agro-exclusive-wrapper">
                <AgroExclusiveRegistered variant="dark" />
              </div>
            )}
            <Card.Body>
              <span className="category">{props.category}</span>
              <p>
                <span>{props.section}</span>
                <span>{props.description}</span>
              </p>
              <h2 className="title" style={clamped}>
                {props.body}
              </h2>
            </Card.Body>
          </Card>
        ));
    return response;
  };

  const cardItem = (styleImg) => {
    return imgOrVideo(styleImg);
  };

  useEffect(() => {
    if (
      link.includes('pulperia/') ||
      link.includes('noticia/') ||
      link.includes('opinion/')
    ) {
      setIsExternal(true);
    } else {
      setIsExternal(false);
    }
  }, [link]);

  return isExternal ? (
    <a href={link} className="link" onClick={() => manageGTM()}>
      {cardItem(bgImage)}
    </a>
  ) : (
    <Link to={link} className="link" onClick={() => manageGTM()}>
      {cardItem(bgImage)}
    </Link>
  );
}

BgImageCard.propTypes = {
  bigBackground: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string,
  imageTitle: PropTypes.string,
  section: PropTypes.string,
  description: PropTypes.string,
  category: PropTypes.string,
  body: PropTypes.string,
  action: PropTypes.func,
  data: PropTypes.object,
  hasTracking: PropTypes.bool,
  position: PropTypes.number,
  exclusive: PropTypes.bool,
  clampText: PropTypes.number,
  customheight: PropTypes.string,
};

export default BgImageCard;
