import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AgroButton, AgroAlert, AgroInput, AgroModal, Text } from 'agrofy-ui-components';
import { trackEvent } from '../../utils/GTMDataLayerFuncs';
import postData from '../../services/PostData';

const NewslettersBR = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [errorValue, setErrorValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [error, setError] = useState(false);
  const [regOK, setRegOK] = useState(false);
  const successMessage = "Sua inscrição foi realizada com sucesso! A partir de agora você vai receber todos os nossos e-mails."

  const isNewsDetail = props.newsDetail;

  const validEmail = () => {
    /* Validate email */
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
  };
  const getCookieValue = (name) => document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || '';

  const handlePage = () => {
    setLoading(true);
    const url = process.env.RAZZLE_ENDPOINT_FORO_API || window.env.RAZZLE_ENDPOINT_FORO_API;

    const params = {
      email: value,
      clientID: getCookieValue('_ga'),
    };
    
    validEmail()
      ? postData({
          url: `${url}/api/Newsletters/InsertOrUpdateUser`,
          params: params,
        })
          .then(() => {
            trackEvent('Newsletter', 'Users', 'Subscription');
            setLoading(false);
            setShowMessage(true);
            setValue('');
            setErrorValue('');
            setRegOK(true);
          })
          .catch((err) => {
            console.error(err);
            setError(!error);
            setShowMessage(false);
            setLoading(false);
            setRegOK(false);
          })
      : setErrorValue('Por favor, insira um endereço de e-mail válido (Ex.: josesilva@gmail.com)');
    !validEmail() && setLoading(false);
  };

  const handleChange = (e) => {
    setValue(e.currentTarget.value);
    value.length === 1 && setErrorValue('');
  };

  return (
    <>
      <Container>
        <ContentsWrapper isNewsDetail={isNewsDetail}>
          <Texts isNewsDetail={isNewsDetail} regOK={regOK}>
            <h3>
              {t('newsletter_title')}
            </h3>
          </Texts>
          {showMessage && <AgroAlert className="agroAlert" title={successMessage} onClick={(e) => console.log(e)} btnLabel=" "/>}
          <FormContainer isNewsDetail={isNewsDetail} regOK={regOK}>
            {!showMessage &&
            <>
              <AgroInput
                id="mailInput"
                className="inputContainer"
                errorMessage={errorValue}
                placeholder='E-mail'
                value={value}
                onChange={(e) => handleChange(e)}
              />
              <AgroButton loading={loading} onClick={handlePage}>
                Me inscreva
              </AgroButton>
            </>
            }
          </FormContainer>
        </ContentsWrapper>
      </Container>
      {showModal && (
        <Modal className='agrofy-modal' onDismiss={() => setShowModal(!showModal)}>
          <Description>
            <img alt='envelope icon' src='https://news.agrofystatic.com/news-br/newsletter.png' />
            <Icon error={error}>
              <Circle error={error} />{' '}
              {error ? (
                <img alt='error icon' src='https://news.agrofystatic.com/news-br/errorIcon.svg' />
              ) : (
                <img
                  alt='success icon'
                  src='https://news.agrofystatic.com/news-br/successIcon.svg'
                />
              )}
            </Icon>
            <TextsWrapper>
              <h2>{error ? 'Atenção!' : 'Parabéns!'}</h2>
              {error ? (
                <Text>
                  Não foi possível realizar a sua inscrição.
                  <br />
                  Por favor, tente novamente.{' '}
                </Text>
              ) : (
                <Text>
                  Sua inscrição foi realizada com sucesso! <br />A partir de agora você vai receber
                  todos os nossos e-mails.
                </Text>
              )}
            </TextsWrapper>
          </Description>
        </Modal>
      )}
    </>
  );
};

export default NewslettersBR;

const Container = styled.div`
  display: flex;
  margin-bottom: 44px;
  justify-content: center;
  align-items: center;
  font-family: Barlow;
  min-height: 120px;
  color: #ffffff;

  @media only screen and (max-width: 992px) {
    max-width: ${(p) => (p.isNewsDetail ? '20%' : '')};
    margin: ${(p) => (p.isNewsDetail ? '0 auto' : '')};
  }

  @media only screen and (max-width: 768px) {
    padding: 0 16px;
  }

  @media only screen and (max-width: 690px) {
    flex-direction: column;
    justify-content: center;
    max-width: ${(p) => (p.isNewsDetail ? '46%' : '')};
    margin: 0 auto;
    
    > div {
      margin-right: 0;
    }
  }
  @media only screen and (min-width:800px) and (max-width: 850px) and (orientation: landscape) {
    flex-direction: column;
    padding-bottom: 24px;
  }
`;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: ${(p) => (p.isNewsDetail ? 'column' : 'row')};
  padding: ${(p) => (p.isNewsDetail ? '22px' : '32px')};
  border: 2px solid #63A105;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  min-width: 0px;
  width: 100%;

  .agroAlert {
    background-color: #FFF;
    
    div {
      min-width: ${(p) => (p.isNewsDetail ? '22%' : '')};
    }

    path {
      fill: #4F8A03;
    }

    span {
      color: #000;
    }

    button {
      display: none;
    }
  }

  @media only screen and (max-width: 690px) {
    flex-direction: column;
    min-width: auto;
    padding: 16px;
  }
`;

const Texts = styled.div`
  display: ${(p) => (p.regOK ? 'none' : 'flex')};
  flex: 1 1 45%;

  h3 {
    margin: 0px;
    text-align: ${(p) => (p.isNewsDetail ? 'center' : 'left')};
    color: #0D1015;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.1px;
  }

  br {
    display: block;
  }

  @media only screen and (max-width: 690px) {
    h3 {
      text-align: center;
      margin: 0px;
      font-size: 23px;
      line-height: 26px;
      padding: 0px;
    }
    br {
      display: none;
    }
  }
`;

const FormContainer = styled.div`
  display: ${(p) => (p.regOK ? 'none' : 'flex')};
  flex: 1 1 36%;
  flex-direction: ${(p) => (p.isNewsDetail ? 'column' : 'row')};
  justify-content: space-between;

  button {
    min-width: 111px;
    font-family: Open Sans;
    padding: 0 16px;
    height: 44px;
  }

  input {
    margin: ${(p) => (p.isNewsDetail ? '12px 0px' : '0px 12px')};
    background: white;
    height: 44px;
    padding: 13px 36px 12px 16px;
  }

  @media only screen and (min-width: 576px) {
    input {
      width: ${(p) => (p.isNewsDetail ? '100%' : '95%')};
    }
  }

  @media only screen and (max-width: 786px) {flex: 1 0 45%;
    button,
    input {
      min-width: 0;
    }
  }

  @media only screen and (max-width: 690px) {
    flex-direction: column;
    min-width: -webkit-fill-available;
    input {
      margin: 32px 0 0 0;
    }
    button {
      margin-top: 8px;
    }
  }
`;

const Modal = styled(AgroModal)`
  .AgroModal__ModalContent-agrofy-library__lioomz-0,
  .sc-dBaXSw {
    border: 2px solid #63a105;
    max-width: 825px;
    padding-bottom: 0;
    padding-left: 0;
    @media only screen and (max-width: 768px) and (orientation: landscape) {
      max-width: 400px;
    }
  }
`;
const Description = styled.div`
  display: flex;
  h2 {
    font-family: Barlow;
    color: #0b0d1d;
    margin-top: 0;
    margin-bottom: 0.5em;
  }
  span {
    font-family: Open Sans;
    color: #495057;
  }
  img {
    width: 242px;
    height: 207px;
    border-bottom-left-radius: 20px;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 16px 24px 16px;
    h2 {
      text-align: center;
    }
  }
`;
const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 22px;
  margin-top: 20px;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  max-height: 87px;
  position: relative;
  margin-top: 20px;
  > img {
    width: ${(p) => (p.error ? '50px' : '37px')};
    height: ${(p) => (p.error ? '50px' : '30px')};
    position: absolute;
    top: ${(p) => (p.error ? '22%' : ' 35%')};
  }
  @media only screen and (max-width: 768px) and (orientation: landscape) {
    display: none;
  }
`;
const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(p) => (p.error ? ' #E4464A' : '#63A105')};
  opacity: ${(p) => (p.error ? ' 0.1' : '0.2')};
  min-width: 87px;
  height: 87px;
  border-radius: 100%;
`;
