import React, {useState, useEffect} from 'react';
import {randomColor} from '../../../node_modules/randomcolor/randomColor';
import TableGraphicsWatermarkLogo from '../WatermarkLogo/TableGraphicsWatermarkLogo';
import {Line} from 'react-chartjs-2';
import {parse} from 'date-fns';
import './Graphic.scss';

const options = {
  responsive: true,
  spanGaps: true,
  scales: {
    yAxes: [{
      ticks: {
        reverse: false
      }
    }]
  }
};

const Graphic = ({data}) => {
  const [graphData, setGraphData] = useState(false);

  useEffect(() => {
    if (!(data && data.length)) {
      setGraphData({
        labels: [],
        datasets: []
      });
      return;
    }

    let dates = [];
    const colors = data && data.length ? randomColor({luminosity: 'dark', count: data.length}) : null;

    data.map(element => {
      return element.graphicData.map(item => {
        const d = dates.find(date => date === item.t);
        return !d ? dates.push(item.t) : null;
      });
    });

    dates = dates.sort((a, b) => parse(a, 'dd-MM-yyyy', new Date()) - parse(b, 'dd-MM-yyyy', new Date()));

    const dataset = data && data.length && data.map((element, index) => {
      const graphicData = element.graphicData;
      const name = element.label;

      return (
        {
          label: name,
          data: dates.map(date => graphicData.find(item => item.t === date) || null),
          fill: false,
          borderColor: colors[index],
          backgroundColor: colors[index]
        }
      );
    });

    setGraphData({
      labels: dates,
      datasets: dataset
    });
  }, [data]);

  return (
    <>
    <TableGraphicsWatermarkLogo />
    <div className="graphic">
      <div className="graphic__data">
        {graphData ? <Line data={graphData} options={options} /> : null}
      </div>
    </div>
    </>
  );
};

export default Graphic;
