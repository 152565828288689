import React, {useState, useEffect} from 'react';
import getData from '../../services/GetData';
import Calendar from '../../atoms/Calendar/Calendar';
import {parse, format} from 'date-fns';
import { useTranslation } from 'react-i18next';
import {Tabs, Tab} from 'react-bootstrap';
import TableBoardPrices from '../../atoms/Tables/TableBoardPrices/TableBoardPrices';
import { Link } from 'react-router-dom';
import NoFilterData from '../../atoms/NoFilterData/NoFilterData';
import './BoardPricesContent.scss';

const BoardPricesContent = () => {
  const { t } = useTranslation();
  const loader = <div className="loader container p-0"><div className="animated-background" /></div>;
  const [data, setData] = useState();
  const [initialDate, setInitialDate] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const fetchData = date => {
    const url = date ? `${window.env.RAZZLE_ENDPOINT_COTZ_API}/api/BoardPrices/GetBoardPricesHome?date=${date}` :
      `${window.env.RAZZLE_ENDPOINT_COTZ_API}/api/BoardPrices/GetBoardPricesHome`;
    return getData({
      url: url
    });
  };

  const getTable = value => <TableBoardPrices prices={value.prod}/>;

  const getTabs = () => {
    const cities = [
      {name: 'Rosario', prod: []},
      {name: 'Buenos Aires', prod: []},
      {name: 'B.Blanca', prod: []},
      {name: 'Quequén', prod: []},
      {name: 'Córdoba', prod: []}
    ];
    // Format cities & products
    cities.map(value => {
      return data[0].Productos.map(prod => {
        const product = {
          name: prod.Producto,
          price: prod.Mercados.find(item => item.Nombre === value.name)
        };
        return value.prod.push(product);
      });
    });
    return cities.map((value, idx) => <Tab key={`city-${idx}`}eventKey={value.name} title={value.name}>{getTable(value)}</Tab>);
  };

  const changeDate = date => {
    const newDate = format(date, 'yyyyMMdd');
    setShowMessage(false);
    setData(null);
    fetchData(newDate).then(resp => {
      const hasData = resp.data && resp.data[0].Productos && resp.data[0].Productos.length ? true : false;
      setData(hasData ? resp.data : []);
      setShowMessage(!hasData);
    });
  };

  useEffect(() => {
    fetchData().then(resp => {
      setData(resp.data);
      const date = parse(resp.data[0].FechaCarga, 'dd-MM-yyyy', new Date());
      setInitialDate(date);
    });
  }, []);

  return (
    <div className="board-prices-content">
      { initialDate ?
        <div className="mb-20 board-prices-content__header">
        <>
          {data && data.length > 0 && 
            <label className="text5 text-uppercase mb-15">{t('rueda_prices')} {data[0].FechaRueda}</label>
          }
        </>
          <div className="d-flex board-prices-content__header__search">
            <label className="pr-10">{t('date_prices')}</label>
            <Calendar
              parentCallback={changeDate}
              maxDate={initialDate}
              initialDate={initialDate}/>
          </div>
        </div>
        : loader 
      }
      <div className="board-prices-content__table mb-15 gray-tabs">
        {
          showMessage ? <NoFilterData/> :
            !(data && data.length) ? loader :
              <div className="board-prices-content__table__wrapper col-lg-12 p-0 col-12">
                <Tabs>
                  {getTabs()}
                </Tabs>
              </div>
        }
      </div>
      <Link to='/granos/series-historicas-futuros' className="new-link">{t('historical_data')}</Link>
    </div>
  );
};

export default BoardPricesContent;
