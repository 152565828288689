import axios from 'axios';
import buildHeaders from './Headers';

const getData = config => {
  return axios.get(config.url,
    {
      headers:buildHeaders(config),
      params: config.params
    }
  );
}

export default getData;