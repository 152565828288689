import React from 'react';
import styled from 'styled-components';
import TagManager from 'react-gtm-module';

export default function NewsIframe() {
    const manageGTMClick = () => {
        const tagManagerArgs = {
            dataLayer: {
                event: 'trackEvent',
                eventCategory: 'Navigation',
                eventAction: 'Agrofy News-Site Iframe',
                eventLabel: 'link to News'
            }
        };

        TagManager.dataLayer(tagManagerArgs);
    };

    return (
        <Wrapper>
            <Title>Visitá</Title><a href="https://news.agrofy.com.ar/" target="_blank" rel="noopener noreferrer" className="logo" onClick={() => manageGTMClick()}><LogoIcon className="agrofy-news-logo"></LogoIcon></a>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Title = styled.h1`
    margin-right: 24px;
`;

const LogoIcon = styled.i`
    height: 200px; 
    width: 300px;
`;