const cities = [
  {
    city: 'Rosario',
    province: 'Santa Fe',
    slug: 'rosario+santa+fe',
    id: '11222'
  },
  {
    city: 'Capital Federal',
    province: 'Ciudad Autónoma de Buenos Aires',
    id: '7894',
    slug: 'buenos+aires+ciudad+autonoma+de+buenos+aires',
    c2: 'Ciudad Autonoma de Buenos Aires',
    c3: 'capital federal',
    c4: 'caba'
  },
  {
    city: 'Córdoba',
    province: 'Córdoba',
    id: '8869',
    slug: 'ciudad+de+cordoba+cordoba',
    c2: 'Cordoba'
  },
  {
    city: '9 de Julio',
    province: 'Buenos Aires',
    id: '7423',
    slug: '9+de+julio+buenos+aires',
    c2: 'Nueve de Julio'
  },
  {
    city: '9 de Julio',
    province: 'Corrientes',
    id: '8987',
    slug: '9+de+julio+corrientes',
    c2: 'Nueve de Julio'
  },
  {
    city: '9 de Julio',
    province: 'San Juan',
    id: '10559',
    slug: '9+de+julio+san+juan',
    c2: 'Nueve de Julio'
  },
  {
    city: '25 de Mayo',
    province: 'Buenos Aires',
    id: '7441',
    slug: '25+de+mayo+buenos+aires',
    c2: 'Veinticinco de Mayo'
  },
  {
    city: '25 de Mayo',
    province: 'La Pampa',
    id: '9620',
    slug: '25+de+mayo+la+pampa',
    c2: 'Veinticinco de Mayo'
  },
  {
    city: 'Adelia María',
    province: 'Córdoba',
    id: '8587',
    slug: 'adelia+maria+cordoba',
    c2: 'Adelia Maria'
  },
  {
    city: 'Armstrong',
    province: 'Santa Fe',
    slug: 'armstrong+santa+fe',
    id: '6004'
  },
  {
    city: 'Arrecifes',
    province: 'Buenos Aires',
    slug: 'arrecifes+buenos+aires',
    id: '7381'
  },
  {
    city: 'Azul',
    province: 'Buenos Aires',
    slug: 'azul+buenos+aires',
    id: '7383'
  },
  {
    city: 'Bahía Blanca',
    province: 'Buenos Aires',
    id: '2931',
    slug: 'bahia+blanca+buenos+aires',
    c2: 'Bahia Blanca'
  },
  {
    city: 'Bandera',
    province: 'Santiago del Estero',
    id: '11416',
    slug: 'bandera+santiago+del+estero'
  },
  {
    city: 'Benito Juárez',
    province: 'Buenos Aires',
    id: '7051',
    slug: 'benito+juarez+buenos+aires',
    c2: 'Benito Juarez'
  },
  {
    city: 'Bernasconi',
    province: 'La Pampa',
    id: '9531',
    slug: 'bernasconi+la+pampa'
  },
  {
    city: 'Berrotarán',
    province: 'Córdoba',
    id: '8803',
    slug: 'berrotaran+cordoba'
  },
  {
    city: 'Cafferata',
    province: 'Santa Fe',
    id: '10882',
    slug: 'cafferata+santa+fe'
  },
  {
    city: 'Campo Gallo',
    province: 'Santiago del Estero',
    id: '11417',
    slug: 'campo+gallo+santiago+del+estero'
  },
  {
    city: 'Capitán Bermúdez',
    province: 'Santa Fe',
    id: '11092',
    slug: 'capitan+bermudez+santa+fe',
    c2: 'capitan bermudez'
  },
  {
    city: 'Capitán Castro',
    province: 'Buenos Aires',
    id: '7069',
    slug: 'capitan+castro+buenos+aires',
    c2: 'capitan castro'
  },
  {
    city: 'Capitán Sarmiento',
    province: 'Buenos Aires',
    id: '7070',
    slug: 'capitan+sarmiento+buenos+aires',
    c2: 'capitan sarmiento'
  },
  {
    city: 'Carlos Casares',
    province: 'Buenos Aires',
    id: '7389',
    slug: 'carlos+casares+buenos+aires'
  },
  {
    city: 'Carlos Pellegrini',
    province: 'Santa Fe',
    id: '11186',
    slug: 'carlos+pellegrini+santa+fe'
  },
  {
    city: 'Carlos Tejedor',
    province: 'Buenos Aires',
    id: '1229395',
    slug: 'carlos+tejedor+buenos+aires'
  },
  {
    city: 'Carmen de Areco',
    province: 'Buenos Aires',
    id: '7390',
    slug: 'carmen+de+areco+buenos+aires'
  },
  {
    city: 'Carmen de Patagones',
    province: 'Buenos Aires',
    id: '7391',
    slug: 'carmen+de+patagones+buenos+aires'
  },
  {
    city: 'Carmen del Sauce',
    province: 'Santa Fe',
    id: '10886',
    slug: 'carmen+del+sauce+santa+fe'
  },
  {
    city: 'Castelli',
    province: 'Chaco',
    id: '8166',
    slug: 'castelli+chaco'
  },
  {
    city: 'Castelli',
    province: 'Buenos Aires',
    id: '7392',
    slug: 'castelli+buenos+aires'
  },
  {
    city: 'Catamarca',
    province: 'Catamarca',
    id: '3028',
    slug: 'ciudad+de+catamarca+catamarca',
    c2: 'San Fernando del Valle de Catamarca'
  },
  {
    city: 'Ceres',
    province: 'Santa Fe',
    id: '11031',
    slug: 'ceres+santa+fe'
  },
  {
    city: 'Chamical',
    province: 'Rioja',
    id: '9686',
    slug: 'chamical+rioja'
  },
  {
    city: 'Chamicó',
    province: 'Córdoba',
    id: '8348',
    slug: 'chamico+cordoba',
    c2: 'chamico'
  },
  {
    city: 'Chascomús',
    province: 'Buenos Aires',
    id: '7394',
    slug: 'chascomus+buenos+aires',
    c2: 'chascomus'
  },
  {
    city: 'Chivilcoy',
    province: 'Buenos Aires',
    id: '7395',
    slug: 'chivilcoy+buenos+aires'
  },
  {
    city: 'Colón',
    province: 'Buenos Aires',
    id: '7396',
    slug: 'colon+buenos+aires',
    c2: 'colon'
  },
  {
    city: 'Comodoro Rivadavia',
    province: 'Chubut',
    id: '3090',
    slug: 'comodoro+rivadavia+chubut'
  },
  {
    city: 'Comodoro Py',
    province: 'Buenos Aires',
    id: '7744',
    slug: 'comodoro+py+buenos+aires'
  },
  {
    city: 'Concepción',
    province: 'Catamarca',
    id: '7925',
    slug: 'concepcion+catamarca',
    c2: 'concepcion'
  },
  {
    city: 'Concepción',
    province: 'Santiago del Estero',
    id: '11273',
    slug: 'concepcion+santiago+del+estero',
    c2: 'concepcion'
  },
  {
    city: 'Concepción',
    province: 'Tucumán',
    id: '11793',
    slug: 'concepcion+tucuman',
    c2: 'concepcion'
  },
  {
    city: 'Concepción del Uruguay',
    province: 'Entre Ríos',
    id: '3638',
    slug: 'concepcion+uruguay+entre+rios',
    c2: 'concepcion del uruguay'
  },
  {
    city: 'Concordia',
    province: 'Entre Ríos',
    id: '3635',
    slug: 'concordia+entre+rios'
  },
  {
    city: 'Coronel Abraham Schweizer',
    province: 'Corrientes',
    id: '8897',
    slug: 'coronel+abraham+schweizer+corrientes'
  },
  {
    city: 'Coronel Arnold',
    province: 'Santa Fe',
    id: '10914',
    slug: 'coronel+arnold+santa+fe'
  },
  {
    city: 'Coronel Dorrego',
    province: 'Buenos Aires',
    id: '7397',
    slug: 'coronel+dorrego+buenos+aires'
  },
  {
    city: 'Coronel Falcón',
    province: 'Buenos Aires',
    id: '7101',
    slug: 'coronel+falcon+buenos+aires',
    c2: 'coronel falcon'
  },
  {
    city: 'Coronel Fernández',
    province: 'Santiago del Estero',
    id: '11274',
    slug: 'coronel+fernandez+santiago+del+estero',
    c2: 'coronel fernandez'
  },
  {
    city: 'Coronel Fraga',
    province: 'Santa Fe',
    id: '10915',
    slug: 'coronel+fraga+santa+fe'
  },
  {
    city: 'Coronel Granada',
    province: 'Buenos Aires',
    id: '7102',
    slug: 'coronel+granada+buenos+aires'
  },
  {
    city: 'Coronel Pringles',
    province: 'Buenos Aires',
    id: '7398',
    slug: 'coronel+pringles+buenos+aires'
  },
  {
    city: 'Coronel Suárez',
    province: 'Buenos Aires',
    id: '7399',
    slug: 'coronel+suarez+buenos+aires',
    c2: 'coronel suarez'
  },
  {
    city: 'Coronel Vidal',
    province: 'Buenos Aires',
    id: '7400',
    slug: 'coronel+vidal+buenos+aires'
  },
  {
    city: 'Corral de Bustos',
    province: 'Córdoba',
    id: '8597',
    slug: 'corral+bustos+cordoba'
  },
  {
    city: 'Corrientes',
    province: 'Corrientes',
    id: '3196',
    slug: 'ciudad+de+corrientes+corrientes',
    c2: ''
  },
  {
    city: 'Curuzú',
    province: 'Corrientes',
    id: '8979',
    slug: 'curuzu+corrientes',
    c2: 'curuzu'
  },
  {
    city: 'Curuzú Cuatiá',
    province: 'Corrientes',
    id: '8952',
    slug: 'curuzu+cuatia+corrientes',
    c2: 'curuzu cuatia'
  },
  {
    city: 'Daireaux',
    province: 'Buenos Aires',
    id: '7401',
    slug: 'Daireaux+buenos+aires'
  },
  {
    city: 'Dean Funes',
    province: 'Córdoba',
    id: '8568',
    slug: 'dean+funes+cordoba'
  },
  {
    city: 'Dolores',
    province: 'Buenos Aires',
    id: '7403',
    slug: 'dolores+buenos+aires'
  },
  {
    city: 'Dolores',
    province: 'Santiago del Estero',
    id: '11278',
    slug: 'dolores+santiago+del+estero'
  },
  {
    city: 'Esquel',
    province: 'Chubut',
    id: '8244',
    slug: 'esquel+chubut'
  },
  {
    city: 'Ezeiza',
    province: 'Buenos Aires',
    id: '7555',
    slug: 'ezeiza+buenos+aires'
  },
  {
    city: 'Federación',
    province: 'Entre Ríos',
    id: '9038',
    slug: 'federacion+entre+rios',
    c2: 'federacion'
  },
  {
    city: 'Federal',
    province: 'Entre Ríos',
    id: '9103',
    slug: 'federal+entre+rios'
  },
  {
    city: 'Firmat',
    province: 'Santa Fe',
    id: '11059',
    slug: 'firmat+santa+fe'
  },
  {
    city: 'Formosa',
    province: 'Formosa',
    id: '3778',
    slug: 'ciudad+de+formosa+formosa'
  },
  {
    city: 'General Acha',
    province: 'La Pampa',
    id: '9543',
    slug: 'general+acha+la+pampa'
  },
  {
    city: 'General Alvear',
    province: 'Buenos Aires',
    id: '7461',
    slug: 'general+alvear+buenos+aires'
  },
  {
    city: 'General Belgrano',
    province: 'Buenos Aires',
    id: '7406',
    slug: 'general+belgrano+buenos+aires'
  },
  {
    city: 'General Deheza',
    province: 'Córdoba',
    id: '8671',
    slug: 'coronel+deheza+cordoba'
  },
  {
    city: 'General La Madrid',
    province: 'Buenos Aires',
    id: '7408',
    slug: 'general+la+madrid+buenos+aires',
    c2: 'general lamadrid'
  },
  {
    city: 'General Pico',
    province: 'La Pampa',
    id: '9533',
    slug: 'general+pico+la+pampa'
  },
  {
    city: 'General Villegas',
    province: 'Buenos Aires',
    id: '7410',
    slug: 'general+villegas+buenos+aires'
  },
  {
    city: 'Goya',
    province: 'Corrientes',
    id: '8954',
    slug: 'goya+corrientes'
  },
  {
    city: 'Gualeguay',
    province: 'Entre Ríos',
    id: '9104',
    slug: 'gualeguay+entre+rios'
  },
  {
    city: 'Gualeguaychú',
    province: 'Entre Ríos',
    id: '3639',
    slug: 'gualeguaychu+entre+rios',
    c2: 'Gualeguaychu'
  },
  {
    city: 'Henderson',
    province: 'Buenos Aires',
    id: '7412',
    slug: 'henderson+buenos+aires'
  },
  {
    city: 'Hernando',
    province: 'Córdoba',
    id: '8571',
    slug: 'hernando+cordoba'
  },
  {
    city: 'Hughes',
    province: 'Santa Fe',
    id: '10950',
    slug: 'hughes+santa+fe'
  },
  {
    city: 'Humahuaca',
    province: 'Jujuy',
    id: '9415',
    slug: 'humahuaca+jujuy'
  },
  {
    city: 'Iguazú',
    province: 'Misiones',
    id: '10081',
    slug: 'iguazu+misiones',
    c2: 'puerto Iguazu'
  },
  {
    city: 'Ingeniero Guillermo Juárez',
    province: 'Formosa',
    id: '9250',
    slug: 'ingeniero+guillermo+juarez+formosa',
    c2: 'ingeniero guillermo juarez'
  },
  {
    city: 'Ituzaingó',
    province: 'Buenos Aires',
    id: '7190',
    slug: 'ituzaingo+buenos+aires',
    c2: 'ituzaingo'
  },
  {
    city: 'Ituzaingó',
    province: 'Corrientes',
    id: '8962',
    slug: 'ituzaingo+corrientes',
    c2: 'ituzaingo'
  },
  {
    city: 'Jesús María',
    province: 'Córdoba',
    id: '8573',
    slug: 'jesus+maria+cordoba',
    c2: 'jesus maria'
  },
  {
    city: 'Jesús María',
    province: 'Santa Fe',
    id: '10957',
    slug: 'jesus+santa+fe',
    c2: 'jesus maria'
  },
  {
    city: 'Joaquín V. González',
    province: 'Salta',
    id: '10325',
    slug: 'joaquin+gonzalez+salta',
    c2: 'joaquin gonzalez'
  },
  {
    city: 'Juan B. Alberdi',
    province: 'Buenos Aires',
    id: '7198',
    slug: 'juan+alberdi+buenos+aires',
    c2: 'juan bautista alberdi'
  },
  {
    city: 'Juan B. Alberdi',
    province: 'Tucumán',
    id: '1174',
    slug: 'juan+alberdi+tucuman',
    c2: 'juan bautista alberdi'
  },
  {
    city: 'Jujuy',
    province: 'Jujuy',
    id: '4337',
    slug: 'ciudad+de+jujuy+jujuy',
    c2: 'San Salvador de Jujuy'
  },
  {
    city: 'Junín',
    province: 'Buenos Aires',
    id: '2923',
    slug: 'junin+buenos+aires',
    c2: 'junin'
  },
  {
    city: 'Junín de los Andes',
    province: 'Neuquén',
    id: '10141',
    slug: 'junin+de+los+andes+neuquen',
    c2: 'Junin de los Andes'
  },
  {
    city: 'La Carlota',
    province: 'Córdoba',
    id: '3163',
    slug: 'la+carlota+cordoba'
  },
  {
    city: 'La Paz',
    province: 'Córdoba',
    id: '3176',
    slug: 'la+paz+cordoba'
  },
  {
    city: 'La Paz',
    province: 'Entre Ríos',
    id: '3640',
    slug: 'la+paz+entre+ríos'
  },
  {
    city: 'La plata',
    province: 'Buenos Aires',
    slug: 'la+plata+buenos+aires',
    id: '7892'
  },
  {
    city: 'La Quiaca',
    province: 'Jujuy',
    id: '4338',
    slug: 'la+quiaca+jujuy'
  },
  {
    city: 'La Rioja',
    province: 'Rioja',
    id: '4573',
    slug: 'la+rioja+rioja'
  },
  {
    city: 'Laboulaye',
    province: 'Córdoba',
    id: '8575',
    slug: 'laboulaye+cordoba'
  },
  {
    city: 'Las Flores',
    province: 'Buenos Aires',
    id: '2938',
    slug: 'las+flores+buenos+aires'
  },
  {
    city: 'Las Flores',
    province: 'San Juan',
    id: '5954',
    slug: 'las+flores+san+juan'
  },
  {
    city: 'Las Flores',
    province: 'Salta',
    id: '10463',
    slug: 'las+flores+salta'
  },
  {
    city: 'Las Lomitas',
    province: 'Formosa',
    id: '3779',
    slug: 'las+lomitas+formosa'
  },
  {
    city: 'Las Rosas',
    province: 'Santa Fe',
    id: '5994',
    slug: 'las+rosas+santa+fe'
  },
  {
    city: 'Las Varillas',
    province: 'Córdoba',
    id: '3171',
    slug: 'las+varillas+cordoba'
  },
  {
    city: 'Las Varillas',
    province: 'San Juan',
    id: '10615',
    slug: 'las+varillas+san+juan'
  },
  {
    city: 'Leones',
    province: 'Córdoba',
    id: '8604',
    slug: 'leones+cordoba'
  },
  {
    city: 'Lincoln',
    province: 'Buenos Aires',
    id: '7414',
    slug: 'lincoln+buenos+aires'
  },
  {
    city: 'Lobería',
    province: 'Buenos Aires',
    id: '7415',
    slug: 'lobería+buenos+aires',
    c2: 'Loberia'
  },
  {
    city: 'Lobería',
    province: 'Chubut',
    id: '3120',
    slug: 'lobería+chubut',
    c2: 'Loberia'
  },
  {
    city: 'Lobos',
    province: 'Buenos Aires',
    id: '7416',
    slug: 'lobos+buenos+aires'
  },
  {
    city: 'Maciá',
    province: 'Entre Ríos',
    id: '9164',
    slug: 'macia+entre+rios',
    c2: 'macia'
  },
  {
    city: 'Maipú',
    province: 'Buenos Aires',
    id: '7419',
    slug: 'maipu+buenos+aires',
    c2: 'maipu'
  },
  {
    city: 'Maipú',
    province: 'Mendoza',
    id: '9875',
    slug: 'maipu+mendoza',
    c2: 'maipu'
  },
  {
    city: 'Malargüe',
    province: 'Mendoza',
    id: '9876',
    slug: 'malargue+mendoza'
  },
  {
    city: 'Maquinchao',
    province: 'Río Negro',
    id: '10227',
    slug: 'maquinchao+rio+negro'
  },
  {
    city: 'Mar del Plata',
    province: 'Buenos Aires',
    id: '7893',
    slug: 'mar+del+plata+buenos+aires'
  },
  {
    city: 'Marcos Juárez',
    province: 'Córdoba',
    id: '8576',
    slug: 'marcos+juarez+cordoba',
    c2: 'marcos juarez'
  },
  {
    city: 'Marcos Paz',
    province: 'Buenos Aires',
    id: '7420',
    slug: 'marcos+paz+buenos+aires'
  },
  {
    city: 'María Teresa',
    province: 'Santa Fe',
    id: '11066',
    slug: 'marian+teresa+santa+fe',
    c2: 'maria teresa'
  },
  {
    city: 'Mendoza',
    province: 'Mendoza',
    id: '5126',
    slug: 'ciudad+de+mendoza+mendoza'
  },
  {
    city: 'Mercedes',
    province: 'Buenos Aires',
    id: '7421',
    slug: 'mercedes+buenos+aires'
  },
  {
    city: 'Mercedes',
    province: 'Corrientes',
    id: '8955',
    slug: 'mercedes+corrientes'
  },
  {
    city: 'Monte Caseros',
    province: 'Corrientes',
    id: '8956',
    slug: 'monte+caseros+corrientes'
  },
  {
    city: 'Necochea',
    province: 'Buenos Aires',
    id: '2924',
    slug: 'necochea+buenos+aires'
  },
  {
    city: 'Neuquén',
    province: 'Neuquén',
    id: '5856',
    slug: 'ciudad+de+neuquen+neuquen',
    c2: 'neuquen'
  },
  {
    city: 'Nogoyá',
    province: 'Entre Ríos',
    id: '9105',
    slug: 'nogoya+entre+rios',
    c2: 'nogoya'
  },
  {
    city: 'Olavarría',
    province: 'Buenos Aires',
    id: '2925',
    slug: 'olvarria+buenos+aires',
    c2: 'olvarria'
  },
  {
    city: 'Orán',
    province: 'Tucuman',
    id: '11736',
    slug: 'oran+tucuman',
    c2: 'oran'
  },
  {
    city: 'Paraná',
    province: 'Entre Ríos',
    id: '3634',
    slug: 'parana+entre+rios',
    c2: 'parana'
  },
  {
    city: 'Parera',
    province: 'La Pampa',
    id: '9607',
    slug: 'parera+la+pampa'
  },
  {
    city: 'Paso de los Libres',
    province: 'Corrientes',
    id: '8957',
    slug: 'paso+de+los+liebres+corrientes'
  },
  {
    city: 'Paso de los Indios',
    province: 'Neuquén',
    id: '10142',
    slug: 'paso+de+los+indios'
  },
  {
    city: 'Pehuajó',
    province: 'Buenos Aires',
    id: '7425',
    slug: 'pehuajo+buenos+aires',
    c2: 'pehuajo'
  },
  {
    city: 'Pellegrini',
    province: 'Buenos Aires',
    id: '7490',
    slug: 'pellegrini+buenos+aires'
  },
  {
    city: 'Pergamino',
    province: 'Buenos Aires',
    id: '2926',
    slug: 'pergamino+buenos+aires'
  },
  {
    city: 'Perito Moreno',
    province: 'Río Negro',
    id: '10232',
    slug: 'perito+moreno+rio+negro'
  },
  {
    city: 'Perito Moreno',
    province: 'Santa Cruz',
    id: '10834',
    slug: 'perito+moreno+santa+cruz'
  },
  {
    city: 'Pilar',
    province: 'Buenos Aires',
    id: '2927',
    slug: 'pilar+buenos+aires'
  },
  {
    city: 'Pilar',
    province: 'Córdoba',
    id: '8607',
    slug: 'pilar+cordoba'
  },
  {
    city: 'Posadas',
    province: 'Misiones',
    id: '5168',
    slug: 'posadas+misiones'
  },
  {
    city: 'Presidencia Roque Sáenz Peña',
    province: 'Chaco',
    id: '3040',
    slug: 'presidencia+roque+saenz+pena',
    c2: 'Presidencia Roque Saenz Peña'
  },
  {
    city: 'Puerto Deseado',
    province: 'Santa Cruz',
    id: '10844',
    slug: 'puerto+deseado+santa+cruz'
  },
  {
    city: 'Rafaela',
    province: 'Santa Fe',
    id: '5992',
    slug: 'rafaela+santa+fe'
  },
  {
    city: 'Rauch',
    province: 'Buenos Aires',
    id: '7430',
    slug: 'rauch+buenos+aires'
  },
  {
    city: 'Reconquista',
    province: 'Santa Fe',
    id: '11038',
    slug: 'reconquista+santa+fe'
  },
  {
    city: 'Río Cuarto',
    province: 'Córdoba',
    id: '3165',
    slug: 'rio+cuarto+cordoba',
    c2: 'rio cuarto'
  },
  {
    city: 'Río Gallegos',
    province: 'Santa Cruz',
    id: '5967',
    slug: 'rio+gallegos+santa+cruz'
  },
  {
    city: 'Río Seco',
    province: 'Tucuman',
    id: '11752',
    slug: 'rio+seco+tucuman',
    c2: 'rio seco'
  },
  {
    city: 'Rivadavia',
    province: 'San Juan',
    id: '10609',
    slug: 'rivadavia+san+juan'
  },
  {
    city: 'Rivadavia',
    province: 'Buenos Aires',
    id: '7431',
    slug: 'rivadavia+buenos+aires'
  },
  {
    city: 'Rivadavia',
    province: 'Mendoza',
    id: '9879',
    slug: 'rivadavia+mendoza'
  },
  {
    city: 'Rojas',
    province: 'Buenos Aires',
    id: '7433',
    slug: 'rojas+buenos+aires'
  },
  {
    city: 'Roque Pérez',
    province: 'Buenos Aires',
    id: '7859',
    slug: 'roque+perez+buenos+aires',
    c2: 'roque perez'
  },
  {
    city: 'Rosario del Tala',
    province: 'Entre Ríos',
    id: '9080',
    slug: 'rosario+del+tala+entre+rios'
  },
  {
    city: 'Rufino',
    province: 'Santa Fe',
    id: '11040',
    slug: 'rufino+santa+fe'
  },
  {
    city: 'Saladillo',
    province: 'Buenos Aires',
    id: '7434',
    slug: 'saladillo+buenos+aires'
  },
  {
    city: 'Saladillo',
    province: 'Córdoba',
    id: '8504',
    slug: 'saladillo+cordoba'
  },
  {
    city: 'Saladillo',
    province: 'Jujuy',
    id: '9389',
    slug: 'saladillo+jujuy'
  },
  {
    city: 'Saladillo',
    province: 'San Luis',
    id: '10803',
    slug: 'saladillo+san+luis'
  },
  {
    city: 'Salliqueló',
    province: 'Buenos Aires',
    id: '7435',
    slug: 'salliquelo+buenos+aires',
    c2: 'salliquelo'
  },
  {
    city: 'Salta',
    province: 'Salta',
    id: '10531',
    slug: 'ciudad+de+salta+salta'
  },
  {
    city: 'San Agustín',
    province: 'Córdoba',
    id: '8736',
    slug: 'san+agustin+cordoba',
    c2: 'san agustin cordoba'
  },
  {
    city: 'San Agustin',
    province: 'San Juan',
    id: '5953',
    slug: 'san+agustin+san+juan',
    c2: 'San Agustin de Valle Fertil'
  },
  {
    city: 'San Antonio de Padua',
    province: 'Buenos Aires',
    id: '7617',
    slug: 'san+antonio+buenos+aires'
  },
  {
    city: 'San Antonio Oeste',
    province: 'Río Negro',
    id: '1447',
    slug: 'san+antonio+oeste+rio+negro'
  },
  {
    city: 'San Carlos',
    province: 'Mendoza',
    id: '5134',
    slug: 'san+carlos+mendoza'
  },
  {
    city: 'San Cristóbal',
    province: 'Santa Fe',
    id: '6000',
    slug: 'san+cristobal+santa+fe',
    c2: 'san cristobal'
  },
  {
    city: 'San Francisco',
    province: 'Córdoba',
    id: '3172',
    slug: 'san+francisco+cordoba'
  },
  {
    city: 'San Javier',
    province: 'Córdoa',
    id: '8746',
    slug: 'san+javier+cordoba'
  },
  {
    city: 'San Javier',
    province: 'Jujuy',
    id: '9446',
    slug: 'san+javier+jujuy'
  },
  {
    city: 'San Javier',
    province: 'Río Negro',
    id: '10251',
    slug: 'san+javier+rio+negro'
  },
  {
    city: 'San Javier',
    province: 'Santa Fe',
    id: '6001',
    slug: 'san+javier+santa+fe'
  },
  {
    city: 'San José de Feliciano',
    province: 'Entre Ríos',
    id: '3641',
    slug: 'san+jose+de+feliciano',
    c2: 'san jose de feliciano'
  },
  {
    city: 'San José de la Esquina',
    province: 'Santa Fe',
    id: '6012',
    slug: 'san+jose+de+la+esquina',
    c2: 'san jose de la esquina'
  },
  {
    city: 'San Juan',
    province: 'San Juan',
    id: '5952',
    slug: 'ciudad+de+san+juan'
  },
  {
    city: 'San Julián',
    province: 'Santa Cruz',
    id: '1485',
    slug: 'san+julian+santa+cruz',
    c2: 'san julian'
  },
  {
    city: 'San Luis',
    province: 'San Luis',
    id: '5955',
    slug: 'ciudad+de+san+luis+san+luis'
  },
  {
    city: 'San Martín',
    province: 'Mendoza',
    id: '5132',
    slug: 'san+martin+mendoza',
    c2: 'san martin'
  },
  {
    city: 'San Martín de los Andes',
    province: 'Neuquén',
    id: '5526',
    slug: 'san+martin+andes+neuquen',
    c2: 'san martin de los andes'
  },
  {
    city: 'San Pedro',
    province: 'Buenos Aires',
    id: '2943',
    slug: 'san+pedro+buenos+aires'
  },
  {
    city: 'San Rafael',
    province: 'Mendoza',
    id: '5127',
    slug: 'san+rafael+mendoza'
  },
  {
    city: 'Santa Fe',
    province: 'Santa Fe',
    id: '11221',
    slug: 'ciudad+de+santa+fe+santa+fe'
  },
  {
    city: 'Santa María',
    province: 'Catamarca',
    id: '1228197',
    slug: 'santa+maria+catamarca',
    c2: 'santa maria'
  },
  {
    city: 'Santa Rosa',
    province: 'La Pampa',
    id: '4566',
    slug: 'santa+rosa+la+pampa'
  },
  {
    city: 'Santa Rosa de Río Primero',
    province: 'Córdoba',
    id: '8624',
    slug: 'santa+rosa+de+rio+primero+cordoba',
    c2: 'santa rosa de rio primero'
  },
  {
    city: 'Santiago del Estero',
    province: 'Santiago del Estero',
    id: '6018',
    slug: 'ciudad+de+santiago+estero+santiago+estero'
  },
  {
    city: 'Santo Tomé',
    province: 'Santa Fe',
    id: '5995',
    slug: 'santo+tome+santa+fe',
    c2: 'santo tome'
  },
  {
    city: 'Sastre',
    province: 'Santa Fe',
    id: '11071',
    slug: 'sastre+santa+fe'
  },
  {
    city: 'Sauce Viejo',
    province: 'Santa Fe',
    id: '11207',
    slug: 'sauce+viejo+santa+fe'
  },
  {
    city: 'Tandil',
    province: 'Buenos Aires',
    id: '2929',
    slug: 'tandil+buenos+aires'
  },
  {
    city: 'Tartagal',
    province: 'Salta',
    id: '10396',
    slug: 'tartagal+salta'
  },
  {
    city: 'Tartagal',
    province: 'Santa Fe',
    id: '11209',
    slug: 'tartagal+santa+fe'
  },
  {
    city: 'Tornquist',
    province: 'Buenos Aires',
    id: '7437',
    slug: 'tornquist+buenos+aires'
  },
  {
    city: 'Trelew',
    province: 'Chubut',
    id: '3091',
    slug: 'trelew+chubut'
  },
  {
    city: 'Trenque Lauquen',
    province: 'Buenos Aires',
    id: '7438',
    slug: 'trenque+lauquen+buenos+aires'
  },
  {
    city: 'Tres Arroyos',
    province: 'Buenos Aires',
    id: '7439',
    slug: 'tres+arroyos+buenos+aires',
    c2: '3 arroyos'
  },
  {
    city: 'Tucumán',
    province: 'Tucuman',
    id: '11869',
    slug: 'ciudad+de+tucuman+tucuman',
    c2: 'San Miguel de Tucumán'
  },
  {
    city: 'Ushuaia',
    province: 'Tierra del Fuego',
    id: '6462',
    slug: 'ushuaia+tierra+del+fuego'
  },
  {
    city: 'Venado Tuerto',
    province: 'Santa Fe',
    id: '11044',
    slug: 'venado+tuerto+santa+fe'
  },
  {
    city: 'Victoria',
    province: 'Buenos Aires',
    id: '7350',
    slug: 'victoria+buenos+aires'
  },
  {
    city: 'Victoria',
    province: 'Entre Ríos',
    id: '3643',
    slug: 'victoria+entre+rios'
  },
  {
    city: 'Viedma',
    province: 'Río Negro',
    id: '5858',
    slug: 'viedma+rio+negro'
  },
  {
    city: 'Villa Cañás',
    province: 'Santa Fe',
    id: '11073',
    slug: 'villa+canas+santa+fe',
    c2: 'villa cañas',
    c3: 'villa canas'
  },
  {
    city: 'Villa Dolores',
    province: 'Córdoba',
    id: '8545',
    slug: 'villa+dolores+cordoba'
  },
  {
    city: 'Villa Dolores',
    province: 'Catamarca',
    id: '7983',
    slug: 'villa+dolores+catamarca'
  },
  {
    city: 'Villa General Belgrano',
    province: 'Córdoba',
    id: '1227998',
    slug: 'villa+general+belgrano'
  },
  {
    city: 'Villa María',
    province: 'Córdoba',
    id: '3164',
    slug: 'villa+maria+cordoba',
    c2: 'villa maria'
  },
  {
    city: 'Villa María',
    province: 'Buenos Aires',
    id: '7363',
    slug: 'villa+maria+buenos+aires',
    c2: 'villa maria'
  },
  {
    city: 'Villa María Grande',
    province: 'Entre Ríos',
    id: '9117',
    slug: 'villa+maria+grande+entre+rios',
    c2: 'villa maria grande'
  },
  {
    city: 'Villa Ocampo',
    province: 'Santa Fe',
    id: '11048',
    slug: 'villa+ocampo+santa+fe'
  },
  {
    city: 'Villa Paranacito',
    province: 'Entre Ríos',
    id: '9092',
    slug: 'villa+paranacito+entre+rios'
  },
  {
    city: 'Villa Reynolds',
    province: 'San Luis',
    id: '10807',
    slug: 'villa+reynolds+san+luis'
  },
  {
    city: 'Villaguay',
    province: 'Entre Ríos',
    id: '3644',
    slug: 'villaguay+entre+rios'
  },
  {
    city: 'Villalonga',
    province: 'Buenos Aires',
    id: '7510',
    slug: 'villalonga+buenos+aires'
  }
];

export default cities;
