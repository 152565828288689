
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import img from '../../images/icons/newsletterImg.png';
import { AgroButton, AgroInput } from 'agrofy-ui-components';
import './NewsletterCardWithInput.scss';

const NewsletterCardWithInput = (props) => {
    const [value, setValue] = useState('');

    const validEmail = () => {
        /* Validate email */
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(value);
    };
    const handlePage = () => {
        props.history.push(`/newsletter?email=${value}`)
    }
    return (
        <div className="newsletter-card-container">
            <img src={img} />
            <div className="form-container">
                <span className="title">¡Suscribite a nuestro newsletter!</span>
                <p className="desc">Y enterate de la últimas novedades del Agro</p>
                <div className="controls-cont">
                    <AgroInput placeholder='Ingresá tu e-mail' value={value}
                        onChange={(e) => setValue(e.currentTarget.value)} />
                    <AgroButton disabled={!validEmail()} onClick={handlePage}>Suscribirme </AgroButton></div>
            </div>
        </div>
    )
}

export default withRouter(NewsletterCardWithInput);