import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './MainBreakingNews.scss';

const MainBreakingNews = ({data}) => {
  const [isExternal, setIsExternal] = useState(false);

  useEffect(() => {
    if (data?.url.includes("pulperia/") || data?.url.includes("noticia/") || data?.url.includes("opinion/")) {
      setIsExternal(true);
    }
    else {
      setIsExternal(false);
    }
  }, [data?.url]);

  const content = () => {
    return (
      <>
        <h6 className="category">{data && data.category}</h6>
        <h2 className="title">{data && data.title}</h2>
        <img alt={data && data.title} src={`${data && data.image}`} />
      </>
    )
  };

  return (
    <div className="breaking-news-main mb-20 col-12 p-0">
      <section className="container">
        {
          isExternal  ?
          <a href={(data && data.url) || '/'} className="m-0">
            {content()}
          </a>
          :
          <Link to={(data && data.url) || '/'} className="m-0">
            {content()}
          </Link>
        }
        
      </section>
    </div>
  );
};

MainBreakingNews.propTypes = {
  data: PropTypes.object
};

export default MainBreakingNews;
