import React, {useEffect, useContext} from 'react';
import UserContext from '../../atoms/UserContext/UserContext';
import { manageGTMUserStatus } from '../../utils/GTMDataLayerFuncs';
import styled from 'styled-components';

const Title = styled.h3`
  margin: 32px 0;
`;

export const TermsOfUse = () => {

  const { mkpUserData, userLoading } = useContext(UserContext);

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div className='container mb-36'>
      <Title>Legales</Title>
      <p>
        El material publicado en este sitio tiene como único propósito el brindar información a cualquier visitante, sin que ello implique responsabilidad alguna
        respecto de la exactitud e integridad de los datos que están volcados en el mismo.
      </p>
      <p>
        Quien acceda a este sitio acepta que toda información que se encuentra en el mismo y los errores o las omisiones en que se incurra no podrán ser utilizados
        como base de cualquier reclamo o demanda o causa de acción legal. De igual forma, la información o cualquier opinión que estén expresadas en este sitio no constituyen
        de manera alguna una oferta o solicitud de compra o venta de producto alguno, ni debe servir como base para decisiones de inversión.
      </p>
      <Title>Condiciones y Términos de uso</Title>
      <p>
        Todos los derechos reservados. La reproducción total o parcial de la información contenida en este sitio (incluyendo el método de frames) queda expresamente prohibida.
        Agrofy News y su isologotipo son marcas registradas de Agrofy S.A.
      </p>
      <p>
        Queda terminantemente prohibida la venta, alquiler, redistribución, transmisión, retransmisión, difusión y conversión a cable o computadora o cualquier otra forma y/o medio
        que se utilice para proveer, difundir o compartir, sea con o sin fines comerciales, la información brindada por Agrofy News o de terceros citados como fuentes
        de información incluidas en el sitio.
      </p>
      <p>Queda expresamente aclarado que Agrofy S.A. no emite juicios de valor de ningún tipo en los informes que brinda ni tampoco tiene responsabilidad alguna
          sobre las decisiones a las que el receptor de este informe arribe, ya que éstas son producto de sus facultades discrecionales.
      </p>
      <p>
        La información ha sido obtenida de medios públicos y/o privados, considerados confiables por Agrofy News, aunque no se asegura su exactitud ni integridad.
        Agrofy S.A. niega toda responsabilidad sobre la precisión, exactitud y/o omisión en los datos exhibidos en sus servicios de información ya que éstos
        son generados por las fuentes citadas en cada caso, reservándose el derecho de publicar la misma a su solo criterio.
      </p>
      <p>
        El usuario o suscriptor de Agrofy News acepta y entiende que el uso de la información y las decisiones que se tomen en razón de las mismas,
        son realizadas enteramente bajo su propio riesgo.
      </p>
      <p>
        Los datos y elaboración de la información, se realizan y muestran como simple ejercicio analítico, no pretendiendo calificar el desempeño de ninguna institución.
      </p>
      <p>
        La información tanto de noticias, informes, cotizaciones de bolsa, eventos, etc. contenida en este sitio es exclusivamente para información del usuario o
        suscriptor de Agrofy News y no supone un asesoramiento, calificación o sugerencia de compra o de venta de ningún producto o servicio.
        Ni Agrofy News ni cualquiera de sus proveedores de contenidos se responsabilizan ante cualquier error o retraso en la información que contiene este sitio.
      </p>
      <p>
        Agrofy News no se responsabiliza por la interpretación y/o mala interpretación de lo expuesto en el sitio o en las consultas (en forma explícita o implícita),
        ni de su uso indebido, ni de perjuicios reales directos o indirectos o supuestos invocados por quienes fueran inducidos a tomar u omitir decisiones o acciones,
        al consultar este sitio o sus servicios.
      </p>
      <p>
        Tampoco garantiza ni se adhiere a la información y opiniones vertidas en los destinos listados en el sitio.
        Cada lector deberá consultar a su asesor legal, impositivo, agente de negocios o asesor que considere oportuno.
        El contenido de la información no deberá tomarse como un asesoramiento, consultoría, recomendación o sugerencia en materia de negocios,
        inversiones, aspectos legales o impositivos.
      </p>
    </div>
  );
};
export default TermsOfUse;
