import React, {useState, useEffect} from 'react';
import getData from '../../services/GetData';
import TableOfitialPrices from '../../atoms/Tables/TableOfitialPrices/TableOfitialPrices';
import Calendar from '../../atoms/Calendar/Calendar';
import NoFilterData from '../../atoms/NoFilterData/NoFilterData';
import {format, parse} from 'date-fns';
import { useTranslation } from 'react-i18next';
import './OficialPricesContent.scss';

const OficialPricesContent = () => {
  const { t } = useTranslation();
  const loader = <div className="loader container p-0"><div className="animated-background" /></div>;
  const [tableData, setTableData] = useState();
  const [initialDate, setInitialDate] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const fetchData = date => {
    const url = date ? `${window.env.RAZZLE_ENDPOINT_COTZ_API}/api/OfficialPrices/GetOfficialPrices?date=${date}` :
      `${window.env.RAZZLE_ENDPOINT_COTZ_API}/api/OfficialPrices/GetOfficialPrices?date=`;
    return getData({
      url: url
    });
  };

  const changeDate = date => {
    const newDate = format(date, 'yyyyMMdd');
    setShowMessage(false);
    setTableData(null);
    fetchData(newDate).then(resp => {
      setTableData(resp.data);
      setShowMessage(!(resp.data && resp.data.length));
    });
  };

  useEffect(() => {
    fetchData().then(resp => {
      setTableData(resp.data);
      const date = parse(resp.data[0].Fecha, 'dd-MM-yy', new Date());
      setInitialDate(date);
    });
  }, []);

  return (
    <div className="oficial-prices-content">
      { initialDate ?
        <div className="mb-20 board-prices-content__header">
          <div className="d-flex board-prices-content__header__search">
            <label className="pr-10 off-lbl">{t('date_prices')}</label>
            <Calendar
              parentCallback={changeDate}
              maxDate={initialDate}
              initialDate={initialDate}/>
          </div>
        </div>
        : loader }
      <div className="oficial-prices-content__table mb-15">
        { // eslint-disable-next-line no-nested-ternary
          showMessage ? <NoFilterData/> :
            !(tableData && tableData.length) ?
              loader : <TableOfitialPrices dataPrices={tableData[0].Productos} />
        }
      </div>
    </div>
  );
};

export default OficialPricesContent;
