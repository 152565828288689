import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './ForecastCard.scss';

const ForecastCard = ({forecast5days}) => {
  const { t } = useTranslation();
  const loader = <div className="loader container pr-0"><div className="animated-background" /></div>;
  const language = process.env.RAZZLE_LANGUAGE || window.env.RAZZLE_LANGUAGE;

  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";

  function formatDate(d) {
    const date = new Date(d);
    const options = {weekday: 'long', day: 'numeric'};

    var dateString = date.toLocaleDateString(language, options);

    if (isBrasil) {
      dateString = dateString.replace("-feira", "");
    }

    return dateString;
  }

  return (
    <React.Fragment>
      {!forecast5days
        ? loader
        : forecast5days && forecast5days.DailyForecasts && forecast5days.DailyForecasts.map((item, index) => (
          <li key={item.Date} className={'forecast-card'}>
            <i className={`clima-icon main-icon icon-${item.Day.Icon}`} />
            <h5 className="day title5">
              {index === 0 ? t('today,') : ''} {formatDate(item.Date)}
            </h5>
            <h2 className="title0 temperature">
              {`${Math.round(item.Temperature.Minimum.Value)}° / ${Math.round(item.Temperature.Maximum.Value)}°`}
            </h2>
            <i className={`clima-icon icon-wind-arrow ${item.Day.Wind.Direction.English}`} />
            <span className="wind-description text2 barlow">
              {`${item.Day.Wind.Direction.Localized}, ${item.Day.Wind.Speed.Value} ${item.Day.Wind.Speed.Unit}`}
            </span>
            <p className="forecast-description mt-20 mb-0">
              <span className="icon-phrase text2 barlow bold">{item.Day.IconPhrase}</span>
              <span className="long-phrase text2 open-sans">{(item.Day.LongPhrase).replace(/;/g, ',')}.</span>
            </p>
          </li>
        ))
      }
    </React.Fragment>
  );
};

ForecastCard.propTypes = {
  forecast5days: PropTypes.object
};

export default ForecastCard;
