import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import es_ar from '../public/locales/es/translation';
import links_ar from '../public/locales/es/links';
import pt_br from '../public/locales/pt/translation';
import links_br from '../public/locales/pt/links';

const resources = {
  es: {
    translation: es_ar,
    links: links_ar
  },
  pt: {
    translation: pt_br,
    links: links_br
  }
};

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'es',
    debug: false,
    resources,
    react: {
      wait: true
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
