/* eslint-disable no-return-assign */
/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable default-case */
/* eslint-disable quotes */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, {useState} from 'react';
import ButtonAgrofy from '../../../../atoms/ButtonAgrofy/ButtonAgrofy';
import {useTranslation} from 'react-i18next';
import data from './../../ConverterData';

const AreaConverter = () => {
  const {t} = useTranslation();
  const [inputValue, setImputValue] = useState();
  const [radioChecked, setRadioChecked] = useState('radio7');
  const [operator, setOperator] = useState('m');
  const [convertedValue, setConvertedValue] = useState();
  const [suffix, setSuffix] = useState();
  const multiplier = data.superficie.multiplier.default;

  const handleChangeInput = e => {
    setImputValue(e.target.value);
  };

  const handleChangeRadio = (radio, op) => {
    setRadioChecked(radio);
    setOperator(op);
  };

  const getSuffix = () => {
    const ldata = data.superficie.suffix.default;
    return operator === 'm' ? ldata.m : ldata.d;
  };

  const handleGetConvertion = () => {
    operator === 'm' ? setConvertedValue((inputValue * multiplier).toFixed(4)) : setConvertedValue((inputValue / multiplier).toFixed(4));
    setSuffix(getSuffix());
  };

  return (
    <div className="converter production">
      <span className="d-flex w-100 mb-10 text text1">{t('I_want_to_convert')}</span>
      <input value={inputValue} placeholder={t('enter_value')} type="number" onChange={e => handleChangeInput(e)} />
      <br />
      <span className="d-flex w-100 my-10 text text1">{t('from')}:</span>

      <label className="radio text">
        <input id="radio7" type="radio" name="areaGroup" className="hidden" checked={radioChecked === 'radio7'} onChange={() => handleChangeRadio('radio7', 'm')} />
        <span className="label" />acre a has
      </label>
      <label className="radio text">
        <input id="radio8" type="radio" name="areaGroup" className="hidden" checked={radioChecked === 'radio8'} onChange={() => handleChangeRadio('radio8', 'd')} />
        <span className="label" />has a acre
      </label>
      <br />
      <p className="w-100 d-flex mt-10 mb-20">
        {typeof convertedValue === "undefined" ? '' :
          convertedValue > 0 ? <h5 className="result open-sans title5">{convertedValue} {suffix}</h5> :
            <span className="error open-sans bold title5">{t('enter_value_greater_than_0')}</span>}
      </p>
      <ButtonAgrofy title={t('convert')} parentCallback={handleGetConvertion} classes="primary" />
    </div>
  );
};

export default AreaConverter;
