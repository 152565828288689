import React from 'react';
import {Table} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import NoFilterData from '../../../atoms/NoFilterData/NoFilterData';
import './TableLiniersPrices.scss';

function TableLiniersPrices({prices}) {
  const { t } = useTranslation();
  const loader = <div className="loader container pr-0"><div className="animated-background" /></div>;

  const setTable = () => {
    const tbody = prices.Categorias.map((value, idx) =>
      <tr key={`prices-${idx}`}>
        <td>{value.Categoria}</td>
        <td className="align-center">{value.PrecioMinimo}</td>
        <td className="align-center">{value.PrecioMaximo}</td>
        <td className="align-center">
          {
            // eslint-disable-next-line no-nested-ternary
            <div className={`${parseFloat(value.Variacion) > 0 ? 'up arrow' : parseFloat(value.Variacion) < 0 ? 'down arrow' : 'equal'}`}>
              <label className="percent pr-2 market-prices-lbl">{value.Variacion}</label>
              <label className="indicator market-prices-lbl">
                <label className="icon market-prices-lbl"></label>
              </label>
            </div>
          }
        </td>
      </tr>
    );
    return (
      <React.Fragment>
        <Table className="table-agrofy is-gray">
          <thead>
            <tr>
              <th>{t('categories')}</th>
              <th className="align-center">{t('min_price')} $</th>
              <th className="align-center">{t('max_price')} $</th>
              <th className="align-center">{t('variation')} $</th>
            </tr>
          </thead>
          <tbody>
            {tbody}
          </tbody>
        </Table>
      </React.Fragment>
    );
  };

  return (
    <div className="market-table-section">
      {prices ? setTable() : <NoFilterData/>}
      {!prices ? null : (
        <React.Fragment>
          <h5 className="mt-20">{t('comment')}</h5>
          <p>{prices.Comentario}</p>
        </React.Fragment>
      )}
    </div>
  );
}

export default TableLiniersPrices;
