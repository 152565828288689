/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-bootstrap/Pagination';
import './Pagination.scss';

const PaginationAgrofy = ({currentPage, amount, parentCallback}) => {
  const handleClick = num => {
    parentCallback(num);
  };

  const setPagination = () => {
    const items = [];
    for (let number = 1; number <= amount; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number == currentPage}
          onClick={event => handleClick(Number(event.target.text))}>
          {number}
        </Pagination.Item>,
      );
    }
    return currentPage == 1 ? items.slice(currentPage - 1, currentPage + 4)
      : currentPage == 2 ? items.slice(currentPage - 2, currentPage + 3)
        : currentPage == amount - 1 ? items.slice(currentPage - 4, currentPage + 2)
          : currentPage == amount ? items.slice(currentPage - 5, currentPage + 2) : items.slice(currentPage - 3, currentPage + 2);
  };

  return (
    <div>
      <Pagination className="agrofy-pagination">
        <Pagination.First key={'first'} className="custom-arrow first" onClick={event => handleClick(1)}/>
        <Pagination.Prev key={'prev'} className="custom-arrow prev" onClick={event => handleClick(currentPage == 1 ? 1 : currentPage - 1)}/>
        {currentPage && setPagination()}
        <Pagination.Next key={'next'} className="custom-arrow next" onClick={event => handleClick(amount == currentPage ? currentPage : currentPage + 1)}/>
        <Pagination.Last key={'last'} className="custom-arrow last" onClick={event => handleClick(amount)}/>
      </Pagination>
    </div>
  );
};

PaginationAgrofy.propTypes = {
  currentPage: PropTypes.number,
  amount: PropTypes.number,
  parentCallback: PropTypes.any
};

export default PaginationAgrofy;
