import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CmaLogo from '../../../images/icons/cma-logo.svg';
import CepeaLogo from '../../../images/icons/cepea-logo.png';
import '../GranosTable/GranosTable.scss';

const GranosTableBR = props => {
  const { t } = useTranslation();
  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";

  const classNames = item => item % 2 ? 'even-item' : 'odd-item';

  const format = (val, prodID) => (prodID && prodID === 32 ? "¢" : "") + (val && val.toString().replace('.',','));

  const translateCBOTPosition = position => {
    return t(`months_short_cbot.${position.slice(0, 3)}`).toUpperCase() + position.slice(3);
  };

  const renderMktLinks = () => {
    const marketLinks = [
      { title: "Futuros online", url: "/granos/futuros-opciones" }, { title: "Precios Pizarra", url: "/granos/precios-pizarra" }, { title: "Mercado Físico", url: "/granos/mercado-fisico" }, { title: "Precios Oficiales", url: "/granos/precios-oficiales" }, { title: "Series históricas", url: "/granos/series-historicas-futuros" }
    ]

    return (
      marketLinks.map(item => {
        return (<h3><Link to={item.url}>{item.title}</Link></h3>)
      })
    )
  };

  const getFutureTable = item => {
    const tbody = item.Mercados.map((value, idx) =>
      <tr key={`future-${idx}`} className={classNames(idx)}>
        <td>{value.Nombre}</td>
        <td>{isBrasil ? translateCBOTPosition(value.Posicion) : value.Posicion}</td>
        <td className="pl-1 pr-0">
          {
            // eslint-disable-next-line no-nested-ternary
            <div className={`${value.Var > 0 ? 'up-icon arrow' : value.Var < 0 ? 'down-icon arrow' : 'equal-icon'} tab-item cell-price d-flex`}>
              <label className="priceTable pr-2">{value.Precio}</label>
              <label className="indicator">
                <label className="icon"></label>
              </label>
            </div>
          }
        </td>
        <td className="pl-1 pr-0">
          {
            // eslint-disable-next-line no-nested-ternary
            <div className={`${value.Var > 0 ? 'up arrow' : value.Var < 0 ? 'down arrow' : 'equal'} tab-item cell-end d-flex`}>
              <label className="percent">{format(value.Var)}</label>
            </div>
          }
        </td>
      </tr>
    );
    return (
      <Table className="fixed-cells">
        <thead>
          <tr>
            <th><h2>{`${item.Nombre} >`}</h2></th>
            <th className="text-uppercase">{t('position')}</th>
            <th className="text-right text-uppercase">{t('price')} U$</th>
            <th className="text-right cell-end">VAR U$</th>
          </tr>
        </thead>
        <tbody>
          {tbody}
        </tbody>
      </Table>
    );
  };

  const getCepeaTable = cepea => {
    const tbody = cepea.map((value, idx) =>
      <tr key={`future-${idx}`} className={classNames(idx)}>
        <td>{value.ProductDescription}</td>
        <td>{value.TypeDescription}</td>
        <td className="pl-1 pr-0">
          {
            // eslint-disable-next-line no-nested-ternary
            <div className={`${value.Variation > 0 ? 'up-icon arrow' : value.Variation < 0 ? 'down-icon arrow' : 'equal-icon'} tab-item cell-price d-flex`}>
              <label className="priceTable pr-2">{format(value.Quote, value.ProductID)}</label>
              <label className="indicator">
                <label className="icon"></label>
              </label>
            </div>
          }
        </td>
        <td className="pl-1 pr-0">
          {
            // eslint-disable-next-line no-nested-ternary
            <div className={`${value.Variation > 0 ? 'up arrow' : value.Variation < 0 ? 'down arrow' : 'equal'} tab-item cell-end d-flex`}>
              <label className="percent">{format(value.Variation)}</label>
            </div>
          }
        </td>
      </tr>
    );
    return (
      <Table className="fixed-cells">
        <thead>
          <tr>
            <th><h2>{`Cepea >`}</h2></th>
            <th className="text-uppercase">{t('unit').toUpperCase()}</th>
            <th className="text-right text-uppercase">{t('price').toUpperCase()} R$</th>
            <th className="text-right cell-end">{t('variation').toUpperCase()} R$</th>
          </tr>
        </thead>
        <tbody>
          {tbody}
        </tbody>
      </Table>
    );
  };

  const getTableOfSelectedWeath = (selectedItem, cepea) => {
    const future = selectedItem && selectedItem.Tipos.find(subItem => subItem.Nombre === 'Futuro');
    
    return (
      <div className="tab-content">
        <div role="tabppanel" aria-hidden="false" className="tab-pane active">
          {future && <div className="tables row">
            <div className="col-12 p-0 d-flex">
              {getFutureTable(future)}
            </div>
            <div className="granos-links fst-table  mb-30">
              <div className="actualization-container">
                <div>
                  <p className={isBrasil ? 'update-date' : ''}>{t('updated')}: {future && future.Fecha}</p>
                  {!isBrasil && <Link to="/granos/series-historicas-futuros">{t('see_historical_data')}</Link>}
                </div>
                <div className="logos-cont">
                  <label className="description-logo">Powered by</label>
                  <div className="description-logo">
                    <img src={CmaLogo} width="56px" height="40px" />
                  </div>
                </div>
              </div>
            </div>
          </div>}
          {cepea && <div className="tables row">
            <div className="col-12 p-0 d-flex">
              {getCepeaTable(cepea)}
            </div>
            <div className="granos-links fst-table mb-30">
              <div className="actualization-container">
                <div className="logos-cont">
                  <label className="description-logo">Powered by</label>
                  <div className="description-logo">
                    <img src={CepeaLogo} width="56px" />
                  </div>
                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
    );
  };

  return (
    <div className="granos-tabs mb-20">
      { getTableOfSelectedWeath(props.cbot[0], props.cepea) }
      {!isBrasil ? 
      <div className="mkt-links-container"><h2 className="mb-20">Más Cotizaciones</h2>
        <div className="granos-links market-lks">
          {renderMktLinks()}
        </div>
      </div> : null}
    </div>
  );
};

export default GranosTableBR;
