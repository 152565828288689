/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import TagManager from 'react-gtm-module';
import './StandarCard.scss';
import { AgroExclusiveRegistered } from 'agrofy-ui-components';

function StandarCard(props) {
  const [isExternalSection, setIsExternalSection] = useState(false);
  const data = props && props.data;
  const hasTracking = props && props.hasTracking;

  const bg =
    (props && props.image && props.image.includes('https://news')) ||
    (props && props.image && props.image.includes('http://news'))
      ? props.image
      : !(props && props.image)
      ? 'https://argentina.agrofystatic.com/media/static/listing/logo-placeholder.jpg'
      : `${props.image}`;

  const link =
    props &&
    props.link &&
    (props.link.includes('.com.ar') || props.link.includes('.com'))
      ? props.link.split('.com.ar')[1] || props.link.includes('.com')
      : `/${props.link}`;

  const clampText = props && props.clampText;

  var clamped = clampText && {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: clampText,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'break-spaces',
  };

  const manageGTM = () => {
    if (hasTracking) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'select_content',
          content_type: 'click_home_news',
          item_id: props.position,
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    }
  };

  const imgOrVideo = (image) => {
    let response = '';
    data && data?.video !== null
      ? (response = (
          <>
            <ReactPlayer
              className="player-standar"
              key={data?.id}
              url={data?.video}
              playing={true}
              loop={true}
              muted={true}
              controls
              config={{
                youtube: {
                  playerVars: { showinfo: 1, rel: 0 },
                },
              }}
              width="100%"
              height="100%"
            />
          </>
        ))
      : (response = (
          <>
            <Card.Img
              src={image}
              title={props.imageTitle}
              className="media"
              alt={props.imageTitle}
            />
          </>
        ));
    return response;
  };

  const cardItem = (image) => {
    return (
      <Card
        className={`${props.miniStandarCard ? 'mini' : ''} ${
          props.littleText ? 'little' : ''
        } standar-card`}
      >
        {props.exclusive && (
          <div
            className={`exclusive-container agro-exclusive-wrapper ${
              props.littleText ? 'littleText' : ''
            }`}
          >
            <AgroExclusiveRegistered variant="dark" />
          </div>
        )}

        {imgOrVideo(image)}
        <Card.Body>
          <span className="category">{props.category}</span>
          <h2 className="title" style={clamped}>
            {props.body}
          </h2>
        </Card.Body>
      </Card>
    );
  };

  useEffect(() => {
    if (
      link.includes('pulperia/') ||
      link.includes('noticia/') ||
      link.includes('opinion/')
    ) {
      setIsExternalSection(true);
    } else {
      setIsExternalSection(false);
    }
  }, [link]);

  return isExternalSection ? (
    <a href={link} onClick={() => manageGTM()}>
      {cardItem(bg)}
    </a>
  ) : (
    <Link to={link} onClick={() => manageGTM()}>
      {cardItem(bg)}
    </Link>
  );
}

StandarCard.propTypes = {
  miniStandarCard: PropTypes.bool,
  link: PropTypes.string,
  image: PropTypes.string,
  imageTitle: PropTypes.string,
  category: PropTypes.string,
  body: PropTypes.string,
  data: PropTypes.object,
  hasTracking: PropTypes.bool,
  exclusive: PropTypes.bool,
  clampText: PropTypes.number,
  position: PropTypes.number,
};

export default StandarCard;

export const LoaderCard = (props) => {
  return (
    <div
      className={`loader container pr-0 ${
        props.miniStandarCard === 'mini'
          ? 'loader-mini-card'
          : 'loader-normal-card'
      }`}
    >
      <div className="animated-background" />
    </div>
  );
};
