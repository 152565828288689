import React from 'react';
import styled from 'styled-components';

export default function LoadingContainer({height, isDarkBackground}) {
    return (
        <div className="loader container p-0">
            <Content height={height} isDarkBackground={isDarkBackground}/>
        </div>
    )
}

const Content = styled.div`
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: ${p => p.isDarkBackground ? "linear-gradient(to right, #232526, #414345)" : "linear-gradient(to right,#eee 8%,#ddd 18%,#eee 33%)"};
  background-size: 1000px 504px;
  height: ${p => p.height};
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  border-radius: 8px;
@keyframes placeHolderShimmer {
  from { background-position: 0px center; }
  to { background-position: 2000px center; }
}
@-webkit-keyframes placeHolderShimmer {
  from { background-position: 0px center; }
  to { background-position: 2000px center; }
}
`;