import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import TagManager from 'react-gtm-module';
import './LeadNews.scss';
import { AgroExclusiveRegistered } from 'agrofy-ui-components';

function LeadNews(props) {
  const data = props && props.data;
  const hasTracking = props && props.hasTracking;
  const image = `${data.image}`;
  const [isExternal, setIsExternal] = useState(false);

  const clampText = props && props.clampText;

  var clamped = clampText && {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: clampText,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'break-spaces',
  };

  const manageGTM = () => {
    if (hasTracking) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'select_content',
          content_type: 'click_home_news',
          item_id: 1,
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    }
  };

  useEffect(() => {
    if (
      data?.url.includes('pulperia/') ||
      data?.url.includes('noticia/') ||
      data?.url.includes('opinion/')
    ) {
      setIsExternal(true);
    } else {
      setIsExternal(false);
    }
  }, [data?.url]);

  const imgOrVideo = () => {
    let response = '';
    data?.video !== null
      ? (response = (
          <>
            <ReactPlayer
              className="react-player-lead"
              key={data?.id}
              url={data?.video}
              playing={true}
              loop={true}
              muted={true}
              controls
              config={{
                youtube: {
                  playerVars: { showinfo: 1, rel: 0 },
                },
              }}
            />
          </>
        ))
      : (response = (
          <>
            <img alt={data.summary} src={image} />
          </>
        ));
    return response;
  };

  const cardItem = () => {
    return (
      <>
        <div className="content col-12 col-lg-4">
          {data.exclusive && (
            <div className="exclusive-container">
              {props.isDarkMode ? (
                <AgroExclusiveRegistered variant="dark" />
              ) : (
                <AgroExclusiveRegistered variant="light" />
              )}
            </div>
          )}
          <h6 className="category">{data.category}</h6>
          <h1 className="title" style={clamped}>
            {data.title}
          </h1>
          <p className="bajada mb-m-20">{data.summary}</p>
        </div>
        <div className="media col-12 col-lg-8">{imgOrVideo()}</div>
      </>
    );
  };

  return (
    <div className="main-news">
      {isExternal ? (
        <a href={data.url} className="d-flex" onClick={() => manageGTM()}>
          {cardItem()}
        </a>
      ) : (
        <Link to={data.url} className="d-flex" onClick={() => manageGTM()}>
          {cardItem()}
        </Link>
      )}
    </div>
  );
}

LeadNews.defaultProps = {
  data: {
    id: 182769,
    image: 'sites/default/files/100-31-750x406.jpg',
    title:
      'Empresas agroexportadoras aclararon el impacto del control de cambios sobre el sector',
    url: 'noticia/182769/empresas-agroexportadoras-aclararon-impacto-control-cambios-sector',
  },
};

LeadNews.propTypes = {
  data: PropTypes.object,
};

export default LeadNews;
