import React from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import { AgroPushNotification } from '../../components/AgroPushNotification/AgroPushNotification';

import './NotFound.scss';

const NotFound = props => {
  const {t} = useTranslation();

  return (
    <div className="not-found-page container px-0">
      <Helmet>
        <title>Agrofy News</title>
        <meta name="robots" content="index,follow" />
      </Helmet>
      <AgroPushNotification location="Section" />
      <section className="not-found container d-flex flex-wrap">
        <div className="i-container mx-auto mb-36">
          <i className="error-icon" />
        </div>
        <h1 className="w-100 open-sans bold mb-15">{t('not_available_page')}</h1>
        <h5 className="title5 w-100 normal open-sans">
          {t('the_page_you_are_looking_for_is_not_found_or_does_not_exist')}.
        </h5>
        <h5 className="text-center title5 w-100 normal open-sans pt-1">
          {t('you_can')} <a onClick={() => props.history.goBack()}>{t('go_back_previous_page')}</a> {t('or')} <Link to='/'>{t('go_to_home')}</Link>.
        </h5>
      </section>
    </div>
  );
};

export default withRouter(NotFound);


