import React, {useState} from 'react';
import getData from '../../services/GetData';
import { useTranslation } from 'react-i18next';
import Graphic from '../../atoms/Graphic/Graphic';
import {format} from 'date-fns';
import MultiSelectAgrofy from '../../atoms/MultiSelectAgrofy/MultiSelectAgrofy';
import Calendar from '../../atoms/Calendar/Calendar';
import ButtonAgrofy from '../../atoms/ButtonAgrofy/ButtonAgrofy';
import EconomicTablePagination from '../EconomicTablePagination/EconomicTablePagination';
import CustomSelectAgrofy from '../../atoms/CustomSelectAgrofy/CustomSelectAgrofy';
import NoFilterData from '../../atoms/NoFilterData/NoFilterData';

const loader = <div className="loader container p-0"><div className="animated-background" style={{height: '300px'}}/></div>;
const indicators = [
  {value: 'Monedas', label: 'Monedas', options: [
    {value: '2', label: 'Dólar Nación'},
    {value: '7', label: 'Dólar Itaú'},
    {value: '8', label: 'Dólar HSBC'},
    {value: '1', label: 'Dólar Minorista'},
    {value: '5', label: 'Euro/Peso'},
    {value: '10', label: 'Euro/Dolar'},
    {value: '6', label: 'Real/Peso'},
    {value: '11', label: 'Real/Dolar'},
    {value: '13', label: 'Yen/Dolar'},
    {value: '12', label: 'Libra/Dolar'}
  ]},
  {value: 'Indicadores Bursátil', label: 'Índices Bursátiles', options: [
    {value: '3', label: 'Merval'},
    {value: '21', label: 'Bovespa'},
    {value: '22', label: 'Dow Jones'},
    {value: '23', label: 'Nasdaq'},
    {value: '24', label: 'S&P 500'},
    {value: '25', label: 'Nikkei'},
    {value: '26', label: 'Hang SEng'},
    {value: '27', label: 'FTSE 100'}
  ]},
  {value: 'Tasas', label: 'Tasas', options: [
    {value: '14', label: 'Badlar $'},
    {value: '15', label: 'Badlar U$S'},
    {value: '0', label: 'Adelanto Cta. Cte.'},
    {value: '16', label: 'Plazo Fijo'},
    {value: '17', label: 'Caja de Ahorro'},
    {value: '18', label: 'Tesoro EE.UU.'},
    {value: '19', label: 'Libor'},
    {value: '20', label: 'SElic - Brasil'}
  ]},
  {value: 'Commodities', label: 'Commodities', options: [
    {value: '28', label: 'Oro'},
    {value: '29', label: 'Petróleo'},
    {value: '30', label: 'Soja'},
    {value: '32', label: 'Aceite de Soja'},
    {value: '31', label: 'Harina de Soja'},
    {value: '33', label: 'Trigo'},
    {value: '34', label: 'Maíz'}
  ]}
];

const EconomicHistoricalData = props => {
  const {t} = useTranslation();
  const [hasData, setHasData] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [data, setData] = useState([]);
  const [graphData, setGraphData] = useState(null);
  const [selected, setSelected] = useState({
    indicator: 'Monedas',
    items: [],
    fromDate: new Date(),
    toDate: new Date()
  });

  const validFilters = () => {
    return selected.indicator && selected.items.length;
  };
  const formatData = oldData => {
    const newData = [];
    oldData.Productos.map(product => {
      const label = product.Nombre;
      const graphicData = [];

      product.Cotizaciones.map(item => {
        return graphicData.push({t: item.Fecha, y: item.Compra});
      });
      return newData.push({
        label: label,
        graphicData: graphicData
      });
    });
    return newData;
  };

  const fetchData = (items, codIndicator, fromDate, toDate) => {
    const url =
    `/api/EconomicIndic/GetHistoricalEconomicIndicators?dateFrom=${fromDate}&dateTo=${toDate}&indicatorName=${codIndicator}&quoteKinds=${items}`;
    return getData({
      url: `${window.env.RAZZLE_ENDPOINT_COTZ_API}${url}`
    });
  };

  const getFilteredData = () => {
    setHasData(false);
    setData(false);
    setHasFilters(true);
    const items = selected.items.map(item => item).join(',');
    const fromDate = format(selected.fromDate, 'yyyyMMdd');
    const toDate = format(selected.toDate, 'yyyyMMdd');
    fetchData(items, selected.indicator, fromDate, toDate).then(resp => {
      setGraphData(resp.data && resp.data.length ? formatData(resp.data[0]) : []);
      setData(resp.data && resp.data.length ? resp.data[0] : []);
      setHasData(true);
    });
  };

  const getOptions = () => {
    return indicators.find(ind => ind.value === selected.indicator).options;
  };

  return (
    <div className="economic-historical-data">
      <div className="economic-historical-data__filters">
        <div className="economic-historical-data__filters_dates d-md-flex flex-wrap justify-content-between mb-20">
          <h1 className="title4 pb-10 pb-md-0">{t('history_of_gold_and_oil')}</h1>
          <div className="economic-historical-data__filters_dates__calendar d-flex align-items-end">
            <div className="economic-historical-data__filters_dates__calendar--left d-md-flex align-items-center pr-0 pr-md-15">
              <label className="pr-10">{t('choose_date_from')}:</label>
              <Calendar
                initialDate={selected.fromDate}
                maxDate={selected.toDate}
                parentCallback={date => setSelected({...selected, fromDate: date})}/>
            </div>
            <div className="economic-historical-data__filters_dates__calendar--right d-md-flex align-items-center">
              <label className="pr-10">{t('choose_date_to')}:</label>
              <Calendar
                initialDate={selected.toDate}
                minDate={selected.fromDate}
                parentCallback={date => setSelected({...selected, toDate: date})}/>
            </div>
          </div>
        </div>
        <div className="economic-historical-data__filters__selects d-md-flex mb-20 justify-content-between">
          <div className="economic-historical-data__filters__selects__inputs d-md-flex">
            <CustomSelectAgrofy
              nameSelectId="indicators"
              placeholder={t('indicators')}
              parentCallback={item => setSelected({...selected, indicator: item, items: []})}
              options={indicators}
              initialValue={'Monedas'}
              value="value"
              label="label" />
            <MultiSelectAgrofy
              placeholder={t('product')}
              options={getOptions()}
              parentCallback={items => setSelected({...selected, items: items})}
            />
          </div>
          <ButtonAgrofy title="search" parentCallback={getFilteredData} disabled={!validFilters()}/>
        </div>
      </div>
      {
        // eslint-disable-next-line no-nested-ternary
        !hasFilters ?
          <div className="economic-historical-data__generate-graphic text-center">
            <div className="i-container mx-auto mb-10">
              <i className="icon-graphic" />
            </div>
            <label className="title6">{t('complete_filters')}</label>
            {/* eslint-disable-next-line no-nested-ternary */}
          </div> : !hasData ? loader : graphData.length ?
            <div className="economic-historical-data__graph mb-20">
              <Graphic data={graphData}/>
            </div> : <NoFilterData/>
      }
      { <EconomicTablePagination data={hasData && data}/> }
    </div>
  );
};


export default EconomicHistoricalData;

