import React from 'react';
import styled from 'styled-components';
import TagsRow from './../tagsRow/TagsRow';

const MomentTopics = ({topics}) => {
  return (
    <MomentTopicsSection
      className="container d-flex flex-wrap"
      hasTopics={topics}>
      {!topics ? null : (
        <div className="d-flex align-items-center col-12 px-0">
          <TagsRow data={topics}/>
        </div>
      )}
    </MomentTopicsSection>
  );
};

export default MomentTopics;

const MomentTopicsSection = styled.section`
  height: 40px;
  position: relative;
  top: 62px;
  margin-bottom: 10px;
  overflow-x: hidden;

  z-index: 8;

  @media only screen and (max-width: 768px) {
    top: 72px;
  }
`;