/* eslint-disable jsx-a11y/aria-role */
import React from 'react';
import { Table } from 'react-bootstrap';
import './GranosTable.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CmaLogo from '../../../images/icons/cma-logo.svg';
import MatbaRofexLogo from '../../../images/icons/matba-rofex-logo.png';
import CepeaLogo from '../../../images/icons/cepea-logo.png';
import { removeString } from '../../../utils/stringUtils';

const GranosTable = props => {
  const { t } = useTranslation();
  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";

  const classNames = item => item % 2 ? 'even-item' : 'odd-item';

  const format = (val, prodID) => (prodID && prodID === 32 ? "¢" : "") + (val && val.toString().replace('.',','));

  const translateCBOTPosition = position => {
    return t(`months_short_cbot.${position.slice(0, 3)}`).toUpperCase() + position.slice(3);
  };

  const getCepeaUpdateDate = (cepeaPrices) => {
    const dates = cepeaPrices && cepeaPrices.map(x => new Date(x.QuoteDate.replace('T00', 'T19')));

    const maxDate = new Date(Math.max.apply(null, dates));

    return `${maxDate.getDate()}/${maxDate.getMonth() + 1}/${maxDate.getFullYear()} ${maxDate.getHours()}:00:00`;
  };

  const renderMktLinks = () => {
    const marketLinks = [
      { title: "Futuros online", url: "/granos/futuros-opciones" }, { title: "Precios Pizarra", url: "/granos/precios-pizarra" }, { title: "Mercado Físico", url: "/granos/mercado-fisico" }, { title: "Precios Oficiales", url: "/granos/precios-oficiales" }, { title: "Series históricas", url: "/granos/series-historicas-futuros" }
    ]
    return (
      marketLinks.map(item => {
        return (<h3><Link to={item.url}>{item.title}</Link></h3>)
      })
    )
  };

  const getFutureTable = item => {
    const defaultTbody = item.Mercados;
    const filteredTBody = item.Mercados.filter(x => x.Nombre === "CBOT");
    
    const tbody = isBrasil ? filteredTBody : defaultTbody;

    const tableBody = tbody.map((value, idx) =>
      <tr key={`future-${idx}`} className={classNames(idx)}>
        <td>{value.Nombre}</td>
        <td>{isBrasil ? translateCBOTPosition(value.Posicion) : value.Posicion}</td>
        <td className="pl-1 pr-0">
          {
            // eslint-disable-next-line no-nested-ternary
            <div className={`${value.Var > 0 ? 'up-icon arrow' : value.Var < 0 ? 'down-icon arrow' : 'equal-icon'} tab-item cell-price d-flex`}>
              <label className="priceTable pr-2">{value.Precio}</label>
              <label className="indicator">
                <label className="icon"></label>
              </label>
            </div>
          }
        </td>
        <td className="pl-1 pr-0">
          {
            // eslint-disable-next-line no-nested-ternary
            <div className={`${value.Var > 0 ? 'up arrow' : value.Var < 0 ? 'down arrow' : 'equal'} tab-item cell-end d-flex`}>
              <label className="percent">{format(value.Var)}</label>
            </div>
          }
        </td>
      </tr>
    );

    return (
      <Table className="fixed-cells">
        <thead>
          <tr>
            <th><Link to="/granos/futuros-opciones"><h2>{`${item.Nombre} >`}</h2></Link></th>
            <th className="text-uppercase">{t('position')}</th>
            <th className="text-right text-uppercase">{t('price')} U$</th>
            <th className="text-right cell-end">VAR U$</th>
          </tr>
        </thead>
        <tbody>
          {tableBody}
        </tbody>
      </Table>
    );
  };

  const getBoardTable = item => {
    const tbody = item.Mercados.map((value, idx) =>
      <tr key={`board-${idx}`} className={classNames(idx)}>
        <td>{value.Nombre}</td>
        <td className="pl-1 pr-0">
          {
            // eslint-disable-next-line no-nested-ternary
            <div className={`${value.Var > 0 ? 'up-icon arrow' : value.Var < 0 ? 'down-icon arrow' : 'equal-icon'} tab-item cell-end d-flex snd-table`}>
              <label className="priceTable pr-2">{value.Precio}</label>
              <label className="indicator">
                <label className="icon"></label>
              </label>
            </div>
          }
        </td>
        <td className="pl-1 pr-0">
          {
            // eslint-disable-next-line no-nested-ternary
            <div className={`${value.Var > 0 ? 'up arrow' : value.Var < 0 ? 'down arrow' : 'equal'} tab-item cell-end d-flex`}>
              <label className="percent">{`${value.Var > 0 ? `+${value.Var}` : value.Var < 0 ? `${value.Var}` : value.Var}`}</label>
            </div>
          }
        </td>
      </tr>
    );
    return (
      <Table>
        <thead>
          <tr>
            <th><Link to="/granos/precios-pizarra"><h2>{`${item.Nombre} ${props.selectedWheat.Nombre} >`}</h2></Link></th>
            <th className="text-right text-uppercase snd-price cell-end">{t('price')} $</th>
            <th className="text-right cell-end">VAR $</th>
          </tr>
        </thead>
        <tbody>
          {tbody}
        </tbody>
      </Table>
    );
  };

  const getPhysicalOfitialTable = (physical, ofitial) => {
    const tbodyp = physical.Mercados.map((value, idx) =>
      <tr key={`physical-${idx}`} className={classNames(idx)}>
        <td>{value.Nombre}</td>
        <td className="pl-1 pr-0">
          {
            // eslint-disable-next-line no-nested-ternary
            <div className={`${value.Var > 0 ? 'up-icon arrow' : value.Var < 0 ? 'down-icon arrow' : 'equal-icon'} tab-item cell-end d-flex`}>
              <label className="priceTable">{value.Moneda} {value.Precio}</label>
            </div>
          }
        </td>
      </tr>
    );
    const tbodyo = ofitial.Mercados.map((value, idx) =>
      <tr key={`physical-${idx}`} className={classNames(idx)}>
        <td>{value.Nombre}</td>
        <td className="pl-1 pr-0">
          {
            // eslint-disable-next-line no-nested-ternary
            <div className={`${value.Var > 0 ? 'up arrow' : value.Var < 0 ? 'down arrow' : 'equal'} tab-item cell-end d-flex`}>
              <label className="percent">{value.Moneda} {value.Precio}</label>
            </div>
          }
        </td>
      </tr>
    );
    return (
      <div>
        <Table className="fixed-cells">
          <thead>
            <tr>
              <th><Link to="/granos/precios-oficiales"><h2>{`${ofitial.Nombre} ${props.selectedWheat.Nombre} >`}</h2></Link></th>
              <th className="text-right text-uppercase cell-end">{t('price')} U$</th>
            </tr>
          </thead>
          <tbody>
            {tbodyo}
          </tbody>
        </Table>
        <div className="granos-links mb-30">
          <p>{t('updated')}: {removeString("Oficiales: ", ofitial.Fecha)}</p>
          {/* <Link to="/granos/series-historicas-futuros">{t('see_historical_data')}</Link> */}
        </div>
        <Table className="fixed-cells">
          <thead>
            <tr>
              <th><Link to="/granos/series-historicas-fisico"><h2>{`${physical.Nombre} ${props.selectedWheat.Nombre} >`}</h2></Link></th>
              <th className="text-right text-uppercase">{t('price')} U$</th>
            </tr>
          </thead>
          <tbody>
            {tbodyp}
          </tbody>
        </Table>
        <div className="granos-links">
          <p>{t('updated')}: {removeString("Físico: ", physical.Fecha)}</p>
          <Link to="/granos/series-historicas-futuros">{t('see_historical_data')}</Link>
        </div>
      </div>
    );
  };

  const getCepeaTable = cepea => {
    const tbody = cepea.map((value, idx) =>
      <tr key={`future-${idx}`} className={classNames(idx)}>
        <td>{value.ProductDescription}</td>
        <td>{value.TypeDescription}</td>
        <td className="pl-1 pr-0">
          {
            // eslint-disable-next-line no-nested-ternary
            <div className={`${value.Variation > 0 ? 'up-icon arrow' : value.Variation < 0 ? 'down-icon arrow' : 'equal-icon'} tab-item cell-price d-flex`}>
              <label className="priceTable pr-2">{format(value.Quote, value.ProductID)}</label>
              <label className="indicator">
                <label className="icon"></label>
              </label>
            </div>
          }
        </td>
        <td className="pl-1 pr-0">
          {
            // eslint-disable-next-line no-nested-ternary
            <div className={`${value.Variation > 0 ? 'up arrow' : value.Variation < 0 ? 'down arrow' : 'equal'} tab-item cell-end d-flex`}>
              <label className="percent">{format(value.Variation)}</label>
            </div>
          }
        </td>
      </tr>
    );
    return (
      <Table className="fixed-cells">
        <thead>
          <tr>
            <th><h2>{`Cepea >`}</h2></th>
            <th className="text-uppercase">{t('unit').toUpperCase()}</th>
            <th className="text-right text-uppercase">{t('price').toUpperCase()} R$</th>
            <th className="text-right cell-end">{t('variation').toUpperCase()} R$</th>
          </tr>
        </thead>
        <tbody>
          {tbody}
        </tbody>
      </Table>
    );
  };

  const getTableOfSelectedWeath = (selectedItem, cepea) => {
    const future = selectedItem && selectedItem.Tipos.find(subItem => subItem.Nombre === 'Futuro');
    const board = !isBrasil && selectedItem && selectedItem.Tipos.find(subItem => subItem.Nombre === 'Pizarra');
    const physical = !isBrasil && selectedItem && selectedItem.Tipos.find(subItem => subItem.Nombre === 'Físico');
    const ofitial = !isBrasil && selectedItem && selectedItem.Tipos.find(subItem => subItem.Nombre === 'Oficial');

    return (
      <div className="tab-content">
        <div role="tabppanel" aria-hidden="false" className="tab-pane active">
          {
            <div className="tables row">
              {future && 
              <>
                <div className="col-12 p-0 d-flex">
                  {getFutureTable(future)}
                </div>
                <div className="granos-links fst-table  mb-30">
                  <div className="actualization-container">
                    <div>
                      <p>{t('updated')}: {future.Fecha}</p>
                      <Link to="/granos/series-historicas-futuros">{t('see_historical_data')}</Link>
                    </div>
                    <div className="logos-cont">
                      <label className="description-logo">Powered by</label>
                      <div className="description-logo">
                        <img src={CmaLogo} width="56px" height="40px" />{`${!isBrasil ? ' and ' : ''}`}{!isBrasil && <img src={MatbaRofexLogo} />}
                      </div>
                    </div>
                  </div>
                </div>
              </>
              }
              {board && <div className="col-12 p-0 d-flex mb-30">
                {getBoardTable(board)}
                <div className="granos-links">
                  <p>{t('updated')}: {board.Fecha}</p>
                  <Link to="/granos/series-historicas-pizarra">{t('see_historical_data')}</Link>
                </div>
              </div>}
              {physical && ofitial && 
              <div className="col-12 p-0 d-flex mb-30">
                {getPhysicalOfitialTable(physical, ofitial)}
              </div>}
            </div>
          }
          {cepea && <div className="tables row">
            <div className="col-12 p-0 d-flex">
              {getCepeaTable(cepea)}
            </div>
            <div className="granos-links fst-table mb-30">
              <div className="actualization-container">
                <div>
                  <p>{t('updated')}: {getCepeaUpdateDate(cepea)}</p>
                </div>
                <div className="logos-cont">
                  <label className="description-logo">Powered by</label>
                  <div className="description-logo">
                    <img src={CepeaLogo} width="56px" />
                  </div>
                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
    );
  };

  return (
    <div className="granos-tabs mb-20">
      {
        getTableOfSelectedWeath(props.selectedWheat, props.cepea)
      }
      {!isBrasil ? 
      <div className="mkt-links-container"><h2 className="mb-20">Más Cotizaciones</h2>
        <div className="granos-links market-lks">
          {renderMktLinks()}
        </div>
      </div> : null}
    </div>
  );
};

export default GranosTable;
