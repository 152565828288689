import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AgroLabel, AgroImage, AgroLink, IarrowDown2, IarrowUp } from 'agrofy-ui-components';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import './TableIndexsResume.scss';

const TableIndexsResume = ({ products, grains }) => {

    const urlImageBase = process.env.RAZZLE_STATIC_IMAGES_SERVICE || window.env.RAZZLE_STATIC_IMAGES_SERVICE;
    const marketUrl = process.env.RAZZLE_ENDPOINT_MARKET || window.env.RAZZLE_ENDPOINT_MARKET;
    const { t } = useTranslation();
    const [headers, setHeader] = useState([]);
    const [headerSelected, setHeaderSelected] = useState(null);
    const [rows, setRows] = useState([]);
    const [rowSelected, setRowSelected] = useState(null);

    useEffect(() => {
        if (!grains || grains.length < 1 || headerSelected) return;

        const newHeaders = grains.map((x, index) => ({ ...x, isSelected: index === 0 }));
        const newHeaderSelected = newHeaders.find(x => x.isSelected === true);
        setHeaderSelected(newHeaderSelected);
        setHeader(newHeaders);
    }, [grains]);

    useEffect(() => {
        if (!products || products.length < 1 || rowSelected) return;

        const newRows = products.map((x, index) => ({ ...x, isSelected: index === 0 }));
        const newRowSelected = newRows.find(x => x.isSelected === true);
        setRowSelected(newRowSelected);
        setRows(newRows);
    }, [products]);

    const classNames = item => item % 2 ? '' : 'odd-item';

    const calculateIndex = (price, quote) => {
        if (quote === 0) return 0;
        const result = price / quote;
        const formatResult = result.toFixed(2);
        return formatResult.endsWith(".00") ? parseInt(formatResult, 10) : formatResult.replace('.', ',');
    }

    const selectHeader = (idHeader) => {
        const newHeaders = headers.map(x => ({ ...x, isSelected: idHeader === x.IdProduct }));
        const newHeaderSelected = newHeaders.find(x => x.isSelected === true);
        setHeaderSelected(newHeaderSelected);
        setHeader(newHeaders);
    }

    const selectRow = (idRow, canUnselectCurrent = false) => {
        if (canUnselectCurrent === false && rowSelected?.id === idRow) return;

        const newRows = rows.map(x => ({ ...x, isSelected: (idRow === x.id && !x.isSelected) }));
        const newRowSelected = newRows.find(x => x.isSelected === true);
        setRowSelected(newRowSelected);
        setRows(newRows);
    }

    const onSelectIndex = (idRow, idHeader) => {
        selectHeader(idHeader);
        selectRow(idRow);
    }

    const getIndexDetailText = () => {
        if (!rowSelected || !headerSelected) {
            return (<><p className="mb-0 index-detail-text">{t('description_index_agrofy_empty')}</p></>);
        } else {
            return (<><p className="mb-0 index-detail-text" dangerouslySetInnerHTML={{ __html: t('description_index_agrofy')
                .replace('INDEX_VALUE', calculateIndex(rowSelected.price, headerSelected.Quote))
                .replace('INDEX_UNIT_QUOTE', headerSelected.UnitQuote === 'ton' ? 'toneladas' : 'kilogramos')
                .replace('INDEX_VALUE_QUOTE', headerSelected.ProductName.toUpperCase())
                .replace('INDEX_PRODUCT_NAME', rowSelected.name)}}>
            </p></>);
        }
    }

    const manageGTM = item => {
        const tagManagerArgs = {
            dataLayer: {
                event: 'trackEvent',
                eventCategory: 'Navigation',
                eventAction: 'Agrofy News-Indices',
                eventLabel: item
            }
        };
        TagManager.dataLayer(tagManagerArgs);
    };

    return (
        <>
            {
                rows &&
                rows.length > 0 &&
                headers &&
                headers.length > 0 && (
                    <>
                        <div className="row table-index-container">
                            <div className="col-lg-12 col-xl-9 px-0">
                                <div className="resume-table-section">
                                    {/* This table is for mobile and tablet devices */}
                                    <div className='d-xl-none'>
                                        <section className="pages-togler h-scroll">
                                            <ul>
                                                {headers.map((h, index) => <li key={`li-${index}`} className={h.isSelected ? 'active' : ''} onClick={() => selectHeader(h.IdProduct)}>{h.ProductName}</li>)}
                                            </ul>
                                        </section>
                                        <Table className="fixed-cells">
                                            <thead>
                                                <tr className="resume-title-items">
                                                    <th key={`th-pos0`} className="text-uppercase text-center"><a href={`${marketUrl}/indispensables-agro`} onClick={() => manageGTM('Marketplace-Indispensables del Agro')} target='_blank'>{t('essentials_of_agriculture')}</a></th>
                                                    <th key={`th-pos1`} className="text-uppercase text-center">{headerSelected.ProductName}<p className='header-quote mb-0'>{headerSelected.Currency}/{headerSelected.UnitQuote} {headerSelected.Quote.toString().replace('.', ',')}</p></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rows.map((row, index) => {
                                                    return (
                                                        <>
                                                            <tr key={`row-${index}`} className={classNames(index)} onClick={() => selectRow(row.id, true)}>
                                                                <td key={`pos0-${index}`} className={`clickable d-flex`}>
                                                                    {row.isSelected ?
                                                                        <IarrowUp height='22px' width='22px' fill='gray7' /> :
                                                                        <IarrowDown2 height='22px' width='22px' fill='gray7' />
                                                                    }
                                                                    {row.name}
                                                                </td>
                                                                <td key={`pos1-${index}`} className={`${row.isSelected ? 'index-active' : 'clickable'} text-center`}>{calculateIndex(row.price, headerSelected.Quote)}</td>
                                                            </tr>
                                                            {row.isSelected && (
                                                                <tr className={`${classNames(index)}`}>
                                                                    <td className='py-3 px-1' colSpan={2}>
                                                                        <div className='row'>
                                                                            <div className="col-4">
                                                                                <div>
                                                                                    <div className='agrocard-index-img text-center'>
                                                                                        <a href={`${marketUrl}${row.urlProduct}`} onClick={() => manageGTM(`Marketplace-${rowSelected.name}`)} target='_blank'>
                                                                                            <AgroImage src={`${urlImageBase}${row.urlImage}`} />
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 p-0">
                                                                                <div>{getIndexDetailText()}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className="col-4 p-0">
                                                                                <div className='p-0 mt-1'>
                                                                                    <AgroLink
                                                                                        className='link-view-product'
                                                                                        href={`${marketUrl}${row.urlProduct}`}
                                                                                        variant='primary'
                                                                                        disabled={false}
                                                                                        justify-content='center'
                                                                                        onClick={() => manageGTM(`Marketplace-${rowSelected.name}`)}
                                                                                        target='_blank'>
                                                                                        Ver producto
                                                                                    </AgroLink>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8 p-0">
                                                                                <AgroLabel className="fair-lbl">U$S {parseInt(row.price).toLocaleString('es-AR')}</AgroLabel>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                    {/* This table is for screens biggers than mobile and tablets */}
                                    <div className='d-xl-table d-none'>
                                        <Table className="fixed-cells">
                                            <thead>
                                                <tr className="resume-title-items">
                                                    <th key={`th-pos0`} className="text-uppercase text-center">
                                                        <a href={`${marketUrl}/indispensables-agro`} onClick={() => manageGTM('Marketplace-Indispensables del Agro')} target='_blank'>{t('essentials_of_agriculture')}</a>
                                                    </th>
                                                    {headers && headers.map((header, index) => <th key={`th-pos${index + 1}`} className="text-uppercase text-center">{header.ProductName}<p className='header-quote mb-0'>{header.Currency}/{header.UnitQuote} {header.Quote.toString().replace('.', ',')}</p></th>)}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rows.map((row, index) => {
                                                    return (
                                                        <tr key={`row-${index}`} className={classNames(index)}>
                                                            <td key={`pos0-${index}`}>{row.name}</td>
                                                            {headers.map((header, headerIndex) => <><td key={`pos${headerIndex + 1}-${index}`} className={`${row.isSelected && header.isSelected ? 'index-active' : 'clickable'} text-center`} onClick={() => onSelectIndex(row.id, header.IdProduct)}>{calculateIndex(row.price, header.Quote)}</td></>)}
                                                        </tr>)

                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                            {/* This container is for screens biggers than mobile and tablets */}
                            <div className="d-xl-block d-none col-xl-3">
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='index-detail-container text-center p-2'>{getIndexDetailText()}</div>
                                    </div>
                                    {rowSelected && (
                                        <div className='col-12 mt-2'>
                                            <a href={`${marketUrl}${rowSelected.urlProduct}`} onClick={() => manageGTM(`Marketplace-${rowSelected.name}`)} target='_blank'>
                                                <div className="agrocard-index">
                                                    <div className='agrocard-index-img'>
                                                        <AgroImage src={`${urlImageBase}${rowSelected.urlImage}`} />
                                                    </div>
                                                    <div className='agrocard-index-lbl'>
                                                        <AgroLabel className="fair-lbl">U$S {parseInt(rowSelected.price).toLocaleString('es-AR')}</AgroLabel>
                                                    </div>
                                                    <div className='agrocard-index-link'>
                                                        <AgroLink
                                                            className='link-view-product'
                                                            target='_blank'
                                                            href={`${marketUrl}${rowSelected.urlProduct}`}
                                                            variant='primary'
                                                            size='lg'
                                                            disabled={false}
                                                            justify-content='center'>
                                                            Ver producto
                                                        </AgroLink>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
}

export default TableIndexsResume;