export const listedCitiesAR = [
  {
    city: 'Buenos Aires',
    province: 'Ciudad Autónoma de Buenos Aires',
    id: '7894',
    slug: 'buenos+aires+ciudad+autonoma+de+buenos+aires',
    c2: 'Ciudad Autonoma de Buenos Aires',
    c3: 'capital federal',
    c4: 'caba'
  },
  {
    city: 'Rosario',
    province: 'Santa Fe',
    slug: 'rosario+santa+fe',
    id: '11222'
  },
  {
    city: 'Mar del Plata',
    province: 'Buenos Aires',
    id: '7893',
    slug: 'mar+del+plata+buenos+aires'
  },
  {
    city: 'Córdoba',
    province: 'Córdoba',
    id: '8869',
    slug: 'ciudad+de+cordoba+cordoba',
    c2: 'Cordoba'
  },
  {
    city: 'La plata',
    province: 'Buenos Aires',
    slug: 'la+plata+buenos+aires',
    id: '7892'
  },
  {
    city: 'Bahía Blanca',
    province: 'Buenos Aires',
    id: '2931',
    slug: 'bahia+blanca+buenos+aires',
    c2: 'Bahia Blanca'
  },
  {
    city: 'Salta',
    province: 'Salta',
    id: '10531',
    slug: 'ciudad+de+salta+salta'
  },
  {
    city: 'Mendoza',
    province: 'Mendoza',
    id: '5126',
    slug: 'ciudad+de+mendoza+mendoza'
  },
  {
    city: 'Bariloche',
    province: 'Río Negro',
    id: '5857',
    slug: 'bariloche+rio+negro',
    c2: 'san carlos de bariloche'
  },
  {
    city: 'Corrientes',
    province: 'Corrientes',
    id: '3196',
    slug: 'ciudad+de+corrientes+corrientes',
    c2: ''
  },
  {
    city: 'Neuquén',
    province: 'Neuquén',
    id: '5856',
    slug: 'ciudad+de+neuquen+neuquen',
    c2: 'neuquen'
  },
  {
    city: 'Formosa',
    province: 'Formosa',
    id: '3778',
    slug: 'ciudad+de+formosa+formosa'
  },
  {
    city: 'Tandil',
    province: 'Buenos Aires',
    id: '2929',
    slug: 'tandil+buenos+aires'
  },
  {
    city: 'Necochea',
    province: 'Buenos Aires',
    id: '2924',
    slug: 'necochea+buenos+aires'
  },
  {
    city: 'Posadas',
    province: 'Misiones',
    id: '5168',
    slug: 'posadas+misiones'
  },
  {
    city: 'San Luis',
    province: 'San Luis',
    id: '5955',
    slug: 'ciudad+de+san+luis+san+luis'
  }
];

export const listedCitiesBR = [
  {
    "id": "45449",
    "city": "Rio de Janeiro",
    "province": "Rio De Janeiro",
    "slug": "rio+de+janeiro+rj"
  },
  {
    "id": "43348",
    "city": "Brasília",
    "province": "Distrito Federal",
    "slug": "brasilia+df"
  },
  {
    "id": "44944",
    "city": "Curitiba",
    "province": "Paraná",
    "slug": "curitiba+pr"
  },
  {
    "id": "45561",
    "city": "Porto Alegre",
    "province": "Rio Grande Do Sul",
    "slug": "porto+alegre+rg"
  },
  {
    "id": "45881",
    "city": "São Paulo",
    "province": "São Paulo",
    "slug": "ciudad+de+são+paulo+sp"
  },
  {
    "id": "36357",
    "city": "Praia Grande",
    "province": "São Paulo",
    "slug": "praia+grande+sp"
  },
  {
    "id": "45883",
    "city": "Campinas",
    "province": "São Paulo",
    "slug": "campinas+sp"
  },
  {
    "id": "45882",
    "city": "Santo André",
    "province": "São Paulo",
    "slug": "santo+andre+sp"
  },
  {
    "id": "33738",
    "city": "Campo Grande",
    "province": "Mato Grosso Do Sul",
    "slug": "campo+grande+ms"
  },
  {
    "id": "35575",
    "city": "Cabo Frio",
    "province": "Rio De Janeiro",
    "slug": "cabo+frio+rj"
  },
  {
    "id": "34736",
    "city": "Ponta Grossa",
    "province": "Paraná",
    "slug": "ponta+grossa+pr"
  },
  {
    "id": "35735",
    "city": "Santa Maria",
    "province": "Rio Grande Do Sul",
    "slug": "santa+maria+rg"
  },
  {
    "id": "44403",
    "city": "Belo Horizonte",
    "province": "Minas Gerais",
    "slug": "belo+horizonte+mg"
  },
  {
    "id": "35732",
    "city": "Passo Fundo",
    "province": "Rio Grande Do Sul",
    "slug": "passo+fundo+rg"
  },
  {
    "id": "36378",
    "city": "Ribeirão Preto",
    "province": "São Paulo",
    "slug": "ribeirao+preto+sp"
  },
  {
    "id": "43599",
    "city": "Goiania",
    "province": "Goiás",
    "slug": "goiania+go"
  }
];

export const provincesBR = {
  "Acre": "AC",
  "Alagoas": "AL",
  "Amapá": "AP",
  "Amazonas": "AM",
  "Bahia": "BA",
  "Ceará": "CE",
  "Distrito Federal": "DF",
  "Espírito Santo": "ES" ,
  "Goiás": "GO",
  "Maranhão": "MA" ,
  "Mato Grosso": "MT" ,
  "Mato Grosso Do Sul": "MS" ,
  "Minas Gerais": "MG" ,
  "Pará": "PA" ,
  "Paraíba": "PB" ,
  "Paraná": "PR" ,
  "Pernambuco": "PE" ,
  "Piauí": "PI" ,
  "Rio De Janeiro": "RJ" ,
  "Rio Grande Do Norte": "RN",
  "Rio Grande Do Sul": "RG" ,
  "Rondônia": "RO" ,
  "Roraima": "RR" ,
  "Santa Catarina": "SC" ,
  "São Paulo": "SP" ,
  "Sergipe": "SE" ,
  "Tocantins": "TO"
};
