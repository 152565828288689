import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';
import StandarCard from '../standarCard/StandarCard';
import getData from '../../services/GetData';
import './RelatedSection.scss';

function RelatedSection({newsId, categoryId, relatedNews}) {
  const { t } = useTranslation();
  const [data, setData] = useState(relatedNews || null);
  const loader = <div className="loader container" style={{height: "700px"}}><div className="animated-background"><div className="background-masker i1"></div><div className="background-masker i2"></div><div className="background-masker i3"></div><div className="background-masker i4"></div><div className="background-masker i5"></div><div className="background-masker i6"></div></div></div>;
  const fetchDataRelated = () => {
    const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
    getData({ url: `${url}/api/News/GetRelatedNewsDetail?newsID=${newsId}&categoryID=${categoryId === 0 ? 101 : categoryId}` })
      .then(resp => {
        setData(resp.data)
      })
  }
  useEffect(() => {
    if (newsId && categoryId !== undefined) {
      fetchDataRelated();
    }
  }, [newsId, categoryId]);
  
  const createCard = (value, isLittle) => {
    return isLittle ?
      <StandarCard
        miniStandarCard
        littleText
        link={value && value.url}
        image={value && `${value.image}?d=170x100`}
        category={value && value.category}
        imageTitle={value.title} body={value.title}>
      </StandarCard> :
      <StandarCard
        miniStandarCard
        link={value && value.url}
        image={value && `${value.image}?d=270x150`}
        category={value && value.category}
        imageTitle={value.title} body={value.title}>
      </StandarCard>;
  };

  const manageGTMClick = item => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'trackEvent',
        eventCategory: 'Navigation',
        eventAction: 'Agrofy News-Tambien puede interesarte',
        eventLabel: item
      }
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  return (
    <section id="related-section">{data ? 
      <div className="container">
        <div className="row d-flex">
          <h2 className="col-12">
            {t('you_may_also_be_interested')}
          </h2>
          { data.slice(0, 4).map((value, key) =>
            <div className="related-card col-lg-3 mb-36" key={`card-${key}`} onClick={() => manageGTMClick(key + 1)}>
              { createCard(value, false) }
            </div>
          )}
          { data.slice(4, 10).map((value, key) =>
            <div className="related-card col-lg-2 mb-36" key={`card-${key}`} onClick={() => manageGTMClick(key + 5)}>
              { createCard(value, true) }
            </div>
          )}
        </div>
      </div> : loader}
    </section>
  );
}
RelatedSection.propTypes = {
  data: PropTypes.array
};

RelatedSection.defaultProps = {

  data: Array(10).fill().map(value => {
    return {
      miniStandarCard: '',
      littleTex: '',
      link: 'https://news.agrofy.com.ar/noticia/180965/campo-cristina-es-jugada-electoral-que-no-cambia-nada',
      image: 'https://argentina.agrofystatic.com/media/static/listing/logo-placeholder.jpg',
      imageTitle: 'Agrofy',
      category: 'Granos',
      body: 'Agrofy'
    };
  })
};

export default RelatedSection;
