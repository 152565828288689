import React from 'react';
import useFacebook from '../../hooks/useFacebook';

const FbComments = () => {

  const facebookLoaded = useFacebook();

  return (
    facebookLoaded ?
      <div className="fb-comments" data-href={window.location.href} data-numposts="5" data-width="100%" /> : ''
  );

}

export default FbComments;
