import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import {DFPSlotsProvider, AdSlot} from 'react-dfp';
import './AdhesionBanner.scss';


const AdhesionBanner = ({adUnit, sizes, specialNetworkID}) => {
  const [showComponent, setShowComponent] = useState(false);
  const [show, setShow] = useState(true);
  const [bannerLoaded, setbannerLoaded] = useState('no-banner');

  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";
  
  const dfpNetworkID = specialNetworkID ?? (process.env.DFP_NETWORK_ID || window.env.DFP_NETWORK_ID);
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  function handleScroll() {
    if (window.scrollY > 180) {
      setShowComponent(true);
    }
  }

  return (
    <React.Fragment>
      {showComponent && show ?
        <div className={`adhesion-banner ${bannerLoaded} ${sizes.length < 2 ? "d-lg-none" : ""}`}>
          <Alert onClose={() => setShow(false)} dismissible>
            <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
              <AdSlot
                adUnit={adUnit}
                sizes={sizes}
                onSlotIsViewable={() => setbannerLoaded('with-banner')}
                shouldRefresh={() => false}
              />
            </DFPSlotsProvider>
          </Alert>
        </div>
        : ''
      }
    </React.Fragment>
  );
};

AdhesionBanner.propTypes = {
  adUnit: PropTypes.string,
  sizes: PropTypes.array
};

export default AdhesionBanner;
