import React, { useEffect, useState } from 'react';
import getData from '../../../services/GetData';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CurrenciesResume from '../TableCurrenciesResume/TableCurrenciesResume';
import './../../../organisms/EconomicIndicatorsContent/EconomicIndicatorsContent.scss';

function TableDollarAndStocks(props) {
  const loader = <div className="loader container p-0"><div className="animated-background" /></div>;
  const currencyMap = {
    "/economia-politica/monedas": "currencies",
    "/economia-politica/bolsas": "stocks",
    "/economia-politica/dolar": "dolar",
  }
  const { t } = useTranslation();
  const [indicators, setIndicators] = useState([]);
  const selectedTab = props && props.match && props.match.url && currencyMap[props.match.url];

  useEffect(() => {
    fetchDataIndicators();
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  const fetchDataIndicators = () => {
    const url = `${window.env.RAZZLE_ENDPOINT_COTZ_API}/api/EconomicIndic/GetEconomicIndicators`;
    return getData({
      url: url
    }).then(resp => setIndicators(resp.data));
  };

  // const selectedPrices = ["U$ Nación ", "U$ Min", "U$ Blue"];
  // const prices = indicators && indicators.find(item => item.name === 'Monedas');
  // const reducedObjPrices = Object.assign({}, prices)
  // const filteredArr = prices && prices.items && reducedObjPrices.items.filter(item => selectedPrices.includes(item.name))
  // reducedObjPrices && reducedObjPrices.items ? reducedObjPrices["items"] = filteredArr : null;

  const handleSelect = (i) => {
    return i === "resume" ? props.history.push("/economia-politica") : props.history.push(`/economia-politica/${(t(i)).toLowerCase()}`)
  }

  return (
    <>
      {!Array.isArray(indicators) || !indicators.length ? loader :
        <div className="tab-container">
          {!props.showStocks && <a href="/economia-politica" className="link-section">{t('show_full_quotes')}</a>}
          <Tabs className="basic-tabs green" transition={false} defaultActiveKey={selectedTab ? selectedTab : props.isTagPage ? "dolar" : "resume"} onSelect={handleSelect}>
          {props.showStocks && <Tab eventKey="resume" title={t('resume')} >
              <CurrenciesResume prices={indicators} selectedCurrency="Resumen"/>
            </Tab>}
            {props.showStocks && <Tab eventKey="currencies" title={t('currencies')}>
              <CurrenciesResume prices={indicators} selectedCurrency="Monedas" />
            </Tab>}
            <Tab eventKey="dolar" title={'Dolar'}>
              <CurrenciesResume prices={indicators} selectedCurrency="Dolar" />
            </Tab>
            {props.showStocks && <Tab eventKey="stocks" title={t('stocks')}>
              <CurrenciesResume prices={indicators} selectedCurrency="Bolsas" />
            </Tab>}
          </Tabs>
        </div>
      }
    </>
  );
}


export default withRouter(TableDollarAndStocks);
