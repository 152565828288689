import React from 'react';

// a component that will render one or more components
// that it doesn't explicitly include in it's JSX
export const CapitalizedReferenceComponent = (props) => {
  
  // get references to all possible components
  // that this component might render
  const { type, components: Components } = props;

  // make a Capitalized reference to a specific component
  // which we'll render
  let Component = Components[type];

  // render the component
  return (<Component {...props} />);

};

export default CapitalizedReferenceComponent ;