import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './TopBreakingNews.scss';

const TopBreakingNews = ({data}) => {
  return (
    <div className="breaking-news mt-20 py-20">
      <section className="container">
        <a href={(data && data.url) || '/'} className="row m-0">
          <div className="media col-12 col-lg-4 pr-lg-15 p-0">
            <img alt={data && data.title} src={`${data && (data.image || data.portada)}`} />
          </div>
          <div className="content col-12 col-lg-8">
            <h6 className="category">{data.category ? data.category : "Streaming"}</h6>
            <h2 className="title">{data && data.title}</h2>
            <p className="bajada mb-m-20">{data && (data.summary || data.descripcion)}</p>
          </div>
        </a>
      </section>
    </div>
  );
};

TopBreakingNews.propTypes = {
  data: PropTypes.object
};

export default TopBreakingNews;
