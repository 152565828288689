import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { AgroIcon, AgroSpinner } from 'agrofy-ui-components';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { DFPSlotsProvider, AdSlot } from 'react-dfp';
import TagManager from 'react-gtm-module';
import { CategoryLink, MainTitle, NewsIcon } from '../../organisms/CategoriesModal/CategoriesModal';
import { NewsContainer } from '../AgrofyTv/AgrofyTv';
import withSSR from '../../withSSR';
import getData from '../../services/GetData';
import { DynamicSection } from "./DynamicSection";
import { CarouselVideos } from '../../molecules/CarouselVideos/CarouselVideos';
import { useStore } from '../../hooks/store'
import AdhesionBanner from '../../atoms/AdhesionBanner/AdhesionBanner';
import PopUpBanner from '../../atoms/PopUpBanner/PopUpBanner';
import { buildBreadcrumbJson } from './../../utils/breadcrumbJson';
import { getBreadcrumbData } from './../../utils/breadcrumbJson';
import { gtmInitialize, manageGTMUserStatus } from './../../utils/GTMDataLayerFuncs';
import { AgroPushNotification } from '../../components/AgroPushNotification/AgroPushNotification';
import Breadcrumb from '../../atoms/Breadcrumb/Breadcrumb';
import UserContext from '../../atoms/UserContext/UserContext';

function Categories(props) {
  const { t } = useTranslation();
  const urlParam = props && props.match && props.match.params && props.match.url;
  const category = props && props.match && props.match.params && props.match.params.listaVideos;
  const [videosData, setVideosData] = useState(null);
  const isStreamingCat = category === "streamings";
  const [pageToken, setPageToken] = useState(isStreamingCat ? "1" : "");
  const [state, dispatch] = useStore();
  const [banner1Loaded, setBanner1Loaded] = useState('no-loaded-banner');
  const [middle2Loaded, setMiddle2Loaded] = useState('no-loaded-banner');
  const [lightVideo, setLightVideo] = useState(true);
  const [isLoading, setIsLoading] = useState(false)
  const pathName = props && props.location && props.location.pathname;

  const url = process.env.RAZZLE_ENDPOINT_SELF || typeof window !== 'undefined' && window.env.RAZZLE_ENDPOINT_SELF;
  
  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";

  const manageGTMPageName = (pPageName, pTvCategory) => {
    const tagManagerArgs = {
      dataLayer: {
        pageName: pPageName,
        tvCategory: pTvCategory
      }
    };

    TagManager.dataLayer(tagManagerArgs);
  };

  const templateBanners = [
    {
      adUnit: 'agrofytv_middle_1',
      sizes: [[728, 90], [300, 250], [320, 50]]
    },
    {
      adUnit: 'agrofytv_middle_2',
      sizes: [[728, 90], [300, 250], [320, 50]]
    }
  ];
  
  const dfpNetworkID = process.env.DFP_NETWORK_ID || typeof window !== 'undefined' && window.env.DFP_NETWORK_ID;

  const { mkpUserData, userLoading } = useContext(UserContext);

  const fetchData = () => {
    const url = process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
    setIsLoading(true)
    return getData({ url: `${url}/api/AgrofyTV/GetVideosListPaginated?url=${urlParam}&size=6&pageToken=${pageToken}` })
      .then(result => {
        setVideosData(result.data),
          manageGTMPageName('AgrofyTv Home Category', 'News-' + result.data.categoryName),
          setIsLoading(false)
      })
  };

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    fetchData();
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      window.onload = () => {
        setTimeout(() => {
          gtmInitialize();
        }, 3000)
      };
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [urlParam]);

  const handleChildClick = data => {
    setLightVideo(false)
    dispatch('SET_CURRENT_CATEGORY_VIDEO_DATA', data)
    window.scrollTo(0, 0);
  }

  const breadcrumbArr = videosData && category && `[{"AgrofyTV":"/tv"},{"${videosData.categoryName}":"${category}"}]`;

  const titleText = isBrasil
  ? 'AgrofyTV | Aqui estão os melhores vídeos de'
  : 'AgrofyTV | Te mostramos los mejores videos de';

  const descriptionText1 = isBrasil
  ? 'No Agrofy Brasil TV, você encontra as melhores entrevistas, vídeos e streamings sobre'
  : 'En AgrofyTV vas a encontrar las mejores charlas, videos y streamings sobre';

  const descriptionText2 = isBrasil
  ? 'Entre em nossa videoteca e faça sua opção!'
  : 'Entra a nuestra videoteca y ¡elegí la mejor opción para vos!';

  const seoTitle = `${titleText} ${category}`;
  const seoUrl = `${url}/${category}`;
  const seoDesc = `${descriptionText1} ${category}. ${descriptionText2}`;

  return (
    <React.Fragment>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDesc} />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href={seoUrl} />
        <meta property="og:url" content={seoUrl} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:image" content={''} />
        <meta property="og:description" content={seoDesc} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:site" content={isBrasil ? '' : '@AgrofyNews'} />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDesc} />
        <script src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        <script type="application/ld+json">{buildBreadcrumbJson(getBreadcrumbData(breadcrumbArr, false))}</script>
      </Helmet>
      <AgroPushNotification location="Section" />
      <NewsContainer>
        <section className="dfp-banner stripped container top-banner">
          <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
            <AdSlot adUnit={'agrofytv_header'} sizes={[[970, 90], [970, 250], [320, 80]]} />
          </DFPSlotsProvider>
        </section>
      </NewsContainer>
      <div>
        <BreadcrumbContainer>
          <Breadcrumb breadcrumbLinks={getBreadcrumbData(breadcrumbArr, true)} />
        </BreadcrumbContainer>
        <MainVideoSection className="fluid-container">
          {
            videosData && videosData.categoryName.toLowerCase() === "crea" ? 
            <EventInfoContainer>
              <BannerContainer>
                <img 
                  srcSet="
                    https://news.agrofystatic.com/banner_CREA_desktop.png?d=1200x100 1x,
                    https://news.agrofystatic.com/banner_CREA_mobile.png?d=346x91 2x
                  "
                  sizes="(max-width: 576px) 420px, 870px"
                />
              </BannerContainer>
              <Summary>
                <p>Generación AGTECH - Creá tu futuro</p>
                <p>Si querés ser parte de la revolución tecnológica, sumate a la transformación que se viene en el agro digital. Empezá hoy a construir el mañana que soñás.</p>
                <p>Para más información e inscripción: <a href="https://acortar.link/QynHSQ">https://acortar.link/QynHSQ</a></p>
              </Summary>
            </EventInfoContainer> 
            : ""
          }
          {videosData && videosData.videos ?
            <CarouselVideos
              isCategoryPage
              withCarousel={false}
              selectedVideoFromCategories={videosData.videos[0]}
              setLightVideo={lightVideo} 
            />
            :
            <Loader><AgroSpinner width={'50px'} height={'50px'} color='primary' /></Loader>
          }
        </MainVideoSection>
        {(videosData && !isLoading) ? <VideoList className="videoList">
          <NewsContainer pt="md">
            <div className={`section-template__banner col-12 m-full-width ${banner1Loaded}`}>
              <div className="dfp-banner stripped">
                {!templateBanners ? <div className="banner b-300-250" /> :
                  <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
                    <AdSlot
                      adUnit={`${templateBanners[0].adUnit}`}
                      sizes={templateBanners[0].sizes}
                      onSlotRender={e => e.event.isEmpty ? '' : setBanner1Loaded('loaded-banner-mobile')}
                    />
                  </DFPSlotsProvider>
                }
              </div>
            </div>
            <DynamicSection urlParam={urlParam} category={category} onHandleClick={handleChildClick} className="dynamic-section" defaultVideoPlaying={videosData.videos[0]} />
            <div className={`mb-35 container px-0 ${middle2Loaded}`}>
              <DFPSlotsProvider dfpNetworkId={dfpNetworkID} collapseEmptyDivs>
                <div className="dfp-banner stripped bm-negative-ml-15">
                  <AdSlot
                    adUnit={`${templateBanners[1].adUnit}`}
                    sizes={templateBanners[1].sizes}
                    onSlotRender={e => e.event.isEmpty ? '' : setMiddle2Loaded('loaded-banner')}
                  />
                </div>
              </DFPSlotsProvider>
            </div>
          </NewsContainer>
          <Link to="/tv" className="link-to-home">
            <CategoryLink isCategoryPage>
              <TitleCategoryContainer><NewsIcon iname="arrowLeft" fill="#63A105" /><MainTitle>Volver a AgrofyTv</MainTitle></TitleCategoryContainer>
            </CategoryLink>
          </Link>
        </VideoList> : <Loader><AgroSpinner width='50px' height='50px' variant='primary' /> </Loader>}
      </div>
      <AdhesionBanner adUnit='agrofytv_adhesion' sizes={[[728, 90], [320, 80]]} />
      <PopUpBanner adUnit='agrofytv_fullscreen' sizes={[[800, 600], [320, 480]]} />
    </React.Fragment>
  )
}

Categories.getInitialProps = async ({ match, req, res }) => {
  const url = process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
  const urlParam = match.url;
  const urlParamModified = match.url.substring(1); //!!!!!hot fix
  const isStreamingCat = urlParam === "tv/streamings";

  try {
    const {
      data
    } = await getData({ url: `${url}/api/AgrofyTV/GetVideosListPaginated?url=${urlParamModified}&size=6&pageToken=${isStreamingCat ? "1" : ""}` });
    return { serverData: data };
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(Categories);

const BreadcrumbContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const EventInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;

  ${({ theme }) => css`
    @media only screen and (min-width: ${theme.breakpoint.lg}) {
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
    }`
  }

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoint.md}) {
      padding-left: 0px;
      padding-right: 0px;
    }`
  }
`;

const Summary = styled.div`
  padding-top: 20px;

  p {
    font-family: 'Barlow';
    color: #FFF;
    margin-top: 10px;
    margin-left: 12px;
    font-size: 1rem;
    line-height: 1.2;

    ${({ theme }) => css`
      @media only screen and (min-width: ${theme.breakpoint.lg}) {
        margin-bottom: 12px;
        margin-left: 0;
      }
    `}

    a {
      color: #63A105;
    }
  }
`;

const BannerContainer = styled.div`
  img {
    width: 100%;
    height: auto;
  }
`;

const MainVideoSection = styled.section`
  background: #212529;
`;
const VideoList = styled.div`
  /* position: relative;
  overflow-y: scroll; */
  /* max-height: 900px; */
  > a:first-child > .video-img-container {
    margin-top: 0;
  };
  .link-to-home {
    display: inline;
  };
  ${({ theme }) => css`
    @media only screen and (min-width: ${theme.breakpoint.lg}) {
      .link-to-home {
        display: none;
      };
    }
  `}
`;
const TitleCategoryContainer = styled.div`
  display: flex;
`;
const Loader = styled.div`
  display: flex;
  justify-content:center;
  align-items: center;
  min-height: 600px;
`;