import React from 'react';
import {useTranslation} from 'react-i18next';

const CheckboxFilters = ({parentCallback}) => {
  const {t} = useTranslation();
  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";

  const sectionsAR = [
    {
      id: 'c1',
      section: '101',
      label: 'general_interest'
    },
    {
      id: 'c2',
      section: '56',
      label: 'supplies'
    },
    {
      id: 'c3',
      section: '23,31',
      label: 'grain'
    },
    {
      id: 'c4',
      section: '67,77',
      label: 'hacienda'
    },
    {
      id: 'c5',
      section: '47,2816,2813',
      label: 'weather'
    },
    {
      id: 'c6',
      section: '100',
      label: 'financial'
    },
    {
      id: 'c7',
      section: '102',
      label: 'enterprises'
    },
    {
      id: 'c8',
      section: '103',
      label: 'institutional'
    }
  ];

  const sectionsBR = [
    {
      id: 'c1',
      section: '31',
      label: 'agriculture'
    },
    {
      id: 'c2',
      section: '77',
      label: 'livestock'
    },
    {
      id: 'c3',
      section: '2816',
      label: 'weather'
    }
  ];

  const sections = isBrasil ? sectionsBR : sectionsAR;

  return (
    <React.Fragment>
      {sections.map(item =>
        <div key={item.id}>
          <input
            id={item.id}
            name={item.id}
            type="checkbox"
            onChange={() => parentCallback(item.section)}
          />
          <label htmlFor={item.id}>{t(item.label)}</label>
        </div>
      )}
    </React.Fragment>
  );
};

export default CheckboxFilters;
