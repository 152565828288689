import React, {useEffect} from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

export const TermsOfUse = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <>
      <Helmet>
        <link href={process.env.RAZZLE_ENDPOINT_SELF} rel='canonical' />
        <title>Agrofy News | Termos e Condições</title>
        <meta
          content='Tudo o que você precisa saber sobre o setor agropecuário vai encontrar na Agrofy News! As principais notícias sobre o campo brasileiro e região estão aqui!'
          name='description'
        />
      </Helmet>
      <div className='container mb-36'>
        <h3>Termos e Condições</h3>
        <p>
          Os presentes Termos e Condições de uso têm como objeto regular a forma de uso que deve
          ser seguida pelas pessoas que acessam o site www.news.agrofy.com.br , bem como a que
          deve ser seguida por aquelas que acessem o conteúdo do site www.news.agrofy.com.br
          (doravante simplesmente a &quot;Agrofy News&quot; ou o &quot;Site&quot;) da AGROFY
          BRASIL ATIVIDADES DE INTERNET LTDA. O simples acesso ao Site, bem como ao seu conteúdo,
          realizado pelo usuário, implica a total aceitação dos Termos e Condições de Uso que
          serão detalhados a seguir.
        </p>
        <p>
          <strong>Objeto</strong> <br />A &quot;Agrofy News&quot; é um site orientado à divulgação
          de informações e material jornalístico referente a temas agropecuários. Igualmente, tem
          como objeto oferecer aos usuários diferentes produtos e/ou serviços publicitários, tanto
          por meio da Agrofy como por terceiros.
        </p>
        <p>
          O material publicado no Site e/ou enviado a você por e-mail, tem como único propósito
          oferecer informações a qualquer visitante, sem que isso implique em alguma classe de
          responsabilidade no que diz respeito a exatidão e integridade dos dados que estejam nele
          incluídos.
        </p>
        <p>
          {' '}
          <strong>Uso do Site</strong> <br />
          As pessoas que acessem o conteúdo do Site aceitam que a informação nele contida e os
          erros ou as omissões em que possa incorrer, não poderão ser usadas como base de qualquer
          reclamação ou demanda ou causa de ação legal. Igualmente, a informação ou qualquer
          opinião que constem expressadas nesse Site, não constituem, de forma alguma, qualquer
          oferta ou solicitação de compra ou venda de qualquer produto, nem devem servir de base
          para a tomada de qualquer classe de decisão.
        </p>
        <p>
          O uso do Site é de exclusiva responsabilidade do usuário, quem, para todos os efeitos,
          deverá usá-lo de acordo com as funcionalidades permitidas que constam detalhadas no
          próprio Site e para os usos autorizados nos presentes Termos e Condições de Uso, assim
          sendo, o usuário obriga-se a usá-lo de tal forma que não atente contra as normas de uso
          e convivência na Internet, as leis da República Federativa do Brasil, a legislação
          vigente no país em que o usuário esteja ao usá-lo, os bons costumes, a dignidade da
          pessoa e os direitos de terceiros.
        </p>
        <p>
          É expressamente proibida a reprodução total ou parcial da informação contida no Site
          (incluindo o método de frames).
        </p>
        <p>
          É terminantemente proibida a venda, locação, redistribuição, transmissão, retransmissão,
          divulgação e conversão por cabo ou computador ou por qualquer outra forma e/ou meio que
          seja usado para fornecer, divulgar ou compartilhar, com ou sem finalidades comerciais, a
          informação fornecida pela &quot;Agrofy News&quot; ou de terceiros citados como fontes da
          informação incluída no Site.
        </p>
        <p>
          É expressamente esclarecido que a &quot;Agrofy News&quot; não emite qualquer classe de
          juízos de valor sobre as notícias que venham a ser publicadas no Site, nem terá qualquer
          responsabilidade sobre as decisões a que o receptor desta informação possa chegar, já
          que elas são produto de seus poderes de apreciação e entendimento.
        </p>
        <p>
          A informação foi retirada de meios públicos e/ou privados considerados confiáveis pela
          &quot;Agrofy News&quot;, embora sua exatidão e integridade não sejam garantidas. A
          Agrofy nega toda e qualquer responsabilidade sobre a precisão, exatidão e/ou omissão nos
          dados exibidos em seus serviços de informação, já que esses dados são gerados pelas
          fontes citadas em cada caso, reservando para si o direito de publicá-la de acordo com o
          seu exclusivo critério.
        </p>
        <p>
          O usuário ou assinante da &quot;Agrofy News&quot; aceita e entende que tanto o uso da
          informação como as decisões que forem tomadas com base nela, serão totalmente realizadas
          sob seu próprio risco.
        </p>
        <p>
          A informação contida nas notícias, relatórios, informes, cotações da bolsa, eventos,
          etc. apresentadas nesse Site, é exclusivamente para informação do usuário ou assinante
          da &quot;Agrofy News&quot; e não supõe qualquer assessoria, qualificação ou sugestão de
          compra ou de venda de qualquer produto ou serviço. Tanto a &quot;Agrofy News&quot; como
          qualquer um de seus fornecedores de conteúdos não se responsabiliza por qualquer erro ou
          atraso na informação contida nesse Site.
        </p>
        <p>
          {' '}
          <strong>Garantia de Indenidade.</strong> <br />A Agrofy News não se responsabiliza pela
          interpretação e/ou má interpretação do que é exposto no Site ou nas consultas realizadas
          (de forma explícita ou implícita), nem pelo seu uso indevido, nem pelos prejuízos reais,
          diretos ou indiretos ou supostos, invocados por aqueles que tenham sido induzidos a
          tomar ou omitir decisões ou ações ao consultar o Site ou seus serviços.
        </p>
        <p>
          Também não garante nem se adere à informação e às opiniões lançadas nas notícias. Cada
          leitor deverá consultar seu assessor jurídico, tributário, agente de negócios ou aquele
          assessor que considere oportuno. O conteúdo da informação não deverá ser considerado e
          adotado como assessoria, consultoria, recomendação ou sugestão em matéria de negócios,
          investimentos, aspectos legais, jurídicos ou tributários.
        </p>
        <p>
          <strong>Serviços prestados por terceiros</strong> <br />A Agrofy não se responsabiliza
          pelo uso em pela idoneidade dos serviços prestados por terceiros (ferramentas, acessos
          digitais, estudos de mercado, etc.), nem pela suspensão desses serviços, portanto,
          isenta-se de qualquer responsabilidade pelos danos e prejuízos de qualquer natureza
          deles resultantes.
        </p>
        <p>
          {' '}
          <strong>Propriedade intelectual e industrial</strong> <br />O Site inclui conteúdos,
          textos, documentos, material publicitário, desenhos, design, material técnico de
          produtos ou de qualquer outra ordem, bases de dados, sons, programas de software, marcas
          corporativas, signos distintivos, marcas, desenhos gráficos (design gráfico),
          combinações de elementos, logotipos e imagens, protegidos pelos direitos da propriedade
          intelectual ou industrial, dos quais a Agrofy é titular ou legítima licenciante. Nesse
          sentido, é terminantemente proibida a reprodução total ou parcial, divulgação pública,
          modificação, transformação, cópia, distribuição ou qualquer outra forma de exploração e
          manipulação do Site, seus dispositivos técnicos, conteúdos, aplicações (aplicativos),
          códigos fonte, design (layout), seleção e forma de apresentação dos materiais e, em
          geral, tudo mais que diga respeito à informação contida no Site. Igualmente, também é
          proibido descompor, realizar engenharia reversa ou, em geral, transmitir em qualquer
          forma ou realizar obras derivadas dos programas para computadores necessários para o
          funcionamento e acesso ao Site e aos serviços aqui contidos, bem como realizar, no que
          diz respeito a eles, qualquer ato de exploração.
        </p>
        <p>
          {' '}
          <strong>Revisão da Termos e Condições</strong> <br />
          Os termos da presente poderão ser atualizados ou adaptados de tempos em tempos para que
          reflitam as nossas atividades, bem como estejam sempre em conformidade com a Legislação
          Aplicável e as melhores práticas.
        </p>

        <p>
          {' '}
          <strong>Jurisdição E Lei Aplicável</strong> <br />
          Este acordo estará regido em todos seus pontos pelas leis vigentes na República
          Federativa do Brasil.
        </p>
        <p>
          Qualquer controversa derivada do presente acordo, sua existência, validade,
          interpretação, alcance ou cumprimento, será submetida ao Foro da Cidade de São Paulo,
          renunciando os Usuários a qualquer outro foro que pudesse corresponder-lhes, inclusive o
          federal.
        </p>
        <p>
          {' '}
          <strong>Domicilio</strong> <br />
          Agrofy constitui domicilio legal na Cidade de São Paulo, Estado de São Paulo Rua
          Professor Atílio Innocenti, 165, 4º andar, sala 102, CEP 04538-000, Itaim Bibi, na
          Cidade de São Paulo, Estado de São Paulo.
        </p>
      </div>
    </>
  );
};
export default TermsOfUse;
