import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { removeAccents } from '../../../utils/stringUtils';
import ConditionalLink from '../../ConditionalLink/ConditionalLink';
import CmaLogo from '../../../images/icons/cma-logo.svg';
import MatbaRofexLogo from '../../../images/icons/matba-rofex-logo.png';
import CepeaLogo from '../../../images/icons/cepea-logo.png';
import './GranosTableResume.scss';

const GranosTableResume = ({ prices }) => {
  const { t } = useTranslation();
  const loader = <div className="loader container"><div className="animated-background" /></div>;

  const language = process.env.RAZZLE_LANGUAGE || window.env.RAZZLE_LANGUAGE;
  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === 'Brasil';

  const formatDate = data => {
    var date = new Date(data);
    const res = date.toLocaleString(language);
    return res;
  }
  
  const translateGrain = grainName => {
    return grainName == "Maíz" ? "Milho" : grainName;
  };

  const marketMap = {
    "CBOT": "futuros-opciones",
    "Pizarra": "precios-pizarra",
    "MtR": "futuros-opciones",
    "Oficial": "precios-oficiales",
    "Físico": "mercado-fisico"
  };

  const renderMktLinks = () => {
    const marketLinks = [
      { title: "Futuros online", url: "granos/futuros-opciones" }, { title: "Precios Pizarra", url: "granos/precios-pizarra" }, { title: "Mercado Físico", url: "granos/mercado-fisico" }, { title: "Precios Oficiales", url: "granos/precios-oficiales" }, { title: "Series históricas", url: "granos/series-historicas-futuros" }
    ]
    return (
      marketLinks.map(item => {
        return (<h3><Link to={item.url}>{item.title}</Link></h3>)
      })
    )
  };

  let cepeaLogoStyle = {
    marginLeft: '10px'
  };

  const setTable = () => {
    const classNames = item => item % 2 ? 'even-item' : 'odd-item';
    return (
      <Table className="table-agrofy">
        <thead>
          <tr className="resume-title-items">
            <th className="text-uppercase">{isBrasil ? t('product') : t('grano')}</th>
            <th className="text-uppercase">{t('mercado')}</th>
            <th className="text-uppercase">{isBrasil ? t('desc') : t('position')}</th>
            <th className="text-uppercase">{isBrasil ? 'VALOR' : t('cotization')}</th>
            <th className="text-uppercase">{t('variation')}</th>
            <th className="text-uppercase">{t('updated')}</th>
          </tr>
        </thead>
        <tbody>
          {prices.map((val, index) =>
            <tr key={`row-${index}`} className={classNames(index)}>
              <td key={`pos1-${index}`}>
                <ConditionalLink condition={!isBrasil} to={`/granos/precio-${removeAccents(val.ProductName.toLowerCase())}`}>
                  {isBrasil ? translateGrain(val.ProductName) : val.ProductName}
                </ConditionalLink>
              </td>
              <td key={`last1-${index}`}><ConditionalLink condition={!isBrasil} to={`/granos/${marketMap[val.Market]}`}>{val.Market}</ConditionalLink></td>
              <td key={`var-${index}`}>
                {val.Position}
              </td>
              <td key={`pos2-${index}`} className="td-lg-content">{
                // eslint-disable-next-line no-nested-ternary
                <div className={`${!val.Currency ? '' : val.VariationPercent === "0 %" ? 'equal-icon arrow' : val.VariationPercent.startsWith("-") ? 'down-icon arrow' : 'up-icon arrow'} tab-item d-flex`}>
                  <label className="priceTable pr-2">{val.Currency ? `${val.Currency} ${val.Quote}` : "S/C"} </label>
                  <label className="indicator">
                    <label className="icon"></label>
                  </label>
                </div>
              }</td>
              <td key={`pos3-${index}`} className="td-md-content">{
                // eslint-disable-next-line no-nested-ternary
                <div className={`${val.VariationPercent === "0 %" ? '0' : val.VariationPercent.startsWith("-") ? 'down-icon arrow' : 'up-icon arrow'} tab-item d-flex`}>
                  <label className="priceTable pr-2">{val.VariationPercent.includes('%') ? val.VariationPercent : `${val.VariationPercent} %`}</label>
                  <label className="indicator">
                    <label className="icon"></label>
                  </label>
                </div>
              }</td>
              <td key={`last2-${index}`} className="td-lg-content">{formatDate(val.UpdateDate)}</td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  };

  return (
    <>
      <div className="resume-table-section">
        {prices ? setTable() : loader}
      </div>
      <div className="logos-cont">
        <label className="description-logo">Powered by</label>
        <div className="description-logo">
          <img src={CmaLogo} width="56px" height="40px" />
          { !isBrasil ? <>and <img src={MatbaRofexLogo}/></> : <>and <img src={CepeaLogo} width="56px" style={{marginLeft: '10px'}}/></>}
        </div>
      </div>
      {!isBrasil ? 
      <div className="mkt-links-container"><h2 className="mb-20">Más Cotizaciones</h2>
        <div className="granos-links market-lks">
          {renderMktLinks()}
        </div>
      </div> : null}
    </>
  );
};

export default GranosTableResume;
