
import React, {useState, useEffect} from 'react';
import NewsletterCard from '../../atoms/NewsletterCard/NewsletterCard';
import './NewsletterCards.scss';

const NewsletterCards = ({data, parentCallback}) => {
  const [items, setItems] = useState(
    [
      {
        request: 'boardPrices',
        title: 'Precios pizarra',
        body: 'De Lunes a Viernes a las 11 hs. Cotizaciones de las principales Cámaras Arbitrales del país.',
        checked: true
      },
      {
        request: 'marketClosure',
        title: 'Cierre de mercados',
        body: 'De Lunes a Viernes a las 18 hs. Cierre de mercados nacionales e internacionales con las variables que impactaron en el precio de cada producto. Además las noticias más importantes.',
        checked: true
      },
      {
        request: 'todayNews',
        title: 'Noticias del día',
        body: 'De Lunes a Sábados a las 8 hs. Las noticias más importantes para arrancar el día sobre clima, granos, hacienda, economía e interés general. Pronóstico del tiempo. Principales temas del foro agropecuario.',
        checked: true
      },
      {
        request: 'dailyResume',
        title: 'Resumen del día',
        body: 'De Lunes a Viernes a las 20 hs. Resumen de las noticias más importantes del día que impactaron en el agro. Precios de granos, hacienda e indicadores económicos. Principales temas del foro agropecuario.',
        checked: true
      },
      {
        request: 'productPresentation',
        title: 'Presentación de productos',
        body: 'Las últimas novedades de las empresas líderes del agro, nuevos productos, beneficios y promociones (eventual).',
        checked: true
      }
    ]
  );

  useEffect(() => {
    if (data) {
      const updatedItems = items.map(item => {
        const value = data[`${item.title}`];
        return {...item, checked: value};
      });
      setItems(updatedItems);
      if (parentCallback) {
        parentCallback(updatedItems);
      }
    } else {
      const updatedItems = items.map(item => {
        return {...item, checked: true};
      });
      setItems(updatedItems);
      if (parentCallback) {
        parentCallback(updatedItems);
      }
    }
  }, [data]);
  const update = item => {
    // eslint-disable-next-line no-confusing-arrow
    const updatedItems = items.map(it => it.title === item.title ? {...it, checked: !it.checked} : it);
    setItems(updatedItems);
    if (parentCallback) {
      parentCallback(updatedItems);
    }
  };

  return (
    <div className="newsletter-cards">
      <div className="newsletter-cards__row row">
        {items.map((item, index) =>
          <div key={index} className="mb-10 col-md-4">
            <NewsletterCard item={item} parentCallback={it => update(it)}></NewsletterCard>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsletterCards;
