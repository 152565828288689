import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Column, AgroSpinner } from 'agrofy-ui-components';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import getData from '../../services/GetData';
import SimplePlayer from './SimplePlayer';
import { formattedDate } from '../../utils/dateUtils';
import { Circle, Description, Date, Content, ContentShareFirst, ContentShareSecond, StreamingTitle, HiddenButton } from '../../molecules/MainVideo/MainVideo';
import ShareVideoButtons from '../../molecules/MainVideo/ShareVideoButtons';
import { useStore } from '../../hooks/store'
import * as videosData from './GetVideosList6-5.json';
import { isEmpty } from '../../utils/helpers';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';

export const CarouselVideos = (props) => {
  const { t } = useTranslation();
  const { selectedVideoFromCategories, withCarousel, isCategoryPage, setLightVideo } = props;
  const [data, setData] = useState(videosData.default);
  const stateFromStore = useStore()[0];
  const [playingVideo, setPlayingVideo] = useState(selectedVideoFromCategories);
  const [state, dispatch] = useStore();
  const [datenow, setDate] = useState();
  
  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";

  const fetchData = () => {
    const url = process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
    return getData({ url: `${url}/api/AgrofyTV/GetVideosListByPlaylistID?playlistID=0&size=5` })
      .then(result => {
        setData(result.data)
      })
      .catch(() => {
        getData({ url: "./GetVideosList_6-5.json" })
          .then(result => {
            setData(result.data)
          })
      })
  };

  useEffect(() => {
    withCarousel && fetchData();
    setPlayingVideo(stateFromStore.videoData || selectedVideoFromCategories)
    var d = new window.Date("10/28/2020 11:00:00");
    setDate(d.getTime());
  }, [stateFromStore]);

  const getFecha = fecha => {
    const event = new window.Date(fecha);
    const months = { 0: "Enero", 1: "Febrero", 2: "Marzo", 3: "Abril", 4: "Mayo", 5: "Junio", 6: "Julio", 7: "Agosto", 8: "Septiembre", 9: "Octubre", 10: "Noviembre", 11: "Diciembre" }
    return `${event.getDate()} de ${months[event.getMonth()]}`;
  }

  const filteredArr = data.videos.filter(item => item).slice(0, 7); // rrmove null values

  const onHandleClick = (data) => {
    withCarousel ? dispatch('SET_CURRENT_VIDEO_DATA', data) : dispatch('SET_CURRENT_CATEGORY_VIDEO_DATA', data)
    window.scrollTo(0, 0);
  }
  const isPlayingVideo = item => item.url === stateFromStore.videoData.url;

  const isStreamingVideo = playingVideo && !playingVideo.url.startsWith('https');

  const Completionist = () => <><Circle /><span>Transmitiendo en vivo</span></>;

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {

    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return <span>Comienza en: {hours}:{minutes}:{seconds} hs</span>;
    }
  };

  return (
    <>
      {playingVideo ? <CarouselColumn lg={12} md={12} sm={12}>
        <MainVideoContainer>
          {!isCategoryPage && playingVideo.isLive && <Link to={`/${playingVideo.streamUrl}`} target="_blank"><HiddenButton /></Link>}
          {playingVideo && <SimplePlayer playlist={[isStreamingVideo ? playingVideo.id : playingVideo.url]} withCarousel={withCarousel} lightVideo={playingVideo.imageUrl ? playingVideo.imageUrl : setLightVideo} />}
        </MainVideoContainer>
        {playingVideo && <Description isCategoryPage isModal={false}>
          <WrapperMainTitle>
            <Content isModal={false}>
              <TitleMainVideo>{playingVideo.title}</TitleMainVideo>
              <ContentShareFirst>
                {playingVideo.isLive ?
                  <StreamingTitle>
                    <Countdown
                      date={datenow}
                      renderer={renderer}
                    />
                  </StreamingTitle>
                  :
                  <Date isModal={false} isMainCard isLiveNews={false}>{`${formattedDate(playingVideo.publishedDate)}`}</Date>}
                <ShareVideoButtons isCategoryPage={isCategoryPage} data={playingVideo} />
              </ContentShareFirst>
            </Content>
            {playingVideo.description && <Text>{playingVideo.description}</Text>}
            <ContentShareSecond isModal={false}>
              {playingVideo.isLive ?
                <StreamingTitle>
                  <Countdown
                    date={datenow}
                    renderer={renderer}
                  />
                </StreamingTitle>
                :
                <Date isModal={false} isMainCard isLiveNews={false}>{`${formattedDate(playingVideo.publishedDate)}`}</Date>}
              <ShareVideoButtons isCategoryPage={isCategoryPage} data={playingVideo} />
            </ContentShareSecond>
          </WrapperMainTitle>
        </Description>}
        <SliderSection>
          {withCarousel && <WrapperTitle>
            <MainTitle>
              {t('videos_most_viewed')}
            </MainTitle>
          </WrapperTitle>}
          {(data.videos && withCarousel) && <Slider {...settings} className="container slider-video">
            {filteredArr.map(item =>
              <SlickItem className="video-slick-item" key={item.title} onClick={() => onHandleClick(item)}>
                <Image src={item.imageUrl} />
                <DescriptionVideo>
                  {(!isEmpty(stateFromStore) && isPlayingVideo(item)) ? <LiveVideo><Circle isCarousel /><span>REPRODUCIENDO</span></LiveVideo> : (typeof window !== 'undefined' && <InfoDate>{`${getFecha(item.publishedDate)}, ${item.duration} min`}</InfoDate>)}
                  <Title isPlaying={!isEmpty(stateFromStore) && isPlayingVideo(item)}>{item.title.slice(0, 75)}</Title>
                </DescriptionVideo>
              </SlickItem>
            )}
            <Link to={isBrasil ? "tv/os-mais-vistos" : "tv/mas-visto"}><LastItem>{t('view_more')}</LastItem></Link>
          </Slider>}
        </SliderSection>
      </CarouselColumn> : <Loader><AgroSpinner width={'50px'} height={'50px'} color='primary' /></Loader>}
    </>
  )
}

{/* // Carousel settings */ }
const settings = {
  infinite: true,
  speed: 600,
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplay: false,
  swipeToSlide: true,
  autoplaySpeed: 10000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        swipeToSlide: true
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 425,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};
const Loader = styled.div`
    display: flex;
    justify-content:center;
    align-items: center;
    min-height: 660px;
`;
const MainVideoContainer = styled.div`
    position: relative;
    a:focus, a:active {
        outline: none;
    }
`;
const SliderSection = styled.section`
    ${({ theme }) => css`
        @media only screen and (max-width: ${theme.breakpoint.md}) {   
            display: none;
        }
    `}
    a:focus, a:active {
        outline: none;
    }
`;
const SlickItem = styled.div`
    display: flex !important;
    color: #ffffff;
    cursor: pointer;
    outline: none;
    @media only screen 
    and (min-width: 1024px) 
    and (max-height: 1366px) 
    and (-webkit-min-device-pixel-ratio: 1.5) {
        label:nth-child(1) {
            font-size: 10px;
        }
        label:nth-child(2) {
            font-size: 12px;
        }
    }
`;
const LastItem = styled.div`
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    font-weight: 600;
    font-size: 25px;
    line-height: 25px;
    color: #93C636;
    cursor: pointer;
`;
const DescriptionVideo = styled.label`
    margin-left: 16px;
    cursor: pointer;
`;
const InfoDate = styled.label`
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 4px;
    color: ${p => p.theme.colors.gray6};
    ${({ theme }) => css`
        @media only screen and (max-width: ${theme.breakpoint.lg}) {   
           margin-top: 12px;
        }
    `}
`;
const Image = styled.img`
    max-height: 76px;
    width: 100%;
    object-fit: cover;
`;
const Title = styled.label`
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    color: #F8F9FA;
    opacity : ${p => p.isPlaying ? "0.7" : "1"};
    padding-right: 10px;
`;
export const LiveVideo = styled.div`
    display: flex;
    padding-top: 4px;
    margin-bottom: 4px;
    > span {
        color: ${p => p.theme.colors.green4};
        font-size: 12px;
        font-weight: 700;
        font-family: 'Open Sans';
    }
`;
const WrapperTitle = styled.div`
    display: flex;
    flex-direction: column;
    background: #212529;
    color: #ffffff;
    ${({ theme }) => css`
        @media only screen and (max-width: ${theme.breakpoint.md}) {   
            display: none;
        }
    `}
`;
const MainTitle = styled.h5`
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
`;
const WrapperMainTitle = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 32px 0;
    ${({ theme }) => css`
        @media only screen and (max-width: ${theme.breakpoint.md}) {   
           flex-direction: column;
           padding: 0 16px;
        }
    `}
`;
const TitleMainVideo = styled.h2`
    font-size: 32px;
    line-height: 40px;
    color: #ffffff;
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ${({ theme }) => css`
        @media only screen and (max-width: ${theme.breakpoint.md}) {   
            font-size: 23px;
            line-height: 26px};
        }
        @media only screen and (min-width: ${theme.breakpoint.lg}) {
            padding-right: 32px;
        }
    `}
`;
const Text = styled.span`
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    font-family: 'Open Sans';
    margin-top: 16px;
    width: 100%;
    ${({ theme }) => css`
        @media only screen and (max-width: ${theme.breakpoint.md}) {   
           margin-bottom: 16px;
        }
        @media only screen and (min-width: ${theme.breakpoint.lg}) {
            width: 84%;
            margin-top: 8px;
        }
    `}
`;
const CarouselColumn = styled(Column)`
    background: #212529;
    margin-bottom: 20px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    .slick-next {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='24' viewBox='0 0 14 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.64865 0L0 2.8L8.7027 12L0 21.2L2.64865 24L14 12L2.64865 0Z' fill='%2393C636'/%3E%3C/svg%3E%0A") !important;
    }
    .slick-prev {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='24' viewBox='0 0 14 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.3514 24L14 21.2L5.2973 12L14 2.8L11.3513 2.31552e-07L-2.00275e-06 12L11.3514 24Z' fill='%2393C636'/%3E%3C/svg%3E%0A") !important;
    }
    .slick-next, .slick-prev {
        top: 46% !important;
        /* transition: all 1s !important;
        transform-origin: 50% 100% !important;
        &:hover {
            transform: scale(0.8) !important;
            top: 33%;
        } */
    }
    ${({ theme }) => css`
        @media only screen and (max-width: ${theme.breakpoint.md}) {   
           padding-right: 0;
           padding-left: 0;
        }
    `}
`;
