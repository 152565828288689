import React, { useState, useEffect, useContext } from "react";
import { DFPSlotsProvider, AdSlot } from "react-dfp";
import { Helmet } from "react-helmet-async";
import AdhesionBanner from "../../atoms/AdhesionBanner/AdhesionBanner";
import getData from "../../services/GetData";
import PaginationAgrofy from "../../atoms/PaginationAgrofy/PaginationAgrofy";
import SectionTemplate from "../../templates/sectionTemplate/SectionTemplate";
import { AgroPushNotification } from "../../components/AgroPushNotification/AgroPushNotification";
import withSSR from "../../withSSR";
import {
  gtmInitialize,
  manageGTMPageName,
  manageGTMUserStatus
} from "./../../utils/GTMDataLayerFuncs";
import UserContext from "../../atoms/UserContext/UserContext";
import TableIndexsResume from '../../atoms/Tables/TableIndexsResume/TableIndexsResume';

const IndicesAgrofy = (props) => {
  const serverData = props && props.serverData ? props.serverData : false;
  const serverVideo = props && props.serverVideo ? props.serverVideo : false;
  const [updatedData, setUpdatedData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(serverData && serverData.pages);
  const [products, setProducts] = useState([]);
  const [grains, setGrains] = useState([]);
  const tagName =
    (serverData &&
      serverData.title_section &&
      serverData.title_section.replace(/^\w/, (c) => c.toUpperCase())) ||
    "";
  
  const selfUrl = process.env.RAZZLE_ENDPOINT_SELF || window.env.RAZZLE_ENDPOINT_SELF;

  const dfpPrefix = "agrofynews_site/indices";
  const dfpNetworkId1 = "21735159925";

  const { mkpUserData, userLoading } = useContext(UserContext);

  const templateBanners = [
    {
      adUnit: "indices_middle_1",
      sizes: [
        [728, 90],
        [300, 250],
        [320, 50],
      ],
    },
    {
      adUnit: "indices_middle_2",
      sizes: [
        [300, 250],
        [320, 50],
      ],
    },
    {
      adUnit: "indices_middle_3",
      sizes: [
        [300, 250],
        [320, 50],
      ],
    },
    {
      adUnit: "indices_filmstrip",
      sizes: [[300, 600]],
    },
  ];

  const fetchData = () => {
    if (serverData && currentPage === 1) {
      if (serverVideo) {
        serverData.videos = serverVideo;
      }
      setUpdatedData(serverData);
      setIsLoading(false);
    } else {
      const url =
        process.env.RAZZLE_ENDPOINT_NOTI_API ||
        window.env.RAZZLE_ENDPOINT_NOTI_API;
      setIsLoading(true);
      return getData({
        url: `${url}/api/News/GetNewsListByTag?tag=tags/indices-agrofy&page=${currentPage - 1}&limit=15`,
      }).then((result) => {
        setUpdatedData(result.data);
        setIsLoading(false);
        setAmount(result.data.pages);
      });
    }
  };
  const fetchProductsData = () => {
    const url =
      process.env.RAZZLE_ENDPOINT_API_GATEWAY_URL ||
      window.env.RAZZLE_ENDPOINT_API_GATEWAY_URL;
    getData({
      url: `${url}/listing/search?&words=indispensables%20agro&size=48&start=0&ExcludeFacets=true&ExcludePriceFacet=true`,
    }).then((result) => {
      const indispensables = result.data?.Hits ?? [];
      const newProducts = indispensables.filter(x => x.usdPrice && x.usdPrice > 0).map(x => (
        { id: x.Id, name: x.name, price: x.usdPrice, urlProduct: x.url_key, urlImage: x.image }
      ));
      setProducts(newProducts);
    }).catch(() => setProducts([]));
  }

  const fetchGrainsData = () => {
    const url =
      process.env.RAZZLE_ENDPOINT_COTZ_API ||
      window.env.RAZZLE_ENDPOINT_COTZ_API;
    return getData({ url: `${url}/api/Rofex/GetContinuousPrices`, })
      .then((response) => response.data ?? [])
      .catch(() => []);
  }

  //uncomment when farm data will be implemented
  // const fetchFarmData = () => {
  //   const url = process.env.RAZZLE_ENDPOINT_COTZ_API || window.env.RAZZLE_ENDPOINT_COTZ_API;
  //   return getData({ url: `${url}/api/Prices/GetFarmPricesHome` })
  //     .then(response => response.data ?? [])
  //     .catch(() => []);
  // };

  const fetchQuotationsData = async () => {
    try {

      //uncomment when farm data will be implemented
      // const [grainsData, farmData] = await Promise.all([fetchGrainsData(), fetchFarmData()]);
      
      const grainsData = await fetchGrainsData();
      const collectionGrains = grainsData.map(x => {
        return ({ Quote: x.Quote, ProductName: x.ProductName, Currency: x.Currency, UnitQuote: "ton", IdProduct: x.IdProduct })
      });

      //uncomment when farm data will be implemented
      // const collectionFarm = farmData.flatMap(x => x.Categorias.filter(cat => cat.Nombre === 'Novillitos').map(x => {
      //   return ({Quote: parseFloat(x.Precios[0].PrecioCañuelas.replace('.', '').replace(',', '.')) , ProductName: x.Nombre, Currency: "U$S", UnitQuote: "kg", IdProduct: 4})
      // }));

      //uncomment when farm data will be implemented
      // setGrains([...collectionGrains, ...collectionFarm])

      setGrains([...collectionGrains])

    } catch (error) {
      setGrains([]);
    }
  };

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    fetchData();
    fetchProductsData();
    fetchQuotationsData();
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      window.onload = () => {
        setTimeout(() => {
          gtmInitialize();
        }, 3000)
      };
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (updatedData) {
      const section = document.getElementById("section-template");
      fetchData();
      if (section) {
        document.getElementById("section-template").scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (window && serverData) {
      manageGTMPageName(`Home ${serverData.title_section}`);
    }
  }, [serverData]);

  return (
    <div className="container px-0">
      <Helmet>
        <title>{`Índices Agrofy | Agrofy News`}</title>
        <meta name="robots" content="index,follow" />
        <link
          rel="canonical"
          href="https://news.agrofy.com.ar/indices"
        />
        <meta
          name="description"
          content="Análisis de precios, mercados y tendencias con información precisa y oportuna para poder tomar mejores 
          decisiones y potenciar el valor de tu negocio en el agro."
        />
      </Helmet>
      <AgroPushNotification location="Section" />
      <section className="mt-30 dfp-banner container top-banner indices">
        <div>
          <h1>ÍNDICES <span>AGROFY</span></h1>
        </div>
      </section>
      <section id="main-section" className="d-flex flex-wrap mt-20">
        <div className="fluid-container col-lg-9 px-0">
          <section className="container">
            <p className="mt-10">
              El índice Agrofy presenta el valor de los productos indispensables del agro en términos de granos,
              facilitando comparaciones entre distintos cultivos y actualizado en tiempo real. Accedé a información precisa para tomar decisiones sobre tu producción y ventas.
            </p>
            <TableIndexsResume products={products} grains={grains} />
            <SectionTemplate
              hasVideo={false}
              isExpo={false}
              videoCategory={selfUrl.includes("integration") ? "30046" : "32265"}
              data={updatedData || serverData}
              classes="small-spacing-top"
              bannersData={templateBanners}
              hideBannerTop={false}
              loading={isLoading}
              hasCarousel={false}
              carouselKeyword=""
              showNewslettersForm={false}
              hasVideoCarousel={true}
            />
            <div className="mb-36">
              {currentPage && amount && (
                <PaginationAgrofy
                  parentCallback={setCurrentPage}
                  currentPage={currentPage}
                  amount={amount}
                />
              )}
            </div>
          </section>
        </div>
        <div className="row col-lg-3 sidebar-item px-0 dfp-banner stripped p-0 banner-section d-none d-lg-block">
          <div className="make-me-sticky">
            <DFPSlotsProvider dfpNetworkId={dfpNetworkId1}>
              <AdSlot adUnit={dfpPrefix + "_followme"} sizes={[[260, 600]]} />
            </DFPSlotsProvider>
          </div>
        </div>
      </section>
      <AdhesionBanner
        adUnit={dfpPrefix + "_adhesion"}
        sizes={[[320, 80]]}
        specialNetworkID={dfpNetworkId1}

      />

    </div>
  );
};

IndicesAgrofy.getInitialProps = async ({ match, req, res }) => {
  const urlNoti = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
  const urlNews = process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
  try {
    const [noticias, videos] = await Promise.all([
      getData({ url: `${urlNoti}/api/News/GetNewsListByTag?tag=tags/indices-agrofy&page=0&limit=15` }),
      getData({ url: `${urlNews}/api/AgrofyTV/GetVideosListByPlaylistID?playlistID=PLgR_sLej6yly3HgLV1BJx4YjqehHG8qWP&size=10` }),
    ]);
    return {
      serverData: noticias.data,
      serverVideo: videos.data,
    };
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(IndicesAgrofy);
