
export const currentDate = () => {
  const language = process.env.RAZZLE_LANGUAGE || window.env.RAZZLE_LANGUAGE;
  const arrayLang = language.split("-");
  const today = new Date();
  const date = today.toLocaleDateString(arrayLang[0]);
  return date;
};
//calculate duration time of youtube videos (format ISO 8601 string):
export const YTDurationToSeconds = duration => {
  let match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);

  match = match.slice(1).map(x => {
    if (x != null) {
      return x.replace(/\D/, '');
    }
  });

  let hours = (parseInt(match[0]) || 0);
  let minutes = (parseInt(match[1]) || 0);
  let seconds = (parseInt(match[2]) || 0);

  return hours * 3600 + minutes * 60 + seconds;
}

export const formattedDate = date => {
  const language = process.env.RAZZLE_LANGUAGE || window.env.RAZZLE_LANGUAGE;
  const arrayLang = language.split("-");
  let d = new Date(date);
  const month = d.toLocaleString(arrayLang[0], { month: 'short' });
  const day = d.getDate();
  const year = d.getFullYear();
  return `${month} ${day}, ${year}`;
}