import React from 'react';
import './Newsletter.scss';
import { AgroButton, Ienvelope } from 'agrofy-ui-components';
import NewsLetterIcon from './NewsLetterIcon';
import TagManager from 'react-gtm-module';

const Newsletter = ({ isFull, withIcon, title, subTitle, placeholder, buttonText, className }) => {

  const manageGTM = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'trackEvent',
        eventCategory: 'Users',
        eventAction: 'Agrofy News-Newsletter',
        eventLabel: 'Intention-Modulo'
      }
    };

    TagManager.dataLayer(tagManagerArgs);
  };

  return (
    <div className={isFull ? 'newsletter-content is-full' : `newsletter-content is-column ${className}`}>
      {withIcon && <NewsLetterIcon />}
      <div className="newsletter-subcontent">
        <div className="nl-text">
          <span className="title">{title}</span>
          <label className="subtitle">{subTitle}</label>
        </div>
        <div className="nl-form">
          <a href="/newsletter" target="_blank">
            <AgroButton onClick={() => manageGTM()} size="lg" className="subNewsletters subNewslettersHeader">
              <Ienvelope height='16px' width='16px' fill='white' />
                Suscribirme
              </AgroButton>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
