import React, {useState, useEffect} from 'react';
import TablePhysical from '../../atoms/Tables/TablePhysical/TablePhysical';
import PaginationAgrofy from '../../atoms/PaginationAgrofy/PaginationAgrofy';
import {CSVLink} from 'react-csv';
import ButtonAgrofy from '../../atoms/ButtonAgrofy/ButtonAgrofy';
import {flattened} from '../../utils/helpers';

const PAGE_AMOUNT = 20;

const PhysicalTablePagination = ({data}) => {
  const [currentPage, setCurrentPage] = useState(1);
const [previousPage, setPreviousPage] = useState(1);
  const [amount, setAmount] = useState(null);
  const [formatData, setFormatData] = useState(false);

  useEffect(() => {
    if (!(data && data.Precios)) {
      return;
    }
    setCurrentPage(1);
    setFormatData(formatTableData());
  }, [data]);

  const formatTableData = () => {
    const newData = [];

    data.Precios.map(price => {
      return price.Destinos.map(dest => {
        return dest.Calidades.map(qual => {
          return qual.Entregas.map(deli => {
            return deli.Items.map(item => {
              const productName = price.Producto;
              return newData.push({
                name: productName,
                date: item.Fecha,
                destination: dest.Nombre,
                quality: qual.Nombre,
                delivery: deli.Nombre,
                price: `${deli.Moneda} ${item.Precio}`
              });
            });
          });
        });
      });
    });
    const count = Math.ceil(newData.length / PAGE_AMOUNT);
    setAmount(count);
    let init = 0;
    const arr = Array(count).fill().map(() => {
      const sub = newData.slice(init, init += PAGE_AMOUNT);
      return sub;
    });
    return arr;
  };
  const headers = [
    {label: 'Producto', key: 'name'},
    {label: 'Fecha', key: 'date'},
    {label: 'Destino', key: 'destination'},
    {label: 'Calidad', key: 'quality'},
    {label: 'Entrega', key: 'delivery'},
    {label: 'Precio', key: 'price'}
  ];

  return (
    <div className="physical-table-pag-content">
      { data && formatData ?
        <div className="physical-table-pag-content__table ">
          <TablePhysical data={data && formatData && formatData[currentPage - 1]} />
          {formatData && <CSVLink data={flattened(formatData)} headers={headers} separator={';'} filename={`series-historicas-fisico-${data.Precios[0].Producto}.csv`}><ButtonAgrofy parentCallback={() => {}} title="Descargar" classes="outline-primary small open-sans d-none d-sm-inline-block"></ButtonAgrofy></CSVLink>}
          {currentPage &&
            <PaginationAgrofy
              parentCallback={setCurrentPage}
              currentPage={currentPage}
              amount={amount}
            />
          }
        </div> : null
      }
    </div>
  );
};

export default PhysicalTablePagination;
