import React from 'react';
import LoadingContainer from '../LoadingContainer/LoadingContainer';
import ShareVideoButtons from '../../molecules/MainVideo/ShareVideoButtons';
import { Circle } from '../../molecules/MainVideo/MainVideo';

import './StreamingCard.scss';

const StreamingCard = ({ data, loading, isMobile }) => {
  const hasImage = data && data.logo;

  return (
    <React.Fragment>
      {loading ? <LoadingContainer height="435px" isDarkBackground /> : <> <div className="live-card"><Circle /><span className="category-page">Transmitiendo en vivo</span></div><div className="videowrapper">
        <iframe
          title="Video"
          width="858"
          height="528"
          id="video"
          src={data && data.video} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          className="videos-list static"
        />
      </div>
      </>}
      {!loading && <div>
        <div className="streaming-card-container">
          <div className="title-container">
            {data.title}
          </div>
          <div className="desc-container">
            {hasImage && <div className="img-cont">
              <img src={data.logo} className="modal-img" />
              <div className="share-btns-desktop"><ShareVideoButtons isCategoryPage={false} isStreaming data={data} /></div>
            </div>}
            {data && <div className="desc-body-container">
              <div className="desc-body" dangerouslySetInnerHTML={{ __html: data.body }}></div>
              {(isMobile || hasImage === null) && <div className="share-btns-mobile">
                <ShareVideoButtons isCategoryPage={false} isStreaming data={data} />
              </div>}
            </div>}
          </div>
        </div>
      </div>}
    </React.Fragment>
  )
};

export default StreamingCard;