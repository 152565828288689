import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import {strImageWithoutParams} from '../../utils/stringUtils';
import './WeatherModal.scss';

function WeatherModal(props) {
  const {t} = useTranslation();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const refImage = strImageWithoutParams(props.mapReference);

  return (
    <div className="weather-modal">
      <Modal
        centered
        scrollable={true}
        show={show}
        onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="modal-title">{t('{props.title}')}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-maps-wrapper">
            <img src={props.mapImage} alt={`${t('weather_image')}`} />
            <img src={props.mapReference} alt={`${t('weather_image_ref')}`} />
          </div>
        </Modal.Body>
      </Modal>
      <div className="box-maps" onClick={handleShow}>
        <div className="maps-wrapper">
          <img src={props.mapImage} alt={`${t('weather_image')}`} />
          <img src={`https://news.agrofystatic.com/clima/${refImage}`} alt={`${t('weather_image_ref')}`} />
        </div>
      </div>
    </div>
  );
}

WeatherModal.defaultProps = {
  title: 'AgrofyNews Modal',
  alt: 'AgrofyNews'
};

export default WeatherModal;
