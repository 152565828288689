/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/messaging';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useStore } from '../../hooks/store';
import { Text, AgroIcon, AgroButton } from 'agrofy-ui-components';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';

const AgroPushNotification = (props) => {
  const { t } = useTranslation();
  const location = props.location;
  const [messaging, setMessaging] = useState();
  const [showNotif, setShowNotif] = useState(false);
  const [display, setDisplay] = useState();
  const [isMobile, setIsMobile] = useState();
  const state = useStore()[0];

  const firebaseConfig = {
    apiKey: 'AIzaSyDFXnk6OFeUlYOosTdTd-fYGVMbH9UiOmo',
    authDomain: 'agrofynews-push-notifications.firebaseapp.com',
    projectId: 'agrofynews-push-notifications',
    storageBucket: 'agrofynews-push-notifications.appspot.com',
    messagingSenderId: '393282723522',
    appId: '1:393282723522:web:309088002fe321ea4d255b'
  }

  const TrackNotificationsEvent = (selection, WPNtoken) => {
    let selectionValue = '';
    
    switch (selection) {
      case 'allowed':
        selectionValue = 'Allow notifications';
        break;
      case 'denied':
        selectionValue = 'Block notifications';
        break;
      case 'default':
        selectionValue = 'Close dialog box';
        break;
    }

    const gtmEvent = {
      dataLayer: {
        event: 'browser_request_answer',
        selection: selectionValue,
        wpn_token: WPNtoken,
      },
    };

    if (TagManager) {
      TagManager.dataLayer(gtmEvent);
    }
  };

  const TrackEvent = (eventCategory, eventAction, eventLabel) => {
    const gtmEvent = {
      dataLayer: {
        event: 'trackEvent',
        eventCategory: eventCategory,
        eventAction: eventAction,
        eventLabel: eventLabel,
      },
    };

    if (TagManager) {
      TagManager.dataLayer(gtmEvent);
    }
  };

  useEffect(() => {
    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
    setIsMobile(isMobileDevice);

    if (!isMobileDevice) {
      setTimeout(() => { setDisplay(true) }, 4000);
    }
  }, []);

  useEffect(() => {
    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);

    const onScroll = e => {
      if (!display) {
        const scrollTop = e.target.documentElement.scrollTop;

        if (isMobileDevice && scrollTop != 0) {
          setTimeout(() => { setDisplay(true) }, 4000)
        }
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [display]);

  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }

    try {
      if (firebase.messaging.isSupported()) {
        setMessaging(firebase.messaging());
      }
    }
    catch (err) { }
  }, []);

  useEffect(() => {
    if (messaging) {
      if (localStorage.webPushWPNTokenRegistrationFailed) {
        resetUI();
      } else if (localStorage.notificationsExpirationDate) {
        if (new Date() > new Date(localStorage.notificationsExpirationDate)) {
          if (
            'Notification' in window &&
            'serviceWorker' in navigator &&
            'PushManager' in window &&
            Notification.permission == 'default'
          ) {
            setShowNotif(true);
            location && TrackEvent('Web Notifications', 'Browser web notifications', 'Impression-' + location);
          }
        }
      } else if (
        'Notification' in window &&
        'serviceWorker' in navigator &&
        'PushManager' in window &&
        Notification.permission == 'default'
      ) {
        {
          setShowNotif(true);
          location && TrackEvent('Web Notifications', 'Browser web notifications', 'Impression-' + location);
        }
      }
    }
  }, [messaging]);

  const resetUI = async () => {
    try {
      const currentToken = await messaging.getToken(
        { vapidKey: 'BFsNrcXThHx5r6K0_w95-L94NZ0id7Z7MTybztJmL5PNBGkOf2og1rauXuP7wcC02aLC-t9fskv07nKPANVppF0'}
      );

      if (currentToken) {
        sendTokenToServer(currentToken);
      } else {
        if (!localStorage.webPushWPNTokenRegistrationFailed) {
          localStorage.setItem('webPushWPNTokenRegistrationFailed', true);
        }
      }
    } catch (err) {
      if (!localStorage.webPushWPNTokenRegistrationFailed) {
        localStorage.setItem('webPushWPNTokenRegistrationFailed', true);
      }

      sendSlackNotification(':pepe_monkas: Web Push Notifications - Firebase token generation error - ' + err.message)
    }
  };

  const sendTokenToServer = async (currentToken) => {
    let payload = {};
    payload['token'] = currentToken;
    payload['country'] = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
    payload['dataExtensionKey'] = process.env.NEWS_NOTIFICATION_DE_KEY || window.env.NEWS_NOTIFICATION_DE_KEY;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    };

    const url = process.env.RAZZLE_ENDPOINT_FORO_API || window.env.RAZZLE_ENDPOINT_FORO_API;
    fetch(`${url}/api/User/SubscribeUserToWebNotifications`, requestOptions)
      .then((resp) => {
        if (resp) {
          TrackNotificationsEvent('allowed', currentToken);
          if (localStorage.webPushWPNTokenRegistrationFailed) {
            localStorage.removeItem('webPushWPNTokenRegistrationFailed');
          }
        } else {
          if (!localStorage.webPushWPNTokenRegistrationFailed) {
            localStorage.setItem('webPushWPNTokenRegistrationFailed', true);
          }
        }
      })
      .catch((error) => {
        return null;
      });
  };

  const sendSlackNotification = async (message) => {
    let payload = {};
    payload['text'] = message;

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(payload)
    };

    const url = process.env.NEWS_SLACK_NOTIFICATIONS_WEBHOOK || window.env.NEWS_SLACK_NOTIFICATIONS_WEBHOOK;
    fetch(`${url}`, requestOptions);
  }

  const onNotifAccepted = () => {
    setShowNotif(false);
    location && TrackEvent('Web Notifications', 'Subscribe to browser web notifications', 'Yes subscribe-' + location);
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      try {
        Notification.requestPermission().then((permission) => {
          switch (permission) {
            case 'granted':
              resetUI();
              break;
            case 'denied':
              TrackNotificationsEvent('denied', '');
              break;
            case 'default':
              TrackNotificationsEvent('default', '');
              break;
          }
        });
      } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
      }
    }
  };

  const onNotifDeclined = () => {
    setShowNotif(false);
    TrackEvent('Web Notifications', 'Subscribe to browser web notifications', 'No subscribe-' + location);
    setNotificationExpirationDate();
  };

  const setNotificationExpirationDate = () => {
    let expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7);
    localStorage.setItem('notificationsExpirationDate', expirationDate.toUTCString());
  };

  return showNotif ? (
    <NotificationContainer display={display} className="push-notification-container">
      <InfoContainer>
        <IconContainer>
          <AgroIcon iname="agrofyLogo2" height='36px' width='40px' fill='#63A105' />
        </IconContainer>
        <ChildrenContent>
          <NotificationMessage>
            {t('notification_message')}
          </NotificationMessage>
        </ChildrenContent>
      </InfoContainer>
      <ActionContainer>
        <StyledButtons>
          <ButtonOptionNo className="push-notification-btn-no" onClick={() => onNotifDeclined()} size='sm' ml='sm'>
            {t('no_thanks')}
          </ButtonOptionNo>
          <ButtonOptionYes className="push-notification-btn-yes" onClick={() => onNotifAccepted()} size="lg">
            {t('receive_notifications')}
          </ButtonOptionYes>
        </StyledButtons>
      </ActionContainer>
    </NotificationContainer>
  ) : null;
};

export { AgroPushNotification };

AgroPushNotification.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  decline: PropTypes.string,
  accept: PropTypes.string,
  api: PropTypes.string,
  tagManager: PropTypes.object,
};

const NotificationContainer = styled.div`
  width: 100%;
  position: fixed;
  background: white;
  z-index: 11;
  bottom: 0px;
  display: ${props => (props.display ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  font-size: 14px;
  box-sizing: border-box;
  border-top: 1px solid #E1E1E1;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}) {
    bottom: 44px;
    right: 31px;
    width: 480px;
    font-size: 14px;
    border-radius: 8px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.22);
    width: 410px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconContainer = styled.div`
  margin: 8px 16px 0 0;
  display: flex;
  justify-content: center;
`;

const ChildrenContent = styled.div`
  flex: 12;
  padding-bottom: 5px;
`;

const NotificationMessage = styled(Text)`
  &&& {
    font-size: 14px;
    font-family: 'Open Sans',sans-serif;
    line-height: 20px;
    letter-spacing: 0.2px;
  }
`;

const ActionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const StyledButtons = styled.div`
  display: flex;
  margin-top: 12px;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}) {
    justify-content: flex-end;
    margin-top: 8px;
  }
`;

const ButtonOptionYes = styled(AgroButton)`
  &&& {
    font-family: 'Open Sans',sans-serif;
    padding: 0px 42px;
    letter-spacing: 0.2px;
  }
`;

const ButtonOptionNo = styled.button`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  transition: all 0.3s ease;
  font-family: 'Open Sans',sans-serif;

  &:hover,
  &:focus {
    outline: none;
  }
  &:active {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }

  background-color: white;
  color: ${({ theme }) => theme.colors.blue6};
  &:hover:not(:active),
  &:focus {
    color: ${({ theme }) => theme.colors.blue8};
  }
  &:active {
    color: ${({ theme }) => theme.colors.blue10};
  }

  padding: 0px 14px;
  height: 48px;
  font-size: 14px;
  line-height: 20px;
`;
