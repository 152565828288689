import React, { useEffect, useState } from 'react';
import getData from '../../services/GetData';
import { LogoSection, ContainerLogo } from '../../pages/AgrofyTv/AgrofyTv';
import Logo from '../../images/icons/agrofytvLogo.svg';
import {Icon} from '../../atoms/VideoCardImg/VideoCardImg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AgroButton } from 'agrofy-ui-components';
import './Video.scss';

const Video = ({ isFull, isHome }) => {
  const { t } = useTranslation();
  const [videos, setVideos] = useState();
  const [embedSrc, setEmbedSrc] = useState();
  const [featuredVideo, setFeaturedVideo] = useState(null);
  const loader = <div className="loader container p-0"><div className="animated-background" /></div>;
  const fetchFeaturedVideo = () => {
    const url = process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
    return getData({ url: `${url}/api/AgrofyTV/GetHighlightedVideo` })
        .then(result => {
            setFeaturedVideo(result.data)
        })
  }
  useEffect(() => {
    const cant = isFull ? '15' : '4';
    const fetchData = () => {
      const url = process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
      getData({ url: `${url}/api/Videos/GetVideosYoutube?cant=${cant}` })
        .then(resp => {
          setVideos(resp.data);
          if (isFull) {
            const video = document.getElementById('video');
            const bottom = video.scrollHeight;
            window.addEventListener('scroll', () => handleScroll(video, bottom));
          }
        });
    };
    fetchFeaturedVideo()
    fetchData();
    !isHome && window.scrollTo(0, 0);
    return () => {
      window.removeEventListener('scroll', () => handleScroll());
    };
  }, []);

  const handleScroll = (video, bottom) => {
    let scrollpos = window.scrollY;
    function add_class_on_scroll() {
      video.classList.add('moving');
      video.classList.add('scrolled');
      video.classList.remove('static');
    }

    function remove_class_on_scroll() {
      video.classList.remove('moving');
      video.classList.remove('scrolled');
      if (!video.classList.contains('static')) {
        video.classList.add('static');
      }
    }
    window.addEventListener('scroll', () => {
      scrollpos = window.scrollY;
      if (scrollpos > (bottom + 58)) {
        add_class_on_scroll();
      } else {
        remove_class_on_scroll();
      }
    });
  };

  const changeVideo = item => {
    setEmbedSrc(`https://www.youtube.com/embed/${item.id.videoId}`);
  };

  const getVideos = () => {

    const featuredVideoID = featuredVideo !== null &&
      featuredVideo.url.replace('https://www.youtube.com/embed/', '');

    var filteredVideos = videos.items.filter(
      item => item.id.videoId !== featuredVideoID
    );

    filteredVideos.length > 3 && filteredVideos.pop();

    return filteredVideos.map(item => {
      if (!item.snippet) {
        return false;
      }

      return <Link to={item.snippet.urlSlug} className="mb-32">
        <li key={item.id.videoId} className="video-element row" onClick={() => changeVideo(item)}>
          <img className={isFull ? '' : 'col-3 p-0'} alt={item.snippet.title} title={item.snippet.title} width="164" height="100" src={item.snippet.thumbnails.default.url} />
          <label className={isFull ? 'pl-20 pt-15 pl-24 m-0 barlow bold' : 'col-8 pt-15 pr-8 pl-24 m-0 barlow bold'}>
            {item.snippet.title}
          </label>
        </li>
      </Link>;
    });
  };

  const getEmbedSrc = () => {
    return embedSrc || `https://www.youtube.com/embed/${videos.items[0].id.videoId}`;
  };

  const removeLastString = str => str.substr(0, str.lastIndexOf("/"));

  return (
    videos && videos.items && videos.items[0] && videos.items[0].id && videos.items[0].id.videoId ?
      <React.Fragment>
        <LogoSection className="tv-logo-section">
          <ContainerLogo><div className="title-video"><span className="mobile-desc">{t('videos_title_mob')}</span><span className="desktop-desc">{t('videos_title')}</span></div> <div><img src={Logo} /></div></ContainerLogo>
        </LogoSection>
        <div className="video-section container px-0" >
          <div className={isFull ? 'row is-full' : 'row home-tv-section'}>
            <div className={isFull ? 'video-container col-lg-12' : 'video-container col-lg-8'}>
              {featuredVideo && <Link className="link-video" to={featuredVideo.urlSlug}>
                <img src={featuredVideo.imageUrl} className="static"/>
                <Icon iname="playCircleOutline" fill="white" width="64px" height="64px" topPos="40%" />
                <h5>{featuredVideo.title}</h5>
              </Link>}
            </div>
            {isFull ? <h3 className="video-header">{t('keep_watching')}</h3> : ''}
            <div className={isFull ? 'col-lg-12 m-0' : 'col-lg-4 videos-list-container'}>
              <ul>
                {!videos ? loader : getVideos()}
              </ul>
              {/* <div className="col-lg-8"></div> */}
              <div className={isFull ? 'd-none' : 'mt-0'}>
                <Link to="/tv"><AgroButton fullWidth className="tv-btn" variant='primary'>{t('see_all_videos')}</AgroButton></Link>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
      : ''
  );
};
export default Video;

