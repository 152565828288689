import React from 'react';
import {hydrate} from 'react-dom';
import {
  BrowserRouter as Router
} from 'react-router-dom';

import App from './App';
import routes from './routes';
import configurePlayerStore from './hooks/videoPlayer-store';
import configurePlayerCategoryStore from './hooks/videoCategoryPlayer-store';
import {Helmet, HelmetProvider} from 'react-helmet-async';

configurePlayerStore();
configurePlayerCategoryStore();

const helmetContext = {};

const router = (
  <Router>
    <HelmetProvider context={helmetContext}>
      <App routes={routes} initialData={window.DATA} />
    </HelmetProvider>
  </Router>
);

// Render it to DOM
hydrate(router, document.getElementById('root'));

if (module.hot) {
  module.hot.accept();
}
