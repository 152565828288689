const buildHeaders = config => {
  let headers = {};
//
  if (config.ContentType) {
    headers['Content-Type'] = config.ContentType;
  }

  if (config.token) {
    headers.Authorization =  'Bearer ' + config.token;
  }

  if (config.noCache) {
    headers['Cache-Control'] = 'no-cache';
  }

  if (config.Accept) {
    headers['Accept'] = config.Accept;
  }

  return headers;
}

export default buildHeaders;