import React from 'react';
import {Table} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CmaLogo from '../../../images/icons/cma-logo.svg';
import MatbaRofexLogo from '../../../images/icons/matba-rofex-logo.png';
import './TableOption.scss';

const TableOption = ({data, market}) => {
  const { t } = useTranslation();
  const loader = <div className="loader container"><div className="animated-background" /></div>;

  const setTable = () => {
    const call = data.filter(item => item.TipoOperacion === 'Call');
    const put = data.filter(item => item.TipoOperacion === 'Put');
    return (
      <div className="option-table-section__content row">
        <div className="option-table-section__content__table call col-6 pr-0">
          <div className="option-table-section__content__table__tab">
            <span className="text4 bold text-uppercase">Call</span>
          </div>
          <Table className="table-agrofy is-gray">
            <thead>
              <tr>
                <th className="text-uppercase">{t('exercise_price')}</th>
                <th className="text-uppercase">{t('prima')}</th>
                <th className="text-uppercase">{t('nominal_variation')}</th>
              </tr>
            </thead>
            <tbody>
              { call.map((item, idx) =>
                item.Precios.map((val, index) =>
                  <tr key={`row-call-${idx}-${index}-${val.PrecioEjercicio}`}>
                    <td key={`pos-call-${idx}-${index}-${val.PrecioEjercicio}`}>{val.PrecioEjercicio}</td>
                    <td key={`last-call-${idx}-${index}-${val.PrecioEjercicio}`}>{val.Prima}</td>
                    <td key={`var-call-${idx}-${index}-${val.PrecioEjercicio}`}>{val.Variacion}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </div>
        <div className="option-table-section__content__table put col-6 pl-0">
          <div className="option-table-section__content__table__tab">
            <span className="text4 bold text-uppercase">Put</span>
          </div>
          <Table className="table-agrofy is-gray">
            <thead>
              <tr>
                <th className="text-uppercase">{t('exercise_price')}</th>
                <th className="text-uppercase">{t('prima')}</th>
                <th className="text-uppercase">{t('nominal_variation')}</th>
              </tr>
            </thead>
            <tbody>
              { put.map((item, idx) =>
                item.Precios.map((val, index) =>
                  <tr key={`row-put-${idx}-${index}-${val.PrecioEjercicio}`}>
                    <td key={`pos-put-${idx}-${index}-${val.PrecioEjercicio}`}>{val.PrecioEjercicio}</td>
                    <td key={`last-put-${idx}-${index}-${val.PrecioEjercicio}`}>
                      { // eslint-disable-next-line no-nested-ternary
                        <div className={`${parseFloat(val.Prima) > 0 ? 'up arrow d-flex' : parseFloat(val.Prima) < 0 ? 'down arrow d-flex' : 'equal d-flex'}`}>
                          <label className="percent pr-2 mb-0">{val.Prima}</label>
                          <label className="indicator mb-0">
                            <label className="icon"></label>
                          </label>
                        </div>
                      }
                    </td>
                    <td key={`var-put-${idx}-${index}-${val.PrecioEjercicio}`}>{val.Variacion}</td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </div>
      </div>
    );
  };

  return (
    <div className="option-table-section  mb-36">
      {data ? setTable() : loader}
      {market.name === "CBOT" && <div className="description-logo opt-table">Powered by <img src={CmaLogo} /></div>}
      {(market.name === "MATBA" || market.name === "ROFEX") && <div className="description-logo opt-table">Powered by <img width="42px" height="34px" src={MatbaRofexLogo} /></div>} 
    </div>
  );
};

export default TableOption;
