import React, { useState, useEffect, useContext } from "react";
import AdhesionBanner from "../../atoms/AdhesionBanner/AdhesionBanner";
import Breadcrumb from "../../atoms/Breadcrumb/Breadcrumb";
import citiesToFilter from "./forecast/Cities";
import { DFPSlotsProvider, AdSlot } from "react-dfp";
import Forecast from "./forecast/Forecast";
import getData from "../../services/GetData";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import PaginationAgrofy from "../../atoms/PaginationAgrofy/PaginationAgrofy";
import PopUpBanner from "../../atoms/PopUpBanner/PopUpBanner";
import SectionTemplate from "../../templates/sectionTemplate/SectionTemplate";
import { useTranslation } from "react-i18next";
import withSSR from "../../withSSR";
import { buildBreadcrumbJson } from "./../../utils/breadcrumbJson";
import { getBreadcrumbData } from "./../../utils/breadcrumbJson";
import { splitUrl, toTitleCase } from "./../../utils/stringUtils";
import { gtmInitialize, manageGTMPageName, manageGTMUserStatus} from "./../../utils/GTMDataLayerFuncs";
import agrofyTVPlaylists from "../../utils/agrofyTVPlaylists";
import UserContext from "../../atoms/UserContext/UserContext";
import { AgroPushNotification } from "../../components/AgroPushNotification/AgroPushNotification";

function Pronostico(props) {
  const pathName = props ? props.location.pathname : "";
  const serverData = props && props.serverData;
  const weatherData = props && props.weatherData;
  const ssrCityName = weatherData && weatherData.Headline.LocalizedName;
  const initalParams = props && props.match && props.match.params;
  const [templateData, setTemplateData] = useState(serverData || null);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const amount = templateData
    ? templateData.pages
    : serverData && serverData.pages;
  const [breadcrumbUpdatedCity, setBreadcrumbUpdatedCity] = useState();
  const [agrofyTVData, setAgrofyTVData] = useState([]);
  const paramsCity =
    props && props.match && props.match.params && props.match.params.city;
  // eslint-disable-next-line no-nested-ternary
  const [forecastData, setForecastData] = useState();
  const [cityID, setCityID] = useState(false);
  const [cityName, setCityName] = useState();
  const [provinceName, setProvinceName] = useState(false);
  const [citySlug, setCitySlug] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const pronosticoData = forecastData ? forecastData : false;
  const [cityLocation, setCityLocation] = useState();

  const selfUrl =
    process.env.RAZZLE_ENDPOINT_SELF || window.env.RAZZLE_ENDPOINT_SELF;
  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";

  const dfpNetworkID = process.env.DFP_NETWORK_ID || window.env.DFP_NETWORK_ID;
  const dfpPrefix = "clima";

  const { mkpUserData, userLoading } = useContext(UserContext);

  const setCityData = () => {
    var forecastCity = pronosticoData && pronosticoData.Headline.LocalizedName;
    var forecastProv =
      pronosticoData && pronosticoData.Headline.ProvinceLocalizedName;
    var forecastId = pronosticoData && pronosticoData.Headline.Key;

    const defaultCitySlug = isBrasil ? "brasilia+df" : "rosario+santa+fe";
    const defaultCityName = isBrasil ? "Brasília" : "Rosario";
    const defaultProvinceName = isBrasil ? "Distrito Federal" : "Santa Fe";
    const defaultCityId = isBrasil ? "43348" : "11222";

    const citySlug =
      paramsCity ||
      JSON.parse(localStorage.getItem("savedCitySlug")) ||
      defaultCitySlug;
    const cityName =
      forecastCity ||
      JSON.parse(localStorage.getItem("savedCityName")) ||
      defaultCityName;
    const provinceName =
      forecastProv ||
      JSON.parse(localStorage.getItem("savedProvinceName")) ||
      defaultProvinceName;
    const cityId =
      forecastId ||
      JSON.parse(localStorage.getItem("savedCityId")) ||
      defaultCityId;

    setCityID(cityId);
    setCityName(cityName);
    setProvinceName(provinceName);
    setCitySlug(citySlug);
  };

  const { t } = useTranslation(["translation", "links"]);
  const loader = (
    <div className="loader container p-0">
      <div className="animated-background" style={{ height: "300px" }} />
    </div>
  );

  const breadcrumb = [
    {
      title: `${t("translation:weather_at_breadcrumb")}`,
      link: `${t("links:weather")}`,
    },
    {
      title: `${t("translation:weather")} ${
        breadcrumbUpdatedCity ? breadcrumbUpdatedCity : cityName
      }`,
      link: "",
    },
  ];

  const templateBanners = [
    {
      adUnit: `${dfpPrefix}_middle_1`,
      sizes: [
        [728, 90],
        [300, 250],
        [320, 50],
      ],
    },
    {
      adUnit: `${dfpPrefix}_middle_2`,
      sizes: [
        [300, 250],
        [320, 50],
      ],
    },
    {
      adUnit: `${dfpPrefix}_middle_3`,
      sizes: [
        [300, 250],
        [320, 50],
      ],
    },
    {
      adUnit: `${dfpPrefix}_filmstrip`,
      sizes: [[300, 600]],
    },
  ];

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    setCityData();
    setAgrofyTVData(agrofyTVPlaylists);
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      window.onload = () => {
        setTimeout(() => {
          gtmInitialize();
        }, 3000)
      };
    } catch (error) {
      window.scrollTo(0, 0);
    }

    window && setCityLocation(splitUrl(window.location.href, 5));
  }, []);

  const fetchData = () => {
    const url =
      process.env.RAZZLE_ENDPOINT_NOTI_API ||
      window.env.RAZZLE_ENDPOINT_NOTI_API;
    setIsLoading(true);
    getData({
      url: `${url}/api/News/GetNewsListByCategory?categoryID=2816&page=${
        currentPage - 1
      }&limit=15&canonical=${pathName}`,
    }).then((result) => {
      setTemplateData(result.data);
      setIsLoading(false);
    });
  };

  const handleReload = () => {
    if (templateData || serverData) {
      const section = document.getElementById("section-template");
      fetchData();
      if (section && serverData !== templateData) {
        document.getElementById("section-template").scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    handleReload();
  }, [currentPage]);

  useEffect(() => {
    pronosticoData && setCityData();
  }, [pronosticoData]);

  useEffect(() => {
    if (window && pronosticoData) {
      manageGTMPageName(
        `Clima ${toTitleCase(paramsCity && paramsCity.replace(/\+/g, " "))}`
      );
    }
  }, [pronosticoData]);

  // eslint-disable-next-line no-shadow
  const handleChangeCity = (value) => {
    setBreadcrumbUpdatedCity(value);
    setCityLocation(splitUrl(window.location.href, 5));
  };

  useEffect(() => {
    const fetchForecastData = () => {
      const url =
        process.env.RAZZLE_ENDPOINT_NEWS_API ||
        window.env.RAZZLE_ENDPOINT_NEWS_API;
      if (paramsCity) {
        getData({
          url: `${url}/api/Weather/GetWeatherBySlug?slug=${paramsCity}`,
        }).then((result) => setForecastData(result.data));
      } else {
        getData({
          url: `${url}/api/Weather/GetWeatherForecast?idCity=${cityID}`,
        }).then((result) => setForecastData(result.data));
      }
    };

    (paramsCity || cityID) && fetchForecastData();
  }, [paramsCity, cityID]);

  const getAgrofyTVPlaylistID = () => {
    const item = agrofyTVData && agrofyTVData.find((e) => e.name == "Clima");
    return item && item.val;
  };

  return (
    <div className="clima-tab pronostico container px-0">
      <Helmet>
        <title>
          {serverData &&
            ssrCityName &&
            serverData.metaTitle.replace("cityName", ssrCityName)}
        </title>
        <meta name="robots" content="index,follow" />
        <link
          rel="canonical"
          href={serverData && serverData.canonical.replace(/\s+/g, "+")}
        />
        {!paramsCity && (
          <link
            rel="alternate"
            hrefLang="es-ar"
            href="https://news.agrofy.com.ar/pronostico-clima-tiempo"
          />
        )}
        {!paramsCity && (
          <link
            rel="alternate"
            hrefLang="pt-br"
            href="https://news.agrofy.com.br/previsao-clima-tempo"
          />
        )}
        <meta
          name="description"
          content={
            serverData &&
            serverData.metaDescription.replace("cityName", ssrCityName)
          }
        />
        <script type="application/ld+json">
          {buildBreadcrumbJson(
            getBreadcrumbData(
              serverData && serverData.breadcrumb,
              false,
              ssrCityName && { replace: "cityName", replacement: ssrCityName }
            )
          )}
        </script>
      </Helmet>
      <AgroPushNotification location="Section" />
      <section className="dfp-banner stripped container top-banner">
        <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
          <AdSlot
            adUnit={`${dfpPrefix}_header`}
            sizes={[
              [970, 90],
              [970, 250],
              [320, 80],
            ]}
          />
        </DFPSlotsProvider>
      </section>
      <section id="main-section" className="d-flex flex-wrap mt-20">
        <div className="fluid-container col-lg-9 px-0">
          <section className="breadcrumb-wrapper p-0">
            <Breadcrumb breadcrumbLinks={breadcrumb} />
          </section>
          {!isBrasil ? (
            <section className="pages-togler">
              <ul>
                <li className="active">
                  <Link to="/pronostico-clima-tiempo">
                    {t("translation:forecast")}
                  </Link>
                </li>
                <li>
                  <Link to="/pronostico-clima-tiempo/lluvias-temperaturas">
                    {t("translation:rains")}
                  </Link>
                </li>
                <li>
                  <Link to="/pronostico-clima-tiempo/humedad">
                    {t("translation:humidity")}
                  </Link>
                </li>
                <li>
                  <Link to="/pronostico-clima-tiempo/imagen-satelital">
                    {t("translation:satelital_image")}
                  </Link>
                </li>
              </ul>
            </section>
          ) : null}
          <section className="container">
            {!pronosticoData ? (
              loader
            ) : (
              <Forecast
                propsCityId={cityID}
                onChangeCity={handleChangeCity}
                propsCityName={cityName}
                propsProvince={provinceName}
                pronosticoData={forecastData}
                initialParams={initalParams}
              />
            )}
            <SectionTemplate
              category="Clima"
              hasVideo={false}
              hasAgrofyTVVideo={false}
              agrofyTVPlaylist={getAgrofyTVPlaylistID()}
              data={templateData || serverData}
              bannersData={templateBanners}
              sectionTitle={t("translation:keep_reading")}
              loading={isLoading}
            />
            <div className="mb-36">
              {currentPage && amount && (
                <PaginationAgrofy
                  parentCallback={setCurrentPage}
                  currentPage={currentPage}
                  amount={amount}
                />
              )}
            </div>
          </section>
        </div>
        <div className="row col-lg-3 sidebar-item px-0 dfp-banner stripped p-0 banner-section d-none d-lg-block">
          <div className="make-me-sticky">
            <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
              <AdSlot adUnit={`${dfpPrefix}_followme`} sizes={[[260, 600]]} />
            </DFPSlotsProvider>
          </div>
        </div>
      </section>
      <AdhesionBanner adUnit={`${dfpPrefix}_adhesion`} sizes={[[320, 80]]} />
      <PopUpBanner
        adUnit={`${dfpPrefix}_fullscreen`}
        sizes={[
          [800, 600],
          [320, 480],
        ]}
      />
    </div>
  );
}

function getcityId(slug) {
  return citiesToFilter.filter((item) =>
    Object.keys(item).some((key) => item[key].toLowerCase().includes(slug))
  );
}
Pronostico.getInitialProps = async ({ match, req, res }) => {
  const urlWeather =
    process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
  const urlNews =
    process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
  const pathName = match && match.url;
  const countryName = process.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";
  const citySlug =
    match.params.city || (isBrasil ? "brasilia+df" : "rosario+santa+fe");

  try {
    const [weather, server] = await Promise.all([
      getData({
        url: `${urlWeather}/api/Weather/GetWeatherBySlug?slug=${citySlug}`,
      }),
      getData({
        url: `${urlNews}/api/News/GetNewsListByCategory?categoryID=2816&page=0&limit=15&canonical=${pathName}`,
      }),
    ]);
    return {
      weatherData: weather.data,
      serverData: server.data,
    };
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(Pronostico);
