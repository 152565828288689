import React, { useState, useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { isAfter } from 'date-fns';
import PropTypes from 'prop-types';
import './Calendar.scss';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('es', es);

function Calendar({ initialDate, maxDate, minDate, parentCallback }) {
  const [date, setDate] = useState(initialDate || new Date());

  const handleChange = (value) => {
    setDate(value);
    if (parentCallback) {
      parentCallback(value);
    }
  };

  useEffect(() => {
    if (isAfter(date, maxDate)) {
      setDate(maxDate);
    }
  }, [maxDate, date]);

  useEffect(() => {
    if (isAfter(minDate, date)) {
      setDate(minDate);
    }
  }, [minDate, date]);

  return (
    <div className="calendar d-flex">
      <DatePicker
        selected={date}
        onChange={handleChange}
        showPopperArrow={false}
        locale="es"
        dateFormat="dd/MM/yyyy"
        maxDate={maxDate || null}
        minDate={minDate || null}
        popperPlacement="bottom-end"
        popperModifiers={{
          offset: {
            enabled: true,
            offset: '5px, 5px',
          },
        }}
      />
      <i className="icon-calendar"></i>
    </div>
  );
}

Calendar.propTypes = {
  initialDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  parentCallback: PropTypes.func,
};

export default Calendar;