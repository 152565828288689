const agrofyTVPlaylists = [
  {
      name: "Actualidad",
      val: "PLQLO7Ebe3R7IYzIDTDKAErJoVLKdCMN4u"
  },
  {
      name: "Clima",
      val: "PLgR_sLej6ylxF5JXrLLB74sghOwgSGyAO"
  },
  {
      name: "Entrevistas",
      val: "PLQLO7Ebe3R7JRy9IdkfoYFw-x0tnu57rT"
  },
  {
      name: "Hacienda",
      val: "PLQLO7Ebe3R7LdsMlZl9TeJzySMUiTKz51"
  },
  {
      name: "Expos",
      val: "PLQLO7Ebe3R7IaGCcrO4PX25yIv9FCBZxO"
  },
  {
      name: "Especiales",
      val: "PLQLO7Ebe3R7JoirNivm2WQuwQAgDNRv-4"
  },
  {
      name: "Streamings",
      val: "1"
  },
  {
      name: "Lo más visto",
      val: "0"
  }
];

export default agrofyTVPlaylists;
