import React from 'react';

function CortevaLogo(props) {
  return (
    <svg x="0px" y="0px" viewBox="0 0 2307 461.4" width='100px' height='auto' {...props}>
    <g>
      <g>
        <path fill='#2B73C2' d="M209.9,3.7c-1.9,0.1-8,0.7-13.5,1.5C131,14.1,73.5,50.3,36.4,106c-9.1,13.6-20,36.1-25.4,52.1
          C4.8,176.7-0.8,205,0.1,213.5c0.3,3,5-1.6,106.8-103.4C207.8,9.2,213.2,3.6,209.9,3.7z"/>
        <path fill='#2B73C2' d="M362.8,228.6h-91.4l-20.7-20.7c-11.5-11.4-20.8-21.1-20.8-21.5c0-0.5,49.5-0.8,109.9-0.8h109.9l-1.5-6.8
          c-2.2-10.1-9.8-32.3-14.2-41.8l-4-8.4l-98-0.2l-98-0.3l20-20.8l20.1-20.7H338c35.1,0,63.9-0.3,63.9-0.8c0-0.4-4.2-5.3-9.4-10.8
          c-30.9-32.9-73.9-57.4-117.6-66.8C272.6,7.7,258.6,21.4,138,142L3.7,276.3l1.2,5.9c0.7,3.2,3.1,11.7,5.5,18.9
          c15.3,46.5,43.2,85,82.5,113.6c32.2,23.5,71.2,38.4,111,42.4l6.5,0.7l-76.8-76.9l-76.7-76.8l16-16l16-16l90.1,90.8
          c59,59.4,90.8,90.7,92.1,90.7c6.5,0,33.4-8.5,49.3-15.6l10.7-4.7L230.2,332.4L129.4,231.6l15.5-15.5c8.5-8.5,15.9-15.5,16.5-15.5
          c0.5,0,47.7,46.7,104.7,103.7l103.6,103.6l7-6.1c8.8-7.8,22.4-22.1,28.1-29.6l4.5-5.7L368.8,326l-40.4-40.4H388h59.6l1.6-7.8
          c2.9-13.6,3.7-20.4,4.4-35l0.6-14.2H362.8z"/>
      </g>
      <g>
        <path d="M730.3,183.9l-5.2,4.8c-2.9,2.6-8.2,6.3-11.9,8.1c-6.5,3.2-7.3,3.3-18.3,3.3c-10.5,0-12-0.2-17.4-2.8
          c-17.2-8-25.6-22.1-25.6-42.8c0-24.4,13.5-41.3,35.9-45c13.4-2.2,29,2.3,38.1,11l4.4,4.2l2.2-2.9c1.2-1.5,11-13.3,21.8-26.2
          c22.3-26.8,21.3-23.5,9-32c-13.8-9.6-31.7-16.4-50.9-19.5c-9.7-1.6-32.2-1.8-41.7-0.4c-21.2,3.1-42.9,12.6-58.3,25.6
          c-43,36.3-51,104.7-17.6,152c17.1,24.2,45.1,40.7,77,45.4c11.7,1.6,38.1,0.6,49.2-1.9c17.7-4.2,36.5-13.5,47.5-23.7l5.4-5
          L752.1,210L730.3,183.9z"/>
        <path d="M897.6,263.8c10.2,2.6,14.1,3.1,29,3.5c13.4,0.4,19.4,0.2,27.5-1.2c51.2-8.5,86.7-43,94.9-92.4c1.7-10,1.4-32.2-0.5-42.2
          c-8.4-44.6-40.1-75.9-87.6-86.4c-6.8-1.5-41.6-3.1-46-2c-0.8,0.2-4.9,0.9-9,1.5c-18.7,3-39.9,12.4-54.3,24
          c-20.4,16.4-34.2,42-37.6,69.5c-3.1,25.1,0.6,47.1,11.4,69C839.5,235.7,864.2,255.1,897.6,263.8z M930.9,107.7
          c0.8,0,4.2,0.4,7.5,0.9c22.6,3.7,37,23.9,35.2,49.5c-1,14.1-5.9,24.8-15.2,33.3c-8,7.3-14.5,9.6-27,9.7c-9.2,0-10.5-0.3-16.7-3.2
          c-8.8-4.1-14.8-9.8-19.1-18.1c-4.4-8.5-6.1-15.7-6.1-25.7C889.5,127.3,905.3,109.6,930.9,107.7z"/>
        <path d="M1273.4,218.8c-14.6-23.3-26.5-42.6-26.5-43c0-0.4,2.8-2.2,6.3-4.1c12.9-7.1,24.3-22.1,28.4-37.2
          c2.3-8.6,2.2-27.1-0.1-35.9c-5.8-22.3-21.1-37.5-45.1-44.9c-14.7-4.6-22.6-5.1-78.7-5.1h-52.8v106.5v106.5h35h35v-38
          c0-29.8,0.3-38,1.3-38c0.6,0.1,10.2,17,21.2,37.7c11,20.6,20.5,37.7,21,37.9c0.6,0.2,19.1,0.3,41.3,0.2l40.3-0.3L1273.4,218.8z
           M1210.7,129.6c-1.3,2-4.4,4.6-7.3,6c-4.2,2.1-6.7,2.5-17.2,2.8l-12.3,0.4v-16.9c0-9.3,0.3-17.3,0.8-17.8
          c1.2-1.3,25.4,0.1,29.2,1.7c6.2,2.7,8.4,6,8.8,13.6C1213,125.1,1212.7,126.6,1210.7,129.6z"/>
        <polygon points="1341.9,48.6 1341.9,78.6 1341.9,108.6 1368.2,108.8 1394.4,109.1 1394.7,185.3 1394.9,261.6 1430.4,261.6 
          1465.9,261.6 1465.9,185.1 1465.9,108.6 1492.4,108.6 1518.9,108.6 1518.9,78.6 1518.9,48.6 1430.4,48.6 		"/>
        <polygon points="1650.9,202.6 1650.9,192.6 1650.9,182.6 1688.2,182.4 1725.4,182.1 1725.7,154.3 1725.9,126.6 1688.4,126.6 
          1650.9,126.6 1650.9,117.1 1650.9,107.6 1690.4,107.6 1729.9,107.6 1729.9,78.1 1729.9,48.6 1656.9,48.6 1583.9,48.6 
          1583.9,155.1 1583.9,261.6 1659.9,261.6 1735.9,261.6 1735.9,232.1 1735.9,202.6 1693.4,202.6 		"/>
        <path d="M1935.1,49.1c-0.3,0.3-8,29.8-17.2,65.6c-9.1,35.7-17,65-17.5,65s-8.8-29.5-18.4-65.5l-17.5-65.6h-40.3
          c-22.2,0-40.3,0.3-40.3,0.7c0,0.5,16.8,48.3,37.4,106.3l37.4,105.5l40,0.3l40,0.2l1.6-4.2c0.9-2.4,18.2-50.2,38.5-106.3l36.8-102
          l-40-0.3C1953.6,48.7,1935.4,48.8,1935.1,49.1z"/>
        <path d="M2179.2,49.1l-38.4-0.3l-38.4-0.2l-41.2,104.7c-22.7,57.7-41.5,105.6-41.9,106.5c-0.6,1.7,1.6,1.8,37.5,1.8
          c22.7,0,38.1-0.4,38.1-0.9s2.3-7.5,5.1-15.5l5.1-14.6l34,0.2l34,0.3l3.9,11c2.2,6,4.6,12.9,5.5,15.2l1.6,4.3h38.9
          c21.4,0,38.9-0.1,38.9-0.3c0-0.1-18.6-47.9-41.4-106.2L2179.2,49.1z M2139.6,175.6h-17.5l8.6-26c4.8-14.3,9-25.6,9.3-25.2
          c0.4,0.4,4.1,11.4,8.3,24.5c4.2,13,7.9,24.4,8.2,25.2C2157,175.4,2154.8,175.6,2139.6,175.6z"/>
        <path d="M2236.9,48.6v3c0,2.9,0.2,3,4.5,3h4.4l0.3,12.7l0.3,12.8l3.8,0.3l3.7,0.3v-13V54.6h4.5c4.3,0,4.5-0.1,4.5-3v-3h-13H2236.9
          z"/>
        <path d="M2301.1,48.6h-5.8l-3.3,10.2c-1.9,5.7-3.6,9.8-3.8,9.3c-0.2-0.6-1.8-5.1-3.6-10l-3.2-9l-5.8-0.3l-5.8-0.3l0.3,15.8
          l0.3,15.8l3.3,0.3l3.2,0.3V69.5c0-6.2,0.2-11,0.5-10.7c0.3,0.3,2.2,5.2,4.3,10.9c3.3,9.3,4,10.4,6.3,10.7c2.5,0.3,2.9-0.3,6.4-10
          c2-5.7,4-10.6,4.4-11c0.3-0.4,0.6,4.1,0.6,10v10.7h3.5h3.5l0.3-15.8l0.3-15.7H2301.1z"/>
        <path d="M855.7,306.7c-3.9-1.4-3.7-1.4-7.8,0.7c-4.3,2.1-6.2,5.6-5.6,10.1c1.8,12.4,19.6,11.1,19.6-1.4
          C861.9,311.5,859.6,308,855.7,306.7z"/>
        <path d="M1048.9,306.3c-0.8,0.3-2.7,1.7-4.2,3.1c-7.1,6.6-0.2,18.8,9.3,16.7c4-0.9,7.9-5.9,7.9-10.1
          C1061.9,310,1054.2,304.2,1048.9,306.3z"/>
        <path d="M645.9,337.6v5.2v5.2l-3.7-3.6c-7.9-7.7-21.4-10.6-32.5-6.9c-11,3.6-17.9,9.8-23,20.6c-5.2,11.2-4.7,24.9,1.2,36.6
          c4.1,8,14.6,16.2,23.4,18.3c5.1,1.2,15.5,0.8,20.6-0.9c2.7-0.9,6.9-3.4,9.4-5.6l4.5-4l0.3,4.8l0.3,4.8l7.8,0.3l7.7,0.3v-37.6
          v-37.5h-8H645.9z M622.5,398.6c-7.7,0-13.1-2.5-17.7-7.9c-12-13.9-4.7-35.6,13.1-39.2c1.9-0.4,3.8-0.7,4.2-0.8c0.4,0,3,0.4,5.7,1
          c10.9,2.3,18.1,11.5,18.1,23.1C645.9,388.9,636.4,398.6,622.5,398.6z"/>
        <path d="M746.9,337.6v5c0,2.7-0.3,5-0.6,5s-2-1.4-3.7-3.2c-7.2-7.1-20.8-10.3-31.5-7.3c-6.9,1.9-10.5,3.9-15.2,8.1
          c-16,14.5-17.1,39.4-2.4,55.3c7.8,8.5,15.1,11.6,26.9,11.6c10.4,0,15.5-1.9,21.9-7.8c2.1-2.1,4.1-3.7,4.4-3.7
          c0.3,0,0.2,3.3-0.1,7.4c-0.7,9.7-3.3,14.7-9.4,18.3c-4.3,2.5-5.8,2.8-13.3,2.8c-9.7-0.1-15-2.2-19.3-7.6l-2.4-3.1l-6.7,3.7
          c-3.6,2-6.6,4-6.6,4.4c0,2.5,8.8,10.4,14.8,13.3c6.5,3.2,7,3.3,19.7,3.3s13.2-0.1,19.7-3.3c8-3.9,13.9-10.2,16.8-17.8
          c1.9-5.2,2-7.7,2-44.9v-39.5h-7.5H746.9z M745.8,379.4c-3.1,14.8-19.3,22.4-33.4,15.8c-9.2-4.2-14.2-13.1-13.2-23.2
          c1.6-15.5,15.8-24.5,31.3-19.9C741.5,355.3,748.4,367.6,745.8,379.4z"/>
        <path d="M916.9,369c-11.5-3.3-16.7-5.9-17.5-8.4c-1.8-5.6,2.8-10,10.4-10c5.3,0,8.7,1.4,11.9,5l2.7,2.9l6.3-3.5
          c3.4-1.9,6.2-3.9,6.2-4.5c0-2.1-7.5-9-12.4-11.4c-6.5-3.2-16.9-4.1-23.9-2.1c-6.7,1.9-12.6,6.3-15.5,11.6c-1.9,3.7-2.3,5.6-2,11.1
          c0.6,11.1,5.7,15.8,23.8,21.3c4.7,1.4,10,3.5,11.7,4.6c5.9,3.6,5.2,9.9-1.2,12.6c-7.4,3-15.7,1.2-21-4.8l-3.8-4.1l-4.3,2.3
          c-2.3,1.3-5.3,3.1-6.5,4.1l-2.3,1.7l2.5,3.6c3.1,4.6,11.1,10.1,16.8,11.6c6.3,1.6,17,1.5,23.3-0.4c13.9-4,20.6-18,14.7-30.4
          C934,376.1,928.1,372.3,916.9,369z"/>
        <path d="M1012.8,387.4l-4.7,4.4c-8.1,7.6-19.2,8.7-28.4,2.9c-12.3-7.8-14.1-25.9-3.5-36.4c9.2-9.2,24.9-8.8,33.3,0.9l3,3.5
          l6.2-3.6c3.4-2.1,6.2-4.4,6.2-5.3c0-3.1-11.3-12.8-17.6-15.1c-6.7-2.6-16.8-3.4-23.4-2.1c-14.8,3.1-26.2,14.6-29.6,29.8
          c-6,27.1,16.2,50.9,44.1,47.1c10.2-1.4,21.4-8.2,25.8-15.7l1.7-3.1l-6.5-3.6L1012.8,387.4z"/>
        <path d="M1157.1,381.6l-0.4-8.8c-0.5-10.5-4.1-19.2-10.7-26c-8.4-8.6-22.8-12.7-35.1-10.2c-11.2,2.4-21.7,10.6-26.8,20.9
          c-2.5,5.1-2.7,6.6-2.7,17.1c0,10.3,0.3,12.1,2.6,17.1c4.8,10.2,13,17.3,23.7,20.5c9.9,3.1,26.2,1.4,34.2-3.4c3-1.9,11-9.3,11-10.3
          c0-0.4-2.7-2.4-6.1-4.4l-6-3.7l-2.7,2.5c-12.2,11.4-35.4,7-39.9-7.6l-1.1-3.7h30H1157.1z M1096.9,368.1c0-1.9,3.5-8.5,5.8-10.9
          c6.4-6.8,18.8-8.7,27.3-4.3c4.6,2.5,9.4,8.3,10.5,12.9l0.6,2.8H1119C1106.8,368.6,1096.9,368.4,1096.9,368.1z"/>
        <path d="M1242.3,351.6c-2.6-5.7-7.7-10.8-13.1-13.2c-5.6-2.5-15.6-3.1-21.5-1.5c-5,1.4-6.3,2.3-11.5,7.1l-3.3,3v-4.7v-4.7h-7.5
          h-7.5v37.5v37.5h7.4h7.4l0.4-23.8c0.4-21.5,0.6-24.1,2.5-27.7c2.5-4.9,4.8-7,9.6-9c7.7-3.2,18-0.5,21.4,5.7
          c1.6,2.9,1.9,6.7,2.3,28.8l0.5,25.5l7.8,0.3l7.8,0.3l-0.3-28.3C1244.4,358.2,1244.2,355.8,1242.3,351.6z"/>
        <path d="M1323.9,387.6c-0.5,0-1.4,0.9-2,2.1c-1.7,3.1-9.7,7.7-14.6,8.5c-6.4,0.9-13.5-1.2-18.4-5.4c-5.7-4.8-8-10-8-18.2
          c0-5.7,0.5-7.3,3.3-12c7.6-12.8,24.9-14.9,35.4-4.4l4.4,4.5l6.2-3.7c3.4-2.1,6.4-3.9,6.6-4c0.7-0.6-5.7-8.5-9.3-11.3
          c-7.6-6.1-22.1-9.3-32.2-7.1c-11.3,2.4-21.5,10.4-26.6,20.8c-3.1,6.3-3.3,7.2-3.3,17.7c0,10.1,0.2,11.5,2.9,17
          c9.6,19.5,33.9,27.5,53.5,17.6c5.2-2.7,15.1-11.9,15.1-14.2C1336.9,394.6,1325.4,387.6,1323.9,387.6z"/>
        <path d="M1403.4,338.7c-6.8-2.6-16.9-3.4-23.5-2.1c-22,4.7-35.1,28.2-28.1,50.6c3.7,12.1,11.7,20.3,23.9,24.5
          c7.1,2.4,20.4,2.7,27.9,0.4c6-1.8,13.6-6.8,16.9-11.1l2.4-3l-6.5-3.8l-6.5-3.8l-4.1,3.5c-11.1,9.6-30.9,6.1-37.5-6.5
          c-3.1-6-4.2-5.8,28-5.8h29.4l0.5-3.3c0.7-5-1.1-15.6-3.8-21.4C1418.9,349.3,1410.7,341.4,1403.4,338.7z M1388.4,368.6
          c-24,0-23.2,0.3-19.5-7c2-4,6.7-8.1,11-9.9c3.8-1.5,13.7-1.3,17.8,0.4c4.7,2,7.9,5,10.3,9.7
          C1411.6,368.9,1412.3,368.6,1388.4,368.6z"/>
        <path d="M814,339.2c-3.2,1.6-6.4,4.3-8.2,6.7l-2.9,4v-6.1v-6.2h-8h-8l0.2,37.2l0.3,37.3l7.8,0.3l7.7,0.3v-20.1
          c0-11.9,0.5-22,1.2-24.7c2-8,10.5-14.3,19.2-14.3h2.6v-8.5v-8.5h-3.3C820.8,336.6,816.9,337.8,814,339.2z"/>
        <polygon points="843.9,337.6 844.1,374.8 844.4,412.1 852.2,412.4 859.9,412.7 859.9,375.1 859.9,337.6 851.9,337.6 		"/>
        <polygon points="1043.9,337.6 1043.9,375.1 1043.9,412.6 1051.9,412.6 1059.9,412.6 1059.9,375.1 1059.9,337.6 1051.9,337.6 		"/>
      </g>
    </g>
    </svg>
  )
}

export default CortevaLogo