/* eslint-disable no-confusing-arrow */
import React from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumb.scss';

function Breadcrumb(props) {
  const data = props && props.breadcrumbLinks;
  const dataBreadcrumb = data && data.filter(breadcrumb => breadcrumb);

  const clickable = (item) => {
    return item.link && item.link !== '' && (item.title !== 'Agricultura' || item.title !== 'Tempo' || item.title !== 'Clima' || item.title !== 'Pecuária');
  };

  return (
    <div className="custom breadcrumb">
      <Link to="/" className="open-sans text4">
        Agrofy News
      </Link>
      {dataBreadcrumb && dataBreadcrumb.map((item, idx) =>
        <Link 
          to={item.link ?? ''}
          key={`${item.title}-${idx}`}
          className={`open-sans text4 ${clickable(item) ? '' : 'link-disabled'}`}>
            {item.title}
        </Link>
      )}
    </div>
  );
}

export default Breadcrumb;
