import React, {useState, useEffect} from 'react';
import {Table} from 'react-bootstrap';
import getData from '../../../services/GetData';
import { useTranslation } from 'react-i18next';
import './LiveStockTable.scss';

function LiveStockTable() {
  const { t } = useTranslation();
  const [prices, setPrices] = useState();

  useEffect(() => {
    const fetchData = () => {
      getData({
        url: `${window.env.RAZZLE_ENDPOINT_COTZ_API}/api/Prices/GetFarmPricesHome`,
      }).then(resp => setPrices(resp.data));
    };
    fetchData();
  }, []);

  const loader = <div className="loader container pr-0"><div className="animated-background" /></div>;

  const setTable = () => {
    const tbodyLiniers = prices[0].Categorias.map((value, idx) =>
      <tr key={`priceLiniers-${idx}`}>
        <td>{value.Nombre}</td>
        <td>
          {
            // eslint-disable-next-line no-nested-ternary
            <div className={`${parseFloat(value.Precios[0].VariacionLiniers) > 0 ? 'up arrow' : parseFloat(value.Precios[0].VariacionLiniers) < 0 ? 'down arrow' : 'equal'}`}>
              <label className="percent pr-2">{value.Precios[0].PrecioLiniers}</label>
              <label className="indicator">
                <label className="icon"></label>
              </label>
            </div>
          }
        </td>
        <td>
          {
            // eslint-disable-next-line no-nested-ternary
            <div className={`${parseFloat(value.Precios[0].VariacionLiniers) > 0 ? 'up arrow' : parseFloat(value.Precios[0].VariacionLiniers) < 0 ? 'down arrow' : 'equal'}`}>
              <label className="percent">{value.Precios[0].VariacionLiniers}</label>
            </div>
          }
        </td>
      </tr>
    );
    const tbodyRosario = prices[0].Categorias.map((value, idx) =>
      <tr key={`priceRosario-${idx}`}>
        <td>
          {
            // eslint-disable-next-line no-nested-ternary
            <div className={`${parseFloat(value.Precios[0].VariacionRosario) > 0 ? 'up arrow' : parseFloat(value.Precios[0].VariacionRosario) < 0 ? 'down arrow' : 'equal'} flex-start`}>
              <label className="percent pr-2">{value.Precios[0].PrecioRosario}</label>
              <label className="indicator">
                <label className="icon"></label>
              </label>
            </div>
          }
        </td>
        <td>
          {
            // eslint-disable-next-line no-nested-ternary
            <div className={`${parseFloat(value.Precios[0].VariacionRosario) > 0 ? 'up arrow' : parseFloat(value.Precios[0].VariacionRosario) < 0 ? 'down arrow' : 'equal'} flex-start`}>
              <label className="percent">{value.Precios[0].VariacionRosario}</label>
            </div>
          }
        </td>
      </tr>
    );
    const tbodyCanuelas = prices[0].Categorias.map((value, idx) =>
      <tr key={`priceLiniers-${idx}`}>
        <td>{value.Nombre}</td>
        <td>
          {
            // eslint-disable-next-line no-nested-ternary
            <div className={`${parseFloat(value.Precios[0].VariacionCañuelas) > 0 ? 'up arrow' : parseFloat(value.Precios[0].VariacionCañuelas) < 0 ? 'down arrow' : 'equal'}`}>
              <label className="percent pr-2">{value.Precios[0].PrecioCañuelas}</label>
              <label className="indicator">
                <label className="icon"></label>
              </label>
            </div>
          }
        </td>
        <td>
          {
            // eslint-disable-next-line no-nested-ternary
            <div className={`${parseFloat(value.Precios[0].VariacionCañuelas) > 0 ? 'up arrow' : parseFloat(value.Precios[0].VariacionCañuelas) < 0 ? 'down arrow' : 'equal'}`}>
              <label className="percent">{value.Precios[0].VariacionCañuelas}</label>
            </div>
          }
        </td>
      </tr>
    );
    return (
      <div>
        <div className="row">
          <div className="col-7 p-0 hacienda-item">
            <label className="hacienda-title m-0">
              <i className="icon-cow"></i>
              {prices.EntradaLiniers > 0 
              ? `Liniers: ${prices[0].EntradaLiniers}`
              : `Cañuelas: ${prices[0].EntradaCañuelas}`}
            </label>
            <Table className="table-agrofy">
              <thead>
                <tr>
                  <th className="text-green">{t('categories')}</th>
                  <th className="align-end text-uppercase">{t('price')} $</th>
                  <th className="align-end text-uppercase">{t('day_var')} $</th>
                </tr>
              </thead>
              <tbody>
                {prices.EntradaLiniers > 0 ? tbodyLiniers : tbodyCanuelas}
              </tbody>
            </Table>
          </div>
          <div className="col-5 p-0 hacienda-item">
            <label className="hacienda-title m-0">
              <i className="icon-cow"></i>
              Rosario: {prices[0].EntradaRosario}
            </label>
            <Table className="table-agrofy fixed-cells">
              <thead>
                <tr>
                  <th className="text-uppercase">{t('price')} $</th>
                  <th className="text-uppercase">{t('day_var')} $</th>
                </tr>
              </thead>
              <tbody>
                {tbodyRosario}
              </tbody>
            </Table>
          </div>
        </div>
        <p className="updated col-12 pl-0">{t('updated')}: {prices[0].FechaActualizacion}</p>
      </div>
    );
  };

  return (
    <div className="hacienda-table-section col-12 col-lg-12 pr-lg-0 mb-36">
      { prices ? setTable() : loader }
    </div>
  );
}

export default LiveStockTable;
