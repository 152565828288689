/* eslint-disable no-confusing-arrow */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DFPSlotsProvider, AdSlot } from 'react-dfp';
import 'intersection-observer'; // optional polyfill
import Observer from '@researchgate/react-intersection-observer';
import { IchatRight, Iclock } from 'agrofy-ui-components';
import BgImageCard from '../../atoms/BgImageCard/BgImageCard';
import LeadNews from '../../atoms/LeadNews/LeadNews';
import MainBreakingNews from '../../atoms/MainBreakingNews/MainBreakingNews';
import StandarCard from '../../components/standarCard/StandarCard';
import DarkModeContext from '../../atoms/DarkModeContext/DarkModeContext';

const HomeTemplate = ({ leadNews, breakingNews, opinionData }) => {
  const { t } = useTranslation();
  const mainNews = leadNews && leadNews.slice(0, 3);
  const news = leadNews && leadNews.slice(3);
  const loader = (
    <div className="loader container pr-0">
      <div className="animated-background" />
    </div>
  );
  const [middle1Loaded, setMiddle1Loaded] = useState('no-loaded-banner-mobile');
  const [middle2Loaded, setMiddle2Loaded] = useState('no-loaded-banner');
  const [middle3Loaded, setMiddle3Loaded] = useState('no-loaded-banner-mobile');
  const [middle4Loaded, setMiddle4Loaded] = useState('no-loaded-banner-mobile');
  const [loadedComponents, setLoadedComponents] = useState({});
  const { isDarkMode, toggleMode } = useContext(DarkModeContext);

  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === 'Brasil';

  const dfpNetworkID = process.env.DFP_NETWORK_ID || window.env.DFP_NETWORK_ID;

  const addView = async (componentName) => {
    let loadedComponent = {};
    import(`../../atoms/${componentName}/${componentName}`).then(
      (Component) => {
        loadedComponent[componentName] = (
          <Component.default isColumn showTitle opinionData={opinionData} />
        );
        setLoadedComponents({ ...loadedComponents, ...loadedComponent });
      }
    );
  };
  const handleChange = async (event, unobserve) => {
    const componentName = event.target.dataset.name;

    if (event.isIntersecting) {
      await addView(componentName);
      unobserve();
    }
  };

  return (
    <React.Fragment>
      <div className="row mb-20 column-mobile">
        {breakingNews ? <MainBreakingNews data={breakingNews} /> : null}
        {mainNews && mainNews.length ? (
          <LeadNews
            data={mainNews[0]}
            clampText={6}
            hasTracking
            isDarkMode={isDarkMode}
            toggleMode={toggleMode}
          />
        ) : (
          loader
        )}
      </div>
      {!(mainNews && mainNews.length) ? (
        loader
      ) : (
        <div className="row mt-20">
          <div className="col-lg-4">
            {mainNews[1].video !== null ? (
              <StandarCard
                miniStandarCard
                littleText
                link={mainNews[1].url}
                image={mainNews[1].image}
                category={mainNews[1].category}
                imageTitle={mainNews[1].title}
                body={mainNews[1].title}
                data={mainNews[1]}
                position={2}
                hasTracking
                isDarkMode={isDarkMode}
                toggleMode={toggleMode}
                exclusive={mainNews[1].exclusive}
              />
            ) : (
              <BgImageCard
                clampText={4}
                image={mainNews[1].image}
                link={mainNews[1].url}
                category={mainNews[1].category}
                imageTitle={mainNews[1].title}
                body={mainNews[1].title}
                position={2}
                hasTracking
                isDarkMode={isDarkMode}
                toggleMode={toggleMode}
                exclusive={mainNews[1].exclusive}
              />
            )}
          </div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-8 py-0 px-m-0">
                <div className={`mt-m-36 ${middle1Loaded}`}>
                  <div className="dfp-banner stripped px-m-0">
                    <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
                      <AdSlot
                        adUnit={`home_middle_1`}
                        sizes={[
                          [300, 250],
                          [300, 250],
                          [320, 50],
                        ]}
                        onSlotRender={(e) =>
                          e.event.isEmpty
                            ? ''
                            : setMiddle1Loaded('loaded-banner-mobile')
                        }
                      />
                    </DFPSlotsProvider>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-m-36">
                <StandarCard
                  miniStandarCard
                  littleText
                  clampText={5}
                  link={mainNews[2].url}
                  image={mainNews[2].image}
                  category={mainNews[2].category}
                  imageTitle={mainNews[2].title}
                  body={mainNews[2].title}
                  data={mainNews[2]}
                  position={3}
                  hasTracking
                  isDarkMode={isDarkMode}
                  toggleMode={toggleMode}
                  exclusive={mainNews[2].exclusive}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="section-title mt-36 mb-20">
        <Iclock
          className="h-title mr-10"
          height="20px"
          width="20px"
          fill="primary"
        />
        <label className="h-title">{t('last_news')}</label>
      </div>
      <div className="row">
        <div className="col-md-8 mb-36 mb-m-20">
          {!(news && news.length) ? (
            loader
          ) : (
            <BgImageCard
              clampText={2}
              bigBackground="big-background"
              data={news[0]}
              image={news[0].image}
              link={news[0].url}
              category={news[0].category}
              imageTitle={news[0].title}
              body={news[0].title}
              position={4}
              hasTracking
              isDarkMode={isDarkMode}
              toggleMode={toggleMode}
              exclusive={news[0].exclusive}
            />
          )}
        </div>
        <div className="col-md-4 mb-36">
          {!(news && news.length) ? (
            loader
          ) : (
            <StandarCard
              clampText={4}
              link={news[1].url}
              image={news[1].image}
              category={news[1].category}
              title={news[1].title}
              body={news[1].title}
              position={5}
              hasTracking
              isDarkMode={isDarkMode}
              toggleMode={toggleMode}
              exclusive={news[1].exclusive}
            />
          )}
        </div>
        <div className={`col-12 mb-35 ${middle2Loaded}`}>
          <div className="dfp-banner stripped bm-negative-ml-15">
            <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
              <AdSlot
                adUnit={`home_middle_2`}
                sizes={[
                  [728, 90],
                  [300, 250],
                  [320, 50],
                ]}
                onSlotRender={(e) =>
                  e.event.isEmpty ? '' : setMiddle2Loaded('loaded-banner')
                }
              />
            </DFPSlotsProvider>
          </div>
        </div>
        {opinionData && opinionData.length >= 3 ? (
          <section className="col-12 opinion-section">
            <div className="section-title">
              <IchatRight
                className="mr-10"
                height="24px"
                width="24px"
                fill="primary"
              />{' '}
              <label className="opinion-title h-title">{t('opinion')}</label>
            </div>
            <Observer onChange={handleChange}>
              <div data-name="SliderCard" className="observer-slidercard">
                {loadedComponents['SliderCard']}
              </div>
            </Observer>
          </section>
        ) : null}
        <div className="col d-flex flex-wrap mb-36">
          <div className="col-5 d-none d-lg-block px-0">
            <div className="dfp-banner stripped">
              <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
                <AdSlot adUnit={`home_filmstrip`} sizes={[[300, 600]]} />
              </DFPSlotsProvider>
            </div>
          </div>
          <div className="col-lg-7 d-flex flex-wrap px-m-0 pr-lg-0">
            <div className="col-lg-6 mb-36 px-m-0 pr-lg-0 mb-m-20">
              {!(news && news.length) ? (
                loader
              ) : (
                <StandarCard
                  miniStandarCard
                  clampText={4}
                  link={news[2].url}
                  image={news[2].image}
                  category={news[2].category}
                  imageTitle={news[2].title}
                  body={news[2].title}
                  position={6}
                  hasTracking
                  isDarkMode={isDarkMode}
                  toggleMode={toggleMode}
                  exclusive={news[2].exclusive}
                ></StandarCard>
              )}
            </div>
            <div className="col-lg-6 pr-lg-0 mb-36 mb-m-20 p-0 pl-lg-15">
              {!(news && news.length) ? (
                loader
              ) : (
                <StandarCard
                  miniStandarCard
                  clampText={4}
                  link={news[3].url}
                  image={news[3].image}
                  category={news[3].category}
                  imageTitle={news[3].title}
                  body={news[3].title}
                  position={7}
                  hasTracking
                  isDarkMode={isDarkMode}
                  toggleMode={toggleMode}
                  exclusive={news[3].exclusive}
                ></StandarCard>
              )}
            </div>
            <div className="col-lg-6 mb-36 px-m-0 pr-lg-0 mb-m-20">
              {!(news && news.length) ? (
                loader
              ) : (
                <StandarCard
                  miniStandarCard
                  clampText={4}
                  link={news[4].url}
                  image={news[4].image}
                  category={news[4].category}
                  imageTitle={news[4].title}
                  body={news[4].title}
                  position={8}
                  hasTracking
                  isDarkMode={isDarkMode}
                  toggleMode={toggleMode}
                  exclusive={news[4].exclusive}
                ></StandarCard>
              )}
            </div>
            <div className="col-lg-6 pr-lg-0 mb-20 mb-m-0 p-0 pl-lg-15">
              {!(news && news.length) ? (
                loader
              ) : (
                <StandarCard
                  miniStandarCard
                  clampText={4}
                  link={news[5].url}
                  image={news[5].image}
                  category={news[5].category}
                  imageTitle={news[5].title}
                  body={news[5].title}
                  position={9}
                  hasTracking
                  isDarkMode={isDarkMode}
                  toggleMode={toggleMode}
                  exclusive={news[5].exclusive}
                ></StandarCard>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 d-flex flex-wrap">
          <div className="col-lg-4 pl-lg-0 mb-36">
            <Observer onChange={handleChange}>
              <div data-name="MostReadedNew" className="observer-mostread">
                {loadedComponents['MostReadedNew']}
              </div>
            </Observer>
          </div>
          <div className="col-lg-8 d-flex flex-wrap px-0">
            <div className="col-lg-4 mb-36 px-m-0">
              {!(news && news.length) ? (
                loader
              ) : (
                <StandarCard
                  miniStandarCard
                  littleText
                  clampText={5}
                  link={news[6].url}
                  image={news[6].image}
                  category={news[6].category}
                  imageTitle={news[6].title}
                  body={news[6].title}
                  position={10}
                  hasTracking
                  isDarkMode={isDarkMode}
                  toggleMode={toggleMode}
                  exclusive={news[6].exclusive}
                ></StandarCard>
              )}
            </div>
            <div className="col-lg-8 pr-lg-0 mb-25 mb-m-36 px-m-0">
              <div className={`m-full-width ${middle3Loaded}`}>
                <div className="dfp-banner stripped">
                  <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
                    <AdSlot
                      adUnit={`home_middle_3`}
                      sizes={[
                        [300, 250],
                        [320, 50],
                      ]}
                      onSlotRender={(e) =>
                        e.event.isEmpty
                          ? ''
                          : setMiddle3Loaded('loaded-banner-mobile')
                      }
                    />
                  </DFPSlotsProvider>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-36 mb-m-20 px-m-0">
              {!(news && news.length) ? (
                loader
              ) : (
                <StandarCard
                  miniStandarCard
                  clampText={3}
                  link={news[7].url}
                  image={news[7].image}
                  category={news[7].category}
                  imageTitle={news[7].title}
                  body={news[7].title}
                  position={11}
                  hasTracking
                  isDarkMode={isDarkMode}
                  toggleMode={toggleMode}
                  exclusive={news[7].exclusive}
                ></StandarCard>
              )}
            </div>
            <div className="col-lg-6 pr-lg-0 mb-m-20 px-m-0">
              {!(news && news.length) ? (
                loader
              ) : (
                <StandarCard
                  miniStandarCard
                  clampText={3}
                  link={news[8].url}
                  image={news[8].image}
                  category={news[8].category}
                  imageTitle={news[8].title}
                  body={news[8].title}
                  position={12}
                  hasTracking
                  isDarkMode={isDarkMode}
                  toggleMode={toggleMode}
                  exclusive={news[8].exclusive}
                ></StandarCard>
              )}
            </div>
          </div>
          <div className="col-lg-4 p-lg-0 mb-m-20 px-m-0">
            {!(news && news.length) ? (
              loader
            ) : (
              <StandarCard
                miniStandarCard
                clampText={3}
                link={news[9].url}
                image={news[9].image}
                category={news[9].category}
                imageTitle={news[9].title}
                body={news[9].title}
                position={13}
                hasTracking
                isDarkMode={isDarkMode}
                toggleMode={toggleMode}
                exclusive={news[9].exclusive}
              ></StandarCard>
            )}
          </div>
          <div className="col-lg-8 d-flex pr-lg-0 flex-wrap px-m-0">
            <div className="col-lg-4 pl-lg-0 px-0 mb-m-0">
              {!(news && news.length) ? (
                loader
              ) : (
                <StandarCard
                  miniStandarCard
                  littleText
                  clampText={5}
                  link={news[10].url}
                  image={news[10].image}
                  category={news[10].category}
                  imageTitle={news[10].title}
                  body={news[10].title}
                  position={14}
                  hasTracking
                  isDarkMode={isDarkMode}
                  toggleMode={toggleMode}
                  exclusive={news[10].exclusive}
                ></StandarCard>
              )}
            </div>
            <div className="col-lg-8 pr-lg-0 mt-m-36 p-m-0">
              <div className={`m-full-width ${middle4Loaded}`}>
                <div className="dfp-banner stripped">
                  <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
                    <AdSlot
                      adUnit={`home_middle_4`}
                      sizes={[
                        [300, 250],
                        [320, 50],
                      ]}
                      onSlotRender={(e) =>
                        e.event.isEmpty
                          ? ''
                          : setMiddle4Loaded('loaded-banner-mobile')
                      }
                    />
                  </DFPSlotsProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

HomeTemplate.propTypes = {
  leadNews: PropTypes.array,
  breakingNews: PropTypes.array,
  opinionData: PropTypes.array,
};

export default HomeTemplate;
