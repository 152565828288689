import React from 'react';
import styled, { css } from 'styled-components';
import { Column, AgroSpinner } from 'agrofy-ui-components';
import NewsPlayerList from '../../atoms/NewsPlayerList/NewsPlayerList';

export default function MainVideo(props) {
    const { data, isModal, videoToPlay, isCategoryPage, playList, onChildPlayerClick, scrolled, onEndedVideo, isMainCard, isStreaming } = props;
    return (
        <VideoColumn lg={12} md={12} sm={12}>
            {data ?
                <Container isModal={isModal} scrolled={scrolled} isCategoryPage={isCategoryPage} className="player-container-modal">
                    <NewsPlayerList playlist={playList} videoToPlay={isStreaming ? videoToPlay.id : videoToPlay.url} onEndedVideo={onEndedVideo} onChildPlayerClick={onChildPlayerClick} defaultVideo={data} />
                </Container> :
                <Loader><AgroSpinner width={'50px'} height={'50px'} variant='primary' /></Loader>
            }
        </VideoColumn>
    )
}

const VideoColumn = styled(Column)`
    padding: 0;
    margin-bottom: ${p => !(p.isModal) && "16px"};
`;
const sharedGrayColor = css`
  color: ${props => props.theme.colors.gray6};
`;
const sharedWhiteColor = css`
  color: ${props => props.theme.colors.white};
`;
const sharedFont = css`
    font-family: 'Open Sans';
`;
const sharedStyled = css`
    ${sharedWhiteColor};
    margin-bottom: 16px;
`;
export const sharedStylesContainer = css`
    display: flex;
    flex-direction: ${p => p.isModal ? "row" : "column"};
    justify-content: ${p => p.isModal && "space-between"};
    align-items: ${p => p.isModal ? "center" : "stretch"};
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: #212529;
    margin-top: ${p => !p.isCategoryPage || !p.isModal && "16px"};
    padding-bottom: ${p => p.isModal ? p.isCategoryPage ? "0" : "24px" : "40px"};
    position: ${p => p.isModal && "sticky"};
    top: ${p => p.isModal && p.isCategoryPage ? p.scrolled ? "65px" : "155px" : "26px"};
    width: ${p => p.isModal && "100%"};
    z-index: ${p => p.isCategoryPage || p.isModal && 1};
    /* width: 100vw; */
    max-height:  ${p => p.isModal && "208px"};
`;
const VideoContainer = styled.div`
    float: none;
    clear: both;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;
export const StreamingTitle = styled.div`
    display: flex;
    ${sharedGrayColor};
    padding: 10px 0;
    > span {
        ${sharedFont};
    }
`;

export const Circle = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${props => props.theme.colors.red5};
    align-self: center;
    margin-right: ${p => p.isCarousel ? "4px" : "8px"};
`;
export const Description = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${p => p.isModal ? "0 16px" : "0"};
    margin-top: ${p => p.isCategoryPage ? "0" : p.isModal ? "0" : "16px"};
    background: ${p => p.isCategoryPage ? "#212529" : "inherit"};
`;
export const TitleMainVideo = styled.h3`
    ${sharedStyled};
`;
export const Title = styled.h6`
    ${sharedStyled};
`;
export const sharedLineStyles = css`
    border-bottom: ${p => p.isModal ? "0.5px solid #495057" : "none"};
    padding-bottom: ${p => p.isModal ? "8px" : "0"};
`;
export const Content = styled.div`
    width: 100%;
    ${sharedStylesContainer};
`;
export const ContentShareFirst = styled.div`
    ${({ theme }) => css`
        @media only screen and (max-width: ${theme.breakpoint.md}) {   
            display: none;
        }
    `}
`;
export const ContentShareSecond = styled.div`
    width: 100%;
    ${({ theme }) => css`
        @media only screen and (min-width: ${theme.breakpoint.md}) {   
            display: none;
        }
    `}
`;
export const Date = styled.div`
    padding-top: ${p => p.isModal ? "0 !important" : "20px"};
    text-transform: ${p => p.isLiveNews ? 'uppercase' : 'capitalize'};
    ${sharedGrayColor};
    ${sharedFont};
    font-size: ${p => p.isMainCard ? "16px" : "12px"};
    line-height: 21px;
`
export const Text = styled.p`
    ${sharedWhiteColor};
`;
export const IconsWrapper = styled.div`
    display: flex;
    margin-top: 16px;
    margin-bottom: ${p => p.isCategoryPage && "40px"};
    button {
        margin-right: 16px;
    }
`;
export const ShareButton = styled.button`
    display: flex;
    align-items: center;
    border: none;
    background: inherit;
    color: ${props => props.theme.colors.green5};
    svg {
        margin-top: 2px;
        margin-left: 4px;
    }
    &:active, &:focus {
        outline: none;
    }
`;
export const HiddenButton = styled.button`
    position: absolute;
    width: 100%;
    z-index: 999;
    height: 100%;
    background: transparent;
    outline: none;
    border: none;
`;

const Loader = styled.div`
    display: flex;
    justify-content:center;
    align-items: center;
    min-height: 400px;
`