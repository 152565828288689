import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import MultiSelectAgrofy from '../../atoms/MultiSelectAgrofy/MultiSelectAgrofy';
import CustomSelectAgrofy from '../../atoms/CustomSelectAgrofy/CustomSelectAgrofy';
import Calendar from '../../atoms/Calendar/Calendar';
import ButtonAgrofy from '../../atoms/ButtonAgrofy/ButtonAgrofy';
import PropTypes from 'prop-types';
import getData from '../../services/GetData';
import CurrenciesTablePagination from '../../organisms/CurrenciesTablePagination/CurrenciesTablePagination';
import Graphic from '../../atoms/Graphic/Graphic';
import NoFilterData from '../../atoms/NoFilterData/NoFilterData';
import {format} from 'date-fns';

const indicators = [
  {value: 'Monedas', label: 'Monedas'},
  {value: 'Indicadores Bursátil', label: 'Índices Bursátiles'},
  {value: 'Tasas', label: 'Tasas'},
  {value: 'Commodities', label: 'Commodities'}
];
const currencies = [
  {value: '2', label: 'Dólar Nación'},
  {value: '7', label: 'Dólar Itaú'},
  {value: '8', label: 'Dólar HSBC'},
  {value: '1', label: 'Dólar Minorista'},
  {value: '5', label: 'Euro/Peso'},
  {value: '10', label: 'Euro/Dolar'},
  {value: '6', label: 'Real/Peso'},
  {value: '11', label: 'Real/Dolar'},
  {value: '13', label: 'Yen/Dolar'},
  {value: '12', label: 'Libra/Dolar'}
];
const loader = <div className="loader container p-0"><div className="animated-background" style={{height: '300px'}}/></div>;

const CurrenciesHistoricalDataContent = ({parentCallback}) => {
  const {t} = useTranslation();
  const [selected, setSelected] = useState({
    indicator: '',
    currencies: [],
    fromDate: new Date(),
    toDate: new Date()
  });
  const [hasData, setHasData] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [data, setData] = useState([]);
  const [graphData, setGraphData] = useState(null);

  const formatData = oldData => {
    const newData = [];
    oldData.Productos.map(product => {
      const label = product.Nombre;
      const graphicData = [];

      product.Cotizaciones.map(item => {
        return graphicData.push({t: item.Fecha, y: item.Compra});
      });
      return newData.push({
        label: label,
        graphicData: graphicData
      });
    });
    return newData;
  };

  const fetchData = (codCurrencies, codIndicator, fromDate, toDate) => {
    const url =
    `/api/EconomicIndic/GetHistoricalEconomicIndicators?dateFrom=${fromDate}&dateTo=${toDate}&indicatorName=${codIndicator}&quoteKinds=${codCurrencies}`;
    return getData({
      url: `${window.env.RAZZLE_ENDPOINT_COTZ_API}${url}`
    });
  };

  const getFilteredData = () => {
    setHasData(false);
    setData(false);
    setHasFilters(true);
    const codCurrencies = selected.currencies.map(item => item).join(',');
    const fromDate = format(selected.fromDate, 'yyyyMMdd');
    const toDate = format(selected.toDate, 'yyyyMMdd');
    fetchData(codCurrencies, selected.indicator, fromDate, toDate).then(resp => {
      setGraphData(resp.data && resp.data.length ? formatData(resp.data[0]) : []);
      setData(resp.data && resp.data.length ? resp.data[0] : []);
      setHasData(true);
    });
  };

  const validFilters = () => {
    return selected.indicator && selected.currencies.length;
  };

  return (
    <div className="currencies-historical-data__content">
      <div className="currencies-historical-data__filters">
        <div className="currencies-historical-data__filters_dates d-md-flex flex-wrap justify-content-between mb-20">
          <h1 className="title4 pb-10 pb-md-0">{t('history_of_the_buying_dollar')}</h1>
          <div className="currencies-historical-data__filters_dates__calendar d-flex align-items-end">
            <div className="currencies-historical-data__filters_dates__calendar--left d-md-flex align-items-center pr-0 pr-md-15">
              <label className="pr-10">{t('choose_date_from')}:</label>
              <Calendar
                initialDate={selected.fromDate}
                maxDate={selected.toDate}
                parentCallback={date => setSelected({...selected, fromDate: date})}/>
            </div>
            <div className="currencies-historical-data__filters_dates__calendar--right d-md-flex align-items-center">
              <label className="pr-10">{t('choose_date_to')}:</label>
              <Calendar
                initialDate={selected.toDate}
                minDate={selected.fromDate}
                parentCallback={date => setSelected({...selected, toDate: date})}/>
            </div>
          </div>
        </div>
        <div className="currencies-historical-data__filters__selects d-md-flex mb-20 justify-content-between">
          <div className="currencies-historical-data__filters__selects__inputs d-md-flex">
            <CustomSelectAgrofy
              nameSelectId="indicators"
              placeholder={t('indicators')}
              parentCallback={item => setSelected({...selected, indicator: item})}
              options={indicators}
              value="value"
              label="label" />
            <MultiSelectAgrofy
              placeholder={t('product')}
              options={currencies}
              parentCallback={items => setSelected({...selected, currencies: items})}
            />
          </div>
          <ButtonAgrofy title="search" parentCallback={getFilteredData} disabled={!validFilters()}/>
        </div>
      </div>
      {
      // eslint-disable-next-line no-nested-ternary
        !hasFilters ?
          <div className="currencies-historical-data__generate-graphic text-center">
            <div className="i-container mx-auto mb-10">
              <i className="icon-graphic" />
            </div>
            <label className="title6">{t('complete_filters')}</label>
            {/* eslint-disable-next-line no-nested-ternary */}
          </div> : !hasData ? loader : graphData.length ?
            <div className="currencies-historical-data__graph mb-20">
              <Graphic data={graphData}/>
            </div> : <NoFilterData/>
      }
      <CurrenciesTablePagination data={hasData && data}/>
    </div>
  );
};

CurrenciesHistoricalDataContent.propTypes = {
  parentCallback: PropTypes.instanceOf(Function)
};

export default CurrenciesHistoricalDataContent;
