import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import './NewsletterCard.scss';

const NewsletterCard = ({item, parentCallback}) => {
  const [state, setState] = useState(true);

  const update = () => {
    const st = state;
    setState(!st);
    if (parentCallback) {
      parentCallback(item);
    }
    return !st;
  };

  useEffect(() => {
    setState(item.checked);
  }, [item]);

  return (
    <div onClick={() => update()} className={state ? 'checked newsletter-card' : 'newsletter-card'}>
      <Card className="newsletter-card__container">
        <Card.Body>
          <div className="newsletter-card__container__body d-flex">
            <input id="check1" name="check1" type="checkbox" checked={state} readOnly/>
            <div className="newsletter-card__container__body__text ml-10">
              <h6>{item && item.title}</h6>
              <p>{item && item.body}</p>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default NewsletterCard;
