import React, {useState, useEffect} from 'react';
import Calendar from '../../../atoms/Calendar/Calendar';
import {subDays, subMonths, subYears} from 'date-fns';
import {useTranslation} from 'react-i18next';

const RadioFilters = ({propRadioChecked, parentCallback}) => {
  const {t} = useTranslation();
  const [radioChecked, setRadioChecked] = useState(propRadioChecked);
  const [minDate, setMinDate] = useState(new Date());

  const handleChangeRadio = (radio, date) => {
    setRadioChecked(radio);
    if (parentCallback) {
      parentCallback(date);
      setMinDate(date);
    }
  };

  useEffect(() => {
    setRadioChecked(propRadioChecked);
  }, [propRadioChecked]);

  return (
    <React.Fragment>
      <div>
        <label className="radio text">
          <input id="r1" type="radio" name="date_range" className="hidden" checked={radioChecked === 'r1'}
            onChange={() => handleChangeRadio('r1', {from: new Date(), to: new Date()})}
          />
          <span className="label" />{t('today')}
        </label>
        <label className="radio text">
          <input id="r2" type="radio" name="date_range" className="hidden" checked={radioChecked === 'r2'}
            onChange={() => handleChangeRadio('r2', {from: subDays(new Date(), 7), to: new Date()})}
          />
          <span className="label" />{t('last_week')}
        </label>
        <label className="radio text">
          <input id="r3" type="radio" name="date_range" className="hidden" checked={radioChecked === 'r3'}
            onChange={() => handleChangeRadio('r3', {from: subDays(new Date(), 30), to: new Date()})}
          />
          <span className="label" />{t('last_month')}
        </label>
        <label className="radio text">
          <input id="r4" type="radio" name="date_range" className="hidden" checked={radioChecked === 'r4'}
            onChange={() => handleChangeRadio('r4', {from: subMonths(new Date(), 6), to: new Date()})}
          />
          <span className="label" />{t('last_semester')}
        </label>
        <label className="radio text">
          <input id="r5" type="radio" name="date_range" className="hidden" checked={radioChecked === 'r5'}
            onChange={() => handleChangeRadio('r5', {from: subMonths(new Date(), 12), to: new Date()})}
          />
          <span className="label" />{t('last_year')}
        </label>
        <label className="radio text">
          <input id="r0" type="radio" name="date_range" className="hidden" checked={radioChecked === 'r0'}
            onChange={() => handleChangeRadio('r0', {from: subYears(new Date(), 25), to: new Date()})}
          />
          <span className="label" />{t('all_news')}
        </label>
        <label className="radio text">
          <input id="r6" type="radio" name="date_range" className="hidden" checked={radioChecked === 'r6'}
            onChange={() => handleChangeRadio('r6', new Date())}
          />
          <span className="label" />{t('choose_date')}
        </label>
        <div className={`search-result-page__results-wrapper__filters__date ${radioChecked === 'r6' ? 'enabled' : ''}`}>
          <span className="open-sans my-1 d-flex">{t('From')}:</span>
          <Calendar
            initialDate={new Date()}
            maxDate={minDate.to || new Date()}
            parentCallback={date => handleChangeRadio('r6', {from: date})} />
          <span className="open-sans my-1 d-flex">{t('to')}:</span>
          <Calendar
            initialDate={new Date()}
            maxDate={new Date()}
            minDate={minDate.from}
            parentCallback={date => handleChangeRadio('r6', {to: date})} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default RadioFilters;
