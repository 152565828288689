import React from 'react'
import imgLight from '../../images/icons/IOL-blue.svg';
import imgDark from '../../images/icons/IOL-white.svg';
import './IolLogo.scss';

const IolLogo = () => {
    return (
        <>
            <div className='logo-iol logo-dark'>
                Información provista por <a href='https://www.invertironline.com' target='blank'><img src={imgDark} /></a>
            </div>
            <div className='logo-iol logo-white'>
                Información provista por <a href='https://www.invertironline.com' target='blank'><img src={imgLight} /></a>
            </div>
        </>
    )
}

export default IolLogo