import React, {useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import './HeaderSearchBar.scss';

const HeaderSearchBar = () => {
  const {t} = useTranslation();
  const [query, setQuery] = useState();
  const history = useHistory();
  const marketUrl = process.env.RAZZLE_ENDPOINT_MARKET || window.env.RAZZLE_ENDPOINT_MARKET;

  const newsQuery = query && query
    .trim()
    .toLowerCase()
    .replace(/\s\s+/g, ' ')
    .replace(/ /g, '%20');

  const marketQuery = query && query
    .trim()
    .toLowerCase()
    .replace(/[ ] +/g, ' ')
    .replace(/[/ ]/g, '-')
    .replace(/[#]/g, '');

  /*const handleKeyDown = (event) => {
    var value = event.target.value;

    if (value && value.length > 2 && event.key === 'Enter') {
      event.preventDefault();
      history.push(`/search/${newsQuery}`);
    }
  };*/

  const manageGTM = categorySearch => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'searchAgrofyNews',
        category: categorySearch,
        term: `${query}`
      }
    };

    TagManager.dataLayer(tagManagerArgs);
  };

  return (
    <div className="header-search-bar">
      <div className="header-search-bar__left-wrapper">
        <input
          className="form-control border-0 pr-0 open-sans"
          type="text"
          name="search-input"
          onChange={e => setQuery(e.target.value)}
          onKeyPress={e => handleKeyDown(e)}
          placeholder = {t('search_all_about_agro')}
          autoComplete="off" />
        <span className="search-icon"><i className="magnifierIcon"></i></span>
      </div>
      <div className="header-search-bar__right-wrapper">
        <a
          href={`/search/${newsQuery}`}
          className={`${!query || (query && query.length < 3) ? 'disabled' : ''} header-search-bar__right-wrapper__btn`}
          onClick={() => manageGTM('Agrofy News')}
        >
          {t('search_news')}
        </a>
        <a
          href={`${marketUrl}/${marketQuery}`}
          className={`${!query || (query && query.length < 3) ? 'disabled' : ''} header-search-bar__right-wrapper__btn`}
          target="_blank"
          onClick={() => manageGTM('Marketplace-Products List')}
        >
          {t('search_products')}
        </a>
      </div>
    </div>
  );
};

export default HeaderSearchBar;
