import React, { useState, useEffect } from 'react';
import { AgroSpinner, Column } from 'agrofy-ui-components';
import styled from 'styled-components';
import { useStore } from '../../hooks/store'
import getData from '../../services/GetData';
import VideoCardImg from '../../atoms/VideoCardImg/VideoCardImg';
import { SpinnerContainer } from '../../templates/CategoryVideosTemplate/CategoryVideosTemplate';
import TagManager from 'react-gtm-module';

export const DynamicSection = ({ urlParam, category, onHandleClick, defaultVideoPlaying }) => {
    const isStreamingCat = category === "streamings";
    const [videosData, setVideosData] = useState([]);
    const [moreContent, setMoreContent] = useState([]);
    const [pageToken, setPageToken] = useState(isStreamingCat ? "1" : "");
    const [loading, setLoading] = useState(false);
    const stateFromStore = useStore()[0];
    const [scrollPosition, setSrollPosition] = useState(0);

    const manageGTM = label => {
        const tagManagerArgs = {
            dataLayer: {
                event: 'trackEvent',
                eventCategory: 'Videos',
                eventAction: 'See more',
                eventLabel: `${label}`
            }
        };
        TagManager.dataLayer(tagManagerArgs);
    };

    const fetchData = () => {
        const url = process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
        return getData({ url: `${url}/api/AgrofyTV/GetVideosListPaginated?url=${urlParam}&size=6&pageToken=${pageToken}` })
            .then(result => {
                if (pageToken !== "") {
                    setMoreContent([...moreContent, ...result.data.videos])
                    setPageToken(result.data.nextPageToken)
                } else {
                    setVideosData(result.data)
                    setPageToken(result.data.nextPageToken)
                }
                setLoading(false)
            })
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setSrollPosition(position);
    };

    const loadMore = () => {
        manageGTM(category)
        setLoading(!loading)
        fetchData();
        window.scrollTo(0, scrollPosition - 150)
    }
    const handleChildClick = data => onHandleClick(data)
    const hasPageToken = typeof pageToken === 'string';
    const isPlayingVideo = item => item.url === (stateFromStore && stateFromStore.videoData && stateFromStore.videoData.url || defaultVideoPlaying.url);

    return (
        videosData ?
            <Wrapper>
                <div className="videos-container">
                    {videosData && videosData.videos && videosData.videos.map((item, index) => {
                        return (
                            <VideoCardImg key={index} data={item} onChildClick={handleChildClick} isModal={false} isPlaying={isPlayingVideo(item)} isCategoryPage />
                        );
                    })}
                </div>
                <div className="videos-container second-content">{moreContent.length > 0 && moreContent.map((item, index) => {
                    return (
                        <VideoCardImg key={index} data={item} onChildClick={handleChildClick} isModal={false} isPlaying={isPlayingVideo(item)} isCategoryPage/>
                    );
                })}
                </div>
                {hasPageToken &&
                    <ButtonCont><LoadMoreButton onClick={() => loadMore(pageToken)} type="button" className="load-more">{"Ver más >"}</LoadMoreButton></ButtonCont>
                }
                {loading && <SpinnerContainer><AgroSpinner width='50px' height='50px' variant='primary' /> </SpinnerContainer>}
            </Wrapper> : <SpinnerContainer><AgroSpinner width='50px' height='50px' variant='primary' /> </SpinnerContainer>
    );
}

const LoadMoreButton = styled.button`
    border: none;
    background: none;
    color: ${props => props.theme.colors.green5};
    font-size: 20px;
    padding: 0 6px;
    &:active, &:focus {
        outline: none;
    }
    &:hover {
        color: #4F8A03;
    }
`;

const Wrapper = styled(Column)`
    padding: 24px 16px 0 16px;
    margin-bottom: ${p => p.isCategoryPage && "32px"};
    margin-top: ${p => p.isFirstTemplate ? "16px" : 0};
    .second-content {
        &:focus {
            border: 3px solid red !important;
        }
    }
    @media only screen and (min-width: 992px) {
        .videos-container {
            display: grid;
            grid-template-columns: 33.3% 33.3% 33%;
            grid-gap: 2rem;
        }
    }
`;
const ButtonCont = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 24px 0;
`;