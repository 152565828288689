import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { UserNotifications } from './UserNotifications';
import { AgroTextButton } from './AgroTextButton'
import { Box, IarrowDown2, IavatarUser2 } from 'agrofy-ui-components';

const UserWrapper = ({ user, parentCallback }) => {
  const marketUrl = process.env.RAZZLE_ENDPOINT_MARKET || window.env.RAZZLE_ENDPOINT_MARKET;
  const [showNotifications, setShowNotifications] = useState(false);
  const loginURL = `${marketUrl}/myaccount/#/dashboard`;

  const toggle = () => {
    const flag = !showNotifications;
    setShowNotifications(flag);
    flag
      ? document.addEventListener('click', handleClickOutside, true)
      : document.removeEventListener('click', handleClickOutside, true);
  };

  const handleClickOutside = (event) => {
    const elem = document.getElementById('user-notifications');
    const btn = document.getElementById('user-link');

    if (!(elem && elem.contains(event.target)) && !(btn && btn.contains(event.target))) {
      setShowNotifications(false);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  const hasWhiteSpace = str => /\s/g.test(str);

  return (
    <UserContainer>
      {!user ? (
        <Loading />
      ) :
        user ?
          (
            <>
              <UserLogged>
                <TextButton elementId='user-link' parentCallback={() => toggle()}>
                  <UserAvatar height='24px' width='24px' fill='white' className="avatar-icon"/>
                  <UserName>
                    {hasWhiteSpace(user.fullName) ? user.fullName.substr(0, user.fullName.indexOf(' ')) : user.fullName}
                  </UserName>
                  <Arrow fill='gray7' />
                </TextButton>
                {showNotifications ? (
                  <UserNotifications parentCallback={parentCallback} />
                ) : null}
              </UserLogged>
            </>
          )
          :
          (
            <>
              <Box mr='xs' display='flex' align-items='center'>
                <IconLink href={loginURL}>
                  <UserAvatar height='16px' width='16px' fill='gray8' />
                  <span>{'login label'}</span>
                </IconLink>
              </Box>
            </>
          )}
    </UserContainer>
  );
};

export default UserWrapper;

const UserContainer = styled.div`
  box-sizing: border-box;
  height: 41px;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-end;

  a {
    text-decoration: none;
  }
`;

const UserLogged = styled.div`
  position: relative;
  
  button {
    padding: 0px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.xxs}) {
    .avatar-icon {
      display: none;
    }
  }
`;

const TextButton = styled(AgroTextButton)`
  max-width: 8em;
  align-items: center;
`;

const UserAvatar = styled(IavatarUser2)`
  background-color: #63A105;
  border-radius: 50%;
  margin-right: 8px;
`;

const UserName = styled.p`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    display: block;
    font-size: 15px;
    line-height: 20px;
    margin: 0px;
    max-width: 8em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Arrow = styled(IarrowDown2)`
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    display: none;
  }
`;

const IconLink = styled.a`
  font-family: Montserrat, serif;
  align-items: center;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.gray8};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-family: 14px;
  line-height: 20px;
  font-weight: 500;
  text-decoration: none;
  text-transform: lowercase;
  padding: 1px 6px;

  span:first-letter {
    text-transform: uppercase;
  }
`;

const gradientBackground = keyframes`
  0% {
    opacity: 0.5;
  }
  50%  {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
`;

const Loading = styled.div`
  height: 18px;
  width: 100px;
  background-color: ${({ theme }) => theme.colors.gray5};
  animation: ${gradientBackground} 2s ease-in-out 0s infinite reverse;
  margin-right: 16px;
`;