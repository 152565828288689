import React, { createContext, useState } from 'react';

const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
  const [isDarkMode, setDarkMode] = useState(() => {
    if (typeof localStorage !== 'undefined') {
      const savedMode = JSON.parse(localStorage.getItem('dark')) || false;
      return savedMode;
    }
    return false;
  });

  const toggleMode = () => {
    const newMode = !isDarkMode;
    setDarkMode(newMode);

    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('dark', JSON.stringify(newMode));
    }

    if (newMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  };

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export default DarkModeContext;
