import React, {useState, useEffect, useContext} from 'react';
import AreaConverter from './Converters/AreaConverter/AreaConverter';
import Breadcrumb from '../../atoms/Breadcrumb/Breadcrumb';
import {DFPSlotsProvider, AdSlot} from 'react-dfp';
import getData from '../../services/GetData';
import {Helmet} from 'react-helmet-async';
import PaginationAgrofy from '../../atoms/PaginationAgrofy/PaginationAgrofy';
import PriceConverter from './Converters/PriceConverter/PriceConverter';
import ProductionConverter from './Converters/ProductionConverter/ProductionConverter';
import SectionTemplate from '../../templates/sectionTemplate/SectionTemplate';
import {Tabs, Tab} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import YieldConverter from './Converters/YieldConverter/YieldConverter';
import withSSR from '../../withSSR';
import './MeasurementConverter.scss';
import { buildBreadcrumbJson } from './../../utils/breadcrumbJson';
import { getBreadcrumbData } from './../../utils/breadcrumbJson';
import {splitUrl} from './../../utils/stringUtils';
import {gtmInitialize, manageGTMPageName, manageGTMUserStatus} from './../../utils/GTMDataLayerFuncs';
import AdhesionBanner from '../../atoms/AdhesionBanner/AdhesionBanner';
import PopUpBanner from '../../atoms/PopUpBanner/PopUpBanner';
import { AgroPushNotification } from '../../components/AgroPushNotification/AgroPushNotification';
import UserContext from '../../atoms/UserContext/UserContext';

const MeasumentConverter = props => {
  const {t} = useTranslation();
  const serverData = props && props.serverData;
  const pathName = props ? props.location.pathname : false;
  const [updatedData, setUpdatedData] = useState(serverData || null);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const amount = (serverData && serverData.pages);
  const loader = <div className="loader container p-0"><div className="animated-background" style={{height: '300px'}}/></div>;

  const templateBanners = [
    {
      adUnit: 'granos_middle_1',
      sizes: [[728, 90], [300, 250], [320, 50]]
    },
    {
      adUnit: 'granos_middle_2',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: 'granos_middle_3',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: 'granos_filmstrip',
      sizes: [[300, 600]]
    }
  ];
  
  const dfpNetworkID = process.env.DFP_NETWORK_ID || typeof window !== 'undefined' && window.env.DFP_NETWORK_ID;

  const { mkpUserData, userLoading } = useContext(UserContext);

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      window.onload = () => {
        setTimeout(() => {
          gtmInitialize();
        }, 3000)
      };
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  const fetchData = () => {
    const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
    setIsLoading(true)
    return getData({url: `${url}/api/News/GetNewsListByCategory?categoryID=31&page=${currentPage - 1}&limit=15&canonical=${pathName}`})
      .then(result => { 
        setUpdatedData(result.data)
        setIsLoading(false)
      });
  };

  useEffect(() => {
    if (updatedData || serverData) {
      const section = document.getElementById('section-template');
      setIsLoading(false);
      if (section && ((serverData !== updatedData) || (currentPage !== previousPage))) {
        setPreviousPage(currentPage);
        fetchData();
        document.getElementById('section-template').scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if(window && serverData) {
      manageGTMPageName('Conversor de Medidas');
    }
  }, [serverData]);

  return (
    <div className="measurement-converter container px-0">
      <Helmet>
        <title>{serverData && serverData.metaTitle}</title>
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href={serverData && serverData.canonical} />
        <meta name="description" content={serverData && serverData.metaDescription} />
        <script type="application/ld+json">{serverData && serverData.breadcrumb && buildBreadcrumbJson(getBreadcrumbData(serverData.breadcrumb, false))}</script>
      </Helmet>
      <AgroPushNotification location="Section" />
      <section className="dfp-banner stripped container top-banner">
        <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
          <AdSlot adUnit={'granos_header'} sizes={[[970, 90], [970, 250], [320, 80]]} />
        </DFPSlotsProvider>
      </section>
      <section id="main-section" className="d-flex flex-wrap mt-20">
        <div className="fluid-container col-lg-9 px-0">
          <section className="breadcrumb-wrapper p-0">
            <Breadcrumb breadcrumbLinks={getBreadcrumbData(serverData && serverData.breadcrumbNav, true)} />
          </section>
          <section className="measurement container mt-15">
            <h1>{serverData && serverData.seoTitle}</h1>
            <p>{serverData && serverData.seoText}</p>
            <Tabs className="basic-tabs green pb-20" transition={false}>
              <Tab eventKey="prices" title={t('prices')}>
                <PriceConverter />
              </Tab>
              <Tab eventKey="production" title={t('production')}>
                <ProductionConverter />
              </Tab>
              <Tab eventKey="yield" title={t('yield')}>
                <YieldConverter />
              </Tab>
              <Tab eventKey="area" title={t('area')}>
                <AreaConverter />
              </Tab>
            </Tabs>
            <SectionTemplate category='Granos' data={updatedData || serverData} sectionTitle={t('keep_reading')} bannersData={templateBanners} loading={isLoading}/>
            <div className="mb-36">
              {currentPage && amount &&
                <PaginationAgrofy
                  parentCallback={setCurrentPage}
                  currentPage={currentPage}
                  amount={amount}
                />
              }
            </div>
          </section>
        </div>
        <div className="row col-lg-3 sidebar-item px-0 dfp-banner stripped p-0 banner-section d-none d-lg-block">
          <div className="make-me-sticky">
            <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
              <AdSlot adUnit={'granos_followme'} sizes={[[260, 600]]} />
            </DFPSlotsProvider>
          </div>
        </div>
      </section>
      <AdhesionBanner adUnit='granos_adhesion' sizes={[[320, 80]]} />
      <PopUpBanner adUnit='granos_fullscreen' sizes={[[800, 600], [320, 480]]} />
    </div>
  );
};

MeasumentConverter.getInitialProps = async ({match, req, res}) => {
  const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
  const pathName = match && match.url;
  try {
    const {
      data
    } = await getData({url: `${url}/api/News/GetNewsListByCategory?categoryID=31&page=0&limit=15&canonical=${pathName}`});
    return {serverData: data};
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(MeasumentConverter);
