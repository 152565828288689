import React, { useState, useEffect, useContext } from 'react';
import AdhesionBanner from '../../atoms/AdhesionBanner/AdhesionBanner';
import PopUpBanner from '../../atoms/PopUpBanner/PopUpBanner';
import Breadcrumb from '../../atoms/Breadcrumb/Breadcrumb';
import ButtonAgrofy from '../../atoms/ButtonAgrofy/ButtonAgrofy';
import { DFPSlotsProvider, AdSlot } from 'react-dfp';
import getData from '../../services/GetData';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import PaginationAgrofy from '../../atoms/PaginationAgrofy/PaginationAgrofy';
import SectionTemplate from '../../templates/sectionTemplate/SectionTemplate';
import { useTranslation } from 'react-i18next';
import withSSR from '../../withSSR';
import './ImagenSatelital.scss';
import { format } from 'date-fns';
import { buildBreadcrumbJson } from './../../utils/breadcrumbJson';
import { getBreadcrumbData } from './../../utils/breadcrumbJson';
import { splitUrl, strImageWithoutParams  } from './../../utils/stringUtils';
import { gtmInitialize, manageGTMPageName, manageGTMUserStatus } from './../../utils/GTMDataLayerFuncs';
import UserContext from '../../atoms/UserContext/UserContext';
import { AgroPushNotification } from '../../components/AgroPushNotification/AgroPushNotification';

function handleShowComment() {
  const el = document.querySelector('.satellite-image-wrapper .right-content');
  el.classList.add('show-comment');
}

function ImagenSatelital(props) {
  const serverData = props && props.serverData;
  const pathName = props ? props.location.pathname : false;
  // const windowEnvRAZZLE_ENDPOINT_NEWS = 'https://news.agrofy.com.ar';
  const [templateData, setTemplateData] = useState(serverData || null);
  const [satelliteData, setSatelliteData] = useState();
  const date = satelliteData && format(new Date(satelliteData.date), 'dd-MM-yy | HH:mm');
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const amount = serverData && serverData.pages;
  const { t } = useTranslation();
  const loader = <div className="loader container px-0"><div className="animated-background" /></div>;

  const templateBanners = [
    {
      adUnit: 'clima_middle_1',
      sizes: [[728, 90], [300, 250], [320, 50]]
    },
    {
      adUnit: 'clima_middle_2',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: 'clima_middle_3',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: 'clima_filmstrip',
      sizes: [[300, 600]]
    }
  ];
  
  const dfpNetworkID = process.env.DFP_NETWORK_ID || typeof window !== 'undefined' && window.env.DFP_NETWORK_ID;

  const { mkpUserData, userLoading } = useContext(UserContext);

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      window.onload = () => {
        setTimeout(() => {
          gtmInitialize();
        }, 3000)
      };
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  const fetchData = () => {
    const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
    setIsLoading(true)
    return getData({ url: `${url}/api/News/GetNewsListByCategory?categoryID=2816&page=${currentPage - 1}&limit=15&canonical=${pathName}` })
      .then(result => {
        setTemplateData(result.data)
        setIsLoading(false)
      });
  };

  const fetchSatelliteData = () => {
    const url = process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
    setIsLoading(true)
    return getData({ url: `${url}/api/Weather/GetSatelitalImage` })
      .then(result => {
        setSatelliteData(result.data)
        setIsLoading(false)
      });
  };

  useEffect(() => {
    fetchSatelliteData()
    if (templateData || satelliteData) {
      const section = document.getElementById('section-template');
      fetchData();
      if (section && (templateData !== satelliteData)) {
        document.getElementById('section-template').scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if (window && satelliteData) {
      manageGTMPageName('Imagen Satelital');
    }
  }, [satelliteData]);

  const refImage = satelliteData && strImageWithoutParams(satelliteData.satelital_map_ref);

  return (
    <div className="clima-tab imagen-satelital container px-0">
      <Helmet>
        <title>{serverData && serverData.metaTitle}</title>
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href={serverData && serverData.canonical} />
        <meta name="description" content={serverData && serverData.metaDescription} />
        <script type="application/ld+json">{serverData && serverData.breadcrumb && buildBreadcrumbJson(getBreadcrumbData(serverData.breadcrumb, false))}</script>
      </Helmet>
      <AgroPushNotification location="Section" />
      <section className="dfp-banner stripped container top-banner">
        <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
          <AdSlot adUnit={'clima_header'} sizes={[[970, 90], [970, 250], [320, 80]]} />
        </DFPSlotsProvider>
      </section>
      <section id="main-section" className="d-flex flex-wrap mt-20">
        <div className="fluid-container col-lg-9 px-0">
          <section className="breadcrumb-wrapper p-0">
            <Breadcrumb breadcrumbLinks={getBreadcrumbData(serverData && serverData.breadcrumbNav, true)} />
          </section>
          <section className="pages-togler">
            <ul>
              <li><Link to="/pronostico-clima-tiempo">{t('forecast')}</Link></li>
              <li><Link to="/pronostico-clima-tiempo/lluvias-temperaturas">{t('rains')}</Link></li>
              <li><Link to="/pronostico-clima-tiempo/humedad">{t('humidity')}</Link></li>
              <li className="active"><Link to="/pronostico-clima-tiempo/imagen-satelital">{t('satelital_image')}</Link></li>
            </ul>
          </section>
          <section className="container">
            <h1>{serverData && serverData.seoTitle}</h1>
            <p>{serverData && serverData.seoText}</p>
            <div className="maps-container satellite-image-wrapper">
              <ul className="w-100">
                <li className="mb-36 w-100">
                  <div className="left-content">
                    {!satelliteData ? loader :
                      <div className="box-maps">
                        <div className="maps-wrapper">
                          <img src={satelliteData.satelital_map} alt={`${t('satelital_image')}`} />
                        </div>
                      </div>
                    }
                  </div>
                  <div className="right-content">
                    <span className="info-date mb-10">{date}</span>
                    <span className="info-title mb-20">{t('qualitative_scale_of_temperature_ranges')}</span>
                    <div className="bg-img">
                      {!satelliteData ? loader :
                        <img src={`https://news.agrofystatic.com/clima/${refImage}`} alt={`${t('satelital_image_ref')}`} />
                      }
                    </div>
                    <span className="info-subtitle d-flex mt-36">{t('comment')}</span>
                    <div className="info-text d-flex mt-10 flex-wrap">
                      {!satelliteData ? loader :
                        <p>{satelliteData.comment}</p>
                      }
                    </div>
                    <div className="show-comment-button mt-20">
                      <ButtonAgrofy title="read_full_comment" parentCallback={handleShowComment} classes="outline-secondary small w-100" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <SectionTemplate category='Clima' data={templateData || serverData} bannersData={templateBanners} sectionTitle={t('keep_reading')} loading={isLoading} />
            <div className="mb-36">
              {currentPage && amount &&
                <PaginationAgrofy
                  parentCallback={setCurrentPage}
                  currentPage={currentPage}
                  amount={amount}
                />
              }
            </div>
          </section>
        </div>
        <div className="row col-lg-3 sidebar-item px-0 dfp-banner stripped p-0 banner-section d-none d-lg-block">
          <div className="make-me-sticky">
            <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
              <AdSlot adUnit={'clima_followme'} sizes={[[260, 600]]} />
            </DFPSlotsProvider>
          </div>
        </div>
      </section>
      <AdhesionBanner adUnit='clima_adhesion' sizes={[[320, 80]]} />
      <PopUpBanner adUnit='clima_fullscreen' sizes={[[800, 600], [320, 480]]} />
    </div>
  );
}

ImagenSatelital.getInitialProps = async ({ match, req, res }) => {
  const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
  const pathName = match && match.url;
  try {
    const {
      data
    } = await getData({ url: `${url}/api/News/GetNewsListByCategory?categoryID=2816&page=0&limit=15&canonical=${pathName}` });
    return { serverData: data };
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(ImagenSatelital);
