import React, {useState} from 'react';
import './CustomSelectAgrofy.scss';

export default function CustomSelectAgrofy({
  nameSelectId,
  options,
  value,
  label,
  parentCallback,
  initialValue,
  returnObject,
  placeholder}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(initialValue || '');
  const getOption = () => {
    if (Array.isArray(options)) {
      return options[0][label];
    }
    return options[0];
  };
  const [labelData, setLabelData] = useState(placeholder ? placeholder : getOption());

  const handleClick = (e, val, labelContent) => {
    setIsOpen(!isOpen);
    if (parentCallback) {
      const resp = returnObject ? options.find(item => item[value] === val) : val;
      parentCallback(resp);
      setSelectedOption(resp);
      setLabelData(labelContent);
    }
  };

  const selectClasses = isOpen ? 'opened' : 'closed';

  return (
    <div id={nameSelectId} className='select-agrofy' onClick={() => setIsOpen(!isOpen)}>
      <button
        defaultValue={selectedOption}
        className='btn-options'
      >
        {labelData}
      </button>
      <div className={selectClasses}>
        {placeholder ? <div className="disable-option">{placeholder}</div> : null}
        {options.map((option, index) =>
          <button key={`option-${nameSelectId}-${index}`} className='option' onClick={e => handleClick(e.currentTarget.value, value ? option[value] : option, label ? option[label] : option)}>
            {label ? option[label] : option}
          </button>
        )}
      </div>
    </div>
  );
}
