import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HeaderSearchBar from '../../atoms/HeaderSearchBar/HeaderSearchBar';
import './Header.scss';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import { AgroButton, IavatarUser, Isearch2, Ienvelope } from 'agrofy-ui-components';
import UserContext from '../../atoms/UserContext/UserContext';
import UserWrapper from '.././../molecules/UserWrapper/UserWrapper';
import MainMenu from '../../atoms/MainMenu/MainMenu';
import withSSR from '../../withSSR';
import getData from '../../services/GetData';
import postData from '../../services/PostData';
import TopMenu from '../../atoms/TopMenu/TopMenu';
import TagManager from 'react-gtm-module';
import { withRouter } from 'react-router-dom';
import AgrofyLogo from '../../atoms/AgrofyLogo/AgrofyLogo'
import MomentTopics from '../MomentTopics/MomentTopics';
import Loader from '../../atoms/Loader/Loader';
import { useResizeScreen } from '../../hooks/useResizeScreen';

const Header =  withRouter((props) => {
  const { t } = useTranslation();
  const data = props && props.serverData;
  const hideTopics = props && props.hideTopics;
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [show, setShow] = useState(false);
  const { mkpUserData, setMkpUserData } = useContext(UserContext);
  const [originURL, setOriginUrl] = useState('https://news.agrofy.com.ar');
  const pathname = props.location.pathname;
  const apiEndpointPrefix = process.env.AGROFYAPI_ENDPOINT_SERVICES_PREFIX || typeof window !== 'undefined' && window.env.AGROFYAPI_ENDPOINT_SERVICES_PREFIX;
  const apiMarket = process.env.RAZZLE_ENDPOINT_MARKET || typeof window !== 'undefined' && window.env.RAZZLE_ENDPOINT_MARKET;
  const { screen } = useResizeScreen();

  const countryName = process.env.COUNTRY_NAME || typeof window !== 'undefined' && window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";

  const [topics, setTopics] = useState();

  const logout = () => {
    postData({ url: `${apiEndpointPrefix}/logout` }).then(() => setMkpUserData(null));
    
    props.history.push(pathname);
  }

  const setOriginURL = () => {
    setOriginUrl(window.location.origin);
  }

  useEffect(() => {
    window.hj=window.hj||function(){(hj.q=hj.q||[]).push(arguments);};

    const fetchData = () => {
      const url = `${window.env.RAZZLE_ENDPOINT_NOTI_API}/api/News/GetMomentTopics`;
      getData({url}).then(resp => setTopics(resp.data));
    };

    fetchData();
  }, []);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 2000);

    setOriginURL();

    /* Toggle button clicks */
    // eslint-disable-next-line prefer-arrow-callback
    document.addEventListener('click', function (event) {
      // If user clicks inside navbar-toggler, do nothing
      if (event.target.closest('.navbar-toggler')) {
        return;
      }
      // If user clicks a link, close menu
      if (event.target.closest('a')) {
        const element = document.querySelector('.navbar-toggler.collapsed') || document.querySelector('.dropdown.show > .dropdown-toggle');
        if (element) {
          element.click();
        }
      }
      // If user clicks inside has-child, do nothing (show childs)
      if (event.target.closest('.navbar-collapse .has-child')) {
        return;
      }
      // If user clicks outside the menu when it is open, hide it!
      const navBar = document.querySelector('.navbar-toggler');
      if (navBar && navBar.classList.contains('collapsed')) {
        navBar.click();
      }
    });
    window.addEventListener('scroll', handleScroll());
    return () => {
      window.removeEventListener('scroll', handleScroll());
    };
  }, []);

  const handleScroll = e => {
    let scrollpos = window.scrollY;
    const root = document.getElementById('root');

    function add_class_on_scroll() {
      root.classList.add('scrolled');
    }

    function remove_class_on_scroll() {
      root.classList.remove('scrolled');
    }
    // eslint-disable-next-line prefer-arrow-callback
    window.addEventListener('scroll', function () {
      scrollpos = window.scrollY;
      if (scrollpos > 0) {
        add_class_on_scroll();
      } else {
        remove_class_on_scroll();
      }
    });
  };

  const handleClick = () => {
    if (isBrasil) {
      const input = document.getElementById('mailInput');

      input.scrollIntoView({behavior: "smooth", block: "center"});
      typeof window !== 'undefined' && window.setTimeout(() => input.focus({preventScroll: true}), 0);
    }
  };

  const manageGTMNewsletters = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'trackEvent',
        eventCategory: 'Users',
        eventAction: 'Agrofy News-Newsletter',
        eventLabel: 'Intention'
      }
    };

    TagManager.dataLayer(tagManagerArgs);
  };

  const manageGTMLogin = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'select_content',
        content_type: 'header',
        item_id: 'go_to_login'
      }
    };

    TagManager.dataLayer(tagManagerArgs);
  };

  const handleBurgerClick = () => {
    setCollapsed(!collapsed);
    setShow(!show);
  };

  const hasSectionIncluded = item => props.extSection.includes(item.name);

  return (
    <div className={`headerContainer ${hideTopics ? 'noTopics' : ''}`}>
      <header className="header">
        <Navbar className="container" expand="sm" >
          <button
            aria-controls="basic-navbar-nav"
            type="button"
            aria-label="Toggle navigation"
            className={`navbar-toggler ${collapsed ? 'collapsed' : ''}`}
            onClick={handleBurgerClick}
          >
            <div className="bars-wrapper">
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </div>
          </button>
          <Link to="/" className="logo">
            <div className="link-head">
              <AgrofyLogo width={screen && screen.sWidth <= 576 ? 156 : 186} heigth={29} />
            </div>
          </Link>
          {
          //Mob
          !isBrasil ? (mkpUserData ?
          <div className="user-wrapper d-md-none d-xs-flex">
            <UserWrapper
              user={mkpUserData}
              parentCallback={() => logout()}
            />
          </div>
          : 
          <div className="login-container d-md-none d-xs-flex">
            <a
              href={`${apiMarket}/auth?urlRedirect=${originURL}${pathname}&bu=NEWS`}
              className="user-icon"
              onClick={() => { manageGTMLogin(); hj('event', 'News-ClickIngresarHeader')();}}>
                <IavatarUser width='24px' height='24px' fill={`${mkpUserData ? 'green6' : 'gray8'}`}/>
            </a>
          </div>
          ) : null}
          <Navbar.Collapse id="basic-navbar-nav" className={show ? 'show' : ''}>
            {data ? <MainMenu data={data} topics={topics} /> : null}
          </Navbar.Collapse>
          <div className="custom-links pr-0">
            <div className="left-content">
              <div className="links d-none d-lg-flex">
                {data ?
                  // eslint-disable-next-line max-len
                  <TopMenu data={data.filter(item => (item.destacado === 1 || hasSectionIncluded(item)))} extSection={data.find(item => hasSectionIncluded(item))} /> :
                  null}
              </div>
            </div>
            <div className="right-content">
              <Dropdown className="finder">
                <Dropdown.Toggle>
                  <Isearch2 height='26px' width='26px' fill='gray8'/>
                </Dropdown.Toggle>
                <Dropdown.Menu className="custom lg sm">
                  <HeaderSearchBar />
                </Dropdown.Menu>
              </Dropdown>
              {
                !isBrasil ? (
                  mkpUserData ?
                  <div className="d-none d-lg-flex">
                    <UserWrapper
                      user={mkpUserData}
                      parentCallback={() => logout()}
                    />
                  </div>
                  : 
                  <a
                    href={`${apiMarket}/auth?urlRedirect=${originURL}${pathname}&bu=NEWS`}
                    className={`login-btn-container d-none d-lg-flex`}
                    onClick={() => { manageGTMLogin(); hj('event', 'News-ClickIngresarHeader');}}
                  >
                    <AgroButton loading={loading} size="md" className="login-btn">
                      <IavatarUser height='15px' width='15px' fill='white'/>
                      {t('login')}
                    </AgroButton>
                  </a>
                ) :
                <AgroButton onClick={() => {manageGTMNewsletters(); handleClick(); }} size="md" className="subNewslettersHeader d-none d-sm-inline-block">
                  <Ienvelope fill='white' />
                  {t('subscribe')}
                </AgroButton>
              }
            </div>
          </div>
        </Navbar>
      </header>
      {!hideTopics && (topics && topics.length > 0) && <MomentTopics topics={topics} />}
    </div>
  );
});

Header.getInitialProps = async ({ match, req, res }) => {
  const url = process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
  try {
    const {
      data
    } = await getData({ url: `${url}/api/Menu/Get` });
    return { serverData: data };
  } catch (e) {
    return console.error(e);
  }
};
Header.defaultProps = {
  extSection: ['Foro']
};

export default withSSR(Header);

