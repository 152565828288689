export const structuredVideoData = (videoData, isStreaming) => {
    const stringVal = val => `"${val}"`;
    const replaceQuotes = str => str && str.replace(/"|(\r\n|\n|\r)/g, "");
    const result = `{
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "name": ${stringVal(replaceQuotes(videoData.title))},
        "description":  ${stringVal(replaceQuotes(videoData.body) || replaceQuotes(videoData.description))},
        "thumbnailUrl": [${stringVal(videoData.portada || videoData.imageUrl)}],
        "uploadDate": ${stringVal(videoData.uploadDate || videoData.strc_uploadDate)},
        "duration": ${stringVal(videoData.strc_duration)},
        "contentURL":  "https://news.agrofy.com.ar${isStreaming ? `/${videoData.url}` : videoData.urlSlug}",
        "embedUrl": ${isStreaming ? stringVal(videoData.video) : stringVal(videoData.url)},
        "expires": ${isStreaming ? stringVal(videoData.expires) : stringVal("")},
        "interactionStatistic": {
            "@type": "InteractionCounter",
            "interactionType": { "@type": "http://schema.org/WatchAction" },
                "userInteractionCount": ${stringVal(videoData.viewCount || videoData.strc_interactionStatistic)}
        },
        "publication": [
           {
              "@type": "BroadcastEvent",
              "isLiveBroadcast": ${isStreaming ? stringVal(true) : stringVal(false)},
              "startDate": ${isStreaming ? stringVal(videoData.startDate) :  stringVal("")},
              "endDate": ${isStreaming ? stringVal(videoData.endDate) :  stringVal("")}
            }
          ] 
    }`;
    return result.toString();
}
