import React from 'react';
import styled, { css } from 'styled-components';
import { AgroIcon, AgroSpinner } from 'agrofy-ui-components';
import { Link } from 'react-router-dom';
import { Date, sharedLineStyles, Circle } from '../../molecules/MainVideo/MainVideo';
import { LiveVideo } from '../../molecules/CarouselVideos/CarouselVideos';
import { formattedDate } from '../../utils/dateUtils';
import {truncateString} from '../../utils/stringUtils';
import { SpinnerContainer } from '../../templates/CategoryVideosTemplate/CategoryVideosTemplate';
import PlayerIcon from './PlayerIcon';

const VideoCardImg = ({ data, onChildClick, isModal, isPlaying, isCategoryPage, isMobile }) => {
    const handleClick = (e, data) => {
        onChildClick(data)
    };

    const handleOnLoad = e => {
        e.target.src = "https://news.agrofystatic.com/agrofy-webinar-1337x769_-_copia.jpg?d=620x375";
    }

    return (
        <Link onClick={(e) => handleClick(e, data)} to={!isCategoryPage && !isMobile ? data.urlSlug : "#"} className="wrapper-link">{data ?
            <>
                <VideoContainer className="video-img-container" isPlaying={isPlaying} shouldHover={!isPlaying} isMobile={isMobile}>
                    <div className="rs-image">
                        <Image src={data.imageUrl} onError={handleOnLoad} />
                    </div>
                    <PlayerIcon />
                    {isPlaying && <LiveVideo className="live-video-container"><Circle isCarousel /><span className="category-page">REPRODUCIENDO</span></LiveVideo>}
                    <div className="description-wrapper">
                        {!isModal && <Title isModal={isModal}>{!isMobile ? truncateString(data.title, 80) : data.title}</Title>}
                        {!isMobile && <hr/>}
                        {!isModal && <DurationVideo className="duration" isMobile={isMobile}><span>{formattedDate(data.publishedDate)}</span> {`- ${data.duration} min`}</DurationVideo>}
                    </div>
                </VideoContainer>
                {isModal && <ContentModalVideo isModal={isModal}>
                    {isModal && <Title isModal={isModal}>{data.title}</Title>}
                    <DateContainer isModal={isModal}>{formattedDate(data.publishedDate)}</DateContainer>
                </ContentModalVideo>}
            </> : <SpinnerContainer><AgroSpinner width='25px' height='25px' variant='primary' /> </SpinnerContainer>
        }
        </Link>
    )
}

export default VideoCardImg;

const sharedFont = css`
    font-family: 'Open Sans';
`;
const sharedStyles = css`
    font-size: 14px;
    line-height: 21px;
    color: ${props => props.theme.colors.gray7};
    ${sharedFont};
`
const VideoContainer = styled.div`
    margin-top: 24px;
    position: relative;
    .live-video-container {
        padding: 8px 0 0 4px;;
        margin-bottom: 0;
    }
    .rs-image {
        position: relative;
        width: 100%;
        height: 100%;
        &:after {
            content: '';
            opacity: 0;
            position: absolute;
            top: -1px;
            right: -1px;
            bottom: -1px;
            left: -1px;
            transition: .3s ease 0s;
            transition-property: opacity;
        }
    }
    svg {
        position: absolute;
        top: 28%;
        left: 50%;
        transform: translateX(-50%);
    }
    svg, hr {
        opacity: 0;
        transition: .3s ease-in-out;
    }
    .duration {
        opacity: ${p => p.isMobile ? 1 : 0};
        transition: .3s ease-in-out;
    }
    hr {
        width: 16px;
        text-align: left;
        height: 4px;
        border: 0; 
        border-top: 2px solid #63A105;
        margin: 12px 0 8px 0; 
        padding: 0;
    }
    .description-wrapper {
        padding: 8px 0 0 4px;
        transition: .3s ease-in-out;
    }
    img {
        border-top: ${p => p.isPlaying ? "2px solid #63A105" : "none" };
    }

    ${p => p.shouldHover && !p.isMobile && css`
      &:hover {
        .rs-image {
            &:after {
                height: 214px;
                background-image: linear-gradient(transparent 0%, rgba(46, 49, 49, 0) 0%, #ffffff 100%);
                opacity: 1;
            }
        }  
        img {
            transform: scale(1.1);
            border-top: 2px solid #63A105;
            transition: all .2s ease-in-out;
            }
        svg, .duration {
            opacity: 1;
        }
        .description-wrapper {
            transform: translateY(-32px);
        }
        h3 {
            transition: .3s ease 0s;
        }
        hr {
            opacity: 1;
        }
      }
   `}
`;
const Image = styled.img`
    width: -webkit-fill-available;
    display: block;
    min-height: 200px;
    height: ${p => p.isModal ? "210px" : "auto"};
    ${({ theme }) => css`
        @media only screen and (min-width: ${theme.breakpoint.lg}) {
            max-height: 200px;
        }
  `}
`;
export const Icon = styled(AgroIcon)`
    position: absolute;
    top: ${p => p.topPos ? p.topPos : "44%"};
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
`;
const DurationVideo = styled.div`
    opacity: ${p => p.isMobile ? 1 : 0};
    margin: 4px 0;
    ${sharedStyles};
    span {
        text-transform: capitalize;
        ${sharedStyles};
    }
`;
const Title = styled.h3`
    color: ${p => p.isModal ? "#FFFFFF" : "#000000"};
    margin-top: ${p => p.isModal && "10px"};
    font-size: 1rem;
    line-height: 1.2;
    ${({ theme }) => css`
        @media only screen and (min-width: ${theme.breakpoint.lg}) {
            margin-bottom: 0;
            height: 48px;
        }
    `}
`;
const ContentModalVideo = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;
    padding: ${p => p.isModal && "0 16px"};
`;
const DateContainer = styled(Date)`
    ${sharedLineStyles};
`;