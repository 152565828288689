import React from 'react';
import {Table} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TableWatermarkLogo from '../../WatermarkLogo/TableWatermarkLogo';
import './TablePhysical.scss';

const TablePhysical = ({data}) => {
  const { t } = useTranslation();
  
  const setTable = () => {
    return (
      <>
      <TableWatermarkLogo />
      <Table className="table-agrofy is-gray">
        <thead>
          <tr>
            <th>{t('date')}</th>
            <th>{t('destination')}</th>
            <th>{t('quality')}</th>
            <th>{t('delivery')}</th>
            <th>{t('price')}</th>
          </tr>
        </thead>
        <tbody>
          { data.map((item, idx) =>
            <tr key={`row-${idx}`}>
              <td key={`date-${idx}`}>{item.date}</td>
              <td key={`dest-${idx}`}>{item.destination}</td>
              <td key={`qual-${idx}`}>{item.quality}</td>
              <td key={`deli-${idx}`}>{item.delivery}</td>
              <td key={`price-${idx}`}>{item.price}</td>
            </tr>
          )}
        </tbody>
      </Table>
      </>
    );
  };

  return (
    <div className="physical-table-section mb-36">
      {data ? setTable() : null}
    </div>
  );
};

export default TablePhysical;
