import React from 'react';
import {Table} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TableWatermarkLogo from '../../WatermarkLogo/TableWatermarkLogo';
import './FuturesHistoricalTable.scss';

const FuturesHistoricalTable = ({data}) => {
  const { t } = useTranslation();
  
  const setTable = () => {
    return (
      <>
      <TableWatermarkLogo />
      <Table className="table-agrofy is-gray">
        <thead>
          <tr>
            <th>{t('date')}</th>
            <th>{t('market')}</th>
            <th>{t('position')}</th>
            <th>{t('adjustment')}</th>
          </tr>
        </thead>
        <tbody>
          { data.map((item, idx) =>
            <tr key={`row-${idx}`}>
              <td key={`date-${idx}`}>{item.date}</td>
              <td key={`market-${idx}`}>{item.market}</td>
              <td key={`position-${idx}`}>{item.position}</td>
              <td key={`adjustment-${idx}`}>{item.adjustment}</td>
            </tr>
          )}
        </tbody>
      </Table>
      </>
    );
  };

  return (
    <div className="historical-futures-table-section  mb-36">
      {data ? setTable() : null}
    </div>
  );
};

export default FuturesHistoricalTable;
