import React, { useState, useEffect, useContext } from 'react';
import AdhesionBanner from '../../atoms/AdhesionBanner/AdhesionBanner';
import PopUpBanner from '../../atoms/PopUpBanner/PopUpBanner';
import { DFPSlotsProvider, AdSlot } from 'react-dfp';
import getData from '../../services/GetData';
import { Helmet } from 'react-helmet-async';
import PaginationAgrofy from '../../atoms/PaginationAgrofy/PaginationAgrofy';
import SectionTemplate from '../../templates/sectionTemplate/SectionTemplate';
import { useTranslation } from 'react-i18next';
import withSSR from '../../withSSR';
import { gtmInitialize, manageGTMPageName, manageGTMUserStatus } from './../../utils/GTMDataLayerFuncs';
import { AgroPushNotification } from '../../components/AgroPushNotification/AgroPushNotification';
import { buildBreadcrumbJson } from './../../utils/breadcrumbJson';
import { getBreadcrumbData } from './../../utils/breadcrumbJson';
import TagManager from 'react-gtm-module';
import UserContext from '../../atoms/UserContext/UserContext';

const Ganaderia = props => {
  const { t } = useTranslation();
  const serverData = props && props.serverData;
  const pathName = props ? props.location.pathname : false;
  const [updatedData, setUpdatedData] = useState(serverData || null);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const [isLoading, setIsLoading] = useState(serverData || false);
  const amount = (serverData && serverData.pages);  
  const dfpNetworkID = process.env.DFP_NETWORK_ID || typeof window !== 'undefined' && window.env.DFP_NETWORK_ID;
  const countryName = process.env.COUNTRY_NAME || typeof window !== 'undefined' && window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";
  const dfpPrefix = isBrasil ? 'pecuaria' : 'ganaderia';
  
  const templateBanners = [
    {
      adUnit: dfpPrefix + '_middle_1',
      sizes: [[728, 90], [300, 250], [320, 50]]
    },
    {
      adUnit: dfpPrefix + '_middle_2',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: dfpPrefix + '_middle_3',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: dfpPrefix + '_filmstrip',
      sizes: [[300, 600]]
    }
  ];

  const fetchData = () => {
    const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
    setIsLoading(true)
    return getData({ url: `${url}/api/News/GetNewsListByCategory?categoryID=77&page=${currentPage - 1}&limit=15&canonical=${pathName}` })
      .then(result => {
        setUpdatedData(result.data)
        setIsLoading(false)
      });
  };

  const { mkpUserData, userLoading } = useContext(UserContext);

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      window.onload = () => {
        setTimeout(() => {
          gtmInitialize();
        }, 3000)
      };
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (updatedData || serverData) {
      const section = document.getElementById('section-template');
      setIsLoading(false);
      if (section && ((serverData !== updatedData) || (currentPage !== previousPage))) {
        setPreviousPage(currentPage);
        fetchData();
        document.getElementById('section-template').scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if (serverData) {
      manageGTMPageName(`Home ${serverData.title_section}`);
    }
  }, [serverData]);
  
  const breadcrumbArr = [{level: 'Agrofy News'}, {level: 'Pecuária', urlLevel: pathName.replace(/^\/|\/$/g, '')}];
  const domainName = process.env.RAZZLE_ENDPOINT_SELF || typeof window !== 'undefined' && window.env.RAZZLE_ENDPOINT_SELF;

  return (
    <div className="general-interest container px-0">
      <Helmet>
        <title>{serverData && serverData.metaTitle}</title>
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href={serverData && serverData.canonical} />
        <link rel="alternate" hrefLang="es-ar" href="https://news.agrofy.com.ar/ganaderia" />
        <link rel="alternate" hrefLang="pt-br" href="https://news.agrofy.com.br/pecuaria" />
        <meta name="description" content={serverData && serverData.metaDescription} />
        <script type="application/ld+json">{serverData && serverData.breadcrumb && buildBreadcrumbJson(getBreadcrumbData(serverData.breadcrumb, false))}</script>
      </Helmet>
      <AgroPushNotification location="Home" />
      <section className="dfp-banner stripped container top-banner">
        <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
          <AdSlot adUnit={dfpPrefix + '_header'} sizes={[[970, 90], [970, 250], [320, 80]]} />
        </DFPSlotsProvider>
      </section>
      <section id="main-section" className="d-flex flex-wrap mt-20">
        <div className="fluid-container col-lg-9 px-0">
          <section className="general-interest container">
              <h1>{serverData && serverData.seoTitle}</h1>
              <p>{serverData && serverData.seoText}</p>
            <SectionTemplate data={updatedData || serverData} bannersData={templateBanners} loading={isLoading} />
            <div className="mb-36">
              {currentPage && amount &&
                <PaginationAgrofy
                  parentCallback={setCurrentPage}
                  currentPage={currentPage}
                  amount={amount}
                />
              }
            </div>
          </section>
        </div>
        <div className="row col-lg-3 sidebar-item px-0 dfp-banner stripped p-0 banner-section d-none d-lg-block">
          <div className="make-me-sticky">
            <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
              <AdSlot adUnit={dfpPrefix + '_followme'} sizes={[[260, 600]]} />
            </DFPSlotsProvider>
          </div>
        </div>
      </section>
      <AdhesionBanner adUnit={dfpPrefix + '_adhesion'} sizes={[[320, 80]]} />
      <PopUpBanner adUnit={dfpPrefix + '_fullscreen'} sizes={[[800, 600], [320, 480]]} />
    </div>
  );
};

Ganaderia.getInitialProps = async ({ match, req, res }) => {
  const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
  const pathName = match && match.url;
  try {
    const {
      data
    } = await getData({ url: `${url}/api/News/GetNewsListByCategory?categoryID=77&page=0&limit=15&canonical=${pathName}` });
    return { serverData: data };
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(Ganaderia);
