import React, {useState, useEffect} from 'react';
import getData from '../../services/GetData';
import MultiSelectAgrofy from '../../atoms/MultiSelectAgrofy/MultiSelectAgrofy';
import {AgroButton, AgroSelect} from 'agrofy-ui-components';
import Calendar from '../../atoms/Calendar/Calendar';
import { useTranslation } from 'react-i18next';
import months from '../../assets/months';
import Graphic from '../../atoms/Graphic/Graphic';
import {format} from 'date-fns';
import FuturesTablePagination from '../../organisms/FuturesTablePagination/FuturesTablePagination';
import NoFilterData from '../../atoms/NoFilterData/NoFilterData';
import './FuturesHistoricalDataContent.scss';

const markets = [
  {value: 1, label: 'MtR'},
  {value: 2, label: 'CBOT'}
];

const products = [
  {value: 'Soja', label: 'Soja'},
  {value: 'Maiz', label: 'Maíz'},
  {value: 'Trigo', label: 'Trigo'},
  {value: 'HarinaDeSoja', label: 'Harina de Soja'},
  {value: 'AceiteDeSoja', label: 'Aceite de Soja'}
];

const yearsAmount = (new Date().getFullYear() + 10) - 2001;
let init = 2001;
const years = Array(yearsAmount).fill().map(() => {
  const year = init++;
  return {value: year, label: year};
});
const loader = <div className="loader container p-0"><div className="animated-background" style={{height: '300px'}}/></div>;
let monthsFormat = [];

const FuturesHistoricalDataContent = props => {
  const {t} = useTranslation();
  
  const [selected, setSelected] = useState({
    product: '',
    market: '',
    years: [],
    months: [],
    fromDate: new Date(),
    toDate: new Date()
  });

  const [hasData, setHasData] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [data, setData] = useState([]);
  const [graphData, setGraphData] = useState(null);

  const hasProductsData = hasData && data.Productos && data.Productos.length;

  useEffect(() => {
    monthsFormat = months.map(month => {
      return {value: month.value, label: t(`months.${month.label}`)};
    });
  }, []);

  useEffect(() => {
    setSelected({...selected, product: ''});
  }, [selected.market]);

  const formatData = oldData => {
    const newData = [];

    oldData.Productos.map(product => {
      const market = product.Mercado[0].NombreMercado;
      const graphicData = [];

      product.Mercado[0].Anio.map(year => {
        return year.Mes.map(month => {
          return month.Fecha.map(date => {
            return graphicData.push({t: date.Fecha, y: date.Ajuste});
          });
        });
      });

      return newData.push({
        label: market,
        graphicData: graphicData
      });
    });

    return newData;
  };

  const getMonthsNames = months => {
    var names = [];

    months.forEach(number => {
      names.push(monthsFormat.find(x => x.value === number).label);
    });

    return names;
  }

  const getProducts = () => {
    if (selected.market === 'CBOT') {
      return products;
    } else if (selected.market === 'MtR') {
      return products.slice(0, 3);
    }
  };

  const fetchData = (codProd, codMarket, codMonths, codYears, fromDate, toDate) => {
    var product = products.find(x => x.label === codProd).value;
    var marketID = markets.find(x => x.label === codMarket).value;
    var monthNames = getMonthsNames(codMonths.split(','));
    
    const url = `/api/Prices/GetHistoricalPrices?marketID=${marketID}&product=${product}&months=${monthNames}&years=${codYears}&dateFrom=${fromDate}&dateTo=${toDate}`;
    return getData({
      url: `${window.env.RAZZLE_ENDPOINT_COTZ_API}${url}`
    });
  };

  const getFilteredData = () => {
    setHasData(false);
    setHasFilters(true);
    const codProduct = selected.product;
    const codMarket = selected.market;
    const codMonths = selected.months.map(item => item).join(',');
    const codYears = selected.years.map(item => item).join(',');
    const fromDate = format(selected.fromDate, 'yyyyMMdd');
    const toDate = format(selected.toDate, 'yyyyMMdd');
    fetchData(codProduct, codMarket, codMonths, codYears, fromDate, toDate).then(resp => {
      setGraphData(resp.data && resp.data.length ? formatData(resp.data[0]) : []);
      setData(resp.data && resp.data.length ? resp.data[0] : []);
      setHasData(true);
    });
  };

  const validFilters = () => {
    return selected.product && selected.market && selected.months.length && selected.years.length;
  };

  return (
    <div className="futures-historical-data__content">
      <div className="futures-historical-data__filters">
        <div className="futures-historical-data__filters_dates d-md-flex flex-wrap justify-content-between mb-20">
          <h1 className="p-0">{t('future_and_options_market')}</h1>
          <div className="futures-historical-data__filters_dates__calendar d-flex align-items-end">
            <div className="futures-historical-data__filters_dates__calendar--left d-md-flex align-items-center pr-0 pr-md-15">
              <label className="pr-10">{t('choose_date_from')}:</label>
              <Calendar
                initialDate={selected.fromDate}
                maxDate={selected.toDate}
                parentCallback={date => setSelected({...selected, fromDate: date})}/>
            </div>
            <div className="futures-historical-data__filters_dates__calendar--right d-md-flex align-items-center">
              <label className="pr-10">{t('choose_date_to')}:</label>
              <Calendar
                initialDate={selected.toDate}
                minDate={selected.fromDate}
                parentCallback={date => setSelected({...selected, toDate: date})}/>
            </div>
          </div>
        </div>
        <div className="futures-historical-data__filters__selects d-md-flex mb-20 justify-content-between">
          <div className="futures-historical-data__filters__selects__inputs d-md-flex">
            <AgroSelect
              className="futuresSelect mr-15"
              value={selected.market} 
              label={t('market')}
              options={markets}
              onChange={(e) => setSelected({...selected, market: e.label})}
            />
            <AgroSelect
              className="futuresSelect mr-15"
              value={selected.product} 
              label={t('product')} 
              options={getProducts()}
              onChange={(e) => setSelected({...selected, product: e.label})} 
            />
            <MultiSelectAgrofy
              placeholder={t('month')}
              options={monthsFormat}
              parentCallback={items => setSelected({...selected, months: items})}
            />
            <MultiSelectAgrofy
              placeholder={t('year')}
              options={years}
              parentCallback={items => setSelected({...selected, years: items})}
            />
          </div>
          <AgroButton className="futuresButton" onClick={getFilteredData} disabled={!validFilters()}>{t('search')}</AgroButton>
        </div>
      </div>
      {
        // eslint-disable-next-line no-nested-ternary
        !hasFilters ?
          <div className="futures-historical-data__generate-graphic text-center">
            <div className="i-container mx-auto mb-10">
              <i className="icon-graphic" />
            </div>
            <label className="title6">{t('complete_filters')}</label>
            {/* eslint-disable-next-line no-nested-ternary */}
          </div> : !hasData ? loader : (hasProductsData) ?
            <div className="futures-historical-data__graph">
              <Graphic data={hasData && data.Productos && graphData}/>
            </div> : <NoFilterData/>
      }
      {hasProductsData ? <FuturesTablePagination data={hasData && data}/> : null}
    </div>
  );
};

export default FuturesHistoricalDataContent;

