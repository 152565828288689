/* eslint-disable no-unreachable */
/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import PaginationAgrofy from '../../atoms/PaginationAgrofy/PaginationAgrofy';
import FuturesHistoricalTable from '../../atoms/Tables/FuturesHistoricalTable/FuturesHistoricalTable';
import { CSVLink } from 'react-csv';
import ButtonAgrofy from '../../atoms/ButtonAgrofy/ButtonAgrofy';
import { flattened } from '../../utils/helpers';
import CmaLogo from '../../images/icons/cma-logo.svg';
import MatbaRofexLogo from '../../images/icons/matba-rofex-logo.png';
import './FuturesTablePagination.scss';

const PAGE_AMOUNT = 20;

const FuturesTablePagination = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
const [previousPage, setPreviousPage] = useState(1);
  const [amount, setAmount] = useState(null);
  const [formatData, setFormatData] = useState(false);

  useEffect(() => {
    if (!data) {
      return;
    }
    setCurrentPage(1);
    setFormatData(formatTableData());
  }, [data]);

  const formatTableData = () => {
    const newData = [];
    data.Productos.map(product => {
      return product.Mercado[0].Anio.map(year => {
        return year.Mes.map(month => {
          const marketName = product.Mercado[0].NombreMercado;
          const position = month.Posicion;
          const productName = product.NombreProducto;
          return month.Fecha.map(date => {
            return newData.push({
              name: productName,
              market: marketName,
              position: position,
              adjustment: date.Ajuste,
              date: date.Fecha
            });
          });
        });
      });
    });
    const count = Math.ceil(newData.length / PAGE_AMOUNT);
    setAmount(count);
    let init = 0;
    const arr = Array(count).fill().map(() => {
      const sub = newData.slice(init, init += PAGE_AMOUNT);
      return sub;
    });
    return arr;
  };
  const headers = [
    { label: 'Producto', key: 'name' },
    { label: 'Mercado', key: 'market' },
    { label: 'Posición', key: 'position' },
    { label: 'Ajuste', key: 'adjustment' },
    { label: 'Fecha', key: 'date' }
  ];

  const marketExists = (market, arr) => formatData && arr[0].some(el => el.market === market);
  const isMatbaRofex = marketExists("MATBA", formatData) || marketExists("ROFEX", formatData) || marketExists("MtR", formatData)
  const isCbot = marketExists("CBOT", formatData);
  const getLogos = () => {
    if(isCbot && isMatbaRofex) {
      return <div className="description-logo">Powered by <img src={CmaLogo} /> and <img width="42px" height="34px" src={MatbaRofexLogo} /></div>
    }
    if(isCbot) {
      return <div className="description-logo">Powered by <img src={CmaLogo} /></div>
    }
    if(isMatbaRofex) {
      return <div className="description-logo">Powered by <img width="42px" height="34px" src={MatbaRofexLogo} /></div>
    }
  }
  return (
    <div className="futures-table-pag-content">
      { data && formatData ?
        <div className="futures-table-pag-content__table ">
          <FuturesHistoricalTable data={data && formatData && formatData[currentPage - 1]} />
          {formatData && <CSVLink data={flattened(formatData)} headers={headers} separator={';'} filename={`series-historicas-futuros-${data.Productos[0].NombreProducto}.csv`}><ButtonAgrofy parentCallback={() => { }} title="Descargar" classes="outline-primary small open-sans d-none d-sm-inline-block"></ButtonAgrofy></CSVLink>}
          {currentPage &&
            <PaginationAgrofy
              parentCallback={setCurrentPage}
              currentPage={currentPage}
              amount={amount}
            />
          }
        </div> : null
      }
      {data && formatData ? getLogos(formatData) : null}
    </div>
  );
};

export default FuturesTablePagination;
