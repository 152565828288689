import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {DFPSlotsProvider, AdSlot} from 'react-dfp';
import Alert from 'react-bootstrap/Alert';
import './PopUpBanner.scss';


const PopUpBanner = ({adUnit, sizes, specialNetworkID}) => {
  const [show, setShow] = useState(true);
  const [bannerLoaded, setbannerLoaded] = useState('no-banner');

  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";
  
  const dfpNetworkID = specialNetworkID ?? (process.env.DFP_NETWORK_ID || window.env.DFP_NETWORK_ID);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <React.Fragment>
      {show ?
        <div className={`popup-banner ${bannerLoaded}`} onClick={() => setShow(false)}>
          <Alert onClose={() => setShow(false)} dismissible>
            <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
              <AdSlot
                adUnit={adUnit}
                sizes={sizes}
                onSlotIsViewable={() => setbannerLoaded('with-banner')}
                shouldRefresh={() => false}
              />
            </DFPSlotsProvider>
          </Alert>
        </div>
        : ''
      }
    </React.Fragment>
  );
};

PopUpBanner.propTypes = {
  adUnit: PropTypes.string,
  sizes: PropTypes.array
};

export default PopUpBanner;
