import React, { useState } from 'react';
import { Column, AgroIcon, AgroSpinner } from 'agrofy-ui-components';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import VideoCardImg from '../../atoms/VideoCardImg/VideoCardImg';
import CategoriesModal from '../../organisms/CategoriesModal/CategoriesModal';
import { useStore } from '../../hooks/store';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';

const CategoryVideosTemplate = (props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [currentVideo, setCurrentVideo] = useState();
  const [state, dispatch] = useStore();
  const { data, isHome, isCategoryPage, isFirstTemplate, withModal, onChildClick, className } = props;
  const videosData = data && data.videos;
  const urlCategory = data && data.url;
  const category = data && data.categoryName
  const amountVideos = videosData && videosData.length;

  const manageGTM = label => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'trackEvent',
        eventCategory: 'Videos',
        eventAction: 'Go to Category',
        eventLabel: `${label}`
      }
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  const handleChildClick = (data) => {
    setCurrentVideo(data)
    !withModal && onChildClick(data)
    withModal && setShowModal(!showModal)
  }
  const handleClickPage = (data) => {
    manageGTM(data);
    //when the user changes page, we update the global state
    dispatch('SET_CURRENT_CATEGORY_VIDEO_DATA', videosData[0])
  }
  const videosList = (data) => {
    return isHome ? data.slice(0, 3) : data;
  }

  return (
    <> {!videosData ? <SpinnerContainer><AgroSpinner width='50px' height='50px' variant='primary' /> </SpinnerContainer> :
      <Wrapper lg={12} md={12} sm={12} mt="sm" isCategoryPage={isCategoryPage} isFirstTemplate={isFirstTemplate} className={className}>
        {isHome || isCategoryPage ? <Link onClick={() => handleClickPage(`${data.categoryName}-Top`)} to={`/${data.url}`}>
          <TitleContainer>
            <Title>{data.categoryName}</Title><NewsIcon iname="arrowRight" fill="#63A105" top={true} />
          </TitleContainer>
        </Link> : null}
        <TitleContainer>
          <Subtitle>{data.description}</Subtitle>
        </TitleContainer>
        {showModal && <CategoriesModal videos={videosData} onDismiss={() => setShowModal(!showModal)} selectedVideo={currentVideo} category={data.categoryName} isCategoryPage={isCategoryPage} url={`${data.url}`} />}
        <VideosContainer withTwoCards={amountVideos < 3} isCategoryPage={isCategoryPage}>
          {videosList(videosData).map((item, index) => {
            return <VideoCardImg key={index} data={item} onChildClick={handleChildClick} isModal={false} isCategoryPage={isCategoryPage} isMobile={withModal} />
          })}
        </VideosContainer>
        {isHome && <Link onClick={() => handleClickPage(`${data.categoryName}-Bottom`)} to={`/${data.url}`}><ViewMoreButton className="view-more-btn">{t('view_more')}<NewsIcon width="20px" height="20px" iname="arrowRight" fill="#63A105" /></ViewMoreButton></Link>}
      </Wrapper>}
    </>
  )
}

export default CategoryVideosTemplate;

const VideosContainer = styled.div`
    ${({ theme }) => css`
        @media only screen and (min-width: ${theme.breakpoint.lg}) {
            display: flex;
            justify-content: ${p => p.withTwoCards ? "flex-start" : "space-between"};
            flex-wrap: wrap;
            > a {
                margin-right: ${p => p.withTwoCards ? "70px" : "0"};
            }
            ${p => p.isCategoryPage && css`
                display: grid;
                grid-template-columns: 33.3% 33.3% 33%;
                grid-gap: 2rem;
            `}
        }
  `}
`;
export const SpinnerContainer = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center;
    min-height: 330px;
`;
const Title = styled.h4`
    color: ${props => props.theme.colors.green5};
`;
const Subtitle = styled.label`
        font-size: 16px;
        line-height: 24px;
`;
const TitleContainer = styled.div`
    display: flex;
`
export const ViewMoreButton = styled.button`
    display: flex;
    align-items: center;
    border: none;
    background: none;
    color: ${props => props.theme.colors.green5};
    font-size: 20px;
    margin-left: auto;
    margin-top: 24px;
    padding-bottom: 16px;
    &:hover {
        color: #4F8A03;
    }
    &:focus, &:active {
        outline: none;
    }
    ${({ theme }) => css`
        @media only screen and (min-width: ${theme.breakpoint.lg}) {
            margin-top: 0;
        }
    `}
`;
export const NewsIcon = styled(AgroIcon)`
    margin-top: ${p => p.top ? "3px" : "6px"};
    &:hover {
        color: #4F8A03;
    }
`;
const Wrapper = styled(Column)`
    padding: 24px 16px 0 16px;
    margin-bottom: ${p => p.isCategoryPage && "32px"};
    margin-top: ${p => p.isFirstTemplate ? "16px" : 0};
    max-width: initial;
    ${({ theme }) => css`
        @media only screen and (min-width: ${theme.breakpoint.lg}) {
            max-width: 1200px;
            margin-left:auto;
            margin-right:auto;
            padding: 24px 24px 0 24px;
            &.tv-mas-visto {
                display: none;
            }
        }
    `}
`;

