import React from 'react';
import { parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import './SearchResultCard.scss';
import { AgroExclusiveRegistered } from 'agrofy-ui-components';

const SearchResultCard = ({ item, isDarkMode  }) => {
  const { t } = useTranslation();
  const data = item;

  const monthNames = [
    `${t('months.january')}`,
    `${t('months.february')}`,
    `${t('months.march')}`,
    `${t('months.april')}`,
    `${t('months.may')}`,
    `${t('months.june')}`,
    `${t('months.july')}`,
    `${t('months.august')}`,
    `${t('months.september')}`,
    `${t('months.october')}`,
    `${t('months.november')}`,
    `${t('months.december')}`,
  ];

  const publishDay = parse(
    data.publish.split('T')[0],
    'yyyy-MM-dd',
    new Date()
  ).getDate();
  const publishMonth =
    monthNames[
      parse(data.publish.split('T')[0], 'yyyy-MM-dd', new Date()).getMonth()
    ];
  const publishYear = parse(
    data.publish.split('T')[0],
    'yyyy-MM-dd',
    new Date()
  ).getFullYear();

  return (
    <div
      className={`search-result-page__results-wrapper__results__item__result-card `}
    >
     {data.exclusive && (
        <div className="exclusive-container">
          {isDarkMode ? (
            <AgroExclusiveRegistered variant="dark" />
          ) : (
            <AgroExclusiveRegistered variant="light" />
          )}
        </div>
      )}
      <span className="search-result-page__results-wrapper__results__item__result-card__category">
        {data.category}
      </span>
      <br />
      <span className="search-result-page__results-wrapper__results__item__result-card__date">
        {publishDay} de {publishMonth} de {publishYear}
      </span>
      <br />
      <h5 className="my-1 search-result-page__results-wrapper__results__item__result-card__title">
        {data.title}
      </h5>
      <p className="search-result-page__results-wrapper__results__item__result-card__summary">
        {data.summary}
      </p>
      <hr />
    </div>
  );
};

export default SearchResultCard;
