import React, {useState, useEffect, useContext} from 'react';
import getData from '../../../services/GetData';
import HistoricalDataContainer from '../HistoricalDataContainer';
import {Helmet} from 'react-helmet-async';
import PaginationAgrofy from '../../../atoms/PaginationAgrofy/PaginationAgrofy';
import PhysicalHistoricalDataContent from '../../../organisms/PhysicalHistoricalDataContent/PhysicalHistoricalDataContent';
import SectionTemplate from '../../../templates/sectionTemplate/SectionTemplate';
import {useTranslation} from 'react-i18next';
import withSSR from '../../../withSSR';
import { buildBreadcrumbJson } from './../../../utils/breadcrumbJson';
import { getBreadcrumbData } from './../../../utils/breadcrumbJson';
import {splitUrl} from './../../../utils/stringUtils';
import {gtmInitialize, manageGTMPageName, manageGTMUserStatus} from './../../../utils/GTMDataLayerFuncs';
import { AgroPushNotification } from '../../../components/AgroPushNotification/AgroPushNotification';
import UserContext from '../../../atoms/UserContext/UserContext';

const loader = <div className="loader container p-0"><div className="animated-background" style={{height: '300px'}}/></div>;

const PhysicalHistoricalData = props => {
  const {t} = useTranslation();
  const serverData = props && props.serverData;
  const pathName = props ? props.location.pathname : false;
  const [updatedData, setUpdatedData] = useState(serverData || null);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const amount = (serverData && serverData.pages);
  const templateBanners = [
    {
      adUnit: 'precios_middle_1',
      sizes: [[728, 90], [300, 250], [320, 50]]
    },
    {
      adUnit: 'precios_middle_2',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: 'precios_middle_3',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: 'precios_filmstrip',
      sizes: [[300, 600]]
    }
  ];

  const { mkpUserData, userLoading } = useContext(UserContext);

  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  const fetchData = () => {
    const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
    return getData({url: `${url}/api/News/GetNewsListByCategory?categoryID=31&page=${currentPage - 1}&limit=15&canonical=${pathName}`})
      .then(result => setUpdatedData(result.data));
  };

  useEffect(() => {
    if (updatedData || serverData) {
      const section = document.getElementById('section-template');
      setIsLoading(false);
      if (section && ((serverData !== updatedData) || (currentPage !== previousPage))) {
        setPreviousPage(currentPage);
        fetchData();
        document.getElementById('section-template').scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  }, [currentPage]);

  const breadcrumbArr = [
    {level: 'Agrofy News'},
    {level: 'Granos', urlLevel: splitUrl(pathName, 1)},
    {level: 'Series históricas Físico', urlLevel: pathName.replace(/^\/|\/$/g, '')}
  ];

  useEffect(() => {
    if(window) {
      manageGTMPageName('Home Granos - Físico');
      gtmInitialize();
    }
  }, []);

  return (
    <HistoricalDataContainer breadcrumb={serverData && serverData.breadcrumbNav}>
      <Helmet>
        <title>{serverData && serverData.metaTitle}</title>
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href={serverData && serverData.canonical} />
        <meta name="description" content={serverData && serverData.metaDescription} />
        <script type="application/ld+json">{serverData && serverData.breadcrumb && buildBreadcrumbJson(getBreadcrumbData(serverData.breadcrumb, false))}</script>
      </Helmet>
      <AgroPushNotification location="Section" />
      <div className="physical-historical-data">
        <PhysicalHistoricalDataContent />
        {!serverData ? loader : <SectionTemplate category='Granos' data={updatedData || serverData} sectionTitle={t('keep_reading')} bannersData={templateBanners} />}
        <div className="mb-36">
          {currentPage && amount &&
            <PaginationAgrofy
              parentCallback={setCurrentPage}
              currentPage={currentPage}
              amount={amount}
            />
          }
        </div>
      </div>
    </HistoricalDataContainer>
  );
};

PhysicalHistoricalData.getInitialProps = async ({match, req, res}) => {
  const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
  const pathName = match && match.url;
  try {
    const {
      data
    } = await getData({url: `${url}/api/News/GetNewsListByCategory?categoryID=31&page=0&limit=15&canonical=${pathName}`});
    return {serverData: data};
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(PhysicalHistoricalData);
