import { datadogRum } from '@datadog/browser-rum';
export const datadogRumInitialize = () => {
    datadogRum.init({
      applicationId: 'f4b678a5-8c93-40d0-a4e0-c87e546e8f8a',
      clientToken: 'pub1845a410bc9caff6b69eaf60139c37ad',
      site: 'datadoghq.com',
      service: 'news',
      env: process.env.DD_RUM_ENV || window.env.DD_RUM_ENV,
      sessionSampleRate: 20,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  };