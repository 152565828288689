const data = {
  precios: {
    multiplier: {
      soja: 0.36743,
      trigo: 0.36743,
      maiz: 0.39369,
      aceiteSoja: 22.04586,
      harinaSoja: 1.10229
    },
    options: [
      {
        name: 'Soja', value: 'soja'
      },
      {
        name: 'Trigo', value: 'trigo'
      },
      {
        name: 'Maíz', value: 'maiz'
      },
      {
        name: 'Aceite de Soja', value: 'aceite-soja'
      },
      {
        name: 'Harina de Soja', value: 'harina-soja'
      }
    ],
    labels: {
      soja: {
        m: 'Cvos/U$S x Bush a U$S x TT',
        d: 'U$S x TT a Cvos/U$S x Bush'
      },
      trigo: {
        m: 'Cvos/U$S x Bush a U$S x TT',
        d: 'U$S x TT a Cvos/U$S x Bush'
      },
      maiz: {
        m: 'Cvos/U$S x Bush a U$S x TT',
        d: 'U$S x TT a Cvos/U$S x Bush'
      },
      aceiteSoja: {
        m: 'CvosU$S/Libra a U$S/ton',
        d: 'U$S/ton a CvosU$S/Libra'
      },
      harinaSoja: {
        m: 'U$S/ton corta a U$S/ton',
        d: 'U$S/ton a U$S/ton corta'
      }
    },
    suffix: {
      soja: {
        m: 'U$S',
        d: 'Cvos/U$S'
      },
      trigo: {
        m: 'U$S',
        d: 'Cvos/U$S'
      },
      maiz: {
        m: 'U$S',
        d: 'Cvos/U$S'
      },
      aceiteSoja: {
        m: 'U$S',
        d: 'CvosU$S/Libra'
      },
      harinaSoja: {
        m: 'U$S',
        d: 'U$S'
      }
    }
  },
  produccion: {
    multiplier: {
      soja: 0.027216,
      sorgo: 0.025401,
      trigo: 0.027216,
      maiz: 0.025401,
      aceiteSoja: 0.045359,
      harinaSoja: 0.907185
    },
    options: [
      {
        name: 'Soja', value: 'soja'
      },
      {
        name: 'Sorgo', value: 'sorgo'
      },
      {
        name: 'Trigo', value: 'trigo'
      },
      {
        name: 'Maíz', value: 'maiz'
      },
      {
        name: 'Aceite de Soja', value: 'aceite-soja'
      },
      {
        name: 'Harina de Soja', value: 'harina-soja'
      }
    ],
    labels: {
      soja: {
        m: 'Bushels a toneladas',
        d: 'Toneladas a Bushels'
      },
      sorgo: {
        m: 'Bushels a toneladas',
        d: 'Toneladas a Bushels'
      },
      trigo: {
        m: 'Bushels a toneladas',
        d: 'Toneladas a Bushels'
      },
      maiz: {
        m: 'Bushels a toneladas',
        d: 'Toneladas a Bushels'
      },
      aceiteSoja: {
        m: 'cwt o libra a ton',
        d: 'ton a cwt o libra'
      },
      harinaSoja: {
        m: 'ton corta a ton',
        d: 'ton a ton corta'
      }
    },
    suffix: {
      soja: {
        m: 'TON',
        d: 'BUSH'
      },
      sorgo: {
        m: 'TON',
        d: 'BUSH'
      },
      trigo: {
        m: 'TON',
        d: 'BUSH'
      },
      maiz: {
        m: 'TON',
        d: 'BUSH'
      },
      aceiteSoja: {
        m: 'TON',
        d: 'CWT'
      },
      harinaSoja: {
        m: 'TON',
        d: 'TON CORTA'
      }
    }
  },
  rendimiento: {
    multiplier: {
      soja: 0.672531,
      sorgo: 0.627681,
      trigo: 0.672531,
      maiz: 0.627681,
      aceiteSoja: 0.01120861
    },
    options: [
      {
        name: 'Soja', value: 'soja'
      },
      {
        name: 'Sorgo', value: 'sorgo'
      },
      {
        name: 'Trigo', value: 'trigo'
      },
      {
        name: 'Maíz', value: 'maiz'
      },
      {
        name: 'Aceite de Soja', value: 'aceite-soja'
      }
    ],
    labels: {
      soja: {
        m: 'bush/acre a qq/ha',
        d: 'qq/ha x bush/acre'
      },
      sorgo: {
        m: 'bush/acre a qq/ha',
        d: 'qq/ha x bush/acre'
      },
      trigo: {
        m: 'bush/acre a qq/ha',
        d: 'qq/ha x bush/acre'
      },
      maiz: {
        m: 'bush/acre a qq/ha',
        d: 'qq/ha x bush/acre'
      },
      aceiteSoja: {
        m: 'libra/acre a qq/ha',
        d: 'qq/ha a libra/acre'
      }
    },
    suffix: {
      soja: {
        m: 'QQ/HA',
        d: 'BUSH/ACRE'
      },
      sorgo: {
        m: 'QQ/HA',
        d: 'BUSH/ACRE'
      },
      trigo: {
        m: 'QQ/HA',
        d: 'BUSH/ACRE'
      },
      maiz: {
        m: 'QQ/HA',
        d: 'BUSH/ACRE'
      },
      aceiteSoja: {
        m: 'QQ/HA',
        d: 'LIBRA/ACRE'
      }
    }
  },
  superficie: {
    multiplier: {
      default: 0.40468
    },
    suffix: {
      default: {
        m: 'HAS',
        d: 'ACRE'
      }
    }
  }
};

export default data;
