import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CmaLogo from '../../../images/icons/cma-logo.svg';
import MatbaRofexLogo from '../../../images/icons/matba-rofex-logo.png';
import './TableFutures.scss';

const TableFutures = ({ prices, nameMarket }) => {
  const { t } = useTranslation();
  const loader = <div className="loader container"><div className="animated-background" /></div>;
  
  const setTable = () => {
    return (
      <Table className="table-agrofy is-gray">
        <thead>
          <tr>
            <th className="text-uppercase">{t('positions')}</th>
            <th className="text-uppercase">{t('last')}</th>
            <th className="text-uppercase">{t('variation')} U$</th>
            <th className="text-uppercase">% {t('variation')}</th>
          </tr>
        </thead>
        <tbody>
          {prices.map((val, index) =>
            <tr key={`row-${index}`}>
              <td key={`pos-${index}`}>{val.position}</td>
              <td key={`last-${index}`}>{val.adjust}</td>
              <td key={`var-${index}`}>
                {
                  // eslint-disable-next-line no-nested-ternary
                  <div className={`${parseFloat(val.variation) > 0 ? 'up arrow flex-center d-flex' : parseFloat(val.variation) < 0 ? 'down arrow flex-center d-flex' : 'equal flex-center d-flex'}`}>
                    <label className="percent pr-2 m-0">{val.variation}</label>
                    <label className="indicator m-0">
                      <label className="icon m-0"></label>
                    </label>
                  </div>
                }
              </td>
              <td key={`varpor-${index}`}>
                {
                  !val.variationPercent ? '-' :
                    // eslint-disable-next-line no-nested-ternary
                    <div className={`${parseFloat(val.variationPercent) > 0 ? 'up arrow flex-center d-flex' : parseFloat(val.variationPercent) < 0 ? 'down arrow flex-center d-flex' : 'equal flex-center d-flex'}`}>
                      <label className="percent pr-2 m-0">{val.variationPercent}</label>
                    </div>
                }
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  };

  return (
    <div className="futures-table-section">
      {prices ? setTable() : loader}
      {nameMarket === "CBOT" && <div className="description-logo">Powered by <img src={CmaLogo} /></div>}
      {(nameMarket === "MATBA" || nameMarket === "ROFEX" || nameMarket === "MtR") && <div className="description-logo">Powered by <img width="42px" height="34px" src={MatbaRofexLogo} /></div>} 
    </div>
  );
};

export default TableFutures;
