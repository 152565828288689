export const homePanelsReferenceAR = [
  {
    type: 'QuoteTabs',
    codeName: 'Granos'
  },
  {
    type: 'DailyDollar',
    codeName: "Monedas"
  },
  {
    type: 'DailyWeather',
    codeName: 'Clima'
  }
];

export const homePanelsReferenceBR = [
  {
    type: 'DailyPecuária',
    codeName: 'Pecuária'
  },
  {
    type: 'DailyAgricultura',
    codeName: 'Agricultura'
  },
  {
    type: 'DailyWeather',
    codeName: 'Clima'
  }
];

export const homePanelsFallBackDataAR = [
  {
    "delta": 0,
    "name": "Granos"
  },
  {
    "delta": 1,
    "name": "Monedas"
  },
  {
    "delta": 2,
    "name": "Clima"
  }
];

export const homePanelsFallBackDataBR = [
  {
    "delta": 0,
    "name": "Agricultura"
  },
  {
    "delta": 1,
    "name": "Pecuária"
  },
  {
    "delta": 2,
    "name": "Clima"
  }
];