/* eslint-disable eqeqeq */
import React, {useState, useEffect} from 'react';
import PaginationAgrofy from '../../atoms/PaginationAgrofy/PaginationAgrofy';
import TableBoardHistorical from '../../atoms/Tables/TableBoardHistorical/TableBoardHistorical';
import {CSVLink} from 'react-csv';
import ButtonAgrofy from '../../atoms/ButtonAgrofy/ButtonAgrofy';
import {flattened} from '../../utils/helpers';

const PAGE_AMOUNT = 20;

const BoardTablePagination = ({data}) => {
  const [currentPage, setCurrentPage] = useState(1);
const [previousPage, setPreviousPage] = useState(1);
  const [amount, setAmount] = useState(null);
  const [formatData, setFormatData] = useState(false);

  useEffect(() => {
    if (!(data && data.Mercados)) {
      return;
    }
    setCurrentPage(1);
    setFormatData(formatTableData());
  }, [data]);

  const formatTableData = () => {
    const newData = [];
    data.Mercados.map(market => {
      return market.Productos.map(prod => {
        return prod.Items.map(item => {
          return newData.push({
            date: item.Fecha,
            market: market.Nombre,
            product: prod.Producto,
            price: `${prod.Moneda} ${item.Valor}`
          });
        });
      });
    });
    const count = Math.ceil(newData.length / PAGE_AMOUNT);
    setAmount(count);
    let init = 0;
    const arr = Array(count).fill().map(() => {
      const sub = newData.slice(init, init += PAGE_AMOUNT);
      return sub;
    });
    return arr;
  };
  const headers = [
    {label: 'Fecha', key: 'date'},
    {label: 'Mercado', key: 'market'},
    {label: 'Producto', key: 'product'},
    {label: 'Precio', key: 'price'}
  ];
  
  return (
    <div className="board-table-pag-content">
      { data && formatData ?
        <div className="board-table-pag-content__table ">
          <TableBoardHistorical data={data && formatData && formatData[currentPage - 1]} />
          {formatData && <CSVLink data={flattened(formatData)} headers={headers} separator={';'} filename={'series-historicas-pizarra.csv'}><ButtonAgrofy parentCallback={() => {}} title="Descargar" classes="outline-primary small open-sans d-none d-sm-inline-block csv-export"></ButtonAgrofy></CSVLink>}
          {currentPage &&
            <PaginationAgrofy
              parentCallback={setCurrentPage}
              currentPage={currentPage}
              amount={amount}
            />
          }
        </div> : null
      }
    </div>
  );
};

export default BoardTablePagination;
