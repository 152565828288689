import React from 'react';
import { Lnews, LnewsDark } from 'agrofy-ui-components';
import './WatermarkLogo.scss';

const TableGraphicsWatermarkLogo = () => {

  return (
    <div className="watermarkGraphicContainer">
      <div className="watermarkGraphic">
        <Lnews className="logo-white" height={"100%"} width={"100%"} />
        <LnewsDark className="logo-dark" height={"100%"} width={"100%"} />
      </div>
    </div>
  );
};

export default TableGraphicsWatermarkLogo;