
const filters = {
  destinations: [
    {value: '52', label: 'Alvear'},
    {value: '141', label: 'Bahia Blanca'},
    {value: '263', label: 'Necochea'},
    {value: '66', label: 'Puerto General San Martín'},
    {value: '175', label: 'Ramallo'},
    {value: '478', label: 'Rosario'},
    {value: '479', label: 'San Lorenzo'},
    {value: '479', label: 'Timbues'},
    {value: '-1', label: 'Otros'}
  ],
  products: [
    {value: '3', label: 'Maíz'},
    {value: '2', label: 'Soja'},
    {value: '4', label: 'Trigo'},
    {value: '5', label: 'Girasol'},
    {value: '6', label: 'Sorgo'},
    {value: '9', label: 'Cebada'},
    {value: '42', label: 'Colza'}
  ],
  qualities: [
    {value: '1', label: 'Fábrica'},
    {value: '2', label: 'Cámara'},
    {value: '3', label: 'Exportación'},
    {value: '5', label: 'Molinería'},
    {value: '7', label: 'Consumo'},
    {value: '6', label: 'Otro'}
  ],
  deliveries: [
    {value: '1', label: 'Disponible'},
    {value: '2', label: 'Forward'}
  ],
  currencies: [
    {value: 'pesos', label: 'Peso'},
    {value: 'dolares', label: 'Dólar'}
  ]
};

export default filters;
