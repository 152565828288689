import React from 'react';
import './Loader.scss';

const Loader = ({height, style}) => {
  const defaultHeight = '1050px';
  return (
    <div className="loader container p-0">
      <div className="animated-background" style={{...style, height: height || defaultHeight}}/>
    </div>
  );
};

export default Loader;